import { Flex, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getAuctionsMembersListQueryKey,
  useAuctionsMembersDestroy,
  useAuctionsMembersList,
  useAuctionsRetrieve,
} from "../../../../api/auctions";
import {
  AuctionMemberPolymorphic,
  DefaultAuctionMemberTyped,
} from "../../../../api/model";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import { CustomTable } from "../../../../components/CustomTable";
import { FSTIcon } from "../../../../components/FSTIcon";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import NumberFormat from "../../../../components/NumberFormat";
import { useSelectedAuctionsMembersStore } from "../AuctionDetails";
import EditAuctionMemberFormModal from "./EditAuctionMemberFormModal";

const TenderMembersTable = ({ searchQuery = "" }) => {
  const queryClient = useQueryClient();
  const { auction_id } = useParams();

  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [selectedTenderMember, setSelectedTenderMember] =
    useState<AuctionMemberPolymorphic>();
  const { selectedRows, setSelectedRows, toggledClearRows } =
    useSelectedAuctionsMembersStore();

  const { data: auction } = useAuctionsRetrieve(
    Number.parseInt(auction_id ?? "")
  );
  const { data: auctionMembers, isLoading: isLoadingListMembers } =
    useAuctionsMembersList(Number.parseInt(auction_id ?? ""));

  const deleteAuctionMemberMutation = useAuctionsMembersDestroy({
    mutation: {
      onError: () => {
        toast.error("Failed to delete auction member");
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          getAuctionsMembersListQueryKey(Number.parseInt(auction_id ?? ""))
        );
        toast.success("Auction member deleted successfully");
        setShowConfirmDeletion(false);
      },
    },
  });

  const columns: TableColumn<AuctionMemberPolymorphic>[] = useMemo(
    () => [
      {
        name: "Full Name",
        cell: (row) => row.trader_first_name + " " + row.trader_last_name,
      },
      { name: "Role", cell: (row) => row.trader_role },
      { name: "Email", cell: (row) => row.trader_email, grow: 2 },
      auction?.auction_type === "DefaultAuction"
        ? {
            name: "Share Balance",
            right: true,
            cell: (row) => (
              <NumberFormat
                type="number"
                value={(row as DefaultAuctionMemberTyped).phantom_stocks}
              />
            ),
          }
        : {},
      {
        name: "Credit Balance",
        right: true,
        cell: (row) => (
          <NumberFormat type="currency" value={row.credit_balance} />
        ),
        grow: 2,
      },
      {
        name: "",
        cell: (row) => (
          <Flex gap={3}>
            <FSTIcon
              noBorder
              pointer
              Icon={MdDeleteOutline}
              disabled={selectedRows && selectedRows.length > 0}
              size={20}
              onClick={() => {
                setSelectedTenderMember(row);
                setShowConfirmDeletion(true);
              }}
            />
            <EditAuctionMemberFormModal
              auctionMemberData={row}
              auction_member_type={row.resourcetype}
              disabled={selectedRows && selectedRows.length > 0}
            />
          </Flex>
        ),
      },
    ],
    [selectedRows, auction]
  );

  return (
    <>
      <ConfirmationDialog
        confirmText="Delete"
        isLoading={deleteAuctionMemberMutation.isLoading}
        show={showConfirmDeletion}
        title="Remove User From the Auction"
        body={
          <>
            Are you sure you want to remove user{" "}
            <Text as="b" color="blue-primary">
              {selectedTenderMember?.trader_email}
            </Text>{" "}
            from the auction?
          </>
        }
        handleClose={() => {
          setShowConfirmDeletion(false);
        }}
        handleSubmit={() => {
          deleteAuctionMemberMutation.mutate({
            id: Number.parseInt(auction_id ?? ""),
            memberId: selectedTenderMember!.id.toString(),
          });
        }}
      />
      {isLoadingListMembers || auctionMembers == undefined ? (
        <LoadingSpinner />
      ) : (
        <CustomTable
          selectableRows
          clearSelectedRows={toggledClearRows}
          columns={columns}
          data={auctionMembers.filter(
            (member) =>
              member.trader_first_name
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              member.trader_last_name
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              member.trader_email
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
          )}
          onSelectedRowsChange={(state) => {
            setSelectedRows(
              state.selectedCount > 0 ? state.selectedRows : false
            );
          }}
        />
      )}
    </>
  );
};

export default TenderMembersTable;
