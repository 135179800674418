import { Heading, Stack, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { FormBackground } from "../../components/FormBackground";

const EmailSentPage = () => {
  const location = useLocation();

  return (
    <FormBackground>
      <Stack align="center" maxW="md" mt="24px" spacing="32px">
        <Heading
          as="h1"
          color="gray.50"
          fontSize="18px"
          fontWeight="bold"
          textAlign="center"
        >
          Email Successfully Sent to{" "}
          <Text as="span" color="primary.500">
            {location.state.email}
          </Text>
        </Heading>
        <Text color="gray.50" fontSize="md" textAlign="center">
          You will receive an email with a login link if the email you entered
          was registered on the system.
        </Text>
      </Stack>
    </FormBackground>
  );
};

export default EmailSentPage;
