type Time = {
  day?: number;
  hour?: number;
  minute: number;
  second: number;
};

export const formatDuration = (ms: number) => {
  if (ms < 0) ms = -ms;
  const time: Time = {
    day: Math.floor(ms / 86400000),
    hour: Math.floor(ms / 3600000) % 24,
    minute: Math.floor(ms / 60000) % 60,
    second: Math.floor(ms / 1000) % 60,
    // millisecond: Math.floor(ms) % 1000,
  };

  // removing trailing zero values (eg. 30 minutes 0 seconds) instead of (0 days 0 hours 30 minutes 0 seconds)
  if (time.day === 0) {
    delete time.day;
    if (time.hour === 0) {
      delete time.hour;
    }
  }

  return Object.entries(time)
    .map(([key, val]) => `${val}${key[0]}`)
    .join(" ");
};

export const stringDurationToObj = (duration: string) => {
  // doesn't handle seconds or milliseconds
  // "2 00:00:00" -> { day: 2, hour: 0, minute: 0 }
  // "00:00:00" -> { date: 0, hour: 0, minute: 0 }
  const parts = duration.split(" ");
  let days, time, hours, minutes;

  if (parts.length === 2) {
    // duration includes days
    [days, time] = parts;
    [hours, minutes] = time.split(":");
  } else {
    // duration does not include days
    days = "0";
    [hours, minutes] = parts[0].split(":");
  }
  return {
    day: parseInt(days, 10),
    hour: parseInt(hours, 10),
    minute: parseInt(minutes, 10),
  };
};
