/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ErrorResponse401,
  ErrorResponse404,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
  Shareholder,
  ShareholdersListErrorResponse400,
  ShareholdersRetrieveErrorResponse400,
} from "./model";
import { apiCustomInstance } from "../services/apiService";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const shareholdersList = (
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<Shareholder[]>(
    { url: `/shareholders/`, method: "GET", signal },
    options
  );
};

export const getShareholdersListQueryKey = () => {
  return [`/shareholders/`] as const;
};

export const getShareholdersListQueryOptions = <
  TData = Awaited<ReturnType<typeof shareholdersList>>,
  TError =
    | ShareholdersListErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof shareholdersList>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getShareholdersListQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof shareholdersList>>
  > = ({ signal }) => shareholdersList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof shareholdersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ShareholdersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof shareholdersList>>
>;
export type ShareholdersListQueryError =
  | ShareholdersListErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useShareholdersList = <
  TData = Awaited<ReturnType<typeof shareholdersList>>,
  TError =
    | ShareholdersListErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof shareholdersList>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShareholdersListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const shareholdersRetrieve = (
  id: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<Shareholder>(
    { url: `/shareholders/${id}/`, method: "GET", signal },
    options
  );
};

export const getShareholdersRetrieveQueryKey = (id: string) => {
  return [`/shareholders/${id}/`] as const;
};

export const getShareholdersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof shareholdersRetrieve>>,
  TError =
    | ShareholdersRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof shareholdersRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getShareholdersRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof shareholdersRetrieve>>
  > = ({ signal }) => shareholdersRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof shareholdersRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ShareholdersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof shareholdersRetrieve>>
>;
export type ShareholdersRetrieveQueryError =
  | ShareholdersRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useShareholdersRetrieve = <
  TData = Awaited<ReturnType<typeof shareholdersRetrieve>>,
  TError =
    | ShareholdersRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof shareholdersRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShareholdersRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
