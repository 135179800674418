export interface ObjWithName {
  first_name?: string;
  last_name?: string;
  full_name?: string;
  email?: string;
}

export const parseName = (objWithName?: ObjWithName) => {
  if (!objWithName) return "—";
  if (objWithName.full_name) return objWithName.full_name;
  return [objWithName.first_name ?? "", objWithName.last_name ?? ""].join(" ");
};

export const parseEmail = (objWithName?: ObjWithName) => {
  if (!objWithName) return "—";
  return objWithName.email ?? "—";
};

export const parseNameOrEmail = (objWithName?: ObjWithName) => {
  if (!objWithName) return "—";
  return parseName(objWithName) || parseEmail(objWithName);
};

export const tableColumnPassClickEvents = { "data-tag": "allowRowEvents" };

export interface Dict<T = string> {
  [Key: string]: T;
}

export const addHttps = (url: string) => {
  if (!url) return url;
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url;
  }
  return url;
};
