import {
  Box,
  Card,
  CardBody,
  Flex,
  Icon,
  MenuItem,
  Spacer,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getGetAuctionAggregateResolutionQueryKey,
  getGetAuctionDummyResolutionQueryKey,
  useAuctionsDefaultResolutionList,
  useAuctionsDutchResolutionList,
  useAuctionsPublishResolutionRetrieve,
  useAuctionsRetrieve,
  useDownloadAuctionResolutionAggregate,
  useGetAuctionAggregateResolution,
  useGetAuctionDummyResolution,
  useGetAuctionFullResolution,
} from "../../../api/auctions";
import {
  AuctionDummyResolution,
  AuctionFullResolutionData,
  AuctionResolution,
} from "../../../api/model";
import { CustomTable } from "../../../components/CustomTable";
import { DropdownMenu } from "../../../components/DropdownMenu";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import NumberFormat from "../../../components/NumberFormat";
import TreasuryTag from "../../../components/TreasuryTag";
import { auctionTypes } from "../../../utils/constants";
import { parseTrader } from "../AdminTransactionsPage";
import AggregateResolutionTab from "./AggregateResolutionTab";

const AuctionSummary = () => {
  const queryClient = useQueryClient();

  const [tabIndex, setTabIndex] = useState(0);
  const [isBlurred] = useState(false);

  const { auction_id } = useParams();

  if (auction_id === undefined) {
    return null;
  }

  const { data: auctionData } = useAuctionsRetrieve(
    Number.parseInt(auction_id ?? "")
  );

  const {
    data,
    isLoading,
  }: { data: AuctionDummyResolution[] | undefined; isLoading: boolean } =
    useGetAuctionDummyResolution(Number.parseInt(auction_id ?? ""), {
      query: {
        enabled: isBlurred,
      },
    });

  const { data: fullData, isLoading: fullIsLoading } =
    useGetAuctionFullResolution(Number.parseInt(auction_id ?? ""), {
      query: {
        enabled: !isBlurred,
      },
    });

  const aggregate = useGetAuctionAggregateResolution(
    Number.parseInt(auction_id ?? "")
  );

  const { refetch: publishResolution, isSuccess: publishSuccess } =
    useAuctionsPublishResolutionRetrieve(Number.parseInt(auction_id ?? ""), {
      query: {
        enabled: false,
        onSuccess: () => {
          toast.success("Resolution published successfully");
        },
        onError: (err) => {
          if (!err.errors[0].detail) {
            toast.error("Failed to publish resolution");
          }
        },
      },
    });

  const downloadResolutionOptions = {
    query: {
      enabled: false,
      onSuccess: (data: AuctionResolution[]) => {
        window.open(
          `${import.meta.env.VITE_API_ROOT_URL}` + data[0].transactions_file,
          "_blank"
        );
      },
      onError: () => {
        toast.error("Failed to download resolution");
      },
    },
  };

  const { refetch: downloadDutchFullResolution } =
    useAuctionsDutchResolutionList(
      Number.parseInt(auction_id ?? ""),
      downloadResolutionOptions
    );

  const { refetch: downloadDefaultFullResolution } =
    useAuctionsDefaultResolutionList(
      Number.parseInt(auction_id ?? ""),
      downloadResolutionOptions
    );

  const { refetch: downloadAggregatedResolution } =
    useDownloadAuctionResolutionAggregate(Number.parseInt(auction_id ?? ""), {
      query: {
        onError: () => {
          toast.error("Failed to download resolution");
        },
        enabled: false,
        onSuccess: (data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "aggregated-transactions.csv");
          document.body.appendChild(link);
          link.click();
        },
      },
    });

  // DISABLE_FEES
  // useEffect(() => {
  //   if (
  //     aggregate.data?.aggregate_has_unsettled ||
  //     !auctionData?.has_paid_for_resolution
  //   ) {
  //     setIsBlurred(true);
  //   } else {
  //     setIsBlurred(false);
  //   }
  // }, [aggregate.data, auctionData?.has_paid_for_resolution]);

  const columns: TableColumn<AuctionDummyResolution>[] = [
    {
      name: "Buyer Name",
      cell: (row) => {
        if (row.is_treasury) {
          return <TreasuryTag />;
        }
        return row.full_name;
      },
    },
    {
      name: "Seller Name",
      cell: () => (
        <Box style={{ filter: "blur(3px)", userSelect: "none" }}>Seller</Box>
      ),
    },
    {
      name: "Quantity",
      cell: () => (
        <Box style={{ filter: "blur(3px)", userSelect: "none" }}>000</Box>
      ),
    },
    {
      name: "Price per Unit",
      cell: () => (
        <Box style={{ filter: "blur(3px)", userSelect: "none" }}>$00.00</Box>
      ),
    },
    {
      name: "Fee",
      cell: () => (
        <Box style={{ filter: "blur(3px)", userSelect: "none" }}>$00.00</Box>
      ),
    },
    {
      name: "Seller Gets",
      cell: () => (
        <Box style={{ filter: "blur(3px)", userSelect: "none" }}>$0000</Box>
      ),
    },
    {
      name: "Buyer Pays",
      right: true,
      cell: (row) => <NumberFormat type="currency" value={row.buyer_pays} />,
    },
    {
      name: "Buyers Due Amount",
      right: true,
      cell: (row) => <NumberFormat type="currency" value={row.amount_due} />,
    },
  ];

  const fullColumns: TableColumn<AuctionFullResolutionData>[] = [
    {
      name: "Buyer",
      cell: (row) => {
        return parseTrader({
          trader: {
            email: row.buyer_email,
            full_name: row.buyer_name,
          },
          is_buyback: row.is_buyer_treasury,
        });
      },
    },
    {
      name: "Seller",
      cell: (row) => {
        return parseTrader({
          trader: {
            email: row.seller_email,
            full_name: row.seller_name,
          },
          is_buyback: row.is_seller_treasury,
        });
      },
    },
    {
      name: "Quantity",
      cell: (row) => row.quantity,
    },
    {
      name: "Price per Unit",
      right: true,
      cell: (row) => (
        <NumberFormat type="currency" value={row.price_per_unit} />
      ),
    },
    // # DISABLE_FEES
    // {
    //   name: "Fee",
    //   right: true,
    //   cell: (row) => <NumberFormat type="currency" value={row.order_fee} />,
    // },
    // {
    //   name: "Seller Gets",
    //   right: true,
    //   cell: (row) => <NumberFormat type="currency" value={row.seller_gets} />,
    // },
    // {
    //   name: "Buyer Pays",
    //   right: true,
    //   cell: (row) => <NumberFormat type="currency" value={row.buyer_pays} />,
    // },
    {
      name: "Total",
      right: true,
      cell: (row) => <NumberFormat type="currency" value={row.buyer_pays} />,
    },
    {
      name: "Buyer Due Amount",
      right: true,
      cell: (row) => <NumberFormat type="currency" value={row.amount_due} />,
    },
  ];

  return (
    <Card>
      <CardBody>
        <Tabs
          variant="unstyled"
          onChange={(index) => {
            setTabIndex(index);
            if (index === 1) {
              queryClient.invalidateQueries(
                getGetAuctionDummyResolutionQueryKey(
                  Number.parseInt(auction_id ?? "")
                )
              );
              queryClient.invalidateQueries(
                getGetAuctionAggregateResolutionQueryKey(
                  Number.parseInt(auction_id ?? "")
                )
              );
            }
          }}
        >
          <Flex alignItems="center" gap="2">
            <TabList>
              <Tab>Aggregate Resolution</Tab>
              <Tab>Resolution</Tab>
            </TabList>
            <Spacer />
            <Flex>
              {/* DISABLE_FEES */}
              {/* <PaymentModal /> */}
              <DropdownMenu title="Resolution">
                {/* DISABLE_FEES */}
                {/* <MenuItem
                  isDisabled={
                    auctionData?.resolution_status === "No Resolution"
                  }
                  onClick={() => {
                    if (auctionData?.has_paid_for_resolution) {
                      if (auctionData?.auction_type === auctionTypes.default) {
                        downloadDefaultFullResolution();
                      } else {
                        downloadDutchFullResolution();
                      }
                    } else {
                      downloadAggregatedResolution();
                    }
                  }}
                >
                  {auctionData?.has_paid_for_resolution
                    ? "Download resolution"
                    : "Download resolution summary"}
                </MenuItem>
                <MenuItem
                  isDisabled={
                    !auctionData?.has_paid_for_resolution ||
                    auctionData?.has_a_published_resolution ||
                    publishSuccess
                  }
                  onClick={() => {
                    publishResolution();
                  }}
                >
                  Publish Resolution
                </MenuItem> */}
                <MenuItem
                  isDisabled={
                    auctionData?.resolution_status === "No Resolution"
                  }
                  onClick={() => {
                    downloadAggregatedResolution();
                  }}
                >
                  Download resolution summary
                </MenuItem>
                <MenuItem
                  isDisabled={
                    auctionData?.resolution_status === "No Resolution"
                  }
                  onClick={() => {
                    if (auctionData?.auction_type === auctionTypes.default) {
                      downloadDefaultFullResolution();
                    } else {
                      downloadDutchFullResolution();
                    }
                  }}
                >
                  Download resolution
                </MenuItem>
                <MenuItem
                  isDisabled={
                    // DISABLE_FEES
                    // !auctionData?.has_paid_for_resolution ||
                    aggregate.data?.aggregate_has_unsettled ||
                    auctionData?.has_a_published_resolution ||
                    publishSuccess
                  }
                  onClick={() => {
                    publishResolution();
                  }}
                >
                  Publish Resolution
                </MenuItem>
              </DropdownMenu>
            </Flex>
          </Flex>
          <TabIndicator
            bg="blue-primary"
            borderRadius="1px"
            height="2px"
            mt="-1.5px"
          />
          {/* DISABLE_FEES */}
          {/* {tabIndex === 1 &&
          (aggregate.data?.aggregate_has_unsettled ||
            !auctionData?.has_paid_for_resolution) ? (
            <Flex
              align="center"
              color="gray.500"
              flex={1}
              justifyContent="center"
              mt="4"
            >
              <Icon
                as={AiOutlineInfoCircle}
                boxSize="20px"
                color="error.600"
                mr="3"
              />
              All columns will become visible once the auction fee is settled
            </Flex>
          ) : undefined} */}
          {aggregate.data?.can_settle &&
            aggregate.data?.aggregate_has_unsettled && (
              <Flex
                align="center"
                color="gray.500"
                flex={1}
                justifyContent="center"
                mt="4"
              >
                <Icon
                  as={AiOutlineInfoCircle}
                  boxSize="20px"
                  color="error.600"
                  mr="3"
                />
                You will be allowed to publish the resolution once the all the
                transactions are settled
              </Flex>
            )}
          {tabIndex === 0 &&
          !aggregate.data?.can_settle &&
          aggregate.data?.aggregate_has_unsettled ? (
            <Flex
              align="center"
              color="gray.500"
              flex={1}
              justifyContent="center"
              mt="4"
            >
              <Icon
                as={AiOutlineInfoCircle}
                boxSize="20px"
                color="error.600"
                mr="3"
              />
              Trader balance settlement will be available once the auction ends
            </Flex>
          ) : undefined}
          <TabPanels>
            <TabPanel>
              <AggregateResolutionTab />
            </TabPanel>
            <TabPanel>
              {isBlurred ? (
                isLoading || data === undefined ? (
                  <LoadingSpinner />
                ) : (
                  <CustomTable columns={columns} data={data} />
                )
              ) : fullIsLoading || fullData === undefined ? (
                <LoadingSpinner />
              ) : (
                <CustomTable
                  columns={fullColumns}
                  data={fullData.full_resolution}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
};

export default observer(AuctionSummary);
