import { Center, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

import { useInvestorDownloadFile } from "../../api/companies";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { FSTIcon } from "../../components/FSTIcon";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { rRoot } from "../../routes/routes";
import NotFoundPage from "../NotFound/NotFoundPage";

export default function InvestResponseFileDownloadLink() {
  const navigate = useNavigate();
  const splat = useParams()["*"];
  const { companyId } = useParams();

  const { refetch } = useInvestorDownloadFile(companyId ?? "", splat ?? "", {
    query: {
      enabled: false,
      onError: () => {
        onOpen();
      },
    },
  });
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    refetch().then((res) => {
      if (res.data) {
        window.opener = null;
        window.open("", "_self");
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", splat?.split("/").pop() ?? "");
        document.body.appendChild(link);
        link.click();
        // window.close();
      }
    });
  }, []);

  if (isOpen) {
    return (
      <>
        <ConfirmationDialog
          body="Unauthorized access or invalid link."
          confirmText="Go to home page"
          handleClose={onClose}
          icon={<FSTIcon withRing Icon={BiErrorCircle} />}
          isLoading={false}
          show={isOpen}
          title="Invalid Link"
          handleSubmit={() => {
            navigate(rRoot);
          }}
        />

        <NotFoundPage />
      </>
    );
  }
  return (
    <>
      <Center fontWeight="bold" mb="4">
        Downloading
      </Center>
      <LoadingSpinner fullHeight />
    </>
  );
}
