import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardBody,
  Collapse,
  Icon,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { IconType } from "react-icons";
import {
  FaCcAmex,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaRegCreditCard,
} from "react-icons/fa6";
import { FiExternalLink } from "react-icons/fi";
import {
  TbStack,
  TbStack2,
  TbStack2Filled,
  TbStackBackward,
  TbStackFilled,
  TbStackForward,
} from "react-icons/tb";
import { useIntl } from "react-intl";
import { useCompaniesRetrieve } from "src/api/companies";
import {
  useSubscribeCancelSubscriptionCreate,
  useSubscribeCancelSubscriptionPreviewCreate,
  useSubscribeChangeSubscription2Create,
  // useSubscribeChangeSubscriptionCreate,
  useSubscribeChangeSubscriptionPreviewCreate,
  useSubscribePortalCreate,
} from "src/api/subscribe";
import ConditionalWrapper from "src/components/ConditionalWrapper";
import { ConfirmationDialog } from "src/components/ConfirmationDialog";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import NumberFormat from "src/components/NumberFormat";
import { SpinnerButton } from "src/components/SpinnerButton";
import { AuthService } from "src/services";
import { useOnboardingStore } from "src/stores";
import { create } from "zustand";

import { BillingSelect } from "./BillingSelect";

export const useConfirmationModalStore = create<{
  showChangedSubscriptionModal: boolean;
  showChangedSubscriptionModalError: boolean;
  openChangedSubscriptionModal: () => void;
  closeChangedSubscriptionModal: () => void;
  openChangedSubscriptionModalError: () => void;
  closeChangedSubscriptionModalError: () => void;
}>((set) => ({
  showChangedSubscriptionModal: false,
  showChangedSubscriptionModalError: false,

  openChangedSubscriptionModal: () =>
    set({ showChangedSubscriptionModal: true }),
  closeChangedSubscriptionModal: () =>
    set({ showChangedSubscriptionModal: false }),
  openChangedSubscriptionModalError: () =>
    set({ showChangedSubscriptionModalError: true }),
  closeChangedSubscriptionModalError: () =>
    set({ showChangedSubscriptionModalError: false }),
}));

export const FSTPricingPlans = [
  {
    index: 101,
    planName: "Founding",
    price: "1",
    users: 5,
    description: "Includes up to 5 users, Company founding team.",
    shortDescription: "Company founding",
    icon: TbStack,
  },
  {
    index: 102,
    planName: "Seed Stage",
    price: "10",
    users: 10,
    description: "Includes up to 10 users, Startups raising their seed round.",
    shortDescription: "Startups raising their seed round",
    icon: TbStackFilled,
  },
  {
    index: 103,
    planName: "Early Stage",
    price: "100",
    users: 100,
    description:
      "Includes up to 100 users, Startups raising Series A or B rounds.",
    shortDescription: "Startups raising Series A or B rounds",
    icon: TbStackForward,
  },
  {
    index: 104,
    planName: "Growth Stage",
    price: "1000",
    users: 200,
    description:
      "Up to 200 individual users, Companies raising late-stage venture capital (Series C or D).",
    shortDescription:
      "Companies raising later-stage venture capital (series C or D)",
    icon: TbStackBackward,
  },
  {
    index: 105,
    planName: "Expansion Stage",
    price: "10000",
    users: 1000,
    description:
      "Up to 1000 individual users, Mid-sized private companies with substantial revenue.",
    shortDescription: "Mid-sized private companies with substantial revenue",
    icon: TbStack2,
  },
  {
    index: 99,
    planName: "Mature Stage",
    users: 100000,
    isMore: true,
    description: "Unlimited individual users, Large private corporations.",
    shortDescription: "Large private corporations",
    icon: TbStack2Filled,
  },
];

export const FSTYearlyPricingPlans: typeof FSTPricingPlans =
  FSTPricingPlans.map((plan) => ({
    ...plan,
    index: plan.index + 100,
    price: (Number(plan.price) * 10).toString(),
  })) as (
    | {
        index: number;
        planName: string;
        price: string;
        users: number;
        description: string;
        shortDescription: string;
        icon: IconType;
      }
    | {
        index: number;
        planName: string;
        price: string;
        users: number;
        description: string;
        shortDescription: string;
        icon: IconType;
      }
  )[];

const CompanyProfileForm = () => {
  const intl = useIntl();

  const [isImmediate, setIsImmediate] = useState(false);
  const [isExternalLink, setIsExternalLink] = useState(false);
  const [pendingPlanUpdate, setPendingPlanUpdate] = useState<string | null>("");
  const [isYearly, setIsYearly] = useState(false);

  const formatDate = useCallback(
    (date: Date | string, long = false) => {
      return intl.formatDate(date, {
        day: "2-digit",
        month: long ? "long" : "short",
        year: "numeric",
      });
    },
    [intl]
  );

  const { toggleCompanySubscription } = useOnboardingStore();

  const { openChangedSubscriptionModal, openChangedSubscriptionModalError } =
    useConfirmationModalStore();

  const {
    showChangedSubscriptionModal,
    closeChangedSubscriptionModal,
    showChangedSubscriptionModalError,
    closeChangedSubscriptionModalError,
  } = useConfirmationModalStore();
  const {
    data: companyData,
    isFetching,
    refetch: refetchCompany,
    status: companyFetchStatus,
  } = useCompaniesRetrieve(AuthService.getUserCompany());

  const {
    isOpen: isOpenCancelSubscription,
    onOpen: onOpenCancelSubscription,
    onClose: onCloseCancelSubscription,
  } = useDisclosure();

  const {
    isOpen: isOpenChangeSubscription,
    onOpen: onOpenChangeSubscription,
    onClose: onCloseChangeSubscription,
  } = useDisclosure();

  const createPortalSession = useSubscribePortalCreate();

  const cancelSubscriptionPreview =
    useSubscribeCancelSubscriptionPreviewCreate();

  const changeSubscriptionPreview = useSubscribeChangeSubscriptionPreviewCreate(
    {
      mutation: {
        onSuccess: (data) => {
          if ((data as unknown as { url: string }).url) {
            window.location.href = (data as unknown as { url: string }).url;
            setIsExternalLink(true);
          }
        },
        onError: () => {
          openChangedSubscriptionModalError();
        },
      },
    }
  );

  const changeSubscription = useSubscribeChangeSubscription2Create({
    mutation: {
      mutationKey: ["change-subscription"],
      onSuccess: (data) => {
        if ((data as unknown as { status: string }).status === "past_due") {
          openChangedSubscriptionModalError();
          refetchCompany();
          onCloseChangeSubscription();
        }
        if ((data as unknown as { url: string }).url) {
          window.location.href = (data as unknown as { url: string }).url;
        } else if (
          (data as unknown as { status: string }).status === "success"
        ) {
          refetchCompany();
          toggleCompanySubscription(AuthService.getUserCompany(), true);
          openChangedSubscriptionModal();
          onCloseChangeSubscription();
        }
      },
    },
  });

  const cancelSubscription = useSubscribeCancelSubscriptionCreate();

  const handleUpdateSubscription = useCallback(
    (plan: string) => {
      setPendingPlanUpdate(plan);
      changeSubscriptionPreview.mutate({
        data: {
          companyUUID: AuthService.getUserCompany(),
          plan_ix: plan,
        },
      });
      onOpenChangeSubscription();
    },
    [onOpenChangeSubscription]
  );

  useEffect(() => {
    if (companyFetchStatus === "success") {
      if (companyData?.is_annual_billing) {
        setIsYearly(true);
      }
      if (companyData?.will_cancel) {
        setIsImmediate(true);
      }
    }
  }, [companyFetchStatus]);

  return (
    <Stack>
      <ConfirmationDialog
        cancelText="Cancel"
        confirmText={isExternalLink ? "Proceed" : "Confirm"}
        show={isOpenChangeSubscription}
        showCancelButton={true}
        showCloseButton={false}
        title="Company subscription change confirmation"
        body={
          changeSubscriptionPreview.isLoading ? (
            <LoadingSpinner fullHeight />
          ) : isExternalLink ? (
            <>
              <Text lineHeight="20px" mb="0" pb="0">
                You will be redirected to the payment page to complete the
                change of your subscription.
              </Text>
              <Alert borderRadius="8px" mt="4" status="info">
                <AlertIcon />
                Please note that you might need to refresh the page after
                successful payment to see the changes.
              </Alert>
            </>
          ) : (
            <Text lineHeight="20px" mb="0" pb="0">
              You will be refunded for the remaining period of your
              subscription.
              <br />
              Refund amount:{" "}
              {changeSubscriptionPreview.data?.refund_amount ? (
                <NumberFormat
                  type="currency"
                  value={
                    (Number.parseInt(
                      changeSubscriptionPreview.data?.refund_amount
                    ) /
                      100) *
                    -1
                  }
                />
              ) : (
                0
              )}{" "}
              USD
              <br />
              Refund to{" "}
              <Icon
                boxSize={6}
                ml="1"
                verticalAlign="middle"
                as={
                  changeSubscriptionPreview.data?.card_brand === "visa"
                    ? FaCcVisa
                    : changeSubscriptionPreview.data?.card_brand ===
                        "mastercard"
                      ? FaCcMastercard
                      : changeSubscriptionPreview.data?.card_brand === "amex"
                        ? FaCcAmex
                        : changeSubscriptionPreview.data?.card_brand === "jcb"
                          ? FaCcJcb
                          : FaRegCreditCard
                }
              />{" "}
              card ending in {changeSubscriptionPreview.data?.card_last4}.
              <br />
              It may take 5-10 business days for the refund to be processed
              <br />
              <br />
              You will be charged for the new plan immediately and will be
              charged:{" "}
              {changeSubscriptionPreview.data?.new_amount ? (
                <NumberFormat
                  type="currency"
                  value={
                    Number.parseInt(
                      changeSubscriptionPreview.data?.new_amount
                    ) / 100
                  }
                />
              ) : (
                0
              )}{" "}
              USD
              <br />
              Amount will be charged to{" "}
              <Icon
                boxSize={6}
                ml="1"
                verticalAlign="middle"
                as={
                  changeSubscriptionPreview.data?.card_brand === "visa"
                    ? FaCcVisa
                    : changeSubscriptionPreview.data?.card_brand ===
                        "mastercard"
                      ? FaCcMastercard
                      : changeSubscriptionPreview.data?.card_brand === "amex"
                        ? FaCcAmex
                        : changeSubscriptionPreview.data?.card_brand === "jcb"
                          ? FaCcJcb
                          : FaRegCreditCard
                }
              />{" "}
              card ending in {changeSubscriptionPreview.data?.card_last4}.
            </Text>
          )
        }
        handleClose={() => {
          onCloseChangeSubscription();
          setIsExternalLink(false);
        }}
        handleSubmit={() => {
          if (isExternalLink) {
            refetchCompany();
            onCloseChangeSubscription();
            setIsExternalLink(false);
          } else {
            changeSubscription.mutate({
              data: {
                companyUUID: AuthService.getUserCompany(),
                date:
                  changeSubscriptionPreview.data?.date ??
                  Math.floor(Date.now() / 1000),
                plan_ix:
                  companyData?.is_subscribed && pendingPlanUpdate
                    ? pendingPlanUpdate
                    : "",
              },
            });
          }
        }}
        isLoading={
          changeSubscriptionPreview.isLoading || changeSubscription.isLoading
        }
      />
      <Stack spacing="24px">
        <Card>
          <CardBody className="companyCurrentPlanCard">
            <Stack direction={{ base: "column", md: "row" }} spacing="32px">
              <Text pt="4">Current Plan</Text>
              <Stack direction="column" spacing="24px">
                <Skeleton borderRadius="8px" isLoaded={!isFetching}>
                  <BillingSelect
                    isDisabled={companyData?.will_cancel}
                    isYearly={isYearly}
                    mode="profile"
                    selectedValue={companyData?.is_subscribed.toString() ?? ""}
                    onChangeCallback={(plan) => {
                      handleUpdateSubscription(plan.value);
                    }}
                  />
                  <Switch
                    cursor="pointer"
                    data-testid="is_yearly"
                    isChecked={isYearly}
                    mt="4"
                    hidden={
                      !!companyData?.is_subscribed || companyData?.is_past_due
                    }
                    onChange={() => {
                      setIsYearly(!isYearly);
                    }}
                  >
                    Annual billing
                  </Switch>
                  <Text
                    fontSize="sm"
                    hidden={!companyData?.is_subscribed}
                    ml="2"
                    opacity="0.7"
                  >
                    {companyData?.will_cancel ? "Cancels" : "Renews"} on{" "}
                    {companyData?.billing_period_end
                      ? formatDate(
                          new Date(companyData.billing_period_end),
                          true
                        )
                      : "N/A"}
                  </Text>
                </Skeleton>
              </Stack>
            </Stack>
            {companyData?.will_cancel && (
              <Alert borderRadius="8px" mt="8" status="info" w="90%">
                <AlertIcon />
                Your subscription will be canceled at on{" "}
                {companyData?.billing_period_end
                  ? formatDate(new Date(companyData.billing_period_end), true)
                  : "N/A"}
                . You can renew your subscription at any time before that date
                but you can't upgrade or downgrade your plan.
              </Alert>
            )}
          </CardBody>
        </Card>
        <Card>
          {companyData?.is_past_due && (
            <Alert borderRadius="8px" status="error">
              <AlertIcon />
              You have an outstanding invoice. Please update your billing
              details to continue using the platform. Click on "Manage
              Subscription" button below to see your current invoice and to
              update your billing details.
            </Alert>
          )}
          <CardBody className="companyInvoicesAndCancelCard">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              spacing="32px"
            >
              <Text>Billing and Invoicing</Text>
              <SpinnerButton
                loading={createPortalSession.isLoading || !companyData}
                loadingText="Manage Subscription"
                variant="outline"
                isDisabled={
                  !companyData || !companyData?.can_open_stripe_portal
                }
                onClick={() => {
                  createPortalSession.mutate(
                    {
                      data: {
                        companyUUID: AuthService.getUserCompany(),
                      },
                    },
                    {
                      onSuccess: (data) => {
                        const url = (data as unknown as { url: string }).url;
                        const link = document.createElement("a");
                        link.href = url;
                        document.body.appendChild(link);
                        link.click();
                      },
                    }
                  );
                }}
              >
                <Box mr="4">
                  <FiExternalLink />
                </Box>
                Manage Subscription
              </SpinnerButton>
            </Stack>
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              pt="4"
              spacing="32px"
            >
              <Text>Cancel Subscription</Text>
              <SpinnerButton
                isDisabled={!companyData || !companyData?.is_subscribed}
                loading={cancelSubscriptionPreview.isLoading || !companyData}
                loadingText="Cancel Subscription"
                variant="outline-danger"
                onClick={() => {
                  cancelSubscriptionPreview.mutate(
                    {
                      data: {
                        companyUUID: AuthService.getUserCompany(),
                      },
                    },
                    {
                      onSuccess: () => {
                        onOpenCancelSubscription();
                      },
                    }
                  );
                }}
              >
                Cancel Subscription
              </SpinnerButton>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
      <ConfirmationDialog
        confirmText="Okay"
        handleClose={closeChangedSubscriptionModal}
        handleSubmit={closeChangedSubscriptionModal}
        show={showChangedSubscriptionModal}
        showCancelButton={false}
        showCloseButton={false}
        title="Company Subscription Updated Successfully"
        body={
          <>
            Your subscription has been updated successfully. You click on{" "}
            <Text as="b" color="blue-primary">
              "Manage Subscription"
            </Text>{" "}
            button to see your new invoice and billing details.
          </>
        }
      />
      <ConfirmationDialog
        confirmText="Okay"
        handleClose={closeChangedSubscriptionModalError}
        handleSubmit={closeChangedSubscriptionModalError}
        show={showChangedSubscriptionModalError}
        showCancelButton={false}
        showCloseButton={false}
        showConfirmButton={false}
        title="Payment Failed"
        body={
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            There was an error updating your subscription. Please try again
            later or click on "Manage Subscription" button below to see your
            current invoice and update your billing details.
            <SpinnerButton
              isDisabled={!companyData}
              loading={createPortalSession.isLoading || !companyData}
              loadingText="Manage Subscription"
              mt="4"
              variant="outline"
              onClick={() => {
                createPortalSession.mutate(
                  {
                    data: {
                      companyUUID: AuthService.getUserCompany(),
                    },
                  },
                  {
                    onSuccess: (data) => {
                      const url = (data as unknown as { url: string }).url;
                      const link = document.createElement("a");
                      link.href = url;
                      document.body.appendChild(link);
                      link.click();
                    },
                  }
                );
              }}
            >
              <Box mr="4">
                <FiExternalLink />
              </Box>
              Manage Subscription
            </SpinnerButton>
          </Box>
        }
      />
      <ConfirmationDialog
        dangerAction
        confirmText="Cancel Subscription"
        isLoading={cancelSubscription.isLoading}
        show={isOpenCancelSubscription}
        showCloseButton={false}
        title="Cancel Subscription"
        body={
          <Box
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            gap="16px"
            justifyContent="flex-start"
          >
            {/* You can either cancel your subscription immediately or cancel it at
            the end of the billing cycle. */}
            <RadioGroup
              colorScheme="red"
              onChange={(value) => setIsImmediate(value === "immediate")}
              value={isImmediate ? "immediate" : "end_of_cycle"}
            >
              <Stack direction="column" spacing="8px">
                <Radio value="immediate">
                  Cancel immediately
                  <Text
                    as="span"
                    fontSize="sm"
                    lineHeight="20px"
                    mb="0"
                    ml="2"
                    opacity="0.7"
                    pb="0"
                  >
                    {formatDate(new Date())}
                  </Text>
                </Radio>
                <ConditionalWrapper
                  condition={companyData?.will_cancel}
                  wrapper={(children) => (
                    <Tooltip label="This option is unavailable as your plan is already scheduled to be cancelled">
                      {children}
                    </Tooltip>
                  )}
                >
                  <div>
                    <Radio
                      isDisabled={companyData?.will_cancel}
                      value="end_of_cycle"
                    >
                      Cancel at the end of the cycle{" "}
                      <Text
                        as="span"
                        fontSize="sm"
                        lineHeight="20px"
                        mb="0"
                        ml="2"
                        opacity="0.7"
                        pb="0"
                      >
                        {cancelSubscriptionPreview.data?.current_period_end
                          ? formatDate(
                              new Date(
                                cancelSubscriptionPreview.data
                                  ?.current_period_end * 1000
                              )
                            )
                          : "N/A"}{" "}
                      </Text>
                    </Radio>
                  </div>
                </ConditionalWrapper>

                <Collapse animateOpacity in={isImmediate}>
                  <Text lineHeight="20px" mb="0" pb="0">
                    You will be refunded for the remaining period of your
                    subscription.
                    <br />
                    Refund amount:{" "}
                    {cancelSubscriptionPreview.data?.prorated_amount ? (
                      <NumberFormat
                        type="currency"
                        value={
                          (Number.parseInt(
                            cancelSubscriptionPreview.data?.prorated_amount
                          ) /
                            100) *
                          -1
                        }
                      />
                    ) : (
                      0
                    )}{" "}
                    USD
                    <br />
                    Refund to{" "}
                    <Icon
                      boxSize={6}
                      ml="1"
                      verticalAlign="middle"
                      as={
                        cancelSubscriptionPreview.data?.card_brand === "visa"
                          ? FaCcVisa
                          : cancelSubscriptionPreview.data?.card_brand ===
                              "mastercard"
                            ? FaCcMastercard
                            : cancelSubscriptionPreview.data?.card_brand ===
                                "amex"
                              ? FaCcAmex
                              : cancelSubscriptionPreview.data?.card_brand ===
                                  "jcb"
                                ? FaCcJcb
                                : FaRegCreditCard
                      }
                    />{" "}
                    card ending in {cancelSubscriptionPreview.data?.card_last4}.
                    <br />
                    It may take 5-10 business days for the refund to be
                    processed
                    <br />
                    <br />
                    Note: You will lose access to the platform immediately.
                  </Text>
                </Collapse>
                <Collapse animateOpacity in={!isImmediate}>
                  <Text lineHeight="20px" mb="0" pb="0">
                    Your plan will be canceled, but is still available until the
                    end of your billing period on{" "}
                    {cancelSubscriptionPreview.data?.current_period_end
                      ? formatDate(
                          new Date(
                            cancelSubscriptionPreview.data?.current_period_end *
                              1000
                          ),
                          true
                        )
                      : "N/A"}{" "}
                    .<br />
                    <br />
                    If you change your mind, you can renew your subscription.
                  </Text>
                </Collapse>
              </Stack>
            </RadioGroup>
          </Box>
        }
        handleClose={() => {
          setIsImmediate(false);
          onCloseCancelSubscription();
        }}
        handleSubmit={() => {
          cancelSubscription.mutate(
            {
              data: {
                companyUUID: AuthService.getUserCompany(),
                cancel_immediately: isImmediate,
                date:
                  cancelSubscriptionPreview.data?.date ??
                  Math.floor(Date.now() / 1000),
              },
            },
            {
              onSuccess: () => {
                onCloseCancelSubscription();
                closeChangedSubscriptionModal();
                if (isImmediate) {
                  window.location.reload();
                }
                refetchCompany();
              },
            }
          );
        }}
      />
    </Stack>
  );
};

export default CompanyProfileForm;
