const BoltIcon = () => {
  return (
    <svg
      fill="none"
      height="56"
      viewBox="0 0 56 56"
      width="56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#F4EBFF" height="48" rx="24" width="48" x="4" y="4" />
      <path
        d="M29 18L19 30H28L27 38L37 26H28L29 18Z"
        stroke="#023E8A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        height="48"
        rx="24"
        stroke="#F9F5FF"
        strokeWidth="8"
        width="48"
        x="4"
        y="4"
      />
    </svg>
  );
};

export default BoltIcon;
