import {
  Grid,
  GridItem,
  Heading,
  Icon,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiDollarSign, FiList, FiTrendingUp } from "react-icons/fi";

import bg from "/png/bg_horizontal.png";

interface CardProps {
  heading: string;
  description: string;
  icon: JSX.Element;
}

const Card = ({ heading, description, icon }: CardProps) => {
  return (
    <VStack
      align="left"
      border="1px"
      borderColor="primary.800"
      borderRadius="8px"
      gap="32px"
      maxW="286px"
      p="24px"
      spacing="20px"
    >
      {icon}
      <VStack gap="8px">
        <Heading as="h2" color="gray.200" fontSize="xl" fontWeight="medium">
          {heading}
        </Heading>
        <Text color="gray.300" fontSize="md">
          {description}
        </Text>
      </VStack>
    </VStack>
  );
};

export default function HowItWorks() {
  return (
    <Stack
      align="center"
      bg="primary.900"
      bgImage={`url(${bg})`}
      bgPosition="center"
      bgSize="cover"
      direction={["column", null, "row"]}
      gap={["32px", null, null, null, "96px"]}
      justify="center"
      p={["32px", null, null, null, "96px"]}
    >
      <VStack gap="12px" my={["32px", null, null, "96px"]}>
        <Heading
          alignSelf={["center", null, "start"]}
          color="gray.200"
          fontSize="md"
        >
          How It Works
        </Heading>
        <Heading
          color="white"
          fontSize={["xl", "2xl", "3xl", "4xl", "5xl"]}
          textAlign={["center", null, "left"]}
        >
          Unlocking the Power of Fundraising
        </Heading>
      </VStack>
      <Grid gap="24px">
        <GridItem colSpan={1} colStart={1} rowSpan={2} rowStart={1}>
          <Card
            description="Register for an account to access our platform and initiate your fundraising journey."
            heading="1. Register and Create Your Auction"
            icon={
              <Icon
                as={FiList}
                color="gray.300"
                fontSize="40px"
                strokeWidth="1px"
              />
            }
          />
        </GridItem>
        <GridItem
          colSpan={1}
          colStart={[1, 2, 1, 2]}
          rowSpan={2}
          rowStart={[3, 2, 3, 2]}
          transition="0.2s ease"
        >
          <Card
            description="Tailor your auction to perfection by setting its duration, choosing members, and defining the buyback amount."
            heading="2. Configure Your Auction"
            icon={
              <Icon
                as={FiTrendingUp}
                color="gray.300"
                fontSize="40px"
                strokeWidth="1px"
              />
            }
          />
        </GridItem>
        <GridItem colSpan={1} colStart={1} rowSpan={2} rowStart={[5, 3, 5, 3]}>
          <Card
            description="Post-auction, seamlessly review the results and share transactions with all participants."
            heading="3. Monitor Results and Share Transactions"
            icon={
              <Icon
                as={FiDollarSign}
                color="gray.300"
                fontSize="40px"
                strokeWidth="1px"
              />
            }
          />
        </GridItem>
      </Grid>
    </Stack>
  );
}
