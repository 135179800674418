import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import { Controller, useForm } from "react-hook-form";
import { BsCurrencyDollar } from "react-icons/bs";
import { FiEdit2, FiZap } from "react-icons/fi";
import { toast } from "react-toastify";

import useChosenOrdersStore from "../../hooks/useChosenOrdersStore";
import OrderType from "../../models/OrderType";
import { tenderStore, userAgreementStore } from "../../stores";
import OrdersStore from "../../stores/ordersStore";
import { system_fee } from "../../utils/constants";
import {
  number_formatter,
  // percent_formatter,
} from "../../utils/numberFormatter";
import { ControlWrapper } from "../ControlWrapper";
import { FSTIcon } from "../FSTIcon";
import NumberFormat from "../NumberFormat";
import { SpinnerButton } from "../SpinnerButton";

type ValidationErrors = { [key: string]: string | null };

type formInputs = {
  quantity: number;
  price_per_unit: number;
};

const notCalculated = "Not calculated yet";

function OrderModal() {
  const [logicErrors, setLogicErrors] = useState<ValidationErrors>({});
  const { ordersStore, orderStoreType } = useChosenOrdersStore();

  const isSell = ordersStore?.stockOrder.type === OrderType.SELL;
  const [isEdit, setIsEdit] = useState(false);

  const {
    setValue,
    formState: { errors },
    setError,
    reset: formReset,
    control,
  } = useForm<formInputs>();

  useEffect(() => {
    if (ordersStore) {
      const hasEditIndex = ordersStore.editModalIndex >= 0;
      setIsEdit(hasEditIndex);
      if (hasEditIndex) {
        setValue("quantity", ordersStore?.stockOrder.stockCount as number);
        setValue(
          "price_per_unit",
          ordersStore?.stockOrder.stockPrice as number
        );
      }
    }
  }, [ordersStore, ordersStore?.editModalIndex]);

  const resetFormAndStore = (keepOpen = false) => {
    ordersStore!.resetOrder(keepOpen);
    formReset();
  };

  const handleClose = useCallback(() => {
    if (ordersStore) {
      ordersStore.showModal = false;
      resetFormAndStore();
    }
    setLogicErrors({});
  }, [setLogicErrors, ordersStore]);

  const handleValidationError = useCallback(
    (fieldName: string, message: string, showToast = false) => {
      showToast && toast.error(message);
      setLogicErrors((prevErrors) => ({ ...prevErrors, [fieldName]: message }));
    },
    [setLogicErrors, logicErrors]
  );

  const clearValidationError = useCallback(
    (fieldName: string) => {
      setLogicErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null }));
    },
    [logicErrors, setLogicErrors]
  );

  const onChange = useCallback(
    (values: Values, target_name: string) => {
      ordersStore?.updateOrder(target_name, values);
    },
    [ordersStore]
  );

  const validateStockPrice = useCallback(
    (stockPrice?: number, showToast = false) => {
      if (!_.isNil(stockPrice) && stockPrice <= 0) {
        handleValidationError(
          "stockPrice",
          "Share price must be greater than zero",
          showToast
        );
        return false;
      } else {
        clearValidationError("stockPrice");
        return true;
      }
    },
    [handleValidationError, clearValidationError]
  );

  const validateStockCount = useCallback(
    (stockCount?: number, showToast = false) => {
      if (!_.isNil(stockCount) && stockCount <= 0) {
        handleValidationError(
          "stockCount",
          "Share count must be greater than zero",
          showToast
        );
        return false;
      }
      clearValidationError("stockCount");
      return true;
    },
    [handleValidationError, clearValidationError, isSell]
  );

  const validateStockOrder = useCallback(
    (showToast = false) => {
      let isValid =
        validateStockPrice(ordersStore?.stockOrder.stockPrice, showToast) &&
        validateStockCount(ordersStore?.stockOrder.stockCount, showToast);

      const store = ordersStore as OrdersStore;

      const stockCountOld =
        isEdit && ordersStore && store.sellOrders && store.sellOrders.length
          ? store.sellOrders[ordersStore.editModalIndex]?.quantity
          : 0;
      const orderTotalOld =
        isEdit && ordersStore && store.buyOrders.length
          ? store.buyOrders[ordersStore.editModalIndex]?.amount
          : 0;
      if (!isSell) {
        if (
          ordersStore?.stockOrder.stockCount &&
          ordersStore?.stockOrder.stockPrice
        ) {
          if (
            (ordersStore.stockOrderTotal - orderTotalOld) * (1 + system_fee) >
            tenderStore.currentBalance
          ) {
            toast.error("Current balance is not enough to make this order");
            isValid = false;
            return;
          }
          if (orderStoreType === "DutchAuction") {
            if (
              ordersStore.stockOrder.stockCount *
                ordersStore.stockOrder.stockPrice >
              tenderStore.currentBalance
            ) {
              toast.error("Current balance is not enough to make this order");
              isValid = false;
            }
            if (
              ordersStore.stockOrder.stockPrice <
              parseFloat(
                tenderStore.tender?.auction.minimum_price_per_share ?? "0"
              )
            ) {
              setError(
                "price_per_unit",
                {
                  type: "manual",
                  message: `Can't have price per share less than the minimum price per share which is ${number_formatter.format(Number(tenderStore.tender?.auction.minimum_price_per_share) ?? 0)}`,
                },
                {
                  shouldFocus: true,
                }
              );
              isValid = false;
            }
            if (
              ordersStore.stockOrder.stockPrice >
              parseFloat(
                tenderStore.tender?.auction.current_price_per_share ?? "0"
              )
            ) {
              setError(
                "price_per_unit",
                {
                  type: "value",
                  message: `Can't have price per share greater than the current price per share which is ${number_formatter.format(Number(tenderStore.tender?.auction.current_price_per_share) ?? 0)}`,
                },
                {
                  shouldFocus: true,
                }
              );
              isValid = false;
            }
          }
        }
      } else {
        if (
          ordersStore.stockOrder.stockCount &&
          ordersStore.stockOrder.stockPrice
        ) {
          if (
            ordersStore.stockOrder.stockCount - stockCountOld >
            tenderStore.currentStocks
          ) {
            toast.error(
              "Current share balance is not enough to make this order"
            );
            isValid = false;
          }
        }
      }
      return isValid;
    },
    [
      ordersStore,
      ordersStore?.stockOrder,
      logicErrors,
      setLogicErrors,
      handleValidationError,
      clearValidationError,
      isEdit,
      isSell,
    ]
  );

  const computeOrderTotal = useCallback(() => {
    if (!ordersStore) return notCalculated;

    const isDutch = orderStoreType === "DutchAuction";
    const total =
      isSell || isDutch
        ? ordersStore.stockOrderTotal
        : (ordersStore as OrdersStore).maxPricePerUnit;
    return isNaN(total) ? notCalculated : total;
  }, [ordersStore, orderStoreType, isSell]);

  // const computeOrderTotalAfterFees = useCallback(() => {
  //   if (!ordersStore) return notCalculated;

  //   const isDutch = orderStoreType === "DutchAuction";
  //   const total =
  //     isSell && !isDutch
  //       ? (ordersStore as OrdersStore).stockSellOrderTotalAfterFees
  //       : ordersStore.stockBuyOrderTotalAfterFees;
  //   return isNaN(total) ? notCalculated : total;
  // }, [ordersStore, orderStoreType, isSell]);

  const checkOrdersPrices = useCallback(() => {
    if (
      _.isNil(ordersStore?.minBuyOrder) ||
      _.isNil((ordersStore as OrdersStore).minSellOrder) ||
      orderStoreType !== "DefaultAuction"
    ) {
      return;
    }
    const store = ordersStore as OrdersStore;

    if (
      store.minBuyOrder &&
      store.minSellOrder &&
      store.minBuyOrder.amount > store.minSellOrder.price_per_unit
    ) {
      toast.warning(
        "You are selling units for a price cheaper than the units you intend to buy."
      );
    }
  }, [ordersStore, ordersStore?.minBuyOrder]);

  useEffect(() => {
    if ((ordersStore?.editModalIndex ?? -1) < 0) {
      formReset();
    }
  }, [ordersStore?.showModal, ordersStore?.editModalIndex]);

  const onSubmit = useCallback(
    (e: React.SyntheticEvent, addAnother: boolean) => {
      e.preventDefault();
      e.stopPropagation();

      const isDutch = orderStoreType === "DutchAuction";

      if (!validateStockOrder(true)) return;

      const saveOrders = () => {
        let isSubmitted = false;
        if (isEdit && ordersStore) {
          isSubmitted = ordersStore.editOrder();
        } else {
          isSubmitted = ordersStore?.addOrder() ?? false;
          ordersStore?.setOrderType(isSell ? OrderType.SELL : OrderType.BUY);
        }

        if (!isSubmitted) {
          toast.error("Please add order info");
        } else {
          checkOrdersPrices();
          if (isEdit) {
            handleClose();
          } else {
            setLogicErrors({});
            if (!addAnother && ordersStore) {
              ordersStore.showModal = false;
            }
            // for some reason, price_per_unit isn't being reset
            resetFormAndStore(true);
          }
        }
        if (isDutch) {
          // for the seller price this happens in the "Send orders button"
          ordersStore?.saveOrdersList(tenderStore.tender?.auction.id);
        }
      };
      if (isDutch) {
        userAgreementStore.checkUserAgreement(() => {
          saveOrders();
        });
      } else {
        saveOrders();
      }
    },
    [ordersStore, logicErrors, setLogicErrors, validateStockOrder, isEdit]
  );

  return (
    <Modal
      isCentered
      isOpen={ordersStore?.showModal ?? false}
      onClose={handleClose}
      size={{ base: "xl", md: "3xl" }}
      variant="one-above-another"
    >
      <ModalOverlay />
      <ModalContent margin="auto">
        <Box
          alignItems="flex-start"
          className={!isSell ? "addBuyOrderModal" : "addSellOrderModal"}
        >
          <ModalHeader>
            <FSTIcon withRing Icon={isEdit ? FiEdit2 : FiZap} />
            <Text mt="3">
              {`${isEdit ? "Edit" : "Add"} ${isSell ? "Sell" : "Buy"} Order`}
            </Text>
            <Text color="gray.500" fontSize="sm" fontWeight="light">
              {!isEdit
                ? `Fill in the necessary details to add a ${
                    isSell ? "sell" : "buy"
                  } order`
                : `Change in the necessary details to edit the ${
                    isSell ? "sell" : "buy"
                  } order`}
            </Text>
          </ModalHeader>
          <ModalCloseButton className="cancelAddOrderButton" />

          <ModalBody>
            <form onSubmit={(e) => onSubmit(e, false)}>
              <Stack direction={{ base: "column", md: "row" }} gap={4} w="100%">
                <ControlWrapper
                  fullLine
                  className="numSharesInput"
                  errorMessage={errors.quantity?.message}
                  isInvalid={!!errors.quantity}
                  formLabel={
                    isSell
                      ? "No. of shares to sell"
                      : orderStoreType === "DutchAuction"
                        ? "No. of shares"
                        : "Minimum no. of shares"
                  }
                >
                  <Controller
                    control={control}
                    name="quantity"
                    render={({ field: { value, onChange: onChangeForm } }) => (
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          #
                        </InputLeftElement>
                        <Input
                          allowNegative={false}
                          as={CurrencyFormat}
                          decimalScale={0}
                          placeholder="Shares amount"
                          thousandSeparator={true}
                          value={isNaN(value) ? "" : value}
                          onValueChange={(values: Values) => {
                            onChangeForm(values.floatValue);
                            onChange(values, "stockCount");
                          }}
                        />
                      </InputGroup>
                    )}
                  />
                </ControlWrapper>
                <Stack w="100%">
                  <ControlWrapper
                    fullLine
                    className="pricePerShareInput"
                    errorMessage={errors.price_per_unit?.message}
                    isInvalid={!!errors.price_per_unit}
                    formLabel={
                      isSell
                        ? "Price per share"
                        : orderStoreType === "DutchAuction"
                          ? "Price per share"
                          : "Total Price"
                    }
                  >
                    <Controller
                      control={control}
                      name="price_per_unit"
                      render={({
                        field: { value, onChange: onChangeForm },
                      }) => (
                        <InputGroup>
                          <InputLeftElement
                            children={<BsCurrencyDollar />}
                            pointerEvents="none"
                          />
                          <Input
                            allowNegative={false}
                            as={CurrencyFormat}
                            decimalScale={5}
                            decimalSeparator="."
                            thousandSeparator={true}
                            value={isNaN(value) ? "" : value}
                            onValueChange={(values: Values) => {
                              onChangeForm(values.value);
                              onChange(values, "stockPrice");
                            }}
                            placeholder={
                              isSell
                                ? "Share price"
                                : orderStoreType === "DutchAuction"
                                  ? "Price per share"
                                  : "Price"
                            }
                          />
                        </InputGroup>
                      )}
                    />
                  </ControlWrapper>
                  <Text
                    color="gray.500"
                    fontSize="sm"
                    hidden={orderStoreType === "DefaultAuction"}
                  >
                    This should be equal or less than the current price per
                    share, which is{" "}
                    {tenderStore.tender?.auction.current_price_per_share !==
                    undefined ? (
                      <NumberFormat
                        type="currency"
                        value={
                          tenderStore.tender?.auction.current_price_per_share
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Text>
                </Stack>
              </Stack>
              <Stack mt="16px" spacing="8px" w="100%">
                <Text
                  className="totalBuyPrice"
                  color="gray.500"
                  variant="sm-regular"
                >
                  {isSell
                    ? "Order total"
                    : orderStoreType === "DutchAuction"
                      ? "Total buy price"
                      : "Maximum buy price per share"}
                  :{" "}
                  <Text as="span" color="primary.800">
                    {typeof computeOrderTotal() === "string" ? (
                      notCalculated
                    ) : (
                      <NumberFormat
                        type="currency"
                        value={computeOrderTotal()}
                      />
                    )}
                  </Text>
                </Text>
                {/* # DISABLE_FEES */}
                {/* <Text
                  className="orderTotalAfterFees"
                  color="gray.500"
                  variant="sm-regular"
                >
                  Order total after fees:{" "}
                  <Text as="span" color="primary.800">
                    {typeof computeOrderTotalAfterFees() === "string" ? (
                      notCalculated
                    ) : (
                      <NumberFormat
                        type="currency"
                        value={computeOrderTotalAfterFees()}
                      />
                    )}
                  </Text>
                </Text> */}
                {/* <Text color="gray.500" variant="xs-regular">
                  Note: there is {percent_formatter.format(system_fee)} system
                  fee for each {isSell ? "sell" : "buy"} order. For example, if
                  you {isSell ? "sold" : "want to buy"} 1 share for $100, the
                  system fee will be {percent_formatter.format(system_fee)} and
                  you will {isSell ? "receive" : "need to have"}{" "}
                  <NumberFormat
                    type="currency"
                    value={
                      isSell ? 100 - 100 * system_fee : 100 + 100 * system_fee
                    }
                  />
                  {!isSell ? " in your credit balance." : "."}
                </Text> */}
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={5}
              w="100%"
            >
              <SpinnerButton
                className="addOrderButton"
                loading={ordersStore?.loading ?? false}
                variant="outline"
                w="100%"
                isDisabled={
                  !ordersStore?.stockOrder.stockPriceString ||
                  !ordersStore.stockOrder.stockCountString
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  isEdit ? handleClose() : onSubmit(e, false);
                }}
              >
                {isEdit
                  ? "Cancel"
                  : isSell
                    ? "Add Sell Order"
                    : "Add Buy Order"}
              </SpinnerButton>
              <SpinnerButton
                className="addAnotherOrderButton"
                loading={ordersStore?.loading ?? false}
                onClick={(e) => onSubmit(e, true)}
                w="100%"
                isDisabled={
                  !ordersStore?.stockOrder.stockPriceString ||
                  !ordersStore.stockOrder.stockCountString
                }
              >
                {isEdit
                  ? "Update"
                  : `Add And Place Another ${isSell ? "Sell" : "Buy"} Order`}
              </SpinnerButton>
            </Stack>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default observer(OrderModal);
