import {
  Button,
  Center,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FiCheckCircle } from "react-icons/fi";

import { FSTIcon } from "../../../components/FSTIcon";
import useChosenOrdersStore from "../../../hooks/useChosenOrdersStore";

function CustomAlertModal() {
  const { ordersStore } = useChosenOrdersStore();
  return (
    <Modal
      isCentered
      isOpen={ordersStore?.showAlertMessage ?? false}
      onClose={ordersStore?.hideAlertMessage ?? (() => {})}
      size="xl"
    >
      <ModalOverlay zIndex={100103} />
      <ModalContent containerProps={{ zIndex: 100104 }} margin="auto">
        <ModalHeader>
          <FSTIcon
            withRing
            Icon={FiCheckCircle}
            bg="success.100"
            iconFill="success.600"
            wrapperBg="success.50"
          />
          <Text mt="3">Order Saved Successfully</Text>
          <Text color="gray.500" fontSize="sm" fontWeight="light">
            Please make sure that you received an email with all of the buy/sell
            orders you intended to add. Please check your spam folder as well.
            If you did not received the email, please try adding them again or
            reach out to the auction admin.
          </Text>
        </ModalHeader>

        <ModalFooter>
          <Center w="100%">
            <Button mr={3} onClick={ordersStore?.hideAlertMessage} w="100%">
              Okay
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default observer(CustomAlertModal);
