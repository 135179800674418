import FSTOnboarding, { FSTStep } from "src/components/FSTOnboarding";
import {
  onboardingExtras,
  traderOnboardingJourneys,
} from "src/utils/constants";

const currentJourney = traderOnboardingJourneys.TRADER_DUTCH_AUCTION;
const currentTutorial = onboardingExtras.TRADER_TUTORIAL;

const AuctionDetailsOnboarding = () => {
  const steps: FSTStep[] = [
    {
      target: ".auctionDetailsTab",
      title: "Welcome to the Auction Details tab",
      content:
        "Here, you can view the details of the ongoing auction and participate in it, place buy orders, and view transaction activities after the auction ends.",
    },
    {
      target: ".auctionEndTime",
      title: "Auction End Time",
      content:
        "This section shows how much time is left until the auction ends.",
    },
    {
      target: ".creditBalance",
      title: "Credit Balance",
      content: "Your available credits for bidding in the auction.",
    },
    {
      target: ".startingPrice",
      title: "Starting Price",
      content:
        "The minimum price per share set at the beginning of the auction.",
    },
    {
      target: ".remainingShares",
      title: "Remaining Shares",
      content: "The number of shares still available in the auction.",
    },
    {
      target: ".currentPrice",
      title: "Current Price",
      content: "The latest price per share in the auction.",
    },
    {
      target: ".viewInfoDocumentButton",
      title: "View Information Document",
      content: "Click here to view detailed information about the auction.",
    },
    {
      target: ".addBuyOrdersButton",
      title: "Add Buy Orders",
      content: "Click here to add new buy orders for the auction.",
      onNext: () => {
        const button = document.querySelector(".addBuyOrdersButton");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
      waitForNext: 500,
    },
    {
      target: ".addBuyOrderModal",
      title: "Add Buy Order Modal",
      content: "Here you can fill in the details to place a buy order.",
    },
    {
      target: ".numSharesInput",
      title: "Number of Shares",
      content: "Enter the number of shares you wish to buy.",
    },
    {
      target: ".pricePerShareInput",
      title: "Price per Share",
      content: "Enter the price you are willing to pay per share.",
    },
    {
      target: ".totalBuyPrice",
      title: "Total Buy Price",
      content: "This section shows the total price of your buy order.",
    },
    // {
    //   target: ".orderTotalAfterFees",
    //   title: "Order Total After Fees",
    //   content:
    //     "This section shows the total cost of your order including any applicable fees.",
    // },
    {
      target: ".addOrderButton",
      title: "Add Buy Order Button",
      content: "Click here to place your buy order.",
    },
    {
      target: ".addAnotherOrderButton",
      title: "Add Another Order Button",
      content:
        "You can click here to place your order and add another one. But you can always add more orders later.",
      onNext: () => {
        const button = document.querySelector(".cancelAddOrderButton");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    // {
    //   target: ".auctionTransactionsTab",
    //   title: "Auction Transactions Tab",
    //   content:
    //     "Now, let's move on to the Auction Transactions tab to view all transaction activities.",
    // },
  ];

  return (
    <FSTOnboarding
      journey={currentJourney}
      steps={steps}
      tutorial={currentTutorial}
    />
  );
};

export default AuctionDetailsOnboarding;
