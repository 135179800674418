import {
  Flex,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { BsFillCircleFill } from "react-icons/bs";
import { HistoryTable } from "src/components/HistoryTable";

import { useGetCompanyCaptable } from "../../../api/companies";
import { Shareholder } from "../../../api/model";
import NumberFormat from "../../../components/NumberFormat";
import { AuthService } from "../../../services";
import CreditManualTransactionModal from "./CreditManualTransactionModal";
import UserFormModal from "./UserFormModal";

const CapTable = ({
  mode,
  search,
}: {
  mode: "general" | "eligible" | "archived";
  search: string;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const shareholders = useGetCompanyCaptable(AuthService.getUserCompany(), {
    page: currentPage,
    page_size: pageSize,
    search: search,
    ...(mode === "general"
      ? { general: true }
      : mode === "eligible"
        ? { eligible_purchasers: true }
        : { archived_members: true }),
  });

  const columns: TableColumn<Shareholder>[] = [
    {
      name: "First name",
      selector: (row) => row.trader?.first_name ?? "",
    },
    {
      name: "Last name",
      selector: (row) => row.trader?.last_name ?? "",
    },
    { name: "Email", selector: (row) => row.trader?.email ?? "" },
    {
      name: "Credit Balance",
      right: true,
      cell: (row) => (
        <>
          {Number.parseFloat(row.credit_balance) === 0 ? (
            "—"
          ) : (
            <NumberFormat type="currency" value={row.credit_balance} />
          )}
          <CreditManualTransactionModal
            edit
            user_email={row.trader?.email ?? ""}
          />
        </>
      ),
    },
    {
      name: "Shares",
      right: true,
      selector: (row) => row.shares,
      cell: (row) =>
        row.shares === 0 ? (
          "—"
        ) : (
          <NumberFormat type="number" value={row.shares} />
        ),
    },
    {
      name: "Role",
      selector: (row) => row.is_admin ?? false,
      cell: (row) => (
        <HStack spacing={4}>
          <Tag
            borderRadius="md"
            size="md"
            colorScheme={
              row.is_admin ?? false
                ? "teal"
                : row.is_investor
                  ? "orange"
                  : "blue"
            }
          >
            <TagLeftIcon as={BsFillCircleFill} boxSize="8px" />
            <TagLabel>
              {row.is_admin ?? false
                ? "Admin"
                : row.is_investor
                  ? "Investor"
                  : "Member"}
            </TagLabel>
          </Tag>
        </HStack>
      ),
    },
    {
      name: "",
      width: "60px",
      cell: (row: Shareholder) => (
        <Flex>
          <UserFormModal edit userData={row} />
        </Flex>
      ),
    },
  ];

  return (
    <HistoryTable<Shareholder>
      noCard
      noFilters
      noSearch
      className="EditHistoryTable"
      columns={columns}
      data={shareholders.data?.results as Shareholder[]}
      header=""
      isLoading={shareholders.isLoading}
      onPageChange={(page) => setCurrentPage(page)}
      onSearch={() => {}}
      totalRows={shareholders.data?.count ?? 0}
      noData={
        <>
          <Text variant="xs-semibold">No records available</Text>
          <Text>Sorry, currently there are no records to display.</Text>
        </>
      }
      // buttons={<ManualShareTransactionForm />}
    />
  );
};

export default CapTable;
