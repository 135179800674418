import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { DefaultValues, useForm } from "react-hook-form";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiOutlinePencil } from "react-icons/hi";
import { toast } from "react-toastify";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";

import {
  getCompaniesRetrieveQueryKey,
  getGetCompanyCaptableQueryKey,
} from "../../../api/companies";
import { useAddUserToCompany, useUpdateUserRole } from "../../../api/companies";
import { Shareholder } from "../../../api/model";
import { ControlWrapper } from "../../../components/ControlWrapper";
import { FSTIcon } from "../../../components/FSTIcon";
import { SpinnerButton } from "../../../components/SpinnerButton";
import IAdminUserModel from "../../../models/IAdminUserModel";
import { AuthService } from "../../../services";

type UserFormModalProps = {
  edit?: boolean;
  userData?: Shareholder;
};

type FormInputs = IAdminUserModel;

function UserFormModal({ userData, edit = false }: UserFormModalProps) {
  const queryClient = useQueryClient();
  const company_id = AuthService.getUserCompany();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const preloadedValues: DefaultValues<FormInputs> = {
    first_name: userData?.trader?.first_name || undefined,
    last_name: userData?.trader?.last_name || undefined,
    email: userData?.trader?.email || undefined,
    is_staff: userData?.is_admin || undefined,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    getValues,
    clearErrors,
  } = useForm<FormInputs>({ defaultValues: preloadedValues });

  const { mutate, isLoading: isLoadingCreate } = useAddUserToCompany({
    mutation: {
      onSuccess: () => {
        toast.success("User created successfully");
        handleClose();
      },
      onError: (err) => {
        if (!err.errors[0].detail) {
          toast.error("Error creating user");
        }
        formServerErrorsHandler(setError, getValues, err);
      },
    },
  });

  const { mutate: updateUserRole, isLoading: isLoadingUpdate } =
    useUpdateUserRole({
      mutation: {
        onSuccess: () => {
          toast.success("User updated successfully");
          handleClose();
          queryClient.invalidateQueries(
            getCompaniesRetrieveQueryKey(company_id)
          );
        },
        onError: () => {
          toast.error("Error updating user");
        },
      },
    });

  const handleOpen = useCallback(() => {
    reset(preloadedValues);
    onOpen();
  }, [userData]);

  const handleClose = useCallback(
    (refetch = true) => {
      if (refetch) {
        queryClient.invalidateQueries(
          getGetCompanyCaptableQueryKey(company_id)
        );
      }
      onClose();
      reset();
      clearErrors();
    },
    [onClose]
  );

  const onSubmit = useCallback(
    (data: FormInputs) => {
      if (edit && userData?.trader?.id) {
        updateUserRole({
          slug: company_id,
          userId: userData?.trader?.id.toString(),
          data: {
            is_staff: data.is_staff,
          },
        });
      } else {
        mutate({
          slug: company_id,
          data,
        });
      }
    },
    [errors, onOpen, onClose]
  );

  return (
    <>
      {edit ? (
        <FSTIcon
          noBorder
          pointer
          Icon={HiOutlinePencil}
          className="editUserModal-icon"
          onClick={handleOpen}
          size={20}
        />
      ) : (
        <Button
          className="addShareholderButton"
          data-testid="add_user"
          onClick={handleOpen}
          w="100%"
        >
          <AiOutlinePlusCircle />
          <Text color="inherit" pl={2}>
            Add new member
          </Text>
        </Button>
      )}
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <Box className={edit ? "editUserModal" : "addUserModal"}>
            <ModalHeader>
              <Center>{edit ? "Edit User" : "Add User"}</Center>
              <Divider />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  <Stack className="userInformationSection" spacing={5}>
                    <ControlWrapper
                      formLabel="First name:"
                      isInvalid={errors.first_name ? true : false}
                      errorMessage={
                        errors.first_name && errors.first_name.message
                      }
                    >
                      <Input
                        disabled={edit}
                        id="first_name"
                        placeholder="First name"
                        {...register("first_name", {
                          required: "Please enter first name",
                        })}
                      />
                    </ControlWrapper>
                    <ControlWrapper
                      formLabel="Last name:"
                      isInvalid={errors.last_name ? true : false}
                      errorMessage={
                        errors.last_name && errors.last_name.message
                      }
                    >
                      <Input
                        disabled={edit}
                        id="last_name"
                        placeholder="Last name"
                        {...register("last_name", {
                          required: "Please enter last name",
                        })}
                      />
                    </ControlWrapper>
                    <ControlWrapper
                      errorMessage={errors.email && errors.email.message}
                      formLabel="Email:"
                      isInvalid={errors.email ? true : false}
                    >
                      <Input
                        disabled={edit}
                        id="email"
                        placeholder="Email"
                        {...register("email", {
                          required: "Please enter email",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              "Entered value does not match email format",
                          },
                        })}
                      />
                    </ControlWrapper>
                  </Stack>
                  <ControlWrapper
                    className="isAdminCheckbox"
                    errorMessage={errors.is_staff && errors.is_staff.message}
                    isInvalid={errors.is_staff ? true : false}
                  >
                    <Checkbox
                      {...register("is_staff")}
                      id="default-checkbox"
                      mt="15px"
                    >
                      Is admin
                    </Checkbox>
                  </ControlWrapper>
                </Stack>
              </form>
            </ModalBody>

            <ModalFooter>
              <Stack direction={["column", "row"]} spacing={5} w="100%">
                <Button
                  className="cancelUserModalButton"
                  onClick={() => handleClose(false)}
                  variant="outline"
                  w="100%"
                >
                  Cancel
                </Button>
                <SpinnerButton
                  data-testid="submit_add_user"
                  loading={isLoadingCreate || isLoadingUpdate}
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  w="100%"
                >
                  {edit ? "Update User" : "Add User"}
                </SpinnerButton>
              </Stack>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default observer(UserFormModal);
