import { ListItem, UnorderedList } from "@chakra-ui/react";

import FSTOnboarding, { FSTStep } from "../../../../components/FSTOnboarding";
import {
  adminOnboardingJourneys,
  onboardingExtras,
} from "../../../../utils/constants";

const currentJourney = adminOnboardingJourneys.ADMIN_TRANSACTIONS;
const currentTutorial = onboardingExtras.ADMIN_TUTORIAL;

const CompanyTransactionsOnboarding = () => {
  const steps: FSTStep[] = [
    {
      target: ".shareTransactionsTab",
      title: "Welcome to the Share Transactions tab",
      content:
        "Here, you can view all transactions created either manually or through an auction.",
      onNext: () => {
        const tab = document.querySelector(".shareTransactionsTab");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".shareTransactionsTable",
      title: "Each transaction listed includes",
      placement: "bottom",
      content: (
        <>
          <UnorderedList styleType="'-'">
            <ListItem>
              <strong>Date Created:</strong> The date when the transaction was
              created.
            </ListItem>
            <ListItem>
              <strong>Buyer:</strong> The individual or entity purchasing the
              shares.
            </ListItem>
            <ListItem>
              <strong>Seller:</strong> The individual or entity selling the
              shares.
            </ListItem>
            <ListItem>
              <strong>Quantity of Shares Traded:</strong> The number of shares
              involved in the transaction.
            </ListItem>
            <ListItem>
              <strong>Price per Unit in USD:</strong> The price per share in
              USD.
            </ListItem>
          </UnorderedList>
          <br />
          <br />
          Also there are some field collapsed in the table, you can expand each
          row to see more details:
          <UnorderedList styleType="'-'">
            <ListItem>
              <strong>Transaction Creator:</strong> Whether it was initiated by
              an admin or through an auction.
            </ListItem>
            <ListItem>
              <strong>Transaction Type:</strong> The type of transaction.
            </ListItem>
            <ListItem>
              <strong>Blockchain Reference:</strong> The unique blockchain
              reference for the transaction.
            </ListItem>
            <ListItem>
              <strong>Note:</strong> Any additional details regarding the
              transaction.
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
    {
      target: ".createManualShareTransactionButton",
      title: "You can also create a manual transaction",
      content: "by triggering the manual share transaction modal.",
      onNext: () => {
        const button = document.querySelector(
          ".createManualShareTransactionButton"
        );
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
      waitForNext: 500,
    },
    {
      target: ".manualShareTransactionModal",
      title:
        "To create a manual transaction, you’ll need to specify the following details",
      content: "",
    },
    {
      target: ".buyer-step",
      title: "Buyer",
      content: (
        <>
          Whether it is a user from the cap table or the treasury.
          <br />
          If the buyer isn't already on the cap table, please add them.
        </>
      ),
    },
    {
      target: ".seller-step",
      title: "Seller",
      content: (
        <>
          Whether it is a user from the cap table or the treasury.
          <br />
          If the seller isn't already on the cap table, please add them.
        </>
      ),
    },
    {
      target: ".quantity-step",
      title: "Quantity of Shares",
      content: "The number of shares that the buyer and seller will trade.",
    },
    {
      target: ".price-step",
      title: "Price per Share",
      content: "The agreed-upon price per share between the buyer and seller.",
    },
    {
      target: ".note-step",
      title: "Note (optional)",
      content: "Any additional details regarding this transaction.",
      onNext: () => {
        const button = document.querySelector(
          ".cancelManualShareTransactionButton"
        );
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".shareTransactionsTable-search",
      title: "Search",
      content:
        "Additionally, you can search for any transaction by creator, buyer, or seller.",
    },
    {
      target: ".creditTransactionsTab",
      title: "",
      content: "Now, let's move on to the Credit Transactions tab.",
    },
    {
      target: ".creditTransactionsTab",
      title: "Welcome to the Shares Transactions tab",
      content:
        "Here, you’ll find all the credit transactions performed by your auction manager or treasury.",
      onAppear: () => {
        const tab = document.querySelector(".creditTransactionsTab");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },

    {
      target: ".creditTransactionsTable",
      title: "The table includes",
      placement: "bottom",
      content: (
        <UnorderedList styleType="'-'">
          <ListItem>
            <strong>Creator:</strong> The admin who created the transaction.
          </ListItem>
          <ListItem>
            <strong>User:</strong> The shareholder or investor affected by the
            transaction.
          </ListItem>
          <ListItem>
            <strong>Type:</strong> Whether it was a deposit or withdrawal.
          </ListItem>
          <ListItem>
            <strong>Amount:</strong> The amount of the deposit or withdrawal.
          </ListItem>
          <ListItem>
            <strong>Blockchain TX:</strong> The unique blockchain reference for
            the transaction.
          </ListItem>
          <ListItem>
            <strong>Date:</strong> The date when the transaction was created.
          </ListItem>
        </UnorderedList>
      ),
    },
    {
      target: ".createManualCreditTransactionButton",
      title: "You can also create a manual credit transaction",
      content: "by triggering the manual credit transaction modal.",
      onNext: () => {
        const button = document.querySelector(
          ".createManualCreditTransactionButton"
        );
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
      waitForNext: 500,
    },
    {
      target: ".manualCreditTransactionModal",
      title:
        "To create a manual transaction, you’ll need to specify the following details",
      content: "",
    },
    {
      target: ".transactionType-step",
      title: "Type",
      content: "Whether it is a deposit or withdrawal.",
    },
    {
      target: ".user-step",
      title: "User Email",
      content: "Which is any user from the cap table.",
    },
    {
      target: ".amount-step",
      title: "Amount",
      content: "The agreed-upon amount that will be deposited or withdrawn.",
      onNext: () => {
        const button = document.querySelector(
          ".cancelManualCreditTransactionButton"
        );
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".createGroupManualCreditTransactionButton",
      title: "Group credit transaction",
      content:
        "If you are editing multiple users at once, you can create a group transaction by triggering the group credit transaction modal.",
      onNext: () => {
        const button = document.querySelector(
          ".createGroupManualCreditTransactionButton"
        );
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
      waitForNext: 500,
    },
    {
      target: ".groupCreditTransactionModal",
      title:
        "This process allows you to deposit or withdraw funds from multiple accounts simultaneously",
      content: "",
    },
    {
      target: ".groupCreditTransactionModal-upload",
      title:
        "Ensure your CSV file is ready for upload. The file should contain the following columns:",
      content: (
        <UnorderedList styleType="'-'">
          <ListItem>
            <strong>Trader:</strong> The email address of the user whose account
            will be credited or debited.
          </ListItem>
          <ListItem>
            <strong>Credit Balance:</strong> The amount of money to be deposited
            or withdrawn.
          </ListItem>
        </UnorderedList>
      ),
      onNext: () => {
        const button = document.querySelector(
          ".cancelGroupCreditTransactionButton"
        );
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".creditTransactionsTable-search",
      title: "Search",
      content:
        "Just like the Shares Transactions tab, you can search for any transaction by name or email of the creator or beneficiary.",
    },
    {
      target: ".sidebar-item-transactions",
      title: "All Done!",
      content:
        "You're all set! You can always come back to this page to create, view, or search for transactions.",
    },
  ];

  return (
    <FSTOnboarding
      journey={currentJourney}
      steps={steps}
      tutorial={currentTutorial}
    />
  );
};

export default CompanyTransactionsOnboarding;
