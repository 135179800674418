/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  Auction,
  AuctionAggregateResolution,
  AuctionDummyResolution,
  AuctionFullResolution,
  AuctionMemberPolymorphic,
  AuctionMemberPolymorphicRequest,
  AuctionPolymorphic,
  AuctionResolution,
  AuctionsApproveParticipationRequestRetrieveErrorResponse400,
  AuctionsApproveResolutionRetrieveErrorResponse400,
  AuctionsCreatePaymentIntentRetrieveErrorResponse400,
  AuctionsDefaultAuctionCreateErrorResponse400,
  AuctionsDefaultAuctionPartialUpdateErrorResponse400,
  AuctionsDefaultResolutionListErrorResponse400,
  AuctionsDestroyErrorResponse400,
  AuctionsDutchAuctionCreateErrorResponse400,
  AuctionsDutchAuctionPartialUpdateErrorResponse400,
  AuctionsDutchResolutionListErrorResponse400,
  AuctionsImportDutchAuctionMembersCreateErrorResponse400,
  AuctionsListErrorResponse400,
  AuctionsMemberPartialUpdateErrorResponse400,
  AuctionsMembersCreateErrorResponse400,
  AuctionsMembersDestroyErrorResponse400,
  AuctionsMembersListErrorResponse400,
  AuctionsParticipationRequestsListErrorResponse400,
  AuctionsPublishResolutionRetrieveErrorResponse400,
  AuctionsRejectParticipationRequestRetrieveErrorResponse400,
  AuctionsRequestToJoinRetrieveErrorResponse400,
  AuctionsRetrieveErrorResponse400,
  DefaultAuction,
  DefaultAuctionRequest,
  DeleteMemberOrdersErrorResponse400,
  DeleteUserBuyOrdersByEmailRequest,
  DownloadAuctionResolutionAggregateErrorResponse400,
  DutchAuction,
  DutchAuctionRequest,
  ErrorResponse401,
  ErrorResponse403,
  ErrorResponse404,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
  GetAuctionAggregateResolutionErrorResponse400,
  GetAuctionDummyResolutionErrorResponse400,
  GetAuctionFullResolutionErrorResponse400,
  ImportAuctionMembersErrorResponse400,
  ImportAuctionMembersFromCapTableErrorResponse400,
  ImportDefaultAuctionMemberRequest,
  ImportDutchAuctionMemberRequest,
  ImportFromCapTableRequest,
  ParticipationRequest,
  PatchedAuctionMemberPolymorphicRequest,
  PatchedDefaultAuctionRequest,
  PatchedDutchAuctionRequest,
  RunAuctionErrorResponse400,
  ScheduleAuctionErrorResponse400,
  ScheduleAuctionRequest,
  StartAuctionErrorResponse400,
} from "./model";
import { apiCustomInstance } from "../services/apiService";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const auctionsList = (
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionPolymorphic[]>(
    { url: `/auctions/`, method: "GET", signal },
    options
  );
};

export const getAuctionsListQueryKey = () => {
  return [`/auctions/`] as const;
};

export const getAuctionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsList>>,
  TError =
    | AuctionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof auctionsList>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuctionsListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auctionsList>>> = ({
    signal,
  }) => auctionsList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsList>>
>;
export type AuctionsListQueryError =
  | AuctionsListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsList = <
  TData = Awaited<ReturnType<typeof auctionsList>>,
  TError =
    | AuctionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof auctionsList>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const auctionsRetrieve = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionPolymorphic>(
    { url: `/auctions/${id}/`, method: "GET", signal },
    options
  );
};

export const getAuctionsRetrieveQueryKey = (id: number) => {
  return [`/auctions/${id}/`] as const;
};

export const getAuctionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsRetrieve>>,
  TError =
    | AuctionsRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuctionsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsRetrieve>>
  > = ({ signal }) => auctionsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsRetrieve>>
>;
export type AuctionsRetrieveQueryError =
  | AuctionsRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsRetrieve = <
  TData = Awaited<ReturnType<typeof auctionsRetrieve>>,
  TError =
    | AuctionsRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const auctionsDestroy = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    { url: `/auctions/${id}/`, method: "DELETE" },
    options
  );
};

export const getAuctionsDestroyMutationOptions = <
  TError =
    | AuctionsDestroyErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return auctionsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsDestroy>>
>;

export type AuctionsDestroyMutationError =
  | AuctionsDestroyErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsDestroy = <
  TError =
    | AuctionsDestroyErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getAuctionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * approve a participation request
 */
export const auctionsApproveParticipationRequestRetrieve = (
  id: number,
  requestId: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<ParticipationRequest>(
    {
      url: `/auctions/${id}/approve-participation-request/${requestId}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getAuctionsApproveParticipationRequestRetrieveQueryKey = (
  id: number,
  requestId: string
) => {
  return [
    `/auctions/${id}/approve-participation-request/${requestId}/`,
  ] as const;
};

export const getAuctionsApproveParticipationRequestRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof auctionsApproveParticipationRequestRetrieve>
  >,
  TError =
    | AuctionsApproveParticipationRequestRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  requestId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsApproveParticipationRequestRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAuctionsApproveParticipationRequestRetrieveQueryKey(id, requestId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsApproveParticipationRequestRetrieve>>
  > = ({ signal }) =>
    auctionsApproveParticipationRequestRetrieve(
      id,
      requestId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(id && requestId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsApproveParticipationRequestRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsApproveParticipationRequestRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof auctionsApproveParticipationRequestRetrieve>>
  >;
export type AuctionsApproveParticipationRequestRetrieveQueryError =
  | AuctionsApproveParticipationRequestRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsApproveParticipationRequestRetrieve = <
  TData = Awaited<
    ReturnType<typeof auctionsApproveParticipationRequestRetrieve>
  >,
  TError =
    | AuctionsApproveParticipationRequestRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  requestId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsApproveParticipationRequestRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAuctionsApproveParticipationRequestRetrieveQueryOptions(
      id,
      requestId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Approve auction resolution
 */
export const auctionsApproveResolutionRetrieve = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<string>(
    { url: `/auctions/${id}/approve-resolution/`, method: "GET", signal },
    options
  );
};

export const getAuctionsApproveResolutionRetrieveQueryKey = (id: number) => {
  return [`/auctions/${id}/approve-resolution/`] as const;
};

export const getAuctionsApproveResolutionRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsApproveResolutionRetrieve>>,
  TError =
    | AuctionsApproveResolutionRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsApproveResolutionRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuctionsApproveResolutionRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsApproveResolutionRetrieve>>
  > = ({ signal }) =>
    auctionsApproveResolutionRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsApproveResolutionRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsApproveResolutionRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsApproveResolutionRetrieve>>
>;
export type AuctionsApproveResolutionRetrieveQueryError =
  | AuctionsApproveResolutionRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsApproveResolutionRetrieve = <
  TData = Awaited<ReturnType<typeof auctionsApproveResolutionRetrieve>>,
  TError =
    | AuctionsApproveResolutionRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsApproveResolutionRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsApproveResolutionRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create Stripe Payment Intent object
 */
export const auctionsCreatePaymentIntentRetrieve = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<string>(
    { url: `/auctions/${id}/create-payment-intent/`, method: "GET", signal },
    options
  );
};

export const getAuctionsCreatePaymentIntentRetrieveQueryKey = (id: number) => {
  return [`/auctions/${id}/create-payment-intent/`] as const;
};

export const getAuctionsCreatePaymentIntentRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsCreatePaymentIntentRetrieve>>,
  TError =
    | AuctionsCreatePaymentIntentRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsCreatePaymentIntentRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAuctionsCreatePaymentIntentRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsCreatePaymentIntentRetrieve>>
  > = ({ signal }) =>
    auctionsCreatePaymentIntentRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsCreatePaymentIntentRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsCreatePaymentIntentRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsCreatePaymentIntentRetrieve>>
>;
export type AuctionsCreatePaymentIntentRetrieveQueryError =
  | AuctionsCreatePaymentIntentRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsCreatePaymentIntentRetrieve = <
  TData = Awaited<ReturnType<typeof auctionsCreatePaymentIntentRetrieve>>,
  TError =
    | AuctionsCreatePaymentIntentRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsCreatePaymentIntentRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsCreatePaymentIntentRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Patch FairStock default auction
 */
export const auctionsDefaultAuctionPartialUpdate = (
  id: number,
  patchedDefaultAuctionRequest: PatchedDefaultAuctionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  if (patchedDefaultAuctionRequest.ends_at !== undefined) {
    formData.append("ends_at", patchedDefaultAuctionRequest.ends_at);
  }
  if (patchedDefaultAuctionRequest.admins_only !== undefined) {
    formData.append(
      "admins_only",
      patchedDefaultAuctionRequest.admins_only.toString()
    );
  }
  if (
    patchedDefaultAuctionRequest.info_document !== undefined &&
    patchedDefaultAuctionRequest.info_document !== null
  ) {
    formData.append(
      "info_document",
      patchedDefaultAuctionRequest.info_document
    );
  }
  if (patchedDefaultAuctionRequest.duration !== undefined) {
    formData.append("duration", patchedDefaultAuctionRequest.duration);
  }
  if (patchedDefaultAuctionRequest.visibility !== undefined) {
    formData.append("visibility", patchedDefaultAuctionRequest.visibility);
  }
  if (patchedDefaultAuctionRequest.resolution_visibility !== undefined) {
    formData.append(
      "resolution_visibility",
      patchedDefaultAuctionRequest.resolution_visibility
    );
  }
  if (patchedDefaultAuctionRequest.title !== undefined) {
    formData.append("title", patchedDefaultAuctionRequest.title);
  }
  if (patchedDefaultAuctionRequest.is_custodial !== undefined) {
    formData.append(
      "is_custodial",
      patchedDefaultAuctionRequest.is_custodial.toString()
    );
  }
  if (patchedDefaultAuctionRequest.has_started !== undefined) {
    formData.append(
      "has_started",
      patchedDefaultAuctionRequest.has_started.toString()
    );
  }
  if (patchedDefaultAuctionRequest.buyback_amount !== undefined) {
    formData.append(
      "buyback_amount",
      patchedDefaultAuctionRequest.buyback_amount
    );
  }
  if (
    patchedDefaultAuctionRequest.scheduling_task !== undefined &&
    patchedDefaultAuctionRequest.scheduling_task !== null
  ) {
    formData.append(
      "scheduling_task",
      patchedDefaultAuctionRequest.scheduling_task.toString()
    );
  }

  return apiCustomInstance<DefaultAuction>(
    {
      url: `/auctions/${id}/default-auction/`,
      method: "PATCH",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getAuctionsDefaultAuctionPartialUpdateMutationOptions = <
  TError =
    | AuctionsDefaultAuctionPartialUpdateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDefaultAuctionPartialUpdate>>,
    TError,
    { id: number; data: PatchedDefaultAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsDefaultAuctionPartialUpdate>>,
  TError,
  { id: number; data: PatchedDefaultAuctionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsDefaultAuctionPartialUpdate>>,
    { id: number; data: PatchedDefaultAuctionRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return auctionsDefaultAuctionPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsDefaultAuctionPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsDefaultAuctionPartialUpdate>>
>;
export type AuctionsDefaultAuctionPartialUpdateMutationBody =
  PatchedDefaultAuctionRequest;
export type AuctionsDefaultAuctionPartialUpdateMutationError =
  | AuctionsDefaultAuctionPartialUpdateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsDefaultAuctionPartialUpdate = <
  TError =
    | AuctionsDefaultAuctionPartialUpdateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDefaultAuctionPartialUpdate>>,
    TError,
    { id: number; data: PatchedDefaultAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsDefaultAuctionPartialUpdate>>,
  TError,
  { id: number; data: PatchedDefaultAuctionRequest },
  TContext
> => {
  const mutationOptions =
    getAuctionsDefaultAuctionPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns the auction resolution for an auction
 */
export const auctionsDefaultResolutionList = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionResolution[]>(
    { url: `/auctions/${id}/default-resolution/`, method: "GET", signal },
    options
  );
};

export const getAuctionsDefaultResolutionListQueryKey = (id: number) => {
  return [`/auctions/${id}/default-resolution/`] as const;
};

export const getAuctionsDefaultResolutionListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsDefaultResolutionList>>,
  TError =
    | AuctionsDefaultResolutionListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsDefaultResolutionList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuctionsDefaultResolutionListQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsDefaultResolutionList>>
  > = ({ signal }) => auctionsDefaultResolutionList(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsDefaultResolutionList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsDefaultResolutionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsDefaultResolutionList>>
>;
export type AuctionsDefaultResolutionListQueryError =
  | AuctionsDefaultResolutionListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsDefaultResolutionList = <
  TData = Awaited<ReturnType<typeof auctionsDefaultResolutionList>>,
  TError =
    | AuctionsDefaultResolutionListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsDefaultResolutionList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsDefaultResolutionListQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Delete buy orders by user email
 */
export const deleteMemberOrders = (
  id: number,
  deleteUserBuyOrdersByEmailRequest: DeleteUserBuyOrdersByEmailRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append("email", deleteUserBuyOrdersByEmailRequest.email);

  return apiCustomInstance<AuctionPolymorphic>(
    {
      url: `/auctions/${id}/delete_member_orders/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getDeleteMemberOrdersMutationOptions = <
  TError =
    | DeleteMemberOrdersErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMemberOrders>>,
    TError,
    { id: number; data: DeleteUserBuyOrdersByEmailRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMemberOrders>>,
  TError,
  { id: number; data: DeleteUserBuyOrdersByEmailRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMemberOrders>>,
    { id: number; data: DeleteUserBuyOrdersByEmailRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return deleteMemberOrders(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMemberOrdersMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMemberOrders>>
>;
export type DeleteMemberOrdersMutationBody = DeleteUserBuyOrdersByEmailRequest;
export type DeleteMemberOrdersMutationError =
  | DeleteMemberOrdersErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useDeleteMemberOrders = <
  TError =
    | DeleteMemberOrdersErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMemberOrders>>,
    TError,
    { id: number; data: DeleteUserBuyOrdersByEmailRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteMemberOrders>>,
  TError,
  { id: number; data: DeleteUserBuyOrdersByEmailRequest },
  TContext
> => {
  const mutationOptions = getDeleteMemberOrdersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Download aggregate resolution for an auction
 */
export const downloadAuctionResolutionAggregate = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<Blob>(
    {
      url: `/auctions/${id}/download-auction-resolution-aggregate/`,
      method: "GET",
      responseType: "blob",
      signal,
    },
    options
  );
};

export const getDownloadAuctionResolutionAggregateQueryKey = (id: number) => {
  return [`/auctions/${id}/download-auction-resolution-aggregate/`] as const;
};

export const getDownloadAuctionResolutionAggregateQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadAuctionResolutionAggregate>>,
  TError =
    | DownloadAuctionResolutionAggregateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadAuctionResolutionAggregate>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadAuctionResolutionAggregateQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadAuctionResolutionAggregate>>
  > = ({ signal }) =>
    downloadAuctionResolutionAggregate(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadAuctionResolutionAggregate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadAuctionResolutionAggregateQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadAuctionResolutionAggregate>>
>;
export type DownloadAuctionResolutionAggregateQueryError =
  | DownloadAuctionResolutionAggregateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useDownloadAuctionResolutionAggregate = <
  TData = Awaited<ReturnType<typeof downloadAuctionResolutionAggregate>>,
  TError =
    | DownloadAuctionResolutionAggregateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadAuctionResolutionAggregate>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadAuctionResolutionAggregateQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Patch dutch auction
 */
export const auctionsDutchAuctionPartialUpdate = (
  id: number,
  patchedDutchAuctionRequest: PatchedDutchAuctionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  if (patchedDutchAuctionRequest.ends_at !== undefined) {
    formData.append("ends_at", patchedDutchAuctionRequest.ends_at);
  }
  if (patchedDutchAuctionRequest.admins_only !== undefined) {
    formData.append(
      "admins_only",
      patchedDutchAuctionRequest.admins_only.toString()
    );
  }
  if (
    patchedDutchAuctionRequest.info_document !== undefined &&
    patchedDutchAuctionRequest.info_document !== null
  ) {
    formData.append("info_document", patchedDutchAuctionRequest.info_document);
  }
  if (patchedDutchAuctionRequest.duration !== undefined) {
    formData.append("duration", patchedDutchAuctionRequest.duration);
  }
  if (patchedDutchAuctionRequest.visibility !== undefined) {
    formData.append("visibility", patchedDutchAuctionRequest.visibility);
  }
  if (patchedDutchAuctionRequest.resolution_visibility !== undefined) {
    formData.append(
      "resolution_visibility",
      patchedDutchAuctionRequest.resolution_visibility
    );
  }
  if (patchedDutchAuctionRequest.title !== undefined) {
    formData.append("title", patchedDutchAuctionRequest.title);
  }
  if (patchedDutchAuctionRequest.is_custodial !== undefined) {
    formData.append(
      "is_custodial",
      patchedDutchAuctionRequest.is_custodial.toString()
    );
  }
  if (patchedDutchAuctionRequest.has_started !== undefined) {
    formData.append(
      "has_started",
      patchedDutchAuctionRequest.has_started.toString()
    );
  }
  if (patchedDutchAuctionRequest.total_shares !== undefined) {
    formData.append(
      "total_shares",
      patchedDutchAuctionRequest.total_shares.toString()
    );
  }
  if (patchedDutchAuctionRequest.starting_price_per_share !== undefined) {
    formData.append(
      "starting_price_per_share",
      patchedDutchAuctionRequest.starting_price_per_share
    );
  }
  if (patchedDutchAuctionRequest.minimum_price_per_share !== undefined) {
    formData.append(
      "minimum_price_per_share",
      patchedDutchAuctionRequest.minimum_price_per_share
    );
  }
  if (patchedDutchAuctionRequest.cycle_duration !== undefined) {
    formData.append(
      "cycle_duration",
      patchedDutchAuctionRequest.cycle_duration.toString()
    );
  }
  if (patchedDutchAuctionRequest.decrease_rate !== undefined) {
    formData.append("decrease_rate", patchedDutchAuctionRequest.decrease_rate);
  }
  if (
    patchedDutchAuctionRequest.scheduling_task !== undefined &&
    patchedDutchAuctionRequest.scheduling_task !== null
  ) {
    formData.append(
      "scheduling_task",
      patchedDutchAuctionRequest.scheduling_task.toString()
    );
  }

  return apiCustomInstance<DutchAuction>(
    {
      url: `/auctions/${id}/dutch-auction/`,
      method: "PATCH",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getAuctionsDutchAuctionPartialUpdateMutationOptions = <
  TError =
    | AuctionsDutchAuctionPartialUpdateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDutchAuctionPartialUpdate>>,
    TError,
    { id: number; data: PatchedDutchAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsDutchAuctionPartialUpdate>>,
  TError,
  { id: number; data: PatchedDutchAuctionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsDutchAuctionPartialUpdate>>,
    { id: number; data: PatchedDutchAuctionRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return auctionsDutchAuctionPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsDutchAuctionPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsDutchAuctionPartialUpdate>>
>;
export type AuctionsDutchAuctionPartialUpdateMutationBody =
  PatchedDutchAuctionRequest;
export type AuctionsDutchAuctionPartialUpdateMutationError =
  | AuctionsDutchAuctionPartialUpdateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsDutchAuctionPartialUpdate = <
  TError =
    | AuctionsDutchAuctionPartialUpdateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDutchAuctionPartialUpdate>>,
    TError,
    { id: number; data: PatchedDutchAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsDutchAuctionPartialUpdate>>,
  TError,
  { id: number; data: PatchedDutchAuctionRequest },
  TContext
> => {
  const mutationOptions =
    getAuctionsDutchAuctionPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns the auction resolution for an auction
 */
export const auctionsDutchResolutionList = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionResolution[]>(
    { url: `/auctions/${id}/dutch-resolution/`, method: "GET", signal },
    options
  );
};

export const getAuctionsDutchResolutionListQueryKey = (id: number) => {
  return [`/auctions/${id}/dutch-resolution/`] as const;
};

export const getAuctionsDutchResolutionListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsDutchResolutionList>>,
  TError =
    | AuctionsDutchResolutionListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsDutchResolutionList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuctionsDutchResolutionListQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsDutchResolutionList>>
  > = ({ signal }) => auctionsDutchResolutionList(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsDutchResolutionList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsDutchResolutionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsDutchResolutionList>>
>;
export type AuctionsDutchResolutionListQueryError =
  | AuctionsDutchResolutionListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsDutchResolutionList = <
  TData = Awaited<ReturnType<typeof auctionsDutchResolutionList>>,
  TError =
    | AuctionsDutchResolutionListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsDutchResolutionList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsDutchResolutionListQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns an aggregate resolution for an auction
 */
export const getAuctionAggregateResolution = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionAggregateResolution>(
    {
      url: `/auctions/${id}/get-auction-aggregate-resolution/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAuctionAggregateResolutionQueryKey = (id: number) => {
  return [`/auctions/${id}/get-auction-aggregate-resolution/`] as const;
};

export const getGetAuctionAggregateResolutionQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuctionAggregateResolution>>,
  TError =
    | GetAuctionAggregateResolutionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuctionAggregateResolution>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAuctionAggregateResolutionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAuctionAggregateResolution>>
  > = ({ signal }) => getAuctionAggregateResolution(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuctionAggregateResolution>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAuctionAggregateResolutionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuctionAggregateResolution>>
>;
export type GetAuctionAggregateResolutionQueryError =
  | GetAuctionAggregateResolutionErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetAuctionAggregateResolution = <
  TData = Awaited<ReturnType<typeof getAuctionAggregateResolution>>,
  TError =
    | GetAuctionAggregateResolutionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuctionAggregateResolution>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuctionAggregateResolutionQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the dummy resolution for auction
 */
export const getAuctionDummyResolution = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionDummyResolution[]>(
    {
      url: `/auctions/${id}/get-auction-dummy-resolution/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAuctionDummyResolutionQueryKey = (id: number) => {
  return [`/auctions/${id}/get-auction-dummy-resolution/`] as const;
};

export const getGetAuctionDummyResolutionQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuctionDummyResolution>>,
  TError =
    | GetAuctionDummyResolutionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuctionDummyResolution>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAuctionDummyResolutionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAuctionDummyResolution>>
  > = ({ signal }) => getAuctionDummyResolution(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuctionDummyResolution>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAuctionDummyResolutionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuctionDummyResolution>>
>;
export type GetAuctionDummyResolutionQueryError =
  | GetAuctionDummyResolutionErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetAuctionDummyResolution = <
  TData = Awaited<ReturnType<typeof getAuctionDummyResolution>>,
  TError =
    | GetAuctionDummyResolutionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuctionDummyResolution>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuctionDummyResolutionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the full resolution for auction
 */
export const getAuctionFullResolution = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionFullResolution>(
    {
      url: `/auctions/${id}/get-auction-full-resolution/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAuctionFullResolutionQueryKey = (id: number) => {
  return [`/auctions/${id}/get-auction-full-resolution/`] as const;
};

export const getGetAuctionFullResolutionQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuctionFullResolution>>,
  TError =
    | GetAuctionFullResolutionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuctionFullResolution>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAuctionFullResolutionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAuctionFullResolution>>
  > = ({ signal }) => getAuctionFullResolution(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuctionFullResolution>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAuctionFullResolutionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuctionFullResolution>>
>;
export type GetAuctionFullResolutionQueryError =
  | GetAuctionFullResolutionErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetAuctionFullResolution = <
  TData = Awaited<ReturnType<typeof getAuctionFullResolution>>,
  TError =
    | GetAuctionFullResolutionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuctionFullResolution>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuctionFullResolutionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Import auction members from a csv file
 */
export const importAuctionMembers = (
  id: number,
  importDefaultAuctionMemberRequest: ImportDefaultAuctionMemberRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append("csv_file", importDefaultAuctionMemberRequest.csv_file);

  return apiCustomInstance<Auction>(
    {
      url: `/auctions/${id}/import-auction-members/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getImportAuctionMembersMutationOptions = <
  TError =
    | ImportAuctionMembersErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importAuctionMembers>>,
    TError,
    { id: number; data: ImportDefaultAuctionMemberRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importAuctionMembers>>,
  TError,
  { id: number; data: ImportDefaultAuctionMemberRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importAuctionMembers>>,
    { id: number; data: ImportDefaultAuctionMemberRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return importAuctionMembers(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportAuctionMembersMutationResult = NonNullable<
  Awaited<ReturnType<typeof importAuctionMembers>>
>;
export type ImportAuctionMembersMutationBody =
  ImportDefaultAuctionMemberRequest;
export type ImportAuctionMembersMutationError =
  | ImportAuctionMembersErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useImportAuctionMembers = <
  TError =
    | ImportAuctionMembersErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importAuctionMembers>>,
    TError,
    { id: number; data: ImportDefaultAuctionMemberRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof importAuctionMembers>>,
  TError,
  { id: number; data: ImportDefaultAuctionMemberRequest },
  TContext
> => {
  const mutationOptions = getImportAuctionMembersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Import auction members from cap table
 */
export const importAuctionMembersFromCapTable = (
  id: number,
  importFromCapTableRequest: ImportFromCapTableRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append(
    "include_investors",
    importFromCapTableRequest.include_investors.toString()
  );
  formData.append(
    "include_employees",
    importFromCapTableRequest.include_employees.toString()
  );

  return apiCustomInstance<string>(
    {
      url: `/auctions/${id}/import-auction-members-from-cap-table/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getImportAuctionMembersFromCapTableMutationOptions = <
  TError =
    | ImportAuctionMembersFromCapTableErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importAuctionMembersFromCapTable>>,
    TError,
    { id: number; data: ImportFromCapTableRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importAuctionMembersFromCapTable>>,
  TError,
  { id: number; data: ImportFromCapTableRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importAuctionMembersFromCapTable>>,
    { id: number; data: ImportFromCapTableRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return importAuctionMembersFromCapTable(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportAuctionMembersFromCapTableMutationResult = NonNullable<
  Awaited<ReturnType<typeof importAuctionMembersFromCapTable>>
>;
export type ImportAuctionMembersFromCapTableMutationBody =
  ImportFromCapTableRequest;
export type ImportAuctionMembersFromCapTableMutationError =
  | ImportAuctionMembersFromCapTableErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useImportAuctionMembersFromCapTable = <
  TError =
    | ImportAuctionMembersFromCapTableErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importAuctionMembersFromCapTable>>,
    TError,
    { id: number; data: ImportFromCapTableRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof importAuctionMembersFromCapTable>>,
  TError,
  { id: number; data: ImportFromCapTableRequest },
  TContext
> => {
  const mutationOptions =
    getImportAuctionMembersFromCapTableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Import auction members from a csv file
 */
export const auctionsImportDutchAuctionMembersCreate = (
  id: number,
  importDutchAuctionMemberRequest: ImportDutchAuctionMemberRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append("csv_file", importDutchAuctionMemberRequest.csv_file);

  return apiCustomInstance<Auction>(
    {
      url: `/auctions/${id}/import-dutch-auction-members/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getAuctionsImportDutchAuctionMembersCreateMutationOptions = <
  TError =
    | AuctionsImportDutchAuctionMembersCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsImportDutchAuctionMembersCreate>>,
    TError,
    { id: number; data: ImportDutchAuctionMemberRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsImportDutchAuctionMembersCreate>>,
  TError,
  { id: number; data: ImportDutchAuctionMemberRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsImportDutchAuctionMembersCreate>>,
    { id: number; data: ImportDutchAuctionMemberRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return auctionsImportDutchAuctionMembersCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsImportDutchAuctionMembersCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsImportDutchAuctionMembersCreate>>
>;
export type AuctionsImportDutchAuctionMembersCreateMutationBody =
  ImportDutchAuctionMemberRequest;
export type AuctionsImportDutchAuctionMembersCreateMutationError =
  | AuctionsImportDutchAuctionMembersCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsImportDutchAuctionMembersCreate = <
  TError =
    | AuctionsImportDutchAuctionMembersCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsImportDutchAuctionMembersCreate>>,
    TError,
    { id: number; data: ImportDutchAuctionMemberRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsImportDutchAuctionMembersCreate>>,
  TError,
  { id: number; data: ImportDutchAuctionMemberRequest },
  TContext
> => {
  const mutationOptions =
    getAuctionsImportDutchAuctionMembersCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Partial update an auction member from a given auction
 */
export const auctionsMemberPartialUpdate = (
  id: number,
  memberId: string,
  patchedAuctionMemberPolymorphicRequest: PatchedAuctionMemberPolymorphicRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<AuctionPolymorphic>(
    {
      url: `/auctions/${id}/member/${memberId}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedAuctionMemberPolymorphicRequest,
    },
    options
  );
};

export const getAuctionsMemberPartialUpdateMutationOptions = <
  TError =
    | AuctionsMemberPartialUpdateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsMemberPartialUpdate>>,
    TError,
    {
      id: number;
      memberId: string;
      data: PatchedAuctionMemberPolymorphicRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsMemberPartialUpdate>>,
  TError,
  {
    id: number;
    memberId: string;
    data: PatchedAuctionMemberPolymorphicRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsMemberPartialUpdate>>,
    {
      id: number;
      memberId: string;
      data: PatchedAuctionMemberPolymorphicRequest;
    }
  > = (props) => {
    const { id, memberId, data } = props ?? {};

    return auctionsMemberPartialUpdate(id, memberId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsMemberPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsMemberPartialUpdate>>
>;
export type AuctionsMemberPartialUpdateMutationBody =
  PatchedAuctionMemberPolymorphicRequest;
export type AuctionsMemberPartialUpdateMutationError =
  | AuctionsMemberPartialUpdateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsMemberPartialUpdate = <
  TError =
    | AuctionsMemberPartialUpdateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsMemberPartialUpdate>>,
    TError,
    {
      id: number;
      memberId: string;
      data: PatchedAuctionMemberPolymorphicRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsMemberPartialUpdate>>,
  TError,
  {
    id: number;
    memberId: string;
    data: PatchedAuctionMemberPolymorphicRequest;
  },
  TContext
> => {
  const mutationOptions =
    getAuctionsMemberPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns the auction members for an auction
 */
export const auctionsMembersList = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionMemberPolymorphic[]>(
    { url: `/auctions/${id}/members/`, method: "GET", signal },
    options
  );
};

export const getAuctionsMembersListQueryKey = (id: number) => {
  return [`/auctions/${id}/members/`] as const;
};

export const getAuctionsMembersListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsMembersList>>,
  TError =
    | AuctionsMembersListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuctionsMembersListQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsMembersList>>
  > = ({ signal }) => auctionsMembersList(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsMembersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsMembersList>>
>;
export type AuctionsMembersListQueryError =
  | AuctionsMembersListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsMembersList = <
  TData = Awaited<ReturnType<typeof auctionsMembersList>>,
  TError =
    | AuctionsMembersListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsMembersListQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Adds an auction member to a given auction
 */
export const auctionsMembersCreate = (
  id: number,
  auctionMemberPolymorphicRequest: AuctionMemberPolymorphicRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<AuctionPolymorphic>(
    {
      url: `/auctions/${id}/members/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: auctionMemberPolymorphicRequest,
    },
    options
  );
};

export const getAuctionsMembersCreateMutationOptions = <
  TError =
    | AuctionsMembersCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsMembersCreate>>,
    TError,
    { id: number; data: AuctionMemberPolymorphicRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsMembersCreate>>,
  TError,
  { id: number; data: AuctionMemberPolymorphicRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsMembersCreate>>,
    { id: number; data: AuctionMemberPolymorphicRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return auctionsMembersCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsMembersCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsMembersCreate>>
>;
export type AuctionsMembersCreateMutationBody = AuctionMemberPolymorphicRequest;
export type AuctionsMembersCreateMutationError =
  | AuctionsMembersCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsMembersCreate = <
  TError =
    | AuctionsMembersCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsMembersCreate>>,
    TError,
    { id: number; data: AuctionMemberPolymorphicRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsMembersCreate>>,
  TError,
  { id: number; data: AuctionMemberPolymorphicRequest },
  TContext
> => {
  const mutationOptions = getAuctionsMembersCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes an auction member from a given auction
 */
export const auctionsMembersDestroy = (
  id: number,
  memberId: string,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    { url: `/auctions/${id}/members/${memberId}/`, method: "DELETE" },
    options
  );
};

export const getAuctionsMembersDestroyMutationOptions = <
  TError =
    | AuctionsMembersDestroyErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsMembersDestroy>>,
    TError,
    { id: number; memberId: string },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsMembersDestroy>>,
  TError,
  { id: number; memberId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsMembersDestroy>>,
    { id: number; memberId: string }
  > = (props) => {
    const { id, memberId } = props ?? {};

    return auctionsMembersDestroy(id, memberId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsMembersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsMembersDestroy>>
>;

export type AuctionsMembersDestroyMutationError =
  | AuctionsMembersDestroyErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsMembersDestroy = <
  TError =
    | AuctionsMembersDestroyErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsMembersDestroy>>,
    TError,
    { id: number; memberId: string },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsMembersDestroy>>,
  TError,
  { id: number; memberId: string },
  TContext
> => {
  const mutationOptions = getAuctionsMembersDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get auction participation requests
 */
export const auctionsParticipationRequestsList = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<ParticipationRequest[]>(
    { url: `/auctions/${id}/participation-requests/`, method: "GET", signal },
    options
  );
};

export const getAuctionsParticipationRequestsListQueryKey = (id: number) => {
  return [`/auctions/${id}/participation-requests/`] as const;
};

export const getAuctionsParticipationRequestsListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsParticipationRequestsList>>,
  TError =
    | AuctionsParticipationRequestsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsParticipationRequestsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuctionsParticipationRequestsListQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsParticipationRequestsList>>
  > = ({ signal }) =>
    auctionsParticipationRequestsList(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsParticipationRequestsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsParticipationRequestsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsParticipationRequestsList>>
>;
export type AuctionsParticipationRequestsListQueryError =
  | AuctionsParticipationRequestsListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsParticipationRequestsList = <
  TData = Awaited<ReturnType<typeof auctionsParticipationRequestsList>>,
  TError =
    | AuctionsParticipationRequestsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsParticipationRequestsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsParticipationRequestsListQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Publish auction resolution
 */
export const auctionsPublishResolutionRetrieve = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<string>(
    { url: `/auctions/${id}/publish-resolution/`, method: "GET", signal },
    options
  );
};

export const getAuctionsPublishResolutionRetrieveQueryKey = (id: number) => {
  return [`/auctions/${id}/publish-resolution/`] as const;
};

export const getAuctionsPublishResolutionRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsPublishResolutionRetrieve>>,
  TError =
    | AuctionsPublishResolutionRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsPublishResolutionRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuctionsPublishResolutionRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsPublishResolutionRetrieve>>
  > = ({ signal }) =>
    auctionsPublishResolutionRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsPublishResolutionRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsPublishResolutionRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsPublishResolutionRetrieve>>
>;
export type AuctionsPublishResolutionRetrieveQueryError =
  | AuctionsPublishResolutionRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsPublishResolutionRetrieve = <
  TData = Awaited<ReturnType<typeof auctionsPublishResolutionRetrieve>>,
  TError =
    | AuctionsPublishResolutionRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsPublishResolutionRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsPublishResolutionRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * reject a participation request
 */
export const auctionsRejectParticipationRequestRetrieve = (
  id: number,
  requestId: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<ParticipationRequest>(
    {
      url: `/auctions/${id}/reject-participation-request/${requestId}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getAuctionsRejectParticipationRequestRetrieveQueryKey = (
  id: number,
  requestId: string
) => {
  return [
    `/auctions/${id}/reject-participation-request/${requestId}/`,
  ] as const;
};

export const getAuctionsRejectParticipationRequestRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof auctionsRejectParticipationRequestRetrieve>
  >,
  TError =
    | AuctionsRejectParticipationRequestRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  requestId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsRejectParticipationRequestRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAuctionsRejectParticipationRequestRetrieveQueryKey(id, requestId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsRejectParticipationRequestRetrieve>>
  > = ({ signal }) =>
    auctionsRejectParticipationRequestRetrieve(
      id,
      requestId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(id && requestId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsRejectParticipationRequestRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsRejectParticipationRequestRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsRejectParticipationRequestRetrieve>>
>;
export type AuctionsRejectParticipationRequestRetrieveQueryError =
  | AuctionsRejectParticipationRequestRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsRejectParticipationRequestRetrieve = <
  TData = Awaited<
    ReturnType<typeof auctionsRejectParticipationRequestRetrieve>
  >,
  TError =
    | AuctionsRejectParticipationRequestRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  requestId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsRejectParticipationRequestRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAuctionsRejectParticipationRequestRetrieveQueryOptions(
      id,
      requestId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * request to join an auction
 */
export const auctionsRequestToJoinRetrieve = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<ParticipationRequest>(
    { url: `/auctions/${id}/request-to-join/`, method: "GET", signal },
    options
  );
};

export const getAuctionsRequestToJoinRetrieveQueryKey = (id: number) => {
  return [`/auctions/${id}/request-to-join/`] as const;
};

export const getAuctionsRequestToJoinRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionsRequestToJoinRetrieve>>,
  TError =
    | AuctionsRequestToJoinRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsRequestToJoinRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuctionsRequestToJoinRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionsRequestToJoinRetrieve>>
  > = ({ signal }) => auctionsRequestToJoinRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionsRequestToJoinRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionsRequestToJoinRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionsRequestToJoinRetrieve>>
>;
export type AuctionsRequestToJoinRetrieveQueryError =
  | AuctionsRequestToJoinRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsRequestToJoinRetrieve = <
  TData = Awaited<ReturnType<typeof auctionsRequestToJoinRetrieve>>,
  TError =
    | AuctionsRequestToJoinRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionsRequestToJoinRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionsRequestToJoinRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Run auction
 */
export const runAuction = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<string>(
    { url: `/auctions/${id}/run-auction/`, method: "GET", signal },
    options
  );
};

export const getRunAuctionQueryKey = (id: number) => {
  return [`/auctions/${id}/run-auction/`] as const;
};

export const getRunAuctionQueryOptions = <
  TData = Awaited<ReturnType<typeof runAuction>>,
  TError =
    | RunAuctionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof runAuction>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRunAuctionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof runAuction>>> = ({
    signal,
  }) => runAuction(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof runAuction>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RunAuctionQueryResult = NonNullable<
  Awaited<ReturnType<typeof runAuction>>
>;
export type RunAuctionQueryError =
  | RunAuctionErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useRunAuction = <
  TData = Awaited<ReturnType<typeof runAuction>>,
  TError =
    | RunAuctionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof runAuction>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRunAuctionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Schedule auction
 */
export const scheduleAuction = (
  id: number,
  scheduleAuctionRequest: ScheduleAuctionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append("scheduled_date", scheduleAuctionRequest.scheduled_date);

  return apiCustomInstance<string>(
    {
      url: `/auctions/${id}/schedule-auction/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getScheduleAuctionMutationOptions = <
  TError =
    | ScheduleAuctionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scheduleAuction>>,
    TError,
    { id: number; data: ScheduleAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof scheduleAuction>>,
  TError,
  { id: number; data: ScheduleAuctionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scheduleAuction>>,
    { id: number; data: ScheduleAuctionRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return scheduleAuction(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ScheduleAuctionMutationResult = NonNullable<
  Awaited<ReturnType<typeof scheduleAuction>>
>;
export type ScheduleAuctionMutationBody = ScheduleAuctionRequest;
export type ScheduleAuctionMutationError =
  | ScheduleAuctionErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useScheduleAuction = <
  TError =
    | ScheduleAuctionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scheduleAuction>>,
    TError,
    { id: number; data: ScheduleAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof scheduleAuction>>,
  TError,
  { id: number; data: ScheduleAuctionRequest },
  TContext
> => {
  const mutationOptions = getScheduleAuctionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Start auction
 */
export const startAuction = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<string>(
    { url: `/auctions/${id}/start-auction/`, method: "GET", signal },
    options
  );
};

export const getStartAuctionQueryKey = (id: number) => {
  return [`/auctions/${id}/start-auction/`] as const;
};

export const getStartAuctionQueryOptions = <
  TData = Awaited<ReturnType<typeof startAuction>>,
  TError =
    | StartAuctionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof startAuction>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStartAuctionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof startAuction>>> = ({
    signal,
  }) => startAuction(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof startAuction>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StartAuctionQueryResult = NonNullable<
  Awaited<ReturnType<typeof startAuction>>
>;
export type StartAuctionQueryError =
  | StartAuctionErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useStartAuction = <
  TData = Awaited<ReturnType<typeof startAuction>>,
  TError =
    | StartAuctionErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof startAuction>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStartAuctionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create FairStock default auction
 */
export const auctionsDefaultAuctionCreate = (
  defaultAuctionRequest: DefaultAuctionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append("company_uuid", defaultAuctionRequest.company_uuid);
  if (defaultAuctionRequest.ends_at !== undefined) {
    formData.append("ends_at", defaultAuctionRequest.ends_at);
  }
  if (defaultAuctionRequest.admins_only !== undefined) {
    formData.append(
      "admins_only",
      defaultAuctionRequest.admins_only.toString()
    );
  }
  if (
    defaultAuctionRequest.info_document !== undefined &&
    defaultAuctionRequest.info_document !== null
  ) {
    formData.append("info_document", defaultAuctionRequest.info_document);
  }
  formData.append("duration", defaultAuctionRequest.duration);
  formData.append("visibility", defaultAuctionRequest.visibility);
  formData.append(
    "resolution_visibility",
    defaultAuctionRequest.resolution_visibility
  );
  formData.append("title", defaultAuctionRequest.title);
  if (defaultAuctionRequest.is_custodial !== undefined) {
    formData.append(
      "is_custodial",
      defaultAuctionRequest.is_custodial.toString()
    );
  }
  if (defaultAuctionRequest.has_started !== undefined) {
    formData.append(
      "has_started",
      defaultAuctionRequest.has_started.toString()
    );
  }
  formData.append("buyback_amount", defaultAuctionRequest.buyback_amount);
  if (
    defaultAuctionRequest.scheduling_task !== undefined &&
    defaultAuctionRequest.scheduling_task !== null
  ) {
    formData.append(
      "scheduling_task",
      defaultAuctionRequest.scheduling_task.toString()
    );
  }

  return apiCustomInstance<DefaultAuction>(
    {
      url: `/auctions/default-auction/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getAuctionsDefaultAuctionCreateMutationOptions = <
  TError =
    | AuctionsDefaultAuctionCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDefaultAuctionCreate>>,
    TError,
    { data: DefaultAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsDefaultAuctionCreate>>,
  TError,
  { data: DefaultAuctionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsDefaultAuctionCreate>>,
    { data: DefaultAuctionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return auctionsDefaultAuctionCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsDefaultAuctionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsDefaultAuctionCreate>>
>;
export type AuctionsDefaultAuctionCreateMutationBody = DefaultAuctionRequest;
export type AuctionsDefaultAuctionCreateMutationError =
  | AuctionsDefaultAuctionCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsDefaultAuctionCreate = <
  TError =
    | AuctionsDefaultAuctionCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDefaultAuctionCreate>>,
    TError,
    { data: DefaultAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsDefaultAuctionCreate>>,
  TError,
  { data: DefaultAuctionRequest },
  TContext
> => {
  const mutationOptions =
    getAuctionsDefaultAuctionCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Create dutch auction
 */
export const auctionsDutchAuctionCreate = (
  dutchAuctionRequest: DutchAuctionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append("company_uuid", dutchAuctionRequest.company_uuid);
  if (dutchAuctionRequest.ends_at !== undefined) {
    formData.append("ends_at", dutchAuctionRequest.ends_at);
  }
  if (dutchAuctionRequest.admins_only !== undefined) {
    formData.append("admins_only", dutchAuctionRequest.admins_only.toString());
  }
  if (
    dutchAuctionRequest.info_document !== undefined &&
    dutchAuctionRequest.info_document !== null
  ) {
    formData.append("info_document", dutchAuctionRequest.info_document);
  }
  formData.append("duration", dutchAuctionRequest.duration);
  formData.append("visibility", dutchAuctionRequest.visibility);
  formData.append(
    "resolution_visibility",
    dutchAuctionRequest.resolution_visibility
  );
  formData.append("title", dutchAuctionRequest.title);
  if (dutchAuctionRequest.is_custodial !== undefined) {
    formData.append(
      "is_custodial",
      dutchAuctionRequest.is_custodial.toString()
    );
  }
  if (dutchAuctionRequest.has_started !== undefined) {
    formData.append("has_started", dutchAuctionRequest.has_started.toString());
  }
  formData.append("total_shares", dutchAuctionRequest.total_shares.toString());
  formData.append(
    "starting_price_per_share",
    dutchAuctionRequest.starting_price_per_share
  );
  formData.append(
    "minimum_price_per_share",
    dutchAuctionRequest.minimum_price_per_share
  );
  formData.append(
    "cycle_duration",
    dutchAuctionRequest.cycle_duration.toString()
  );
  formData.append("decrease_rate", dutchAuctionRequest.decrease_rate);
  if (
    dutchAuctionRequest.scheduling_task !== undefined &&
    dutchAuctionRequest.scheduling_task !== null
  ) {
    formData.append(
      "scheduling_task",
      dutchAuctionRequest.scheduling_task.toString()
    );
  }

  return apiCustomInstance<DutchAuction>(
    {
      url: `/auctions/dutch-auction/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getAuctionsDutchAuctionCreateMutationOptions = <
  TError =
    | AuctionsDutchAuctionCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDutchAuctionCreate>>,
    TError,
    { data: DutchAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof auctionsDutchAuctionCreate>>,
  TError,
  { data: DutchAuctionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auctionsDutchAuctionCreate>>,
    { data: DutchAuctionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return auctionsDutchAuctionCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuctionsDutchAuctionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof auctionsDutchAuctionCreate>>
>;
export type AuctionsDutchAuctionCreateMutationBody = DutchAuctionRequest;
export type AuctionsDutchAuctionCreateMutationError =
  | AuctionsDutchAuctionCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionsDutchAuctionCreate = <
  TError =
    | AuctionsDutchAuctionCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auctionsDutchAuctionCreate>>,
    TError,
    { data: DutchAuctionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof auctionsDutchAuctionCreate>>,
  TError,
  { data: DutchAuctionRequest },
  TContext
> => {
  const mutationOptions = getAuctionsDutchAuctionCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
