import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { theme } from "./chakra/theme";
import SidebarWithHeader from "./components/SidebarWithHeader/SidebarWithHeader";
import { StandaloneLayout } from "./layouts";
import {
  AdminCompanyPage,
  AdminTenderDetailsPage,
  AdminTendersPage,
  AdminTransactionPage,
  AdminUsersPage,
} from "./pages/Admin";
import { TendersPage, TraderAuctionPage } from "./pages/Auctions";
import InvestLink from "./pages/Investor/InvestLink";
import InvestResponseFileDownloadLink from "./pages/Investor/InvestResponseFileDownloadLink";
import LandingPage from "./pages/Landing/LandingPage";
import { EmailSentPage, LoginPage, LoginSuccessPage } from "./pages/Login";
import { CompanyPublicProfile } from "./pages/Marketplace/CompanyPublicProfile";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import { Pricing } from "./pages/Pricing";
import {
  InvestorRegisterPage,
  RegisterSuccessPage,
  StartupRegisterPage,
  TraderRegisterPage,
} from "./pages/Register";
import { TraderTransactionsPage, TraderUserPage } from "./pages/Trader";
import ProtectedRoute from "./routes/ProtectedRoute";
import {
  rAdmin,
  rAdminAuctionsActive,
  rAdminAuctionsDraft,
  rAdminAuctionsEnded,
  rAdminCompany,
  rAdminCompanyBilling,
  rAdminCompanyData,
  rAdminCompanyDocuments,
  rAdminCompanyEquity,
  rAdminCompanyPreferences,
  rAdminEditHistory,
  rAdminTenderDetails,
  rAdminTenders,
  rAdminTransactions,
  rAdminUsers,
  rCompanyInvestLink,
  rCompanyInvestorFormFileLink,
  rCompanyPublicProfile,
  rInvestorCompany,
  rInvestorRegister,
  rLogin,
  rLoginSuccess,
  rLoginToken,
  rPricing,
  rRegisterSuccess,
  rRoot,
  rStartupRegister,
  rTender,
  rTenders,
  rTrader,
  rTraderRegister,
  rTraderTransactions,
  rTraderUser,
} from "./routes/routes";
import { ApiService } from "./services";
import { useOnboardingStore } from "./stores";

function App() {
  // not the best option
  const navigate = useNavigate();

  const [defaultTheme, setTheme] = useState(theme);

  useEffect(() => {
    ApiService.setNavigate(navigate);
  }, []);

  const { inJourney } = useOnboardingStore();

  // this is to avoid the joyride beacon from being above the modal
  // we reverse the z-index of the modal to allow the tooltip to be
  // above the modal if there's a journey in progress

  // search for 100104 in the codebase to see where we have multiple modals open
  // we use higher z-index for those cases
  const NotInOnBoardingJourneyTheme = extendTheme(theme, {
    components: {
      Modal: {
        baseStyle: {
          dialogContainer: {
            zIndex: 100102,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (inJourney) {
      setTheme(theme);
    } else {
      setTheme(NotInOnBoardingJourneyTheme);
    }
  }, [inJourney]);

  return (
    <ChakraProvider theme={defaultTheme}>
      <Routes>
        <Route element={<EmailSentPage />} path={rLoginSuccess} />
        <Route element={<LoginSuccessPage />} path={rLoginToken} />
        <Route element={<LoginPage />} path={rLogin} />
        <Route element={<StartupRegisterPage />} path={rStartupRegister} />
        <Route element={<TraderRegisterPage />} path={rTraderRegister} />
        <Route element={<InvestorRegisterPage />} path={rInvestorRegister} />
        <Route element={<RegisterSuccessPage />} path={rRegisterSuccess} />
        <Route
          element={<InvestResponseFileDownloadLink />}
          path={rCompanyInvestorFormFileLink}
        />
        <Route element={<InvestLink />} path={rCompanyInvestLink} />
        <Route element={<ProtectedRoute userGroup="Trader" />}>
          <Route element={<SidebarWithHeader />} path={rTrader}>
            <Route element={<TraderAuctionPage />} path={rTender} />
            <Route element={<TendersPage />} path={rTenders} />
            <Route
              element={<TraderTransactionsPage />}
              path={rTraderTransactions}
            />
            <Route element={<TraderUserPage />} path={rTraderUser} />
            <Route element={<CompanyPublicProfile />} path={rInvestorCompany} />
            {/*
              DISABLE_MARKETPLACE
              <Route element={<ProtectedRoute userGroup="Investor" />}>
            <Route
              element={<BrowseCompanies />}
              path={rInvestorBrowseCompanies}
            />
          */}
            {/*
            DISABLE_MARKETPLACE
            <Route
              element={<TendersPage investor />}
              path={rInvestorBrowseAuctions}
              />
              </Route>
          */}
          </Route>
        </Route>
        <Route element={<ProtectedRoute userGroup="CorporateAdmin" />}>
          <Route element={<SidebarWithHeader isAdmin />} path={rAdmin}>
            <Route index element={<Navigate to={rAdminTenders} />} />
            <Route element={<AdminTendersPage />} path={rAdminTenders} />
            <Route element={<AdminTendersPage />} path={rAdminAuctionsDraft} />
            <Route element={<AdminTendersPage />} path={rAdminAuctionsActive} />
            <Route element={<AdminTendersPage />} path={rAdminAuctionsEnded} />
            <Route element={<AdminTendersPage />} path={rAdminTenders} />
            <Route element={<AdminUsersPage />} path={rAdminUsers} />
            <Route element={<AdminUsersPage />} path={rAdminEditHistory} />
            <Route element={<AdminCompanyPage />} path={rAdminCompany} />
            <Route element={<AdminCompanyPage />} path={rAdminCompanyEquity} />
            <Route
              element={<AdminCompanyPage />}
              path={rAdminCompanyPreferences}
            />
            <Route
              element={<AdminCompanyPage />}
              path={rAdminCompanyDocuments}
            />
            <Route element={<AdminCompanyPage />} path={rAdminCompanyBilling} />
            <Route element={<AdminCompanyPage />} path={rAdminCompanyData} />
            <Route
              element={<AdminTenderDetailsPage />}
              path={rAdminTenderDetails}
            />
            <Route
              element={<AdminTransactionPage />}
              path={rAdminTransactions}
            />
          </Route>
        </Route>
        <Route element={<LandingPage />} path={rRoot} />
        {/* <Route
        path={rMarketPlace}
        element={
          <StandaloneLayout>
            <Marketplace />
          </StandaloneLayout>
        }
      /> */}
        <Route element={<Pricing />} path={rPricing} />
        <Route
          path={rCompanyPublicProfile}
          element={
            <StandaloneLayout>
              <CompanyPublicProfile standalone />
            </StandaloneLayout>
          }
        />
        <Route element={<SidebarWithHeader />} path="*">
          <Route element={<NotFoundPage />} path="*" />
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

export default App;
