import { useSearchParams } from "react-router-dom";

import { FormBackground } from "../../components/FormBackground";
import { TraderRegisterForm } from "./TraderRegister";

function InvestorRegisterPage() {
  const formHeading =
    "Please Fill Out Form To Register To Register As Investor!";
  const [searchParams] = useSearchParams();

  return (
    <FormBackground>
      <TraderRegisterForm
        formHeading={formHeading}
        invite={searchParams.get("invite") ?? ""}
        type="investor"
      />
    </FormBackground>
  );
}

export default InvestorRegisterPage;
