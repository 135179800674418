import CurrencyFormat from "react-currency-format";

import { tableColumnPassClickEvents } from "../../utils/misc";

interface Props {
  type: "number" | "currency";
  value: string | number | undefined;
  prefix?: string;
  tableValue?: boolean;
  color?: string;
  testId?: string;
}

const NumberFormat = ({
  type,
  value,
  prefix = "$",
  tableValue = false,
  color,
  testId,
}: Props) => {
  const format =
    type === "number"
      ? Intl.NumberFormat("en-US")
      : Intl.NumberFormat("en-US", {
          maximumFractionDigits: 5,
          minimumFractionDigits: 2,
          useGrouping: false,
        });
  const removeCommas = (value: string) => Number(value.replace(/,/g, ""));
  const valueWithoutLeadingZero = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5,
  });

  return (
    <CurrencyFormat
      data-testid={testId}
      decimalScale={type === "currency" ? 5 : 0}
      displayType="text"
      fixedDecimalScale={false}
      prefix={type === "currency" ? prefix : undefined}
      thousandSeparator={true}
      style={
        color
          ? { color: color, wordBreak: "keep-all" }
          : { wordBreak: "keep-all" }
      }
      value={valueWithoutLeadingZero.format(
        removeCommas(format.format(Number(value)))
      )}
      {...(tableValue && tableColumnPassClickEvents)}
    />
  );
};

export default NumberFormat;
