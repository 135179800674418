import {
  Box,
  Card,
  CardBody,
  Stack,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { components, Select } from "chakra-react-select";
import { useEffect, useMemo, useState } from "react";
import { GroupBase, OptionProps, ValueContainerProps } from "react-select";
import { useCompaniesRetrieve } from "src/api/companies";
import { useSubscribeSubscribeCreate } from "src/api/subscribe";
import ConditionalWrapper from "src/components/ConditionalWrapper";
import NumberFormat from "src/components/NumberFormat";
import { AuthService } from "src/services";

import { FSTPricingPlans, FSTYearlyPricingPlans } from "./CompanyBillingForm";

const PlanCard = ({
  isCurrent,
  isDowngrade,
  isUpgrade,
  planName,
  planIndex,
  price,
  onClick,
  description,
  mode = "value",
  isSelectValue,
  canDowngrade = true,
  canUpgrade = true,
  isYearly,
}: {
  isCurrent: boolean;
  isDowngrade: boolean;
  isUpgrade: boolean;
  planName: string;
  planIndex: string;
  price: string;
  description: string;
  onClick?: (arg0: string) => void;
  isSelectValue?: boolean;
  mode: "value" | "profile" | "register";
  canDowngrade?: boolean;
  canUpgrade?: boolean;
  has_potential_card?: boolean;
  isYearly: boolean;
}) => {
  const createSubscription = useSubscribeSubscribeCreate();

  const handleSubscribe = () => {
    createSubscription.mutate(
      {
        data: {
          companyUUID: AuthService.getUserCompany(),
          plan_ix: price,
          is_yearly: isYearly,
        },
      },
      {
        onSuccess: (data) => {
          window.location.href = (data as unknown as { url: string }).url;
        },
      }
    );
  };

  const cannotDowngrade = isDowngrade && !canDowngrade;
  const cannotUpgrade = isUpgrade && !canUpgrade;
  const isDisabled = cannotDowngrade || cannotUpgrade;
  return (
    <ConditionalWrapper
      condition={isDisabled}
      wrapper={(children) => (
        <Tooltip label="You can't downgrade to this plan. For further information, contact support at support@fairstock.trade">
          {children}
        </Tooltip>
      )}
    >
      <Card
        border={isSelectValue ? "" : "1px solid"}
        borderColor="gray.200"
        boxShadow="0px"
        color="inherit"
        cursor={isDisabled ? "not-allowed" : "pointer"}
        _hover={
          isDisabled
            ? {}
            : {
                boxShadow: "0px 14px 18px rgba(0, 0, 0, 0.05)",
                borderColor: "primary.800",
              }
        }
        {...((isSelectValue || isCurrent) && {
          pointerEvents: "none",
        })}
        // {...(isCurrent && {
        //   bg: "gray.100",
        // })}
        onClick={
          isDisabled
            ? undefined
            : onClick
              ? () => {
                  onClick(planIndex);
                }
              : mode === "value"
                ? handleSubscribe
                : undefined
        }
        {...(isDisabled && {
          backgroundColor: "gray.200",
          borderRadius: "0px",
        })}
      >
        <CardBody p={isSelectValue ? "8px" : "20px"}>
          <Stack
            direction="row"
            justify="space-between"
            spacing="24px"
            w="100%"
          >
            <Box alignItems="center" display="flex" gridGap="8px">
              <Text
                fontWeight="600"
                hidden={
                  isCurrent || isSelectValue || (!isDowngrade && !isUpgrade)
                }
              >
                {isUpgrade ? "Upgrade to" : isDowngrade ? "Downgrade to" : ""}
              </Text>
              <Text color="primary.800" fontWeight="600">
                {planName}
              </Text>
              {planName === "" ? (
                <Text>
                  Please choose a plan in order to use FairStock.trade
                </Text>
              ) : (
                <Text>
                  <NumberFormat type="currency" value={price} />/
                  {isYearly ? "year" : "month"}
                </Text>
              )}
            </Box>
            {isCurrent && <Tag bgColor="gray.300">Current Plan</Tag>}
          </Stack>
          <Stack direction="column" spacing="24px" w="100%">
            <Box>
              <Text>{description}</Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </ConditionalWrapper>
  );
};

export const BillingOption = ({
  mode = "profile",
  isYearly,
  onClick1,
  ...props
}: OptionProps<PlanOption, false, GroupBase<PlanOption>> & {
  mode?: "register" | "profile";
  isYearly: boolean;
  onClick1?: (arg0: string) => void;
}) => {
  const plans = isYearly ? FSTYearlyPricingPlans : FSTPricingPlans;

  const { data: companyData } = useCompaniesRetrieve(
    AuthService.getUserCompany(),
    { query: { enabled: false } }
  );

  const currentPlan = useMemo(() => {
    if (!companyData?.is_subscribed) return undefined;
    return plans.find(
      (plan) => plan.index.toString() === companyData.is_subscribed.toString()
    );
  }, [companyData?.is_subscribed]);

  const companyUsers = useMemo(
    () => Number.parseInt(companyData?.cap_count ?? "0"),
    [companyData?.cap_count]
  );

  const iterPlan = useMemo(() => {
    return plans.find((plan) => plan.index.toString() === props.data.value);
  }, [props.data.value]);

  const currentPlanUsers = currentPlan?.users ?? 0;
  const iterPlanUsers = iterPlan?.users ?? 0;

  const isDowngrade = currentPlanUsers > iterPlanUsers;
  const canDowngrade = isDowngrade && companyUsers <= iterPlanUsers;
  const isUpgrade = currentPlanUsers < iterPlanUsers;
  const canUpgrade = isUpgrade && companyUsers <= iterPlanUsers;

  let chosenPlanPrice;
  if (isYearly) {
    chosenPlanPrice = FSTYearlyPricingPlans.find(
      (plan) => plan.index.toString() === props.data.value
    )?.price;

    if (!chosenPlanPrice) {
      chosenPlanPrice = FSTYearlyPricingPlans.find(
        (plan) => plan.index === Number(props.data.value) + 100
      )?.price;
    }
  } else {
    chosenPlanPrice = FSTPricingPlans.find(
      (plan) => plan.index === Number(props.data.value)
    )?.price;

    if (!chosenPlanPrice) {
      chosenPlanPrice = FSTPricingPlans.find(
        (plan) => plan.index === Number(props.data.value) - 100
      )?.price;
    }
  }

  if (mode === "register") {
    return (
      <components.Option {...props}>
        <PlanCard
          canDowngrade={false}
          canUpgrade={false}
          description={props.data.description}
          isCurrent={false}
          isDowngrade={false}
          isUpgrade={false}
          isYearly={isYearly}
          mode="register"
          planIndex={props.data.value}
          planName={props.data.label}
          price={chosenPlanPrice ?? ""}
        />
      </components.Option>
    );
  }

  return (
    // <components.Option {...props}>
    <PlanCard
      canDowngrade={canDowngrade}
      canUpgrade={canUpgrade}
      description={props.data.description}
      has_potential_card={companyData?.can_open_stripe_portal ?? false}
      isCurrent={props.data.value === currentPlan?.index.toString()}
      isDowngrade={isDowngrade}
      isUpgrade={isUpgrade}
      isYearly={isYearly}
      mode="profile"
      onClick={onClick1}
      planIndex={props.data.value}
      planName={props.data.label}
      price={chosenPlanPrice ?? ""}
    />
    // </components.Option>
  );
};

type PlanOption = {
  value: string;
  label: string;
  description: string;
};

export const BillingValueContainer = (
  props: ValueContainerProps<PlanOption, false, GroupBase<PlanOption>> & {
    handleOpenMenu?: () => void;
    handleCloseMenu?: () => void;
    isMenuOpen?: boolean;
    isYearly: boolean;
  }
) => {
  let chosenPlanPrice;
  if (props.isYearly) {
    chosenPlanPrice = FSTYearlyPricingPlans.find(
      (plan) => plan.index === Number(props.getValue()[0]?.value)
    )?.price;

    if (!chosenPlanPrice) {
      chosenPlanPrice = FSTYearlyPricingPlans.find(
        (plan) => plan.index === Number(props.getValue()[0]?.value) + 100
      )?.price;
    }
  } else {
    chosenPlanPrice = FSTPricingPlans.find(
      (plan) => plan.index === Number(props.getValue()[0]?.value)
    )?.price;

    if (!chosenPlanPrice) {
      chosenPlanPrice = FSTPricingPlans.find(
        (plan) => plan.index === Number(props.getValue()[0]?.value) - 100
      )?.price;
    }
  }

  return (
    <components.ValueContainer {...props}>
      <Box
        maxWidth="500px"
        minWidth="500px"
        onBlur={props.handleCloseMenu}
        onClick={() => {
          if (props.isMenuOpen) {
            props.handleCloseMenu?.();
            return;
          }
          props.handleOpenMenu?.();
        }}
      >
        <PlanCard
          isSelectValue
          description={props.getValue()[0]?.description ?? ""}
          isCurrent={false}
          isDowngrade={false}
          isUpgrade={false}
          isYearly={props.isYearly}
          mode="value"
          planIndex={props.getValue()[0]?.value ?? ""}
          planName={props.getValue()[0]?.label ?? ""}
          price={chosenPlanPrice ?? ""}
        />
      </Box>
    </components.ValueContainer>
  );
};

export const BillingSelect = ({
  selectedValue,
  mode = "profile",
  isDisabled = false,
  onChangeCallback,
  isYearly,
}: {
  selectedValue: string;
  mode?: "register" | "profile";
  isDisabled?: boolean;
  onChangeCallback?: (value: {
    value: string;
    label: string;
    description: string;
  }) => void;
  isYearly: boolean;
}) => {
  const [newPlan, setNewPlan] = useState<{
    value: string;
    label: string;
    description: string;
  }>({
    value: "",
    label: "",
    description: "",
  });

  const planOptions = useMemo(() => {
    const plans = isYearly ? FSTYearlyPricingPlans : FSTPricingPlans;
    return plans.flatMap((plan) => {
      if (!plan.price || plan.isMore) {
        return [];
      }
      return {
        value: plan.index.toString(),
        label: plan.planName,
        description: plan.description,
      };
    });
  }, [isYearly]);

  useEffect(() => {
    if (selectedValue) {
      setNewPlan(
        planOptions.find(
          (option) => option.value === selectedValue
        ) as PlanOption
      );
    } else {
      setNewPlan(planOptions[0]);
      onChangeCallback?.(planOptions[0]);
    }
  }, [selectedValue]);

  return (
    <>
      <Select
        aria-label=""
        blurInputOnSelect={mode === "register" ? true : false}
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        maxMenuHeight={400}
        menuPlacement="auto"
        minMenuHeight={200}
        options={planOptions}
        placeholder=""
        value={newPlan}
        components={{
          Option: (props) => (
            <BillingOption
              isYearly={isYearly}
              mode={mode}
              {...props}
              onClick1={(value) => {
                onChangeCallback?.(
                  planOptions.find(
                    (option) => option.value === value
                  ) as PlanOption
                );
              }}
            />
          ),
          ValueContainer: (props) => (
            <BillingValueContainer
              {...props}
              isYearly={isYearly}
              // handleCloseMenu={handleCloseMenu}
              // handleOpenMenu={handleOpenMenu}
              // isMenuOpen={isMenuOpen}
            />
          ),
        }}
        onChange={(option) => {
          if (mode === "register") {
            setNewPlan(option ?? planOptions[0]);
            onChangeCallback?.(option as PlanOption);
          }
        }}
        styles={{
          container: (provided) => ({
            ...provided,
            height: "2.5rem",
          }),
          valueContainer: (provided) => ({
            ...provided,
            height: "100%",
            minWidth: "500px",
            width: "100%",
          }),
          control: (provided) => ({
            ...provided,
            height: "2.5rem",
          }),
        }}
      />
    </>
  );
};
