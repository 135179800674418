import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  useBoolean,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { AuthService } from "../../services";
import Navbar from "./Navbar";
import SidebarContent from "./SidebarContent";
import TutorialDialog from "./TutorialDialog";

const SidebarWithHeader = ({ isAdmin }: { isAdmin?: boolean }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [collapsed, setCollapsed] = useBoolean(false);
  const [isLargerThanMd] = useMediaQuery("(min-width: 768px)");

  // used to determine if the user is logged in or not in the case of the 404 page
  // check NotFoundPage component and route when you update this
  const token = AuthService.getAccessToken() ? true : false;
  const isTrader = AuthService.getUserGroup()?.value === "Trader";

  useEffect(() => {
    if (isLargerThanMd) {
      onClose();
      setCollapsed.off();
    }
  }, [isLargerThanMd]);

  return (
    <Box
      as={Flex}
      bg="gray.25"
      flexDir={{ base: "column", md: "row" }}
      minH="100vh"
    >
      <TutorialDialog />
      {isLargerThanMd && (
        <SidebarContent
          isAdmin={isAdmin || (token && !isTrader)}
          isCollapsed={collapsed}
          noItems={!token}
          onClose={onClose}
          setCollapsed={setCollapsed.toggle}
        />
      )}
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
        onOverlayClick={onClose}
        placement="left"
        returnFocusOnClose={false}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            isAdmin={isAdmin || (token && !isTrader)}
            noItems={!token}
            onClose={onClose}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Flex
        flexDir="column"
        ml={{ base: 0, md: collapsed ? 14 : 60 }}
        transition="0.5s ease"
        w="full"
      >
        <Navbar onOpen={onOpen} />
        <Box flex="1" p="5">
          <Outlet />
        </Box>
      </Flex>
    </Box>
  );
};

export default SidebarWithHeader;
