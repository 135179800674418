import { Box, Flex, useToken } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { AiFillCheckCircle } from "react-icons/ai";

import { useColorFromToken } from "../../hooks/useColorFromToken";

const WrapperRing = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
}) => (condition ? wrapper(children) : children);

const FSTIcon = ({
  withRing = false,
  noBorder = false,
  wrapperBg = "",
  bg = "",
  Icon = AiFillCheckCircle,
  iconFill = "",
  onClick = (e: React.SyntheticEvent) => {
    e;
  },
  pointer = false,
  size = 16,
  disabled = false,
  ...rest
}) => {
  const [brand700, brand900, primary50, primary100, gray900] = useToken(
    "colors",
    ["brand.700", "brand.900", "primary.50", "primary.100", "gray.900"]
  );
  const getColorFromToken = useColorFromToken();

  let iconColor =
    iconFill === ""
      ? withRing || noBorder
        ? brand900
        : brand700
      : getColorFromToken(iconFill);
  iconColor = disabled ? gray900 : iconColor;

  if (noBorder)
    return (
      <Flex justifyContent="center" opacity={disabled ? 0.38 : 1}>
        <Icon
          color={iconColor}
          cursor={disabled ? "not-allowed" : pointer ? "pointer" : "default"}
          onClick={disabled ? () => {} : onClick}
          size={size}
        />
      </Flex>
    );
  return (
    <WrapperRing
      condition={withRing}
      wrapper={(children) => (
        <Box
          bg={wrapperBg === "" ? primary50 : getColorFromToken(wrapperBg)}
          borderRadius="full"
          cursor={disabled ? "not-allowed" : pointer ? "pointer" : "default"}
          p={1.5}
          w="fit-content"
          {...rest}
        >
          {children}
        </Box>
      )}
    >
      <Flex
        bg={bg === "" ? primary100 : getColorFromToken(bg)}
        borderRadius="full"
        cursor={disabled ? "not-allowed" : pointer ? "pointer" : "default"}
        justifyContent="center"
        onClick={disabled ? () => {} : onClick}
        opacity={disabled ? 0.38 : 1}
        p={1}
        w="fit-content"
        {...rest}
      >
        <Icon color={iconColor} size={size ? size : withRing ? 24 : 16} />
      </Flex>
    </WrapperRing>
  );
};

export default FSTIcon;
