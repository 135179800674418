/* eslint-disable  */
import FSTOnboarding, { FSTStep } from "../../../../components/FSTOnboarding";
import {
  adminOnboardingJourneys,
  onboardingExtras,
} from "../../../../utils/constants";

const currentJourney = adminOnboardingJourneys.ADMIN_CAPTABLE;
const currentTutorial = onboardingExtras.ADMIN_TUTORIAL;

const CompanyCaptableOnboarding = () => {
  const steps: FSTStep[] = [
    {
      target: ".capTableTab",
      title: "Welcome to the Cap Table",
      content:
        "Here, you can view all your shareholders, edit their details, and manage their credit balances.",
      onNext: () => {
        const tab = document.querySelector(".capTableTab");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".shareholderListTable",
      placementBeacon: "top",
      title: "Shareholder List",
      content:
        "This is your cap table where you will find all your shareholders. You can filter them by eligible purchasers, or archived members listed in different tabs.",
    },
    {
      // eslint-disable-next-line quotes
      target: 'div[data-column-id="4"][role="cell"]',
      title: "Deposit/Withdraw",
      content:
        "Click here to deposit to or withdraw from a shareholder's credit balance.",
      onNext: () => {
        const button = document.querySelector(".editCreditBalanceButton");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
      waitForNext: 500,
    },
    {
      target: ".manualCreditTransactionModal",
      title: "Edit User Credit Balance",
      content:
        "Use this section to deposit to or withdraw from a specific user.",
    },
    {
      target: ".transactionType-step",
      title: "Type",
      content: "Whether it is a deposit or withdrawal.",
    },
    {
      target: ".amount-step",
      title: "Amount",
      content: "The agreed-upon amount that will be deposited or withdrawn.",
      onNext: () => {
        const button = document.querySelector(
          ".cancelManualCreditTransactionButton"
        );
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".editUserModal-icon",
      title: "Edit Shareholder",
      content:
        "Here you can edit a shareholder's details and promote them to a company admin.",
      onNext: () => {
        const button = document.querySelector(".editUserModal");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
      waitForNext: 500,
    },
    {
      target: ".editUserModal",
      title: "Managing Shareholder Roles",
      content:
        "Here you can edit the shareholder's information and manage their role.",
    },
    {
      target: ".userInformationSection",
      title: "View Shareholder Information",
      content: "Here you can view your shareholder's basic information.",
    },
    {
      target: ".isAdminCheckbox",
      title: "Edit Role",
      content:
        "You can edit the shareholder's role to be an admin or remove their admin privileges.",
      onNext: () => {
        const button = document.querySelector(".cancelUserModalButton");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".addShareholderButton",
      title: "Add New Shareholder",
      content: "You can add a new shareholder to your cap table from here.",
      onNext: () => {
        const button = document.querySelector(".addShareholderButton");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
      waitForNext: 500,
    },
    {
      target: ".addUserModal",
      title: "Add New Shareholder",
      content:
        "You can add a new shareholder to your cap table as a member of your private company.",
    },
    {
      target: ".userInformationSection",
      title: "Required Information",
      content: "Enter their first name, last name, and email address.",
    },
    {
      target: ".isAdminCheckbox",
      title: "Role Specification",
      content:
        "Specify whether you want them to have the role of an admin here.",
      onNext: () => {
        const button = document.querySelector(".cancelUserModalButton");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".addInvestorButton",
      title: "Invite Investors",
      content:
        "Invite investors to your cap table to participate in current and upcoming auctions.",
      onNext: () => {
        const button = document.querySelector(".addInvestorButton");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
      waitForNext: 500,
    },
    {
      target: ".addInvestorModal",
      title: "Add New Investor",
      content:
        "Use this feature to invite investors to participate in private auctions.",
    },
    {
      target: ".emailInput",
      title: "Email Invitation",
      content: (
        <div>
          Enter the investor's email address here to send them the accreditation
          form. They must fill out this form to participate.
          <br />
          <br />
          <br />
          Disclaimer: The investor can sign up to the form using an email other
          than the entered email.",
        </div>
      ),
    },
    {
      target: ".copyInvitationLink",
      title: "Copy Link",
      content:
        "Alternatively, copy the link to the accreditation form and share it through any private or public communication channel.",
      onNext: () => {
        const button = document.querySelector(".cancelInvestorModalButton");
        button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".editHistoryTab",
      title: "Edit History",
      content:
        "The edit history tab contains a record of all edits performed on any shareholder or investor, including invitations and permission changes.",
      onAppear: () => {
        const tab = document.querySelector(".editHistoryTab");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
  ];

  return (
    <FSTOnboarding
      journey={currentJourney}
      steps={steps}
      tutorial={currentTutorial}
    />
  );
};

export default CompanyCaptableOnboarding;
