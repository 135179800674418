import {
  Button,
  Link,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FiCheckCircle } from "react-icons/fi";

import { FSTIcon } from "../../../components/FSTIcon";
import { tenderStore, userAgreementStore } from "../../../stores";

function UserAgreementModal() {
  return (
    <Modal
      isCentered={true}
      isOpen={userAgreementStore.showModal}
      onClose={userAgreementStore.hideUserAgreementModal}
      size="xl"
    >
      <ModalOverlay zIndex={100103} />
      <ModalContent containerProps={{ zIndex: 100104 }} margin="auto">
        <ModalHeader>
          <FSTIcon
            withRing
            Icon={FiCheckCircle}
            bg="success.100"
            iconFill="success.600"
            wrapperBg="success.50"
          />
          <Text mt="3">
            User Agreement to{" "}
            <Text as="span" color="primary.800">
              {tenderStore.tender?.auction.title}
            </Text>
          </Text>
          <Text color="gray.500" fontSize="sm" fontWeight="light">
            Please confirm that by sending these orders, you acknowledge having
            read the auction's{" "}
            <Text
              _hover={{ textDecoration: "none" }}
              as={Link}
              color="primary.800"
              fontWeight="semibold"
              href={tenderStore.tender?.auction.info_document}
              target="_blank"
            >
              information document
            </Text>{" "}
            and are participating of your own accord.
          </Text>
        </ModalHeader>

        <ModalFooter justifyItems="space-between">
          <Stack direction={["column", "row"]} spacing={5} w="100%">
            <Button
              onClick={userAgreementStore.hideUserAgreementModal}
              variant="outline"
              w="100%"
            >
              Cancel
            </Button>
            <Button onClick={userAgreementStore.acceptUserAgreement} w="100%">
              I Confirm
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default observer(UserAgreementModal);
