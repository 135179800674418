import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  SimpleGrid,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useMemo } from "react";
import { Bar, Line } from "react-chartjs-2";
import { MdOutlineAttachMoney, MdOutlineMoving } from "react-icons/md";
import { RiExchangeDollarLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import {
  useGetCompanyAnalytics,
  useGetCompanyAuctionsAnalytics,
} from "src/api/companies";
import { AuctionCard } from "src/components/AuctionCard";
import { CustomNoDataComponent } from "src/components/CustomTable";
import NumberFormat from "src/components/NumberFormat";
import { parseName } from "src/utils/misc";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const interactionOptions = {
  interaction: {
    mode: "nearest",
    intersect: false,
    axis: "x",
  } as {
    mode: "nearest" | "x" | "index" | "dataset" | "point";
    intersect: boolean;
    axis: "x" | "y" | "xy";
  },
  plugins: {
    tooltip: {
      enabled: true,
      displayColors: false,
    },
    legend: {
      display: false,
    },
  },
};

const lineChartOptions = {
  responsive: true,
  // maintainAspectRatio: false,
  scales: {
    x: {
      display: false,
    },
    y: {
      display: true,
      position: "left",
    } as {
      display: boolean;
      position: "left" | "center" | "top" | "right";
    },
  },
  ...interactionOptions,
};

const barChartOptions = {
  responsive: true,
  // maintainAspectRatio: false,
  scales: {
    x: {
      display: true,
    },
    y: {
      display: true,
      position: "left",
    } as {
      display: boolean;
      position: "left" | "center" | "top" | "right";
    },
  },
  ...interactionOptions,
};

export const MarketplaceAnalytics = () => {
  const { companyId } = useParams();

  const { data } = useGetCompanyAnalytics(companyId ?? "", {
    request: {
      // DISABLE_MARKETPLACE
      // transformRequest: (data, headers) => {
      //   delete headers.authorization;
      //   return data;
      // },
    },
  });

  const { data: chartData } = useGetCompanyAuctionsAnalytics(companyId ?? "", {
    request: {
      // DISABLE_MARKETPLACE
      // transformRequest: (data, headers) => {
      //   delete headers.authorization;
      //   return data;
      // },
    },
  });

  const lineChartData = useMemo(
    () => ({
      labels: chartData?.auctions.map(
        (a) => `${a.title} (${new Date(a.ends_at).toLocaleDateString()})`
      ),
      datasets: [
        {
          label: "Avg. price per share",
          data: chartData?.auctions.map((a) => a.average_price_per_stock),
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          borderWidth: 2,
        },
      ],
    }),
    [chartData]
  );

  const barChartData = useMemo(
    () => ({
      labels: chartData?.auctions.map(
        (a) => `${a.title} (${new Date(a.ends_at).toLocaleDateString()})`
      ),
      datasets: [
        {
          label: "No. of shares traded",
          data: [chartData?.auctions.map((a) => a.no_of_traded_stocks)].flat(),
          backgroundColor: "#90E0EF",
          borderWidth: 0,
          borderRadius: {
            topLeft: 10,
            topRight: 10,
            bottomLeft: 0,
            bottomRight: 0,
          },
        },
      ],
    }),
    [chartData]
  );

  return (
    <Flex direction="column" gap="16px">
      <SimpleGrid gap="8px" minChildWidth="280px">
        <Skeleton isLoaded={!!data}>
          <AuctionCard
            Icon={MdOutlineAttachMoney}
            title="Average Price per Stock"
            leading={
              data?.auction_details ? (
                <NumberFormat
                  type="currency"
                  value={data?.auction_details.average_price_per_stock}
                />
              ) : (
                "—"
              )
            }
            subtitle={
              data?.auction_details
                ? `Last auction on ${new Date(
                    data?.auction_details?.end_date ?? ""
                  ).toLocaleDateString()} for ${data?.auction_details.participants} participants`
                : ""
            }
          />
        </Skeleton>
        <Skeleton isLoaded={!!data}>
          <AuctionCard
            Icon={MdOutlineMoving}
            subtitle={data?.auction_details ? <p>&nbsp;</p> : ""}
            title="Number of Investors"
            leading={
              parseInt(data?.number_of_investors ?? "") !== 0 ? (
                <NumberFormat type="number" value={data?.number_of_investors} />
              ) : (
                "—"
              )
            }
          />
        </Skeleton>
        <Skeleton isLoaded={!!data}>
          <AuctionCard
            Icon={RiExchangeDollarLine}
            subtitle={data?.auction_details ? <p>&nbsp;</p> : ""}
            title="Estimated Company Valuation"
            leading={
              parseInt(data?.estimated_company_valuation ?? "") !== 0 ? (
                <NumberFormat
                  type="currency"
                  value={data?.estimated_company_valuation}
                />
              ) : (
                "—"
              )
            }
          />
        </Skeleton>
      </SimpleGrid>

      {/* Charts */}
      <Skeleton borderRadius={8} isLoaded={!!chartData}>
        <Card className="companyChartsCard">
          <CardHeader>
            <Text variant="display-xs-semibold">Histogram of prices</Text>
          </CardHeader>
          <CardBody>
            <div className="chart-container" style={{ position: "relative" }}>
              <Line
                data={lineChartData}
                height={20}
                options={lineChartOptions}
                width="90%"
              />
              <Bar
                data={barChartData}
                height={20}
                options={barChartOptions}
                width="100%"
              />
            </div>
          </CardBody>
        </Card>
      </Skeleton>

      {/* Main stakeholders */}
      <Skeleton borderRadius={8} isLoaded={!!data}>
        <Card className="companyStakeholdersCard">
          <CardHeader>
            <Text variant="display-xs-semibold">Main Stakeholders</Text>
          </CardHeader>
          <CardBody>
            <SimpleGrid columns={[1, 2]} gap="8">
              {data?.main_stakeholders.length === 0 ? (
                <Box gridColumn="1 / -1">
                  {CustomNoDataComponent("No main stakeholders found")}
                </Box>
              ) : (
                data?.main_stakeholders.map((stakeholder) => (
                  <Flex
                    key={parseName(stakeholder)}
                    alignItems="center"
                    data-testid="stakeholder"
                    gap="8px"
                  >
                    <Avatar key={parseName(stakeholder)} />
                    <Text data-testid="stakeholder-name">
                      {parseName(stakeholder)}
                    </Text>
                  </Flex>
                ))
              )}
            </SimpleGrid>
          </CardBody>
        </Card>
      </Skeleton>
    </Flex>
  );
};
