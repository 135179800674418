import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdElectricBolt } from "react-icons/md";
import { toast } from "react-toastify";

import {
  getCompaniesTransactionsListQueryKey,
  useBulkCreateCreditTransactions,
} from "../../../api/companies";
import { ControlWrapper } from "../../../components/ControlWrapper";
import { FSTIcon } from "../../../components/FSTIcon";
import { SpinnerButton } from "../../../components/SpinnerButton";
import AuthService from "../../../services/authService";

function CreditBulkTransactionsModal() {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [creditTransactionsFile, setCreditTransactionsFile] =
    useState<null | File>(null);

  const [bulkTransactionUploadErrors, setBulkTransactionUploadErrors] =
    useState<string[]>([]);

  const {
    mutate: bulkCreateCreditTransactions,
    isLoading: bulkCreateCreditTransactionsLoading,
  } = useBulkCreateCreditTransactions({
    mutation: {
      onError: (err) => {
        if (err.type === "validation_error") {
          err.errors.forEach((error) => {
            if (error.attr.includes("csv_file.row")) {
              setBulkTransactionUploadErrors((prev) => [
                ...prev,
                `Row ${error.detail} has errors`,
              ]);
            }
            if (error.attr.includes("csv_file.field_errors")) {
              // get column name from the attr after the last dot
              const columnName = error.attr.split(".").pop();
              if (error.code === "required") {
                setBulkTransactionUploadErrors((prev) => [
                  ...prev,
                  `${columnName} column is required`,
                ]);
              }
              if (error.code === "invalid") {
                setBulkTransactionUploadErrors((prev) => [
                  ...prev,
                  `${columnName}: ${error.detail}`,
                ]);
              }
            }
          });
        }
      },
      onSuccess: () => {
        toast.success("Bulk transactions created successfully");
        queryClient.invalidateQueries(
          getCompaniesTransactionsListQueryKey(AuthService.getUserCompany())
        );
        handleClose();
      },
    },
  });

  const handleClose = useCallback(
    (refetch = true) => {
      if (refetch) {
        queryClient.invalidateQueries(
          getCompaniesTransactionsListQueryKey(AuthService.getUserCompany(), {
            type: "credit",
          })
        );
      }
      onClose();
      setCreditTransactionsFile(null);
    },
    [onClose]
  );

  const onSubmit = useCallback(() => {
    if (creditTransactionsFile) {
      setBulkTransactionUploadErrors([]);
      bulkCreateCreditTransactions({
        uuid: AuthService.getUserCompany(),
        data: { csv_file: creditTransactionsFile },
      });
    }
  }, [bulkCreateCreditTransactions, creditTransactionsFile]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject, inputRef } =
    useDropzone({
      accept: { "text/csv": [] },
      multiple: false,
      onDropAccepted: (acceptedFiles) => {
        setCreditTransactionsFile(acceptedFiles[0]);
        setBulkTransactionUploadErrors([]);
      },
      onDropRejected: (fileRejections) =>
        toast.error(fileRejections[0].errors[0].message),
    });

  return (
    <>
      <Button
        className="createGroupManualCreditTransactionButton"
        onClick={onOpen}
        variant="outline"
        w="100%"
      >
        <Text color="inherit">Create Group Transaction</Text>
      </Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => handleClose(false)}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent margin="auto">
          <Box className="groupCreditTransactionModal">
            <ModalHeader>
              <FSTIcon withRing Icon={MdElectricBolt} />
              <Flex direction="column" gap={3}>
                <Text mt="3">Create Group Transaction</Text>

                <Text color="gray.500" fontSize="sm" fontWeight="light">
                  Are you sure you want to proceed with the CSV upload for bulk
                  transactions? This action will initiate multiple transactions
                  based on the provided data.
                </Text>
              </Flex>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onSubmit}>
                <ControlWrapper
                  className="groupCreditTransactionModal-upload"
                  errorMessage={undefined}
                  isInvalid={false}
                >
                  <input
                    {...getInputProps()}
                    id="credit_transactions"
                    placeholder="Credit Transactions"
                  />
                  <Stack
                    {...getRootProps()}
                    alignItems="center"
                    bg="gray.25"
                    border="1px dashed"
                    borderRadius="8px"
                    maxW="512px"
                    px="6"
                    py="4"
                    borderColor={
                      isDragAccept
                        ? "primary.800"
                        : bulkTransactionUploadErrors.length > 0
                          ? "error.600"
                          : isDragReject
                            ? "error.600"
                            : "gray.200"
                    }
                  >
                    <FSTIcon
                      withRing
                      Icon={AiOutlineCloudUpload}
                      bg="gray.100"
                      iconFill="gray.500"
                      wrapperBg="gray.50"
                    />
                    <Text fontSize="sm" onClick={(e) => e.stopPropagation()}>
                      {creditTransactionsFile && (
                        <Text
                          as="p"
                          color="primary.800"
                          marginBottom={3}
                          variant="sm-semibold"
                        >
                          {creditTransactionsFile.name}
                        </Text>
                      )}
                      <Text
                        as="span"
                        color="primary.800"
                        cursor="pointer"
                        onClick={() => inputRef?.current?.click()}
                        variant="sm-semibold"
                      >
                        Click to upload{" "}
                      </Text>
                      or drag and drop
                    </Text>
                    <Text
                      color="gray.500"
                      fontSize="sm"
                      onClick={(e) => e.stopPropagation()}
                      textAlign="center"
                    >
                      Refer to the{" "}
                      <Link
                        color="primary.800"
                        href="/bulk_credit_transactions.csv"
                        textDecoration="underline"
                      >
                        CSV template
                      </Link>{" "}
                      for more details
                    </Text>
                    <Text
                      color="error.600"
                      fontSize="sm"
                      hidden={bulkTransactionUploadErrors.length === 0}
                      mb="4"
                      mt="2"
                      textAlign="center"
                    >
                      CSV file is incorrectly formatted. Please use the template
                      provided as a reference.
                    </Text>
                    {bulkTransactionUploadErrors.map((error, index) => (
                      <Text key={index} color="error.600" variant="sm-semibold">
                        {error}
                      </Text>
                    ))}
                  </Stack>
                </ControlWrapper>
              </form>
            </ModalBody>
            <ModalFooter>
              <Stack direction={["column", "row"]} spacing={5} w="100%">
                <Button
                  className="cancelGroupCreditTransactionButton"
                  onClick={() => handleClose(false)}
                  variant="outline"
                  w="100%"
                >
                  Cancel
                </Button>
                <SpinnerButton
                  loading={bulkCreateCreditTransactionsLoading}
                  onClick={onSubmit}
                  type="submit"
                  w="100%"
                >
                  Confirm Upload
                </SpinnerButton>
              </Stack>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default observer(CreditBulkTransactionsModal);
