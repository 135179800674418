import _ from "lodash";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ErrorCode401Enum } from "src/api/model";
import { DBStore, useOnboardingStore } from "src/stores";

import { useAuthGetUserDataRetrieve } from "../api/auth";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { AuthService } from "../services";
import { rAdminCompanyBilling, rLogin, rRoot } from "./routes";

interface ProtectedRouteProps {
  userGroup: "Trader" | "CorporateAdmin" | "Investor";
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProtectedRoute = ({ userGroup }: ProtectedRouteProps) => {
  const location = useLocation();

  const { isCurrentCompanySubscribed, setStore } = useOnboardingStore();
  const originalSearchParams = _.trimStart(location.search, "?");
  const { data, isLoading, error, isError, isSuccess } =
    useAuthGetUserDataRetrieve();
  const navigateTo = `${rLogin}?redirect_to=${location.pathname}&${originalSearchParams}`;

  useEffect(() => {
    if (isSuccess && data?.onboarding_journeys) {
      const temp = {
        subscriptions: data.subscriptions,
        ...data?.onboarding_journeys,
      } as DBStore;
      setStore(temp);
    }
  }, [isSuccess, data, setStore]);

  if (isLoading) return <LoadingSpinner fullHeight />;

  if (
    isError &&
    error &&
    (error?.errors[0]?.code === ErrorCode401Enum.not_authenticated ||
      error?.errors[0]?.code === ErrorCode401Enum.authentication_failed)
  ) {
    return <Navigate replace to={navigateTo} />;
  }
  if (
    (userGroup === "CorporateAdmin" &&
      AuthService.getUserGroup()?.value !== "Admin") ||
    (userGroup === "Trader" &&
      AuthService.getUserGroup()?.value !== "Trader") ||
    (userGroup === "Investor" && AuthService.getUserGroup()?.key !== "Investor")
  ) {
    return <Navigate replace to={rRoot} />;
  }

  if (
    userGroup === "CorporateAdmin" &&
    AuthService.getUserGroup()?.value === "Admin"
  ) {
    if (
      !isCurrentCompanySubscribed() &&
      location.pathname !== rAdminCompanyBilling
    ) {
      return <Navigate replace to={rAdminCompanyBilling} />;
    }
  }

  return isSuccess ? <Outlet /> : null;
};

export default ProtectedRoute;
