import { Heading, Image, Stack, Text, VStack } from "@chakra-ui/react";

export default function AboutUs() {
  return (
    <Stack
      align="center"
      bg="#6D7D9326"
      borderBottomLeftRadius={["300", null, "none"]}
      borderBottomRightRadius="300"
      borderTopRightRadius={[null, null, "300"]}
      direction={["column", null, "row"]}
      gap={["32px", null, null, "140px"]}
      justify="center"
      my={["64px", null, "96px"]}
      p="16px"
      px={[null, null, "32px"]}
    >
      <VStack gap="16px">
        <Heading
          alignSelf={["center", null, "start"]}
          color="gray.800"
          fontSize="4xl"
        >
          About Us
        </Heading>
        <Text
          align={["center", null, "start"]}
          color="gray.500"
          fontSize="xl"
          lineHeight="30px"
          maxW="600px"
        >
          We're not just a platform; we're architects of innovation. Founded by
          finance experts, we're dedicated to making fundraising accessible and
          transparent.
          <br />
          <br />
          We envision a world where fundraising is straightforward, and
          opportunities are within everyone's reach.
        </Text>
      </VStack>
      <Image
        mx={["30px", null, "48px"]}
        my={["30px", null, "48px"]}
        src="/svg/P2P.svg?url"
        width={["250px", null, null, "400px"]}
      />
    </Stack>
  );
}
