import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useChakraSelectProps } from "chakra-react-select";
import { Duration } from "duration-converter";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsFilter, BsSearch } from "react-icons/bs";
import { FiUserPlus } from "react-icons/fi";
import { IoRefresh } from "react-icons/io5";
import {
  MdDeleteOutline,
  MdErrorOutline,
  MdOutlineAttachMoney,
  MdOutlineCalendarToday,
  MdOutlinePeople,
  MdOutlineSchedule,
} from "react-icons/md";
import { VscRunAll } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import TimezoneSelect, {
  ITimezone,
  ITimezoneOption,
} from "react-timezone-select";
import { toast } from "react-toastify";
import { ControlWrapper } from "src/components/ControlWrapper";
import { SpinnerButton } from "src/components/SpinnerButton";
import { useDateUtils } from "src/hooks/useDateUtils";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";
import { create } from "zustand";

import {
  getAuctionsMembersListQueryKey,
  getAuctionsRetrieveQueryKey,
  useAuctionsMembersDestroy,
  useAuctionsRetrieve,
  useRunAuction,
  useScheduleAuction,
  useStartAuction,
} from "../../../api/auctions";
import { AuctionMemberPolymorphic } from "../../../api/model";
import { AuctionCard } from "../../../components/AuctionCard";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { FSTIcon } from "../../../components/FSTIcon";
import NumberFormat from "../../../components/NumberFormat";
import {
  formatDuration,
  stringDurationToObj,
} from "../../../utils/dateFormatter";
import { parseName } from "../../../utils/misc";
import AddAuctionMembers from "./auctionForms/AddAuctionMembers";
import { TenderMembersTable } from "./auctionMembersTable";

type selectedAuctionMembersStore = {
  selectedRows: false | AuctionMemberPolymorphic[];
  setSelectedRows: (selectedRows: false | AuctionMemberPolymorphic[]) => void;
  toggledClearRows: boolean;
  updateToggledClearRows: () => void;
};
export const useSelectedAuctionsMembersStore =
  create<selectedAuctionMembersStore>()((set) => ({
    selectedRows: false,
    setSelectedRows: (selectedRows: false | AuctionMemberPolymorphic[]) =>
      set((state) => ({
        ...state,
        selectedRows,
      })),
    toggledClearRows: false,
    updateToggledClearRows: () =>
      set((state) => ({
        ...state,
        toggledClearRows: !state.toggledClearRows,
      })),
  }));

const AuctionDetails = ({
  onRunningAuction,
}: {
  onRunningAuction: () => void;
}) => {
  const reactSelectChakraStyles = useChakraSelectProps<ITimezone>({});
  const queryClient = useQueryClient();

  const { getOffsetFromGMT } = useDateUtils();

  // States and functions for the schedule auction functionality
  const {
    isOpen: isScheduleOpen,
    onOpen: onScheduleOpen,
    onClose: onScheduleClose,
  } = useDisclosure();

  const {
    isOpen: startAuctionConfirmOpen,
    onOpen: onStartAuctionConfirmOpen,
    onClose: onStartAuctionConfirmClose,
  } = useDisclosure();

  const {
    register: registerSchedule,
    handleSubmit: handleSubmitSchedule,
    formState: { errors: scheduleErrors },
    control: controlSchedule,
    reset: resetSchedule,
    watch: watchSchedule,
    getValues: getScheduleValues,
    setError: setScheduleError,
  } = useForm<{
    scheduled_date: string;
    timezone: string;
  }>({
    defaultValues: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  const handleScheduleModalClose = () => {
    resetSchedule();
    onScheduleClose();
  };

  const onSubmitSchedule = handleSubmitSchedule(
    useCallback(
      (data: { scheduled_date: string; timezone: string }) => {
        const date = moment
          .tz(data.scheduled_date, data.timezone)
          .toISOString();
        scheduleAuctionMutation.mutate({
          id: Number.parseInt(auction_id ?? ""),
          data: {
            scheduled_date: date as string,
          },
        });
      },
      [scheduleErrors, onScheduleClose, onScheduleOpen]
    )
  );

  const selectedTimezone = watchSchedule("timezone");
  const selectedEndDate = watchSchedule("scheduled_date");

  const timezoneDiff = useMemo(() => {
    const userTimezone = moment.tz.guess();
    if (!isEmpty(selectedEndDate) && selectedTimezone !== userTimezone) {
      const time = moment
        .tz(selectedEndDate, selectedTimezone)
        .tz(userTimezone);
      return time.format("hh:mm A on Do of MMMM YYYY");
    }
    return false;
  }, [selectedEndDate, selectedTimezone]);

  const scheduleAuctionMutation = useScheduleAuction({
    mutation: {
      onError: (err) => {
        formServerErrorsHandler(setScheduleError, getScheduleValues, err);
      },
      onSuccess: () => {
        handleScheduleModalClose();
        queryClient.invalidateQueries(
          getAuctionsRetrieveQueryKey(Number.parseInt(auction_id ?? ""))
        );
        toast.success("Auction scheduled successfully");
      },
    },
  });
  // end of schedule auction functionality

  const {
    isOpen: isAddMembersOpen,
    onOpen: onAddMembersOpen,
    onClose: onAddMembersClose,
  } = useDisclosure();
  const {
    isOpen: isBulkDeleteMembersOpen,
    onOpen: onBulkDeleteMembersOpen,
    onClose: onBulkDeleteMembersClose,
  } = useDisclosure();

  const { auction_id } = useParams();

  const [tick, setTick] = useState(0);

  const { selectedRows, setSelectedRows, updateToggledClearRows } =
    useSelectedAuctionsMembersStore();

  const deleteAuctionMemberMutation = useAuctionsMembersDestroy({
    mutation: {
      onError: () => {
        toast.error("Failed to delete auction member");
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          getAuctionsMembersListQueryKey(Number.parseInt(auction_id ?? ""))
        );
        toast.success("Auction member deleted successfully");
        onBulkDeleteMembersClose();
        setSelectedRows(false);
        updateToggledClearRows();
      },
    },
  });

  useEffect(() => {
    const timerID = setInterval(() => setTick((tick) => tick + 1), 1000);
    return () => clearInterval(timerID);
  }, []);

  const { data: auction, refetch } = useAuctionsRetrieve(
    Number.parseInt(auction_id ?? "")
  );

  const isDutch = auction?.auction_type === "DutchAuction";

  const { refetch: startAuction, isFetching: startAuctionLoading } =
    useStartAuction(Number.parseInt(auction_id ?? ""), {
      query: {
        enabled: false,
      },
    });

  const { refetch: runAuction } = useRunAuction(
    Number.parseInt(auction_id ?? ""),
    {
      query: {
        enabled: false,
        onSuccess: () => {
          toast.success("Auction ran successfully");
          refetch();
          onRunningAuction();
        },
        onError: (err) => {
          if (!err.errors[0].detail) {
            toast.error("Failed to run auction");
          }
        },
      },
    }
  );

  const [searchQuery, setSearchQuery] = useState("");

  const selectedRowsCount = useSelectedAuctionsMembersStore((state) => {
    if (typeof state.selectedRows === "boolean") {
      return 0;
    }
    return state.selectedRows.length;
  });

  const countdownRemaining = useMemo(() => {
    let text = "";

    if (auction?.ends_at) {
      const now = new Date();
      const then = new Date(auction?.ends_at);
      if (auction?.is_after_auction_end_date || then < now) {
        text = "Finished";
        if (!auction?.is_after_auction_end_date) {
          refetch();
        }
      } else if (!auction.has_started) {
        text = "Auction not running";
      } else {
        text = formatDuration(Duration.between(now, then).MilliSeconds);
        if (text.includes("59s")) {
          refetch();
        }
        if (text.includes("30s")) {
          refetch();
        }
      }
      return text;
    }

    if (!auction?.has_started && auction?.is_scheduled) {
      const now = new Date();
      const then = new Date(auction?.is_scheduled);
      if (now.getTime() > then.getTime()) {
        refetch();
      }
    }

    const duration = stringDurationToObj(auction?.duration ?? "");
    text = `${duration.day ?? 0}d ${duration.hour ?? 0}h ${duration.minute}m`;
    return text;
  }, [auction, tick]);

  return (
    <Stack spacing={4}>
      <ConfirmationDialog
        confirmText="Delete"
        isLoading={deleteAuctionMemberMutation.isLoading}
        show={isBulkDeleteMembersOpen}
        showCloseButton={false}
        title={<Text mt="3">Remove Auction Members</Text>}
        body={
          <>
            Please confirm your intent to remove all the following auction
            members:
            {selectedRows &&
              selectedRows.map((member) => (
                <Text
                  key={member.id}
                  as="b"
                  color="blue-primary"
                  display="block"
                >
                  {parseName({
                    first_name: member.trader_first_name,
                    last_name: member.trader_last_name,
                  })}
                </Text>
              ))}
          </>
        }
        handleClose={() => {
          onBulkDeleteMembersClose();
        }}
        handleSubmit={() => {
          if (selectedRows !== false) {
            deleteAuctionMemberMutation.mutate({
              id: Number.parseInt(auction_id ?? ""),
              memberId: selectedRows.map((member) => member.id).join(","),
            });
          }
        }}
        icon={
          <FSTIcon
            withRing
            Icon={MdErrorOutline}
            bg="error.100"
            iconFill="error.600"
            size={32}
            wrapperBg="error.50"
          />
        }
      />
      <ConfirmationDialog
        confirmText="Confirm"
        isLoading={startAuctionLoading}
        show={startAuctionConfirmOpen}
        title="Start Auction now"
        body={
          <>
            Please confirm your intent to start{" "}
            <Text as="b" color="blue-primary">
              {auction?.title}
            </Text>{" "}
            now
          </>
        }
        handleClose={() => {
          onStartAuctionConfirmClose();
        }}
        handleSubmit={() => {
          startAuction().then((response) => {
            if (response.isSuccess) {
              toast.success("Auction started successfully");
              refetch();
              onStartAuctionConfirmClose();
            } else if (response.isError) {
              const err = response.error;
              if (err.errors[0].detail) {
                toast.error(err.errors[0].detail);
              } else {
                toast.error("Failed to start auction");
              }
            }
          });
        }}
      />
      {isDutch && auction?.is_after_auction_end_date && (
        <Alert
          as={Flex}
          borderRadius="8px"
          flexDir="row"
          hidden={auction.has_concluded}
          justify="space-between"
          mt="4"
          status="info"
          variant="left-accent"
        >
          <Box alignItems="center" display="flex">
            <AlertIcon />
            Please wait, We're finalizing the auction on our side. You will not
            be able to see the resolution until then.
          </Box>
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              refetch();
            }}
          >
            <IoRefresh size="20px"></IoRefresh>
            Refresh
          </Button>
        </Alert>
      )}
      <SimpleGrid gap="8px" minChildWidth="280px">
        <Skeleton isLoaded={!!auction}>
          <AuctionCard
            Icon={MdOutlineSchedule}
            leading={countdownRemaining}
            title={auction?.ends_at ? "Ends At" : "Duration"}
            subtitle={
              auction?.is_scheduled ? (
                `Scheduled for ${new Date(auction?.is_scheduled).toLocaleString()}`
              ) : auction?.ends_at ? (
                `Ends at ${new Date(auction?.ends_at).toLocaleString()} ${getOffsetFromGMT()}`
              ) : (
                <p>&nbsp;</p>
              )
            }
          />
        </Skeleton>
        <Skeleton
          hidden={auction && !auction?.has_started}
          isLoaded={!!auction}
        >
          <AuctionCard
            Icon={MdOutlinePeople}
            leading={`${auction?.number_of_participants}`}
            subtitle={<p>&nbsp;</p>}
            title="Participants"
          />
        </Skeleton>
        <Skeleton hidden={isDutch} isLoaded={!!auction}>
          {auction?.auction_type === "DefaultAuction" && (
            <AuctionCard
              Icon={MdOutlineAttachMoney}
              subtitle={<p>&nbsp;</p>}
              title="Buyback Amount"
              leading={
                <NumberFormat type="currency" value={auction?.buyback_amount} />
              }
            />
          )}
        </Skeleton>
        {/* DISABLE_FEES */}
        {/* {!!auction && auction?.has_started && (
          <AuctionCard
            Icon={MdCreditCard}
            title="System Fees"
            info={
              auction?.auction_fees !== null
                ? `System auction fees are ${percent_formatter.format(
                    system_fee
                  )} of each transaction`
                : "Total auction fees are only available when you have a resolution"
            }
            leading={
              auction?.resolution_status !== "No Resolution" &&
              auction?.is_after_auction_end_date ? (
                <NumberFormat type="currency" value={auction?.auction_fees} />
              ) : (
                "—"
              )
            }
          />
        )} */}
        {isDutch && (
          <AuctionCard
            Icon={MdOutlineAttachMoney}
            title="Starting Price"
            leading={
              <NumberFormat
                type="currency"
                value={auction?.starting_price_per_share}
              />
            }
          />
        )}
        {isDutch && (
          <AuctionCard
            Icon={MdOutlineAttachMoney}
            title="Total Shares"
            leading={
              <NumberFormat type="number" value={auction?.total_shares} />
            }
          />
        )}
        {isDutch && (
          <AuctionCard
            Icon={MdOutlineAttachMoney}
            title="Remaining Shares"
            leading={
              <NumberFormat type="number" value={auction?.remaining_shares} />
            }
          />
        )}
        {isDutch && (
          <AuctionCard
            Icon={MdOutlineAttachMoney}
            title="Minimum Price"
            leading={
              <NumberFormat
                type="currency"
                value={auction?.minimum_price_per_share}
              />
            }
          />
        )}
        {isDutch && (
          <AuctionCard
            Icon={MdOutlineAttachMoney}
            title="Current Price"
            leading={
              <NumberFormat
                type="currency"
                value={auction?.current_price_per_share}
              />
            }
          />
        )}
        {isDutch && auction?.has_started && (
          <AuctionCard
            Icon={MdOutlineAttachMoney}
            title="Final Price"
            leading={
              auction?.is_after_auction_end_date && auction.has_concluded ? (
                <NumberFormat
                  type="currency"
                  value={auction?.final_price_per_share}
                />
              ) : (
                "—"
              )
            }
          />
        )}
      </SimpleGrid>

      <Spacer />

      <Flex
        flexDir={{ base: "column", lg: "row" }}
        gap={4}
        justifyContent="space-between"
      >
        <Box
          backgroundColor="white"
          borderRadius={8}
          width={{ base: "100%", lg: "55%" }}
        >
          <InputGroup>
            <InputLeftElement children={<BsSearch />} pointerEvents="none" />
            <Input
              aria-describedby="search-icon"
              aria-label="search"
              placeholder="Search for traders"
              value={searchQuery}
              onChange={({ target: { value } }) =>
                setSearchQuery(value.toLowerCase())
              }
            />
          </InputGroup>
        </Box>
        <Button leftIcon={<BsFilter />} variant="outline">
          Filters
        </Button>
      </Flex>
      <Spacer />
      <Card>
        <CardBody>
          <Flex
            alignItems="center"
            flexDir={{ base: "column", lg: "row" }}
            gap={4}
            justifyContent="space-between"
            mb="4"
          >
            <Heading color="gray.900" fontSize={18}>
              Auction Members
            </Heading>
            <Stack spacing={4}>
              <Flex direction={["column", "row"]}>
                <Box hidden={selectedRowsCount <= 0}>
                  <Button
                    fontWeight="medium"
                    leftIcon={<MdDeleteOutline />}
                    variant="solid"
                    onClick={() => {
                      if (selectedRowsCount <= 0) {
                        return;
                      }
                      onBulkDeleteMembersOpen();
                    }}
                  >
                    Delete Selected
                  </Button>
                </Box>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  hidden={selectedRowsCount > 0}
                  spacing={3}
                >
                  {auction?.is_after_auction_end_date || (
                    <>
                      <Button onClick={onAddMembersOpen} variant="outline">
                        Add Auction Members
                      </Button>
                      <Modal
                        isCentered
                        isOpen={isAddMembersOpen}
                        onClose={onAddMembersClose}
                        size="3xl"
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>
                            <FSTIcon
                              withRing
                              Icon={FiUserPlus}
                              bg="primary.100"
                              iconFill="primary.900"
                              wrapperBg="primary.50"
                            />
                            <Text mt="3">Add Auction Members</Text>
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <AddAuctionMembers
                              padCard
                              auctionId={Number.parseInt(auction_id ?? "")}
                              auctionType={auction?.auction_type ?? ""}
                              handleClose={onAddMembersClose}
                              isCustodial={auction?.is_custodial ?? false}
                            />
                          </ModalBody>
                          <ModalFooter></ModalFooter>
                        </ModalContent>
                      </Modal>
                    </>
                  )}

                  {auction?.auction_type === "DefaultAuction" &&
                    auction.has_started &&
                    !auction.has_a_published_resolution && (
                      <Button
                        fontWeight="medium"
                        leftIcon={<VscRunAll />}
                        variant="solid"
                        onClick={() => {
                          runAuction();
                        }}
                      >
                        Run Auction
                      </Button>
                    )}

                  {auction?.has_started ||
                    auction?.is_after_auction_end_date || (
                      <>
                        <Menu>
                          <MenuButton
                            as={Button}
                            data-testid="start-auction-button"
                            rightIcon={<MdOutlineCalendarToday />}
                            variant="outline"
                          >
                            Start Auction
                          </MenuButton>
                          <MenuList zIndex={9999}>
                            <MenuItem
                              data-testid="start-auction-now-option"
                              onClick={() => onStartAuctionConfirmOpen()}
                            >
                              Start auction now
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                onScheduleOpen();
                              }}
                            >
                              Schedule later
                            </MenuItem>
                          </MenuList>
                        </Menu>
                        <Modal
                          isCentered
                          isOpen={isScheduleOpen}
                          onClose={onScheduleClose}
                          size="xl"
                        >
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>
                              <FSTIcon
                                withRing
                                Icon={MdOutlineCalendarToday}
                                bg="primary.100"
                                iconFill="primary.900"
                                wrapperBg="primary.50"
                              />
                              <Text mt="3">
                                {auction?.is_scheduled
                                  ? "Change the scheduled date"
                                  : "Schedule the auction to start at a later date"}
                              </Text>
                              {auction?.is_scheduled && (
                                <Text color="gray.500" fontSize="sm">
                                  Current scheduled date:{" "}
                                  {new Date(
                                    auction?.is_scheduled
                                  ).toLocaleString()}
                                </Text>
                              )}
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              <Grid gap={5}>
                                <GridItem colSpan={2}>
                                  <ControlWrapper
                                    fullLine
                                    formLabel="Scheduled date"
                                    errorMessage={
                                      scheduleErrors.scheduled_date &&
                                      scheduleErrors.scheduled_date.message
                                    }
                                    isInvalid={
                                      scheduleErrors.scheduled_date
                                        ? true
                                        : false
                                    }
                                  >
                                    <Input
                                      id="scheduled_date"
                                      placeholder="Scheduled date"
                                      type="datetime-local"
                                      {...registerSchedule("scheduled_date", {
                                        required:
                                          "Please enter auction scheduled date",
                                      })}
                                      cursor="text"
                                    />
                                  </ControlWrapper>
                                </GridItem>
                                <GridItem colSpan={2}>
                                  <ControlWrapper
                                    fullLine
                                    formLabel="Time zone"
                                    errorMessage={
                                      scheduleErrors.timezone &&
                                      scheduleErrors.timezone.message
                                    }
                                    helperText={
                                      timezoneDiff
                                        ? `Timezone mismatch: the selected time corresponds to ${timezoneDiff} in your local timezone.`
                                        : ""
                                    }
                                    isInvalid={
                                      scheduleErrors.timezone ? true : false
                                    }
                                  >
                                    <Controller
                                      control={controlSchedule}
                                      name="timezone"
                                      render={({
                                        field: { value, onChange },
                                      }) => (
                                        <TimezoneSelect
                                          {...reactSelectChakraStyles}
                                          useBasicStyles
                                          labelStyle="abbrev"
                                          value={value}
                                          onChange={(tz) =>
                                            onChange(
                                              (tz as ITimezoneOption).value
                                            )
                                          }
                                        />
                                      )}
                                    />
                                  </ControlWrapper>
                                </GridItem>
                              </Grid>
                            </ModalBody>
                            <ModalFooter>
                              <Stack
                                direction={["column-reverse", "row"]}
                                spacing={5}
                                w="100%"
                              >
                                <Button
                                  onClick={handleScheduleModalClose}
                                  variant="outline"
                                  w="100%"
                                >
                                  Close
                                </Button>
                                <SpinnerButton
                                  isDisabled={scheduleAuctionMutation.isLoading}
                                  loading={scheduleAuctionMutation.isLoading}
                                  type="submit"
                                  w="100%"
                                  onClick={() => {
                                    onSubmitSchedule();
                                  }}
                                >
                                  Schedule
                                </SpinnerButton>
                              </Stack>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </>
                    )}
                </Stack>
              </Flex>
            </Stack>
          </Flex>
          <TenderMembersTable searchQuery={searchQuery} />
        </CardBody>
      </Card>
    </Stack>
  );
};

export default observer(AuctionDetails);
