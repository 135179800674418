import { Input, InputGroup, InputLeftElement, Stack } from "@chakra-ui/react";
import _ from "lodash";
import CurrencyFormat, { Values } from "react-currency-format";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormSetValue,
} from "react-hook-form";
import { AiOutlineNumber } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";

import { ControlWrapper } from "../../../../components/ControlWrapper";
import { UsersDropDownSelect } from "../../../../components/UsersDropDownSelect";
import { SingleMemberFormInputs } from "../../hooks/useAddAuctionMembersHandler";

const AddSingleAuctionMember = ({
  addAuctionMemberMode,
  singleMemberErrors,
  singleMemberControl,
  singleMemberSetValue,
  auctionType,
  isCustodial,
}: {
  addAuctionMemberMode: string;
  singleMemberErrors: Partial<FieldErrorsImpl<SingleMemberFormInputs>>;
  singleMemberControl: Control<SingleMemberFormInputs>;
  singleMemberSetValue: UseFormSetValue<SingleMemberFormInputs>;
  auctionType: string;
  isCustodial: boolean;
}) => {
  return (
    <Stack direction={["column-reverse", "row"]} spacing={5} w="100%">
      <ControlWrapper
        fullLine
        fade={!["all", "single"].includes(addAuctionMemberMode)}
        formLabel="Email address"
        isInvalid={singleMemberErrors.trader_id ? true : false}
        errorMessage={
          singleMemberErrors.trader_id && singleMemberErrors.trader_id.message
        }
      >
        <UsersDropDownSelect<SingleMemberFormInputs>
          isClearable
          control={singleMemberControl}
          field_name="trader_id"
          isDisabled={!["all", "single"].includes(addAuctionMemberMode)}
          placeholder="Select email"
          field_rules={{
            required: "Please select auction member email",
          }}
          onChangeSideEffect={(val) => {
            if (val) {
              singleMemberSetValue(
                "credit_balance",
                Number.parseFloat(val.credit_balance)
              );
            } else {
              singleMemberSetValue("credit_balance", Number.NaN);
            }
          }}
        />
      </ControlWrapper>
      {auctionType === "DefaultAuction" && !isCustodial && (
        <ControlWrapper
          fullLine
          fade={!["all", "single"].includes(addAuctionMemberMode)}
          formLabel="Shares"
          isInvalid={singleMemberErrors.phantom_stocks ? true : false}
          errorMessage={
            singleMemberErrors.phantom_stocks &&
            singleMemberErrors.phantom_stocks.message
          }
        >
          <Controller
            control={singleMemberControl}
            name="phantom_stocks"
            render={({ field: { value, onChange } }) => (
              <InputGroup>
                <InputLeftElement
                  children={<AiOutlineNumber />}
                  pointerEvents="none"
                />
                <Input
                  allowNegative={false}
                  as={CurrencyFormat}
                  decimalScale={0}
                  isDisabled={!["all", "single"].includes(addAuctionMemberMode)}
                  thousandSeparator={true}
                  value={_.isNaN(value) ? "" : value}
                  onValueChange={(values: Values) => {
                    onChange(values.floatValue);
                  }}
                />
              </InputGroup>
            )}
            rules={{
              required: "Please enter shares",
            }}
          />
        </ControlWrapper>
      )}
      <ControlWrapper
        fullLine
        fade={!["all", "single"].includes(addAuctionMemberMode)}
        formLabel="Available balance"
        isInvalid={singleMemberErrors.credit_balance ? true : false}
        errorMessage={
          singleMemberErrors.credit_balance &&
          singleMemberErrors.credit_balance.message
        }
      >
        <Controller
          control={singleMemberControl}
          name="credit_balance"
          render={({ field: { value, onChange } }) => (
            <InputGroup>
              <InputLeftElement
                children={<BsCurrencyDollar />}
                pointerEvents="none"
              />
              <Input
                allowNegative={false}
                as={CurrencyFormat}
                decimalScale={5}
                decimalSeparator="."
                isDisabled={!["all", "single"].includes(addAuctionMemberMode)}
                thousandSeparator={true}
                value={isNaN(value) ? "" : value}
                onValueChange={(values: Values) => {
                  onChange(values.floatValue);
                }}
              />
            </InputGroup>
          )}
          rules={{
            required: "Please enter credit balance",
          }}
        />
      </ControlWrapper>
    </Stack>
  );
};

export default AddSingleAuctionMember;
