/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type {
  AuthenticateInvestorInviteErrorResponse400,
  AuthenticateInvestorInviteLoggedInErrorResponse400,
  ErrorResponse401,
  ErrorResponse403,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
  InvestorInviteRequestRequest,
} from "./model";
import { apiCustomInstance } from "../services/apiService";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const authenticateInvestorInvite = (
  investorInviteRequestRequest: InvestorInviteRequestRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/investor/auth-investor-invite/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: investorInviteRequestRequest,
    },
    options
  );
};

export const getAuthenticateInvestorInviteMutationOptions = <
  TError =
    | AuthenticateInvestorInviteErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticateInvestorInvite>>,
    TError,
    { data: InvestorInviteRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authenticateInvestorInvite>>,
  TError,
  { data: InvestorInviteRequestRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authenticateInvestorInvite>>,
    { data: InvestorInviteRequestRequest }
  > = (props) => {
    const { data } = props ?? {};

    return authenticateInvestorInvite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticateInvestorInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof authenticateInvestorInvite>>
>;
export type AuthenticateInvestorInviteMutationBody =
  InvestorInviteRequestRequest;
export type AuthenticateInvestorInviteMutationError =
  | AuthenticateInvestorInviteErrorResponse400
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthenticateInvestorInvite = <
  TError =
    | AuthenticateInvestorInviteErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticateInvestorInvite>>,
    TError,
    { data: InvestorInviteRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authenticateInvestorInvite>>,
  TError,
  { data: InvestorInviteRequestRequest },
  TContext
> => {
  const mutationOptions = getAuthenticateInvestorInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authenticateInvestorInviteLoggedIn = (
  investorInviteRequestRequest: InvestorInviteRequestRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/investor/auth-investor-invite-logged-in/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: investorInviteRequestRequest,
    },
    options
  );
};

export const getAuthenticateInvestorInviteLoggedInMutationOptions = <
  TError =
    | AuthenticateInvestorInviteLoggedInErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticateInvestorInviteLoggedIn>>,
    TError,
    { data: InvestorInviteRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authenticateInvestorInviteLoggedIn>>,
  TError,
  { data: InvestorInviteRequestRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authenticateInvestorInviteLoggedIn>>,
    { data: InvestorInviteRequestRequest }
  > = (props) => {
    const { data } = props ?? {};

    return authenticateInvestorInviteLoggedIn(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticateInvestorInviteLoggedInMutationResult = NonNullable<
  Awaited<ReturnType<typeof authenticateInvestorInviteLoggedIn>>
>;
export type AuthenticateInvestorInviteLoggedInMutationBody =
  InvestorInviteRequestRequest;
export type AuthenticateInvestorInviteLoggedInMutationError =
  | AuthenticateInvestorInviteLoggedInErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthenticateInvestorInviteLoggedIn = <
  TError =
    | AuthenticateInvestorInviteLoggedInErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticateInvestorInviteLoggedIn>>,
    TError,
    { data: InvestorInviteRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authenticateInvestorInviteLoggedIn>>,
  TError,
  { data: InvestorInviteRequestRequest },
  TContext
> => {
  const mutationOptions =
    getAuthenticateInvestorInviteLoggedInMutationOptions(options);

  return useMutation(mutationOptions);
};
