import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Step,
  StepDescription,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useDisclosure,
  useMediaQuery,
  useRadioGroup,
  useSteps,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { FiPlus, FiZap } from "react-icons/fi";
import { HiMagnifyingGlass } from "react-icons/hi2";
import {
  TbLayoutBottombarCollapseFilled,
  TbLayoutSidebarRightCollapse,
} from "react-icons/tb";
import { toast } from "react-toastify";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";

import {
  useAuctionsDefaultAuctionCreate,
  useAuctionsDutchAuctionCreate,
} from "../../../api/auctions";
import { getGetCompanyDraftAuctionsQueryKey } from "../../../api/companies";
import { FSTIcon } from "../../../components/FSTIcon";
import { RadioCard } from "../../../components/RadioCard";
import { SpinnerButton } from "../../../components/SpinnerButton";
import { AuthService } from "../../../services";
import { auctionTypes } from "../../../utils/constants";
import AddAuctionMembers from "./auctionForms/AddAuctionMembers";
import DutchAuctionForm, { FormDutchInputs } from "./auctionForms/DutchForm";
import SellerPriceAuctionForm, {
  FormSellerInputs,
} from "./auctionForms/SellerPriceForm";

const steps = [
  {
    title: "Select Auction Type",
    description: "Please select the auction type",
    icon: HiMagnifyingGlass,
  },
  {
    title: "Add auction details",
    description: "Add details about your auction",
    icon: AiOutlinePlusCircle,
  },
  {
    title: "Add Auction Member",
    description: "Start inviting your auction members",
    icon: BsPerson,
  },
];

const custodialOptions = [
  {
    title: "Live Auction",
    description:
      "Auction results will reflect on the cap table and all participants need to be in the cap table",
    value: "true",
    key: "custodial_option",
  },
  {
    title: "Sandbox Auction",
    description:
      "Auction results will not reflect on the cap table and not all participants need to be in the cap table",
    value: "false",
    key: "non_custodial_option",
  },
];

const auctionOptions = [
  {
    label: "Seller Price Auction",
    value: auctionTypes.default,
  },
  {
    label: "Dutch Auction",
    value: auctionTypes.dutch,
  },
];

const AddAuctionModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenAuctionInfo, onToggle: onToggleAuctionInfo } =
    useDisclosure();
  const { activeStep, goToNext, goToPrevious, setActiveStep, isActiveStep } =
    useSteps({
      index: 0,
      count: steps.length,
    });
  const isLastStep = isActiveStep(steps.length - 1);
  const [isLargerThanMd] = useMediaQuery("(min-width: 768px)");
  const [isCustodial, setIsCustodial] = useState(true);
  const [auctionType, setAuctionType] = useState("");
  const [auctionId, setAuctionId] = useState<number>();

  const queryClient = useQueryClient();
  const company = AuthService.getUserCompany();

  const {
    register: sellerAuctionRegister,
    handleSubmit: sellerAuctionHandleSubmit,
    formState: { errors: sellerAuctionErrors },
    control: sellerAuctionControl,
    reset: sellerAuctionReset,
    watch: sellerAuctionWatch,
    setError: sellerAuctionSetError,
    getValues: sellerValues,
  } = useForm<FormSellerInputs>({
    defaultValues: {
      duration_days: "0",
      duration_hours: "0",
      duration_minutes: "0",
      auctionVisibility: "private",
      transactionsVisibility: "admins",
    },
  });

  const {
    register: dutchAuctionRegister,
    handleSubmit: dutchAuctionHandleSubmit,
    formState: { errors: dutchAuctionErrors },
    control: dutchAuctionControl,
    reset: dutchAuctionReset,
    watch: dutchAuctionWatch,
    getValues: dutchValues,
    setValue: dutchAuctionSetValue,
    setError: dutchAuctionSetError,
    clearErrors: dutchAuctionClearErrors,
  } = useForm<FormDutchInputs>({
    defaultValues: {
      duration_days: "0",
      duration_hours: "0",
      duration_minutes: "0",
      auctionVisibility: "private",
      transactionsVisibility: "admins",
    },
  });

  const createSellerPriceAuctionMutation = useAuctionsDefaultAuctionCreate({
    mutation: {
      onError: (err) => {
        if (!err.errors[0].detail) {
          toast.error("Error creating auction");
        }
        formServerErrorsHandler(sellerAuctionSetError, sellerValues, err);
      },
      onSuccess: (data) => {
        setAuctionId(data.id || undefined);
        queryClient.invalidateQueries(
          getGetCompanyDraftAuctionsQueryKey(company)
        );
        toast.success("Auction created successfully");
        goToNext();
      },
    },
  });

  const createDutchAuctionMutation = useAuctionsDutchAuctionCreate({
    mutation: {
      onError: (err) => {
        if (!err.errors[0].detail) {
          toast.error("Error creating auction");
        }
        formServerErrorsHandler(dutchAuctionSetError, dutchValues, err);
      },
      onSuccess: (data) => {
        setAuctionId(data.id || undefined);
        queryClient.invalidateQueries(
          getGetCompanyDraftAuctionsQueryKey(company)
        );
        toast.success("Auction created successfully");
        goToNext();
      },
    },
  });

  const onSubmitSellerAuction = sellerAuctionHandleSubmit(
    useCallback(
      (AuctionData: FormSellerInputs) => {
        createSellerPriceAuctionMutation.mutate({
          data: {
            ...AuctionData,
            buyback_amount: AuctionData.buyback_amount.toString(),
            info_document: AuctionData.info_document[0],
            is_custodial: isCustodial,
            company_uuid: company,
            duration: `${AuctionData.duration_days} ${AuctionData.duration_hours}:${AuctionData.duration_minutes}:00`,
            visibility: AuctionData.auctionVisibility,
            resolution_visibility: AuctionData.transactionsVisibility,
          },
        });
      },
      [isCustodial, sellerAuctionErrors, onOpen, onClose]
    )
  );

  const onSubmitDutchAuction = dutchAuctionHandleSubmit(
    useCallback(
      (AuctionData: FormDutchInputs) => {
        createDutchAuctionMutation.mutate({
          data: {
            ...AuctionData,
            info_document: AuctionData.info_document[0],
            is_custodial: isCustodial,
            decrease_rate: AuctionData.decrease_rate.toString(),
            minimum_price_per_share:
              AuctionData.minimum_price_per_share.toString(),
            starting_price_per_share:
              AuctionData.starting_price_per_share.toString(),
            company_uuid: company,
            duration: `${AuctionData.duration_days} ${AuctionData.duration_hours}:${AuctionData.duration_minutes}:00`,
            visibility: AuctionData.auctionVisibility,
            resolution_visibility: AuctionData.transactionsVisibility,
          },
        });
      },
      [isCustodial, sellerAuctionErrors, onOpen, onClose]
    )
  );

  const handleClose = useCallback(() => {
    onClose();
    setActiveStep(0);
    setIsCustodial(true);
    setAuctionType("");
    sellerAuctionReset();
    dutchAuctionReset();
  }, []);

  const backAction = useCallback(() => {
    goToPrevious();
  }, []);

  const { getRadioProps } = useRadioGroup({
    name: "isCustodial",
    defaultValue: isCustodial.toString(),
    value: isCustodial.toString(),
    onChange: (value) => setIsCustodial(value == "true"),
  });

  const handleNext = useCallback(() => {
    if (activeStep === 1) {
      if (auctionType === auctionTypes.default) {
        onSubmitSellerAuction();
      } else if (auctionType == auctionTypes.dutch) {
        onSubmitDutchAuction();
      }
      return;
    }
    goToNext();
  }, [activeStep, handleClose, goToNext]);

  return (
    <>
      <Button className="add_auction" leftIcon={<FiPlus />} onClick={onOpen}>
        Create new auction
      </Button>

      <Modal
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleClose}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent
          p={8}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <ModalHeader p={0}>
            <FSTIcon withRing Icon={FiZap} />
            <Box py="4">
              <Text fontSize="lg" pb="2">
                Add Auction{" "}
                {auctionType && activeStep > 0
                  ? `— ${!isCustodial ? "Non" : ""} Custodial ${
                      auctionOptions.find((type) => type.value === auctionType)
                        ?.label
                    }`
                  : ""}
              </Text>
              <Text color="gray.500" fontSize="sm" fontWeight="normal">
                Fill in the necessary details to create auction
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody px={{ base: 0, md: 6 }}>
            <Stepper
              gap="0"
              height={{ base: "200px", md: "auto" }}
              index={activeStep}
              mb="12"
              orientation={isLargerThanMd ? "horizontal" : "vertical"}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <Stack
                    align="center"
                    direction={{ base: "row", md: "column" }}
                    h="68px"
                  >
                    <StepStatus
                      active={<FSTIcon withRing Icon={step.icon} />}
                      complete={<FSTIcon withRing />}
                      incomplete={<FSTIcon withRing Icon={step.icon} />}
                    />
                    <Box
                      flexShrink="0"
                      textAlign={{ base: "left", md: "center" }}
                    >
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                  </Stack>
                  <StepSeparator
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    _vertical={{
                      ml: "6px",
                      transform: "translateY(22px)",
                    }}
                  />
                </Step>
              ))}
            </Stepper>

            {activeStep === 0 ? (
              // ======== Step 1 ========
              <>
                <Heading
                  color="gray.700"
                  fontSize="sm"
                  fontWeight="semibold"
                  mb="6px"
                >
                  Choose one
                </Heading>
                <Stack gap="3" mb="5">
                  {custodialOptions.map((option) => (
                    <RadioCard
                      key={option.value}
                      description={option.description}
                      optionId={option.key}
                      title={option.title}
                      {...getRadioProps({ value: option.value })}
                    />
                  ))}
                </Stack>
                <Heading
                  color="gray.700"
                  fontSize="sm"
                  fontWeight="semibold"
                  mb="6px"
                >
                  Auction type
                </Heading>
                <Select
                  useBasicStyles
                  id="auction_type"
                  onChange={(val) => setAuctionType(val?.value || "")}
                  options={auctionOptions}
                  placeholder="Select auction type"
                  value={auctionOptions.find(
                    (type) => type.value === auctionType
                  )}
                />
                <Flex
                  align="center"
                  alignContent="center"
                  alignItems="center"
                  cursor="pointer"
                  direction="row"
                  gap="1"
                  justifyContent="flex-start"
                  ml="2"
                  mt="2"
                  onClick={onToggleAuctionInfo}
                  textAlign="center"
                >
                  {isOpenAuctionInfo ? (
                    <TbLayoutBottombarCollapseFilled
                      onClick={onToggleAuctionInfo}
                    />
                  ) : (
                    <TbLayoutSidebarRightCollapse
                      onClick={onToggleAuctionInfo}
                    />
                  )}
                  <Text color="gray.500" fontSize="sm">
                    Info about auctions
                  </Text>
                </Flex>
                <Collapse animateOpacity in={isOpenAuctionInfo}>
                  <Text fontSize="sm" ml="2" mt="4">
                    <b>Seller Price Auction</b>: In this auction, participants
                    can place confidential buy and sell orders for shares.
                    Buyers can specify the number of shares they wish to
                    purchase and the price per share they are willing to pay.
                    Similarly, sellers can set the price per share for their
                    sell orders.
                    <div style={{ marginBottom: "15px" }} />
                    <b>Dutch Auction</b>: In this auction, the seller sets the
                    starting price and the price decreases over time. Buyers
                    place confidential orders without seeing others' bids. The
                    participants can buy the shares at the price they want.
                  </Text>
                </Collapse>
              </>
            ) : activeStep === 1 ? (
              // ======== Step 2 ========
              auctionType === auctionTypes.default ? (
                <SellerPriceAuctionForm
                  control={sellerAuctionControl}
                  errors={sellerAuctionErrors}
                  register={sellerAuctionRegister}
                  values={sellerValues}
                  watch={sellerAuctionWatch}
                />
              ) : (
                <DutchAuctionForm
                  clearErrors={dutchAuctionClearErrors}
                  control={dutchAuctionControl}
                  errors={dutchAuctionErrors}
                  register={dutchAuctionRegister}
                  setError={dutchAuctionSetError}
                  setValue={dutchAuctionSetValue}
                  values={dutchValues}
                  watch={dutchAuctionWatch}
                />
              )
            ) : (
              // ======== Step 3 ========
              <AddAuctionMembers
                auctionId={auctionId as number}
                auctionType={auctionType}
                handleClose={handleClose}
                isCustodial={isCustodial}
              />
            )}
          </ModalBody>

          {!isLastStep && (
            <ModalFooter>
              <Stack direction={["column-reverse", "row"]} spacing={5} w="100%">
                <>
                  <Button
                    onClick={activeStep > 0 ? backAction : handleClose}
                    variant="outline"
                    w="100%"
                    isDisabled={
                      createSellerPriceAuctionMutation.isLoading ||
                      createDutchAuctionMutation.isLoading
                    }
                  >
                    {activeStep > 0 ? "Back" : "Cancel"}
                  </Button>
                  <SpinnerButton
                    data-testid="add-auction-next"
                    isDisabled={auctionType === ""}
                    onClick={handleNext}
                    type="submit"
                    variant="solid"
                    w="100%"
                    loading={
                      createSellerPriceAuctionMutation.isLoading ||
                      createDutchAuctionMutation.isLoading
                    }
                  >
                    Next
                  </SpinnerButton>
                </>
              </Stack>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAuctionModal;
