import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { NavigateFunction } from "react-router-dom";

import AuthService from "./authService";

class ApiService {
  private static instance: ApiService;
  private static axiosInstance: AxiosInstance;
  private static navigate: NavigateFunction;
  private baseURL = `${import.meta.env.VITE_API_ROOT_URL}/`;
  // private baseURL = "http://api.staging.fairstock.trade/tender/";

  private constructor() {
    // axios.defaults.headers.common = {
    //   "Content-type": "application/json",
    // };
    // set base url
    ApiService.axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers: {
        authorization: AuthService.getAccessToken()
          ? `Token ${AuthService.getAccessToken()}`
          : "",
      },
    });

    // set refresh token in case failure
    ApiService.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const errorResponse = error.response ? error.response.data : error;

        if (error.response.status === 401) {
          // AuthService.resetAuthData();
        }
        if (error.response.status === 500) {
          return Promise.reject(error);
        }
        return Promise.reject(errorResponse);
      }
    );
  }

  public static setNavigate(navigate: NavigateFunction) {
    if (!ApiService.navigate) {
      ApiService.navigate = navigate;
    }
  }
  public static getInstance(): AxiosInstance {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
      if (!ApiService.axiosInstance.defaults.headers.common.authorization) {
        ApiService.axiosInstance.defaults.headers.common = {
          authorization: AuthService.getAccessToken()
            ? `Token ${AuthService.getAccessToken()}`
            : "",
        };
      }
    }

    return ApiService.axiosInstance;
  }
  // public static setToken(token: string): AxiosInstance {
  //   ApiService.accessToken = token;
  //   axios.defaults.headers.common = {
  //     authorization: `Token ${token}`,
  //   };
  //   return ApiService.axiosInstance;
  // }
}

export const apiCustomInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const API_AXIOS_INSTANCE = ApiService.getInstance();
  const source = axios.CancelToken.source();
  const promise = API_AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  })
    .then(({ data }) => data)
    .catch((error) => {
      throw error; // Propagate the error response data
    });

  // @ts-expect-error from orval documentation for custom axios instance
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

export default ApiService;
