import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Buffer } from "buffer";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdPhoto } from "react-icons/md";
import { toast } from "react-toastify";
import { useFileReader } from "src/hooks/useFileReader";

import { FSTIcon } from "./FSTIcon";
import { SpinnerButton } from "./SpinnerButton";

const MAX_IMAGE_SIZE = 512;

const dataUrlToBuffer = (dataUrl: string) => {
  const buffer = Buffer.from(dataUrl.split(",")[1]!, "base64");
  return buffer;
};
interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface UploadAvatarModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onSubmitBuffer?: (buffer: ArrayBuffer) => void;
}

export const UploadAvatarModal: React.FC<UploadAvatarModalProps> = ({
  isOpen,
  onClose,
  isLoading,
  onSubmitBuffer,
}) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [crop, setCrop] = useState<Rect | null>(null);

  const [{ result }, setFile] = useFileReader({
    method: "readAsDataURL",
  });

  const onInputFile = (acceptedFiles: File[]) => {
    if (!acceptedFiles?.length) {
      return;
    }
    setLoading(true);

    const limit = 5 * 1000000; // max limit 5mb
    const file = acceptedFiles[0]!;

    if (file.size > limit) {
      toast.error(
        `That file is too large! Max file size is ${limit / 1000000} MB`
      );
      setLoading(false);
    } else {
      setFile(file);
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, inputRef } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "image/jpg": [],
      },
      multiple: false,
      onDropAccepted: onInputFile,
      onDropRejected: (fileRejections) =>
        toast.error(fileRejections[0].errors[0].message),
    });

  const submitBuffer = useCallback(
    async (crop: Rect | null) => {
      setIsSubmitting(true);
      try {
        if (!crop) return;
        const croppedImage = await getCroppedImage(result as string, crop);
        const buffer = dataUrlToBuffer(croppedImage);
        onSubmitBuffer?.(buffer);
        onClose();
        setIsSubmitting(false);
      } catch (e) {
        console.error(e);
        setIsSubmitting(false);
      }
    },
    [onSubmitBuffer, result]
  );

  useEffect(() => {
    if (result) {
      setLoading(false);
    }
  }, [result]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FSTIcon withRing Icon={MdPhoto} />
          <Text mt="3">Upload profile picture</Text>
        </ModalHeader>
        <ModalBody>
          <VStack h="full" spacing="6">
            <Box h="500px" position="relative" w="500px">
              {loading && <Progress isIndeterminate mb="6" />}
              {result ? (
                <CropContainer image={result as string} onComplete={setCrop} />
              ) : (
                <Box margin="auto">
                  {/* <input
                    accept="image/*"
                    id="upload-avatar-input"
                    onInput={onInputFileOld}
                    style={{ display: "none" }}
                    type="file"
                  /> */}
                  <input
                    {...getInputProps()}
                    accept="image/*"
                    id="upload-avatar-input"
                    style={{ display: "none" }}
                    type="file"
                  />
                  <Stack
                    {...getRootProps()}
                    alignItems="center"
                    bg="gray.25"
                    border="1px dashed"
                    borderRadius="8px"
                    maxW="512px"
                    px="6"
                    py="4"
                    borderColor={
                      isDragReject
                        ? "error.600"
                        : isDragAccept
                          ? "primary.800"
                          : "gray.200"
                    }
                  >
                    <FSTIcon
                      withRing
                      Icon={AiOutlineCloudUpload}
                      bg="gray.100"
                      iconFill="gray.500"
                      wrapperBg="gray.50"
                    />
                    <Text fontSize="sm" onClick={(e) => e.stopPropagation()}>
                      <Text
                        as="span"
                        color="primary.800"
                        cursor="pointer"
                        onClick={() => inputRef?.current?.click()}
                        variant="sm-semibold"
                      >
                        Click to upload image
                      </Text>{" "}
                      or drag and drop
                    </Text>
                  </Stack>
                </Box>
              )}
            </Box>
            {result && (
              <Button
                as="span"
                cursor="pointer"
                onClick={() => setFile(null)}
                size="sm"
                variant="ghost"
              >
                Discard
              </Button>
            )}
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="gray" onClick={onClose} variant="ghost">
              Cancel
            </Button>
            <SpinnerButton
              loading={isLoading || isSubmitting || loading || false}
              onClick={() => submitBuffer(crop)}
            >
              Save
            </SpinnerButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface CropContainerProps {
  image: string;
  onComplete: (crop: Rect) => void;
}

const CropContainer: React.FC<CropContainerProps> = ({ image, onComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  return (
    <Box>
      <Cropper
        aspect={1}
        crop={crop}
        cropShape="round"
        image={image}
        onCropChange={setCrop}
        onCropComplete={(_, crop) => onComplete(crop)}
        onZoomChange={setZoom}
        zoom={zoom}
        style={{
          containerStyle: {
            borderRadius: "12px",
          },
        }}
      />
    </Box>
  );
};

const createImage = (url: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
};

const getCroppedImage = async (src: string, crop: Rect): Promise<string> => {
  const image = await createImage(src);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;

  const maxSize = Math.max(image.naturalWidth, image.naturalHeight);
  const resizeRatio =
    MAX_IMAGE_SIZE / maxSize < 1 ? Math.max(MAX_IMAGE_SIZE / maxSize, 0.75) : 1;

  ctx.imageSmoothingEnabled = false;
  canvas.width = canvas.height = Math.min(maxSize * resizeRatio, crop.width);

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  if (resizeRatio <= 0.75) {
    // With a smaller image, thus improved ratio. Keep doing this until the resizeRatio > 0.75.
    return getCroppedImage(canvas.toDataURL("image/png"), {
      width: canvas.width,
      height: canvas.height,
      x: 0,
      y: 0,
    });
  }

  return canvas.toDataURL("image/png");
};
