import {
  Button,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { isNaN } from "lodash";
import { useMemo, useRef, useState } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";

import {
  ResolutionVisibilityEnum,
  VisibilityEnum,
} from "../../../../api/model";
import { ControlWrapper } from "../../../../components/ControlWrapper";
import { usePillTabsStyle } from "../../../../hooks/usePillTabsStyle";
import Duration from "./Duration";
export type FormSellerInputs = {
  title: string;
  buyback_amount: number;
  duration_days: string;
  duration_hours: string;
  duration_minutes: string;
  info_document: FileList;
  auctionVisibility: VisibilityEnum;
  transactionsVisibility: ResolutionVisibilityEnum;
};

type Props = {
  control: Control<FormSellerInputs>;
  errors: Partial<FieldErrorsImpl<FormSellerInputs>>;
  register: UseFormRegister<FormSellerInputs>;
  watch: UseFormWatch<FormSellerInputs>;
  values: UseFormGetValues<FormSellerInputs>;
};

const documentInfo =
  "This is an important document that should contain all relevant information for your investors and employees. It enables your investors to decide whether to buy or sell. For the company, this includes financial statements such as the balance sheet, income statement, profit and loss statement, and cash flow statement. It could also include a letter from management regarding the company's performance. The document might also detail the history of previous options and major events in terms of the company's capitalization table. It could list the largest shareholders on the cap table and significant events such as lawsuits, among other things.";

const SellerPriceAuctionForm = ({
  control,
  errors,
  register,
  watch,
}: Props) => {
  const { tabStyle, selectedTabStyle } = usePillTabsStyle({ variant: "ghost" });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => inputRef.current?.click();
  const { ref, ...rest } = register("info_document", {
    required: "Please upload auction info document",
  }) as {
    ref: (instance: HTMLInputElement | null) => void;
  };

  const [showFullText, setShowFullText] = useState(false);

  const truncatedDocumentInfo = useMemo(
    () => `${documentInfo.slice(0, 100)}...`,
    []
  );

  return (
    <Stack spacing={5}>
      <Stack direction={{ base: "column", md: "row" }} mt="4" spacing={5}>
        <ControlWrapper
          fullLine
          errorMessage={errors.title && errors.title.message}
          formLabel="Auction title"
          isInvalid={errors.title ? true : false}
        >
          <Input
            id="title"
            placeholder="Title"
            {...register("title", { required: "Please enter auction title" })}
          />
        </ControlWrapper>
        <ControlWrapper
          fullLine
          errorMessage={errors.buyback_amount && errors.buyback_amount.message}
          formLabel="Buyback amount"
          isInvalid={errors.buyback_amount ? true : false}
        >
          <Controller
            control={control}
            name="buyback_amount"
            render={({ field: { value, onChange } }) => (
              <InputGroup>
                <InputLeftElement
                  children={<BsCurrencyDollar />}
                  pointerEvents="none"
                />
                <Input
                  allowNegative={false}
                  as={CurrencyFormat}
                  decimalScale={5}
                  decimalSeparator="."
                  thousandSeparator={true}
                  value={isNaN(value) ? "" : value}
                  onValueChange={(values: Values) => {
                    onChange(values.floatValue);
                  }}
                />
              </InputGroup>
            )}
            rules={{
              required: "Please enter buyback amount",
            }}
          />
        </ControlWrapper>
      </Stack>
      <Duration
        control={control}
        errors={errors}
        has_started={false}
        watch={watch}
      />
      <ControlWrapper
        fullLine
        errorMessage={errors.info_document && errors.info_document.message}
        formLabel="Info document"
        isInvalid={errors.info_document ? true : false}
      >
        <Stack>
          <InputGroup>
            <Input
              cursor="pointer"
              id="info_document"
              placeholder="Information document"
              pt="8px"
              type="file"
              sx={{
                "::file-selector-button": {
                  display: "none",
                },
              }}
              {...rest}
              ref={(e) => {
                ref(e);
                inputRef.current = e;
              }}
            />
            <InputRightElement mr="6px" width="fit-content">
              <Button
                leftIcon={<AiOutlinePaperClip />}
                onClick={handleClick}
                size="sm"
                variant="outline"
              >
                Browse
              </Button>
            </InputRightElement>
          </InputGroup>
        </Stack>
        <Text color="gray.500" fontSize="sm">
          {showFullText ? documentInfo : truncatedDocumentInfo}
          {"  "}
          <Button
            colorScheme="blue"
            fontSize="sm"
            onClick={() => setShowFullText(!showFullText)}
            variant="link"
          >
            {showFullText ? "Show less" : "Read more"}
          </Button>
        </Text>
      </ControlWrapper>

      <Grid
        gap={5}
        templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
      >
        <GridItem hidden colSpan={2}>
          <ControlWrapper
            fullLine
            formLabel="Auction Visibility"
            isInvalid={errors.auctionVisibility ? true : false}
            errorMessage={
              errors.auctionVisibility && errors.auctionVisibility.message
            }
          >
            <Controller
              control={control}
              name="auctionVisibility"
              render={({ field: { value, onChange } }) => (
                <Tabs
                  defaultIndex={value === "private" ? 0 : 1}
                  variant="unstyled"
                  onChange={(index) => {
                    onChange(index === 0 ? "private" : "public");
                  }}
                >
                  <TabList>
                    <Tab
                      _selected={{ ...selectedTabStyle }}
                      borderLeftRadius="8px"
                      borderRight="0"
                      sx={{
                        ...tabStyle,
                        lineHeight: "20px",
                        fontSize: "15px",
                      }}
                    >
                      Private
                    </Tab>
                    <Tab
                      _selected={{ ...selectedTabStyle }}
                      borderLeft="0"
                      borderRightRadius="8px"
                      sx={{
                        ...tabStyle,
                        lineHeight: "20px",
                        fontSize: "15px",
                      }}
                    >
                      Public
                    </Tab>
                  </TabList>
                </Tabs>
              )}
            />
          </ControlWrapper>
        </GridItem>
        <GridItem colSpan={2}>
          <ControlWrapper
            fullLine
            formLabel="Share Transaction Visibility"
            isInvalid={errors.transactionsVisibility ? true : false}
            errorMessage={
              errors.transactionsVisibility &&
              errors.transactionsVisibility.message
            }
          >
            <Controller
              control={control}
              name="transactionsVisibility"
              render={({ field: { value, onChange } }) => (
                <Tabs
                  defaultIndex={value === "members" ? 0 : 1}
                  variant="unstyled"
                  onChange={(index) => {
                    onChange(index === 0 ? "members" : "admins");
                  }}
                >
                  <TabList>
                    <Tab
                      _selected={{ ...selectedTabStyle }}
                      borderLeftRadius="8px"
                      borderRight="0"
                      sx={{
                        ...tabStyle,
                        lineHeight: "20px",
                        fontSize: "15px",
                      }}
                    >
                      All invites
                    </Tab>
                    <Tab
                      _selected={{ ...selectedTabStyle }}
                      borderLeft="0"
                      borderRightRadius="8px"
                      sx={{
                        ...tabStyle,
                        lineHeight: "20px",
                        fontSize: "15px",
                      }}
                    >
                      Admins only
                    </Tab>
                  </TabList>
                </Tabs>
              )}
            />
          </ControlWrapper>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default SellerPriceAuctionForm;
