import { Tag } from "@chakra-ui/react";
import { Center, CircularProgress } from "@chakra-ui/react";
import _ from "lodash";
import { observer } from "mobx-react-lite";

import useChosenOrdersStore from "../../hooks/useChosenOrdersStore";
import IBuyOrder from "../../models/IBuyOrder";
import IDutchBuyOrder from "../../models/IDutchBuyOrder";
import OrderType from "../../models/OrderType";
import { OrderCard } from "../OrderCard";
import OrderListWrapper from "./OrderListWrapper";

const BuyOrders = () => {
  const { ordersStore, orderStoreType } = useChosenOrdersStore();

  const orderCards = () => {
    if (orderStoreType === "DefaultAuction") {
      return (
        <OrderListWrapper>
          {_.isNil(ordersStore) ? (
            <></>
          ) : (
            ordersStore.buyOrders.map((item, key) => {
              const order: IBuyOrder = item as IBuyOrder;
              return (
                <OrderCard
                  key={key}
                  count={order.minimum_quantity}
                  index={key + 1}
                  order={item}
                  price={order.amount}
                  type={OrderType.BUY}
                />
              );
            })
          )}
        </OrderListWrapper>
      );
    } else {
      return (
        <OrderListWrapper>
          {_.isNil(ordersStore) ? (
            <></>
          ) : (
            ordersStore.buyOrders.map((item, key) => {
              const order: IDutchBuyOrder = item as IDutchBuyOrder;
              return (
                <OrderCard
                  key={key}
                  count={order.quantity}
                  index={key + 1}
                  order={item}
                  price={order.price_per_unit}
                  type={OrderType.BUY}
                />
              );
            })
          )}
        </OrderListWrapper>
      );
    }
  };

  if (ordersStore?.loading) {
    return (
      <OrderListWrapper center>
        <Center w="full">
          <CircularProgress isIndeterminate />
        </Center>
      </OrderListWrapper>
    );
  }
  if (ordersStore?.buyOrders.length == 0) {
    return (
      <OrderListWrapper center>
        <Center w="full">
          <Tag variant="muted">No buy orders yet</Tag>
        </Center>
      </OrderListWrapper>
    );
  } else {
    return <>{orderCards()}</>;
  }
};

export default observer(BuyOrders);
