import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Flex,
  FormControl,
  Grid,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { TableColumn } from "react-data-table-component";
import DatePicker from "react-datepicker";
import { FiSearch, FiX } from "react-icons/fi";
import { IoFilterSharp } from "react-icons/io5";

import { useDateUtils } from "../../hooks/useDateUtils";
import ConditionalWrapper from "../ConditionalWrapper";
import { CustomTable, CustomTableProps } from "../CustomTable";

interface IProps<T> {
  buttons?: React.ReactElement;
  columns: TableColumn<T>[];
  collapseColumns?: TableColumn<T>[];
  data: T[];
  header: string | React.ReactElement;
  isLoading: boolean;
  onPageChange: (page: number, totalRows: number) => void;
  onSearch: (searchTerm: string) => void;
  totalRows: number;
  noFilters?: boolean;
  noSearch?: boolean;
  noCard?: boolean;
  searchBarPosition?: "top" | "header";
  searchPlaceholder?: string;
  noServerPagination?: boolean;
  filterOptions?: {
    default: string;
    accessor: string;
    filterType: "text" | "date" | "select" | "number";
  }[];
}

function expandableRowComponent<T>(row: T, columns: TableColumn<T>[]) {
  return (
    <Grid gap={4}>
      <CustomTable
        noHeader
        // noTableHead
        // persistTableHead
        columns={columns}
        data={[row]}
        pagination={false}
        selectableRows={false}
        style={{ width: "100%" }}
      />
    </Grid>
  );
}

function HistoryTable<T>({
  className = "HistoryTable",
  buttons,
  columns,
  data,
  header,
  isLoading,
  onPageChange,
  onSearch,
  totalRows,
  noFilters = false,
  searchBarPosition = "top",
  searchPlaceholder = "Search",
  noServerPagination = false,
  collapseColumns = [],
  noSearch = false,
  filterOptions,
  noCard = false,
  ...restTableProps
}: IProps<T> & CustomTableProps<T>) {
  const { formatDate, subtractDaysFromDate } = useDateUtils();

  const [filersOpen, setFilersOpen] = useState(false);
  const [rangePickerEndValue, setRangePickerEndValue] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState("");
  const rangePickerStartDate = subtractDaysFromDate(rangePickerEndValue, 7);

  const onDatePickerChange = (date: Date) => {
    setRangePickerEndValue(date);
  };

  return (
    <>
      {searchBarPosition === "top" && !noSearch ? (
        <Flex justifyContent="space-between" mb="24px">
          <FormControl>
            <HStack justifyContent="space-between">
              <InputGroup
                bg="white"
                className={`${className}-search`}
                w="400px"
              >
                <InputLeftElement>
                  <Icon as={FiSearch} />
                </InputLeftElement>
                <Input
                  placeholder={searchPlaceholder}
                  value={searchTerm}
                  onChange={(evt) => {
                    setSearchTerm(evt.target.value);
                    onSearch(evt.target.value);
                  }}
                />
                {searchTerm ? (
                  <InputRightElement
                    cursor="pointer"
                    onClick={() => {
                      setSearchTerm("");
                      onSearch("");
                    }}
                  >
                    <Icon as={FiX} />
                  </InputRightElement>
                ) : null}
              </InputGroup>
              {noFilters ? null : (
                <div>
                  <Button
                    leftIcon={<IoFilterSharp />}
                    onClick={() => setFilersOpen(!filersOpen)}
                    variant="shadow"
                  >
                    Filters
                  </Button>
                </div>
              )}
            </HStack>
          </FormControl>
        </Flex>
      ) : null}
      <Collapse animateOpacity in={filersOpen}>
        <Grid gap={4} templateColumns="repeat(6, 1fr)">
          {filterOptions?.map((filter) => {
            if (filter.filterType === "text") {
              return (
                <FormControl key={filter.accessor}>
                  <InputGroup>
                    <InputLeftElement>
                      <Icon as={FiSearch} />
                    </InputLeftElement>
                    <Input placeholder={filter.default} />
                  </InputGroup>
                </FormControl>
              );
            } else if (filter.filterType === "date") {
              return (
                <Popover key={filter.accessor}>
                  <PopoverTrigger>
                    <Button variant="outline">
                      {formatDate(rangePickerStartDate)} -{" "}
                      {formatDate(rangePickerEndValue)}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent w="auto">
                    <DatePicker
                      inline
                      showMonthDropdown
                      showYearDropdown
                      onChange={onDatePickerChange}
                      selected={rangePickerEndValue}
                    />
                  </PopoverContent>
                </Popover>
              );
            } else if (filter.filterType === "select") {
              return (
                <FormControl key={filter.accessor}>
                  <InputGroup>
                    <InputLeftElement>
                      <Icon as={FiSearch} />
                    </InputLeftElement>
                    <Input placeholder={filter.default} />
                  </InputGroup>
                </FormControl>
              );
            } else if (filter.filterType === "number") {
              return (
                <FormControl key={filter.accessor}>
                  <InputGroup>
                    <InputLeftElement>
                      <Icon as={FiSearch} />
                    </InputLeftElement>
                    <Input placeholder={filter.default} />
                  </InputGroup>
                </FormControl>
              );
            }
          })}
        </Grid>
      </Collapse>
      <ConditionalWrapper
        condition={!noCard}
        wrapper={(children) => (
          <Card
            border="1px solid"
            borderColor="gray.200"
            borderRadius="8px"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);"
          >
            {children}
          </Card>
        )}
      >
        {!buttons && !header ? null : (
          <CardHeader>
            <Flex
              alignItems="center"
              flexFlow={{ base: "column", md: "row" }}
              gap={4}
              justifyContent="space-between"
            >
              <Text fontSize={18}>{header}</Text>
              <>
                <Flex alignItems="center">{buttons}</Flex>
                {searchBarPosition === "header" && !noSearch ? (
                  <FormControl
                    className={`${className}-search`}
                    sx={{ width: 400 }}
                  >
                    <InputGroup>
                      <InputLeftElement>
                        <Icon as={FiSearch} />
                      </InputLeftElement>
                      <Input
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(evt) => {
                          setSearchTerm(evt.target.value);
                          onSearch(evt.target.value);
                        }}
                      />
                      {searchTerm ? (
                        <InputRightElement
                          cursor="pointer"
                          onClick={() => {
                            setSearchTerm("");
                            onSearch("");
                          }}
                        >
                          <Icon as={FiX} />
                        </InputRightElement>
                      ) : null}
                    </InputGroup>
                  </FormControl>
                ) : null}
              </>
            </Flex>
          </CardHeader>
        )}

        <CardBody p={0}>
          <CustomTable
            persistTableHead
            className={className}
            columns={columns}
            data={data}
            expandableRows={collapseColumns.length > 0}
            onChangePage={onPageChange}
            paginationServer={!noServerPagination}
            paginationTotalRows={totalRows}
            progressPending={isLoading}
            expandableRowsComponent={({ data }) =>
              expandableRowComponent(data, collapseColumns)
            }
            {...restTableProps}
          />
        </CardBody>
      </ConditionalWrapper>
    </>
  );
}

export default observer(HistoryTable);
