import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Input,
  Link as ChakraLink,
  Skeleton,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { BsLinkedin } from "react-icons/bs";
import { MdClose, MdOutlineBorderColor, MdOutlineSave } from "react-icons/md";
import { useCompaniesRetrieve } from "src/api/companies";
import { Company } from "src/api/model";
import ConditionalWrapper from "src/components/ConditionalWrapper";
import { ControlWrapper } from "src/components/ControlWrapper";
import { SpinnerButton } from "src/components/SpinnerButton";
import TagsList from "src/components/Tags/TagsList";
import { useColorFromToken } from "src/hooks/useColorFromToken";

import CompanyPhotoUpload from "../CompanyPhotoUpload";

type CompanyCardProps = {
  isEdit?: boolean;
  viewOnly?: boolean;
  companyId: string;
  errors?: FieldErrors<Company>;
  register?: UseFormRegister<Company>;
  onCancel?: () => void;
  isSubmitting?: boolean;
  tags?: string[];
  linkedin?: string;
  onSave?: () => void;
  toggleEdit?: () => void;
};

const CompanyCard: React.FC<CompanyCardProps> = ({
  isEdit = false,
  viewOnly = false,
  onCancel = () => {},
  onSave = () => {},
  toggleEdit = () => {},
  companyId,
  errors,
  register,
  isSubmitting = false,
  tags,
  linkedin,
}) => {
  const getColorFromToken = useColorFromToken();

  const { data: companyData, isLoading: loading } = useCompaniesRetrieve(
    companyId,
    {
      // DISABLE_MARKETPLACE
      // request: {
      //   transformRequest: (data, headers) => {
      //     delete headers.authorization;
      //     return data;
      //   },
      // },
    }
  );

  return (
    <Skeleton borderRadius={8} isLoaded={!loading}>
      <Card className="companyInfoCard">
        <CardBody>
          <ControlWrapper
            errorMessage={errors ? errors.title && errors.title.message : ""}
            isInvalid={!!errors?.title}
          >
            <Stack direction={{ base: "column", sm: "row" }} spacing="32px">
              <CompanyPhotoUpload
                currentPhoto={companyData?.profile_picture ?? ""}
                isEdit={isEdit}
                title={companyData?.title}
              />
              <Flex align="start" direction="column" w="100%">
                {/* company title */}
                <Box
                  display="flex"
                  gap="0"
                  justifyContent="flex-start"
                  w="full"
                >
                  <Input
                    fontFamily="Open Sans, sans-serif"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={600}
                    id="title"
                    lineHeight="28px"
                    placeholder="Company name"
                    value={viewOnly ? companyData?.title : undefined}
                    w={isEdit ? { base: "100%", sm: "45%" } : "fit-content"}
                    {...register?.("title", {
                      required: "Please enter a company name",
                    })}
                    {...(isEdit
                      ? { isReadOnly: false }
                      : { isReadOnly: true, border: "none" })}
                  />

                  {isEdit ? (
                    <Box display="flex" gap={2}>
                      <Button
                        data-testid="cancel-company-card-button"
                        onClick={onCancel}
                        variant="outline"
                      >
                        <MdClose size="24px" />
                      </Button>
                      <SpinnerButton
                        data-testid="save-company-card-button"
                        disabled={loading || isSubmitting}
                        loading={loading || isSubmitting}
                        onClick={() => onSave()}
                        variant="solid"
                      >
                        <MdOutlineSave />
                      </SpinnerButton>
                    </Box>
                  ) : (
                    <Button
                      data-testid="edit-company-card-button"
                      hidden={viewOnly}
                      onClick={toggleEdit}
                      variant="ghost"
                    >
                      <MdOutlineBorderColor
                        color={getColorFromToken("primary.800")}
                      />
                    </Button>
                  )}
                </Box>
                {/* company link */}
                <ConditionalWrapper
                  condition={!!linkedin}
                  wrapper={(children) => (
                    <ChakraLink
                      isExternal
                      color="primary.800"
                      data-testid="linkedin-link-company-card"
                      href={linkedin}
                      paddingStart={4}
                    >
                      {children}
                    </ChakraLink>
                  )}
                >
                  <Box
                    alignItems="center"
                    as="span"
                    cursor={linkedin ? undefined : "not-allowed"}
                    display="flex"
                    gap={2}
                    paddingStart={linkedin ? 0 : 4}
                  >
                    <BsLinkedin color="#0A66C2" />
                    <Text color="primary.800" variant="sm-semibold">
                      Linkedin account
                    </Text>
                  </Box>
                </ConditionalWrapper>

                {/* company description */}
                <Textarea
                  color="gray.900"
                  fontFamily="Open Sans, sans-serif"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight={400}
                  id="description"
                  lineHeight="24px"
                  placeholder="Company description"
                  resize="none"
                  value={viewOnly ? companyData?.description ?? "" : undefined}
                  w={{ base: "80%" }}
                  {...register?.("description")}
                  {...(isEdit
                    ? { isReadOnly: false }
                    : { isReadOnly: true, border: "none" })}
                />
                {/* company industries */}
                <Box mt={1} paddingStart={4}>
                  <TagsList items={tags?.map((industry) => industry) ?? []} />
                </Box>
              </Flex>
            </Stack>
          </ControlWrapper>
        </CardBody>
      </Card>
    </Skeleton>
  );
};

export default CompanyCard;
