import { FormBackground } from "../../components/FormBackground";
import { TraderRegisterForm } from "./TraderRegister";

function TraderRegisterPage() {
  const formHeading = "Please Fill Out Form To Register To Register As Trader!";

  return (
    <FormBackground>
      <TraderRegisterForm formHeading={formHeading} type="trader" />
    </FormBackground>
  );
}

export default TraderRegisterPage;
