import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "8px",
    // _focus: {
    //   boxShadow: "none",
    // },
    boxShadow: "4px 4px 20px -10px #0000001A",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
  variants: {
    solid: {
      color: "white",
      bg: "blue.800",
      _hover: {
        bg: "blue.800",
        opacity: "92%", // same as overlay white with 8% opacity
      },
      _focus: {
        bg: "blue.800",
        opacity: "88%",
      },
      _pressed: {
        bg: "blue.800",
        opacity: "88%",
      },
      _disabled: {
        bg: "gray.100",
        border: "1px solid",
        borderColor: "gray.300",
        color: "#9ca0a8",
        _hover: {
          cursor: "not-allowed",
        },
      },
    },
    "solid-danger": {
      color: "white",
      bg: "error.600",
      _hover: {
        bg: "error.600",
        opacity: "92%",
      },
      _focus: {
        bg: "error.600",
        opacity: "88%",
      },
      _pressed: {
        bg: "error.600",
        opacity: "88%",
      },
      _disabled: {
        bg: "gray.100",
        border: "1px solid",
        borderColor: "gray.300",
        color: "#9ca0a8",
        _hover: {
          cursor: "not-allowed",
        },
      },
    },
    "solid-light": {
      color: "primary.900",
      bg: "primary.25",
      _hover: {
        bg: "primary.800",
        color: "primary.100",
      },
      _focus: {
        bg: "primary.800",
        color: "primary.100",
      },
    },
    outline: {
      bg: "white",
      color: "gray.700",
      border: "1px solid",
      borderColor: "gray.300",
      _disabled: {
        borderColor: "gray.300",
        bg: "gray.100",
        color: "gray.900",
        _hover: {
          cursor: "not-allowed",
        },
      },
    },
    "outline-danger": {
      bg: "white",
      color: "error.600",
      border: "1px solid",
      borderColor: "error.600",
      _hover: {
        bg: "error.600",
        color: "white",
      },
      _focus: {
        bg: "error.600",
        color: "white",
      },
      _loading: {
        color: "error.600",
      },
      _disabled: {
        borderColor: "gray.300",
        bg: "gray.100",
        color: "gray.900",
        _hover: {
          cursor: "not-allowed",
          color: "gray.900",
        },
      },
    },
    shadow: {
      bg: "white",
      color: "gray.700",
      border: "1px solid",
      borderColor: "gray.300",
      boxShadow: "rgba(16, 24, 40, 0.05)",
      _hover: {
        boxShadow: "rgba(16, 24, 40, 0.05)",
        bg: "gray.100",
      },
      _disabled: {
        borderColor: "gray.300",
        bg: "gray.100",
        color: "gray.900",
        _hover: {
          cursor: "not-allowed",
        },
      },
    },
    icon: {
      bg: "transparent",
      color: "blue-primary",
      border: "none",
      boxShadow: "none",
    },
    "icon-danger": {
      bg: "transparent",
      color: "error.600",
      border: "none",
      boxShadow: "none",
    },
    "icon-success": {
      bg: "transparent",
      color: "error.600",
      border: "none",
      boxShadow: "none",
    },
    text: {
      bg: "transparent",
      color: "gray.100",
      _focus: {
        bg: "primary.50",
        color: "primary.900",
        textDecoration: "none",
      },
      _hover: {
        bg: "primary.25",
        color: "primary.900",
        textDecoration: "none",
      },
    },
  },
};
