import FSTOnboarding, { FSTStep } from "../../../../components/FSTOnboarding";
import {
  adminOnboardingJourneys,
  onboardingExtras,
} from "../../../../utils/constants";

const currentJourney = adminOnboardingJourneys.ADMIN_COMPANY_PROFILE;
const currentTutorial = onboardingExtras.ADMIN_TUTORIAL;

const CompanyProfileOnboarding = () => {
  const steps: FSTStep[] = [
    {
      target: ".companyInfoCard",
      title: "Enter Company Name",
      content:
        "This is where you can set and update your company name, description, and company logo.",
      onNext: () => {
        // scroll to the bottom of the page
        window.scrollTo(0, document.body.scrollHeight);
      },
    },
    {
      target: ".sharesInfoCard",
      title: "Setting Total issued and outstanding company shares",
      content:
        "Next, you'll set the total number of shares for your company. This value is fundamental for various financial and governance processes. Please note that it can also be set only once, so ensure it's correct.",
      // You can only set this in the form. Please contact us at support@fairstock.trade to change this figure.
      onAppear: () => {
        const tab = document.querySelector(".sharesInfoTabIcon");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".userInformationCard",
      title: "Uploading Your CAP Table",
      content:
        "You can only bulk upload the cap table once. Afterwards, New shareholders have to be added manually and new transactions have to be entered into the system to assign shares.",
    },
    {
      target: ".companyPreferencesTabIcon",
      title: "Switch to Company Preferences Tab",
      content:
        "You can switch to the company preferences tab to set your company preferences.",
      spotlightClicks: true,
      disableOverlayClose: true,
      placement: "bottom",
      onAppear: () => {
        const tab = document.querySelector(".companyPreferencesTabIcon");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".companyPreferences",
      title: "Setting Company Preferences",
      content:
        "You can set your company preferences here. This includes your company's industry, and the type of company you are.",
    },
    {
      target: ".companyDocumentsTabIcon",
      title: "Switch to Company Documents Tab",
      content:
        "You can switch to the company documents tab to upload your company documents.",
      spotlightClicks: true,
      disableOverlayClose: true,
      placement: "bottom",
      onAppear: () => {
        const tab = document.querySelector(".companyDocumentsTabIcon");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".companyDocumentsTab",
      title: "Uploading Company Documents",
      content:
        "You can upload your company documents here. This includes your company's certificate of incorporation, memorandum and articles of association, and other important documents. These documents will be visible to the shareholders you invite. You can edit and delete them at any time.",
    },
    {
      target: ".companyBillingTabIcon",
      title: "Switch to Company Billing Tab",
      content:
        "You can switch to the company billing tab to view and set your company billing information.",
      spotlightClicks: true,
      disableOverlayClose: true,
      placement: "bottom",
      onAppear: () => {
        const tab = document.querySelector(".companyBillingTabIcon");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".companyCurrentPlanCard",
      title: "Viewing Your Current Plan",
      content:
        "You can view your current plan here. You can also upgrade or downgrade your plan here.",
    },
    {
      target: ".companyInvoicesAndCancelCard",
      title: "Viewing Your Invoices and managing billing details",
      content:
        "You can view your invoices from Stripe portal here, and manage your billing details such as updating your card details.",
    },
    {
      target: ".companyDataTabIcon",
      title: "Switch to Company Data Tab",
      content:
        "You can switch to the company data tab to export your company information.",
      spotlightClicks: true,
      disableOverlayClose: true,
      placement: "bottom",
      onAppear: () => {
        const tab = document.querySelector(".companyDataTabIcon");
        tab?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      },
    },
    {
      target: ".companyDataTab",
      title: "Exporting Company Data",
      content:
        "You can export your company data here. This includes your company's cap table, transactions, and other important data. This data will be exported in an excel file and sent to your email once the export is complete.",
    },
    {
      target: ".sidebar-item-manage-profile",
      title: "All Done!",
      content:
        "You're all set! You can always come back to this page to update your company profile.",
    },
  ];

  return (
    <FSTOnboarding
      journey={currentJourney}
      steps={steps}
      tutorial={currentTutorial}
    />
  );
};

export default CompanyProfileOnboarding;
