import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { isNaN } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import { HiOutlinePencil } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getAuctionsRetrieveQueryKey,
  useAuctionsDutchAuctionPartialUpdate,
} from "../../../../api/auctions";
import { useCompaniesRetrieve } from "../../../../api/companies";
import {
  DutchAuctionTyped,
  // DutchAuction,
  ResolutionVisibilityEnum,
  VisibilityEnum,
} from "../../../../api/model";
import { ControlWrapper } from "../../../../components/ControlWrapper";
import { FSTIcon } from "../../../../components/FSTIcon";
import NumberFormat from "../../../../components/NumberFormat";
import { SpinnerButton } from "../../../../components/SpinnerButton";
import { usePillTabsStyle } from "../../../../hooks/usePillTabsStyle";
import { AuthService } from "../../../../services";
import { stringDurationToObj } from "../../../../utils/dateFormatter";
import Duration from "./Duration";

export type FormDutchInputs = {
  title: string;
  total_shares: number;
  minimum_price_per_share: number;
  starting_price_per_share: number;
  cycle_duration: number;
  decrease_rate: number;
  duration_days: string;
  duration_hours: string;
  duration_minutes: string;
  company_admin_id: number;
  info_document: FileList;
  admins_only: boolean;
  is_custodial: boolean;
  auctionVisibility: VisibilityEnum;
  transactionsVisibility: ResolutionVisibilityEnum;
};

type Props = {
  icon?: boolean;
  auctionData: DutchAuctionTyped;
  onChange?: () => void;
};

const documentInfo =
  "This is an important document that should contain all relevant information for your investors and employees. It enables your investors to decide whether to buy or sell. For the company, this includes financial statements such as the balance sheet, income statement, profit and loss statement, and cash flow statement. It could also include a letter from management regarding the company's performance. The document might also detail the history of previous options and major events in terms of the company's capitalization table. It could list the largest shareholders on the cap table and significant events such as lawsuits, among other things.";

const DutchAuctionForm = ({ icon, auctionData, onChange }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { tabStyle, selectedTabStyle } = usePillTabsStyle({ variant: "ghost" });
  const queryClient = useQueryClient();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => inputRef.current?.click();

  const [showFullText, setShowFullText] = useState(false);
  const [decreaseRate, setDecreaseRate] = useState(0);

  const truncatedDocumentInfo = useMemo(
    () => `${documentInfo.slice(0, 100)}...`,
    []
  );

  const { auction_id } = useParams();

  const editMutation = useAuctionsDutchAuctionPartialUpdate({
    mutation: {
      onError: (err) => {
        if (!err.errors[0].detail) {
          toast.error("Cannot update auction");
        }
      },
      onSuccess: () => {
        if (auction_id) {
          queryClient.invalidateQueries(
            getAuctionsRetrieveQueryKey(Number.parseInt(auction_id ?? ""))
          );
        }
        toast.success("Auction updated successfully");
        handleClose();
        onChange && onChange();
      },
    },
  });

  const { data: companyData } = useCompaniesRetrieve(
    AuthService.getUserCompany()
  );

  const preloadedValues: DefaultValues<FormDutchInputs> = useMemo(
    () => ({
      title: auctionData.title,
      total_shares: auctionData.total_shares,
      minimum_price_per_share: parseFloat(auctionData.minimum_price_per_share),
      starting_price_per_share: parseFloat(
        auctionData.starting_price_per_share
      ),
      cycle_duration: auctionData.cycle_duration,
      decrease_rate: parseFloat(auctionData.decrease_rate),
      duration_days: stringDurationToObj(auctionData.duration).day.toString(),
      duration_hours: stringDurationToObj(auctionData.duration).hour.toString(),
      duration_minutes: stringDurationToObj(
        auctionData.duration
      ).minute.toString(),
      is_custodial: auctionData.is_custodial,
      auctionVisibility: auctionData.visibility,
      transactionsVisibility: auctionData.resolution_visibility,
      admins_only: auctionData.admins_only,
    }),
    [auctionData]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
    watch,
    getValues,
    setValue,
  } = useForm<FormDutchInputs>({
    defaultValues: preloadedValues,
  });

  const onSubmit = useCallback(
    (data: FormDutchInputs) => {
      const AuctionData = {
        ...data,
      };
      if (!auctionData.is_after_auction_end_date) {
        editMutation.mutate({
          id: auctionData.id,
          data: {
            ...AuctionData,
            info_document: AuctionData.info_document[0],
            duration: `${AuctionData.duration_days} ${AuctionData.duration_hours}:${AuctionData.duration_minutes}:00`,
            decrease_rate: AuctionData.decrease_rate.toString(),
            minimum_price_per_share:
              AuctionData.minimum_price_per_share.toString(),
            starting_price_per_share:
              AuctionData.starting_price_per_share.toString(),
            visibility: AuctionData.auctionVisibility,
            resolution_visibility: AuctionData.transactionsVisibility,
          },
        });
      }
    },
    [errors, onOpen, onClose]
  );

  const { ref, ...rest } = register("info_document", {}) as {
    ref: (instance: HTMLInputElement | null) => void;
  };

  const calculateDecreaseRate = useCallback(
    (
      durationInDays: number,
      durationInHours: number,
      durationInMinutes: number,
      cycleDuration: number,
      startingPrice: number,
      minimumPrice: number
    ) => {
      const finalDurationInMinutes =
        durationInDays * 24 * 60 + durationInHours * 60 + durationInMinutes;
      let newValue =
        (startingPrice - minimumPrice) /
        (finalDurationInMinutes / cycleDuration);
      newValue = parseFloat(newValue.toPrecision(5));
      setDecreaseRate(newValue);
      setValue("decrease_rate", newValue);
    },
    []
  );

  useEffect(() => {
    if (!companyData) return;
    calculateDecreaseRate(
      parseInt(getValues("duration_days")),
      parseInt(getValues("duration_hours")),
      parseInt(getValues("duration_minutes")),
      getValues("cycle_duration"),
      getValues("starting_price_per_share"),
      getValues("minimum_price_per_share")
    );
  }, [
    watch("duration_days"),
    watch("duration_hours"),
    watch("duration_minutes"),
    watch("cycle_duration"),
    watch("starting_price_per_share"),
    watch("minimum_price_per_share"),
  ]);

  const handleClose = useCallback(() => {
    onClose();
    reset();
    clearErrors();
  }, []);

  useEffect(() => {
    reset(preloadedValues);
    if (
      !preloadedValues.duration_days ||
      !preloadedValues.duration_hours ||
      !preloadedValues.duration_minutes ||
      !preloadedValues.cycle_duration ||
      !preloadedValues.starting_price_per_share ||
      !preloadedValues.minimum_price_per_share
    )
      return;
    calculateDecreaseRate(
      parseInt(preloadedValues.duration_days),
      parseInt(preloadedValues.duration_hours),
      parseInt(preloadedValues.duration_minutes),
      preloadedValues.cycle_duration,
      preloadedValues.starting_price_per_share,
      preloadedValues.minimum_price_per_share
    );
  }, [auctionData]);

  return (
    <>
      {icon ? (
        <FSTIcon
          noBorder
          pointer
          Icon={HiOutlinePencil}
          onClick={onOpen}
          size={20}
        />
      ) : (
        <Button onClick={onOpen} variant="outline">
          Edit Auction
        </Button>
      )}
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="3xl">
        <ModalOverlay />
        <ModalContent
          p={8}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <ModalHeader p={0}>
            <Center fontSize="2xl">Edit Auction</Center>
            <Divider />
          </ModalHeader>
          <ModalBody>
            <Stack spacing={5}>
              <ControlWrapper
                fullLine
                errorMessage={errors.title && errors.title.message}
                formLabel="Auction title"
                isInvalid={errors.title ? true : false}
              >
                <Input
                  id="title"
                  placeholder="Title"
                  {...register("title", {
                    required: "Please enter auction title",
                  })}
                  isDisabled={auctionData.is_after_auction_end_date}
                />
              </ControlWrapper>
              <ControlWrapper
                fullLine
                formLabel="Auctioned shares"
                isInvalid={errors.total_shares ? true : false}
                errorMessage={
                  errors.total_shares && errors.total_shares.message
                }
                helperText={
                  <>
                    Total shares in treasury is{" "}
                    <NumberFormat
                      type="number"
                      value={companyData?.company_shares ?? 0}
                    />{" "}
                    and the percentage of auctioned shares in treasury are{" "}
                    {isNaN(
                      getValues("total_shares") /
                        (companyData?.company_shares ?? 1)
                    )
                      ? "0"
                      : parseFloat(
                          (
                            (getValues("total_shares") /
                              (companyData?.company_shares ?? 1)) *
                            100
                          ).toPrecision(4)
                        )}
                    %
                  </>
                }
              >
                <Controller
                  control={control}
                  name="total_shares"
                  render={({ field: { value, onChange } }) => (
                    <Tooltip
                      label="This is the total number of shares that users who have been invited to the auction can purchase in the auction"
                      maxWidth="900px"
                    >
                      <InputGroup>
                        <Input
                          allowNegative={false}
                          as={CurrencyFormat}
                          decimalScale={0}
                          isDisabled={auctionData.has_started}
                          thousandSeparator={true}
                          value={isNaN(value) ? "" : value}
                          onValueChange={(values: Values) => {
                            onChange(values.floatValue);
                          }}
                        />
                      </InputGroup>
                    </Tooltip>
                  )}
                  rules={{
                    required: "Please enter Total Shares",
                  }}
                />
              </ControlWrapper>

              <Grid
                gap={5}
                templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
              >
                <GridItem colSpan={2}>
                  <ControlWrapper
                    fullLine
                    formLabel="Starting price"
                    isInvalid={errors.starting_price_per_share ? true : false}
                    errorMessage={
                      errors.starting_price_per_share &&
                      errors.starting_price_per_share.message
                    }
                    helperText={
                      <>
                        The value of the shares to be auctioned is{" "}
                        <NumberFormat
                          type="currency"
                          value={
                            isNaN(
                              getValues("total_shares") *
                                getValues("starting_price_per_share")
                            )
                              ? 0
                              : getValues("total_shares") *
                                getValues("starting_price_per_share")
                          }
                        />
                      </>
                    }
                  >
                    <Controller
                      control={control}
                      name="starting_price_per_share"
                      render={({ field: { value, onChange } }) => (
                        <Tooltip
                          label="This is the starting price per share then the price per share will decrease with each cycle"
                          placement="top"
                        >
                          <InputGroup>
                            <InputLeftElement
                              children={<BsCurrencyDollar />}
                              pointerEvents="none"
                            />
                            <Input
                              allowNegative={false}
                              as={CurrencyFormat}
                              decimalScale={5}
                              decimalSeparator="."
                              isDisabled={auctionData.has_started}
                              thousandSeparator={true}
                              value={isNaN(value) ? "" : value}
                              onValueChange={(values: Values) => {
                                onChange(values.value);
                              }}
                            />
                          </InputGroup>
                        </Tooltip>
                      )}
                      rules={{
                        required: "Please enter Starting Price",
                      }}
                    />
                  </ControlWrapper>
                </GridItem>
                <GridItem colSpan={2}>
                  <ControlWrapper
                    fullLine
                    formLabel="Reserve price"
                    isInvalid={errors.minimum_price_per_share ? true : false}
                    errorMessage={
                      errors.minimum_price_per_share &&
                      errors.minimum_price_per_share.message
                    }
                  >
                    <Controller
                      control={control}
                      name="minimum_price_per_share"
                      render={({ field: { value, onChange } }) => (
                        <Tooltip label="This is the minimum price per share you can't buy a share less than the minimum price per share">
                          <InputGroup>
                            <InputLeftElement
                              children={<BsCurrencyDollar />}
                              pointerEvents="none"
                            />
                            <Input
                              allowNegative={false}
                              as={CurrencyFormat}
                              decimalScale={5}
                              decimalSeparator="."
                              isDisabled={auctionData.has_started}
                              thousandSeparator={true}
                              value={isNaN(value) ? "" : value}
                              onValueChange={(values: Values) => {
                                onChange(values.value);
                              }}
                            />
                          </InputGroup>
                        </Tooltip>
                      )}
                      rules={{
                        required: "Please enter reserve price",
                        validate: (value) => {
                          const startingPrice = getValues(
                            "starting_price_per_share"
                          );
                          const current_reserve_price = parseFloat(
                            value.toString()
                          );
                          const current_starting_price = parseFloat(
                            startingPrice.toString()
                          );
                          if (current_reserve_price > current_starting_price) {
                            return "Reserve price cannot be greater than starting price";
                          }
                          if (
                            current_reserve_price === current_starting_price
                          ) {
                            return "Reserve price must be less than starting price";
                          }
                        },
                      }}
                    />
                  </ControlWrapper>
                </GridItem>
              </Grid>
              <Duration
                control={control}
                errors={errors}
                has_started={auctionData.has_started}
                watch={watch}
              />
              <Grid
                gap={5}
                templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
              >
                <GridItem colSpan={{ sm: 2, md: 1 }}>
                  <ControlWrapper
                    fullLine
                    formLabel="Decrease cycle"
                    isInvalid={errors.cycle_duration ? true : false}
                    errorMessage={
                      errors.cycle_duration && errors.cycle_duration.message
                    }
                  >
                    <Controller
                      control={control}
                      name="cycle_duration"
                      render={({ field: { value, onChange } }) => (
                        <InputGroup>
                          <Input
                            isDisabled={auctionData.has_started}
                            maxLength={4}
                            placeholder="Enter cycle duration"
                            value={value}
                            onChange={(e) => {
                              let val = e.target.value;
                              val = val.replace(/\D/g, "");
                              onChange(val);
                            }}
                          />
                          <InputRightAddon
                            children="minutes"
                            bgColor="transparent"
                          />
                        </InputGroup>
                      )}
                      rules={{
                        required: "Please enter cycle duration",
                      }}
                    />
                  </ControlWrapper>
                </GridItem>
                <GridItem
                  alignSelf="flex-end"
                  colSpan={{ sm: 2, md: 2 }}
                  color="gray.500"
                  fontSize="sm"
                >
                  <Box>
                    This means that after each{" "}
                    {getValues("cycle_duration")
                      ? getValues("cycle_duration")
                      : "—"}{" "}
                    minutes, the price will decline by{" "}
                    {isFinite(decreaseRate) && decreaseRate !== 0 ? (
                      <NumberFormat type="currency" value={decreaseRate} />
                    ) : (
                      "—"
                    )}{" "}
                    and the ending
                  </Box>
                  price would be{" "}
                  <NumberFormat
                    type="currency"
                    value={getValues("minimum_price_per_share")}
                  />{" "}
                </GridItem>
              </Grid>
              <ControlWrapper
                fullLine
                formLabel="Info document"
                isInvalid={errors.info_document ? true : false}
                errorMessage={
                  errors.info_document && errors.info_document.message
                }
              >
                <Stack>
                  <InputGroup>
                    <Input
                      cursor="pointer"
                      id="info_document"
                      isDisabled={auctionData.is_after_auction_end_date}
                      placeholder="Information document"
                      pt="8px"
                      type="file"
                      sx={{
                        "::file-selector-button": {
                          display: "none",
                        },
                      }}
                      {...rest}
                      ref={(e) => {
                        ref(e);
                        inputRef.current = e;
                      }}
                    />
                    <InputRightElement mr="6px" width="fit-content">
                      <Button
                        isDisabled={auctionData.is_after_auction_end_date}
                        leftIcon={<AiOutlinePaperClip />}
                        onClick={handleClick}
                        size="sm"
                        variant="outline"
                      >
                        Browse
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {auctionData.info_document && (
                    <Link
                      isExternal
                      color="blue-primary"
                      href={auctionData.info_document}
                    >
                      view current info document
                    </Link>
                  )}
                </Stack>
                <Text color="gray.500" fontSize="sm">
                  {showFullText ? documentInfo : truncatedDocumentInfo}
                  {"  "}
                  <Button
                    colorScheme="blue"
                    fontSize="sm"
                    onClick={() => setShowFullText(!showFullText)}
                    variant="link"
                  >
                    {showFullText ? "Show less" : "Read more"}
                  </Button>
                </Text>
              </ControlWrapper>
              <Grid
                gap={5}
                templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
              >
                <GridItem hidden colSpan={2}>
                  <ControlWrapper
                    fullLine
                    formLabel="Auction Visibility"
                    isInvalid={errors.auctionVisibility ? true : false}
                    errorMessage={
                      errors.auctionVisibility &&
                      errors.auctionVisibility.message
                    }
                  >
                    <Controller
                      control={control}
                      name="auctionVisibility"
                      render={({ field: { onChange } }) => (
                        <Tabs
                          variant="unstyled"
                          defaultIndex={
                            auctionData.visibility === "private" ? 0 : 1
                          }
                          onChange={(index) => {
                            onChange(index === 0 ? "private" : "public");
                          }}
                        >
                          <TabList>
                            <Tab
                              _selected={{ ...selectedTabStyle }}
                              borderLeftRadius="8px"
                              borderRight="0"
                              isDisabled={auctionData.is_after_auction_end_date}
                              sx={{
                                ...tabStyle,
                                lineHeight: "20px",
                                fontSize: "15px",
                              }}
                            >
                              Private
                            </Tab>
                            <Tab
                              _selected={{ ...selectedTabStyle }}
                              borderLeft="0"
                              borderRightRadius="8px"
                              isDisabled={auctionData.is_after_auction_end_date}
                              sx={{
                                ...tabStyle,
                                lineHeight: "20px",
                                fontSize: "15px",
                              }}
                            >
                              Public
                            </Tab>
                          </TabList>
                        </Tabs>
                      )}
                    />
                  </ControlWrapper>
                </GridItem>
                <GridItem colSpan={2}>
                  <ControlWrapper
                    fullLine
                    formLabel="Share Transaction Visibility"
                    isInvalid={errors.transactionsVisibility ? true : false}
                    errorMessage={
                      errors.transactionsVisibility &&
                      errors.transactionsVisibility.message
                    }
                  >
                    <Controller
                      control={control}
                      name="transactionsVisibility"
                      render={({ field: { onChange } }) => (
                        <Tabs
                          variant="unstyled"
                          defaultIndex={
                            auctionData.resolution_visibility === "members"
                              ? 0
                              : 1
                          }
                          onChange={(index) => {
                            onChange(index === 0 ? "members" : "admins");
                          }}
                        >
                          <TabList>
                            <Tab
                              _selected={{ ...selectedTabStyle }}
                              borderLeftRadius="8px"
                              borderRight="0"
                              isDisabled={auctionData.is_after_auction_end_date}
                              sx={{
                                ...tabStyle,
                                lineHeight: "20px",
                                fontSize: "15px",
                              }}
                            >
                              All invites
                            </Tab>
                            <Tab
                              _selected={{ ...selectedTabStyle }}
                              borderLeft="0"
                              borderRightRadius="8px"
                              isDisabled={auctionData.is_after_auction_end_date}
                              sx={{
                                ...tabStyle,
                                lineHeight: "20px",
                                fontSize: "15px",
                              }}
                            >
                              Admins only
                            </Tab>
                          </TabList>
                        </Tabs>
                      )}
                    />
                  </ControlWrapper>
                </GridItem>
              </Grid>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack direction={["column", "row"]} spacing={5} w="100%">
              <Button onClick={handleClose} variant="outline" w="100%">
                Cancel
              </Button>
              <SpinnerButton
                loading={editMutation.isLoading}
                onClick={handleSubmit(onSubmit)}
                type="submit"
                w="100%"
              >
                Update Auction
              </SpinnerButton>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DutchAuctionForm;
