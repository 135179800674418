import {
  Box,
  Button,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import { Controller, useForm } from "react-hook-form";
import { BsCurrencyDollar } from "react-icons/bs";
import { FiEdit2, FiZap } from "react-icons/fi";
import { toast } from "react-toastify";
import * as yup from "yup";

import { getGetCompanyCaptableQueryKey } from "../../../api/companies";
import {
  getCompaniesTransactionsListQueryKey,
  useCompaniesTransactionsCreate as useTransactionsCreditBalanceCreate,
} from "../../../api/companies";
import { ControlWrapper } from "../../../components/ControlWrapper";
import { FSTIcon } from "../../../components/FSTIcon";
import { SpinnerButton } from "../../../components/SpinnerButton";
import { UsersDropDownSelect } from "../../../components/UsersDropDownSelect";
import { usePillTabsStyle } from "../../../hooks/usePillTabsStyle";
import IFundTransactionFormModel from "../../../models/IFundTransaction";
import { AuthService } from "../../../services";
import { Modify } from "../../../utils/typescript-helpers";

type createType = {
  edit: false;
  user_email?: string;
};

type editType = {
  edit: true;
  user_email: string;
};

type CreditManualTransactionModalProps = createType | editType;

type FormInputs = Modify<
  IFundTransactionFormModel,
  {
    user_email: {
      label: string;
      value: string;
    };
  }
>;

const TransactionSchema = yup.object({
  transaction_type: yup.mixed().oneOf(["deposit", "withdraw"]).required(),
  user_email: yup.object().shape({
    value: yup.string().required("Please select a user"),
  }),
  amount: yup.number().required("Please enter a valid amount"),
});

function CreditManualTransactionModal({
  edit = false,
  user_email = "",
}: CreditManualTransactionModalProps) {
  const { tabStyle, selectedTabStyle } = usePillTabsStyle({});

  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
  } = useForm<FormInputs>({
    defaultValues: {
      ...(user_email
        ? { user_email: { value: user_email, label: user_email } }
        : {}),
      transaction_type: "deposit",
    },
    resolver: yupResolver(TransactionSchema),
  });

  const {
    mutate: createTransactionMutation,
    isLoading: createTransactionLoading,
  } = useTransactionsCreditBalanceCreate({
    mutation: {
      onSuccess: () => {
        toast.success("Transaction created successfully");
        handleClose();
      },
    },
  });

  const handleClose = useCallback(
    (refetch = true) => {
      if (refetch) {
        if (edit) {
          queryClient.invalidateQueries(
            getGetCompanyCaptableQueryKey(AuthService.getUserCompany())
          );
        } else {
          queryClient.invalidateQueries(
            getCompaniesTransactionsListQueryKey(AuthService.getUserCompany(), {
              type: "credit",
            })
          );
        }
      }
      onClose();
      clearErrors();
    },
    [onClose]
  );

  const onSubmit = useCallback(
    (data: FormInputs) => {
      const { transaction_type, user_email, amount } = data;
      createTransactionMutation({
        uuid: AuthService.getUserCompany(),
        data: {
          amount:
            transaction_type === "deposit" ? String(amount) : String(-amount),
          beneficiary:
            transaction_type === "deposit"
              ? user_email.value
              : AuthService.getUserEmail(),
          remitter:
            transaction_type === "deposit"
              ? AuthService.getUserEmail()
              : user_email.value,
          transaction_type: "credit",
        },
      });
    },
    [errors, onOpen, onClose]
  );

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <>
      {edit ? (
        <FSTIcon
          pointer
          Icon={FiEdit2}
          bg="#FCFCFD"
          className="editCreditBalanceButton"
          ml="10px"
          onClick={onOpen}
        />
      ) : (
        <Button
          className="createManualCreditTransactionButton"
          onClick={onOpen}
          variant="solid"
          w="100%"
        >
          <Text color="inherit">Create Manual Transaction</Text>
        </Button>
      )}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => handleClose(false)}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent margin="auto">
          <Box className="manualCreditTransactionModal">
            <ModalHeader>
              <FSTIcon withRing Icon={edit ? FiEdit2 : FiZap} />
              <Text mt="3">
                {edit
                  ? "Edit User Credit Balance"
                  : "Create Manual Transaction"}
              </Text>
              {!edit ? (
                <Text color="gray.500" fontSize="sm" fontWeight="light">
                  Input Customized Transaction Entries To Create Manual
                  Transaction
                </Text>
              ) : null}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={5}>
                  <Stack
                    align="center"
                    className="transactionType-step"
                    direction={["column", "row"]}
                  >
                    <FormLabel w={["100%", "60%"]}>
                      Select transaction type
                    </FormLabel>
                    <Spacer />
                    <Tabs
                      variant="unstyled"
                      onChange={(index) => {
                        setValue(
                          "transaction_type",
                          index === 0 ? "deposit" : "withdraw"
                        );
                      }}
                    >
                      <TabList>
                        <Tab
                          _selected={{ ...selectedTabStyle }}
                          borderLeftRadius="8px"
                          borderRight="0"
                          sx={{
                            ...tabStyle,
                            lineHeight: "20px",
                            fontSize: "15px",
                          }}
                        >
                          Deposit
                        </Tab>
                        <Tab
                          _selected={{ ...selectedTabStyle }}
                          borderLeft="0"
                          borderRightRadius="8px"
                          sx={{
                            ...tabStyle,
                            lineHeight: "20px",
                            fontSize: "15px",
                          }}
                        >
                          Withdrawal
                        </Tab>
                      </TabList>
                    </Tabs>
                  </Stack>
                  {!edit ? (
                    <ControlWrapper
                      fullLine
                      className="user-step"
                      formLabel="User email"
                      isInvalid={errors.user_email ? true : false}
                      errorMessage={
                        errors.user_email && errors.user_email.value?.message
                      }
                    >
                      <UsersDropDownSelect<FormInputs>
                        emailAsValue
                        showSelectedDetails
                        control={control}
                        field_name="user_email"
                        placeholder="Select a user..."
                      />
                    </ControlWrapper>
                  ) : null}
                  <ControlWrapper
                    fullLine
                    className="amount-step"
                    errorMessage={errors.amount && errors.amount.message}
                    formLabel="Enter amount"
                    isInvalid={errors.amount ? true : false}
                  >
                    <Controller
                      control={control}
                      name="amount"
                      render={({ field: { value, onChange } }) => (
                        <InputGroup>
                          <InputLeftElement
                            children={<BsCurrencyDollar />}
                            pointerEvents="none"
                          />
                          <Input
                            allowNegative={false}
                            as={CurrencyFormat}
                            decimalScale={5}
                            decimalSeparator="."
                            thousandSeparator={true}
                            value={isNaN(value) ? "" : value}
                            onValueChange={(values: Values) => {
                              onChange(values.floatValue);
                            }}
                          />
                        </InputGroup>
                      )}
                    />
                  </ControlWrapper>
                </Stack>
              </form>
            </ModalBody>

            <ModalFooter>
              <Stack direction={["column", "row"]} spacing={5} w="100%">
                <Button
                  className="cancelManualCreditTransactionButton"
                  onClick={() => handleClose(false)}
                  variant="outline"
                  w="100%"
                >
                  Cancel
                </Button>
                <SpinnerButton
                  loading={createTransactionLoading}
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  w="100%"
                >
                  {edit ? "Save" : "Create Transaction"}
                </SpinnerButton>
              </Stack>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default observer(CreditManualTransactionModal);
