import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link as ChakraLink,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useAuthInvestorRegisterCreate,
  useAuthTraderRegisterCreate,
} from "src/api/auth";
import {
  AuthTraderRegisterCreateErrorResponse400,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
} from "src/api/model";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";
import * as yup from "yup";

import { SpinnerButton } from "../../../components/SpinnerButton";
import ITraderModel from "../../../models/ITraderModel";
import { rRegisterSuccess } from "../../../routes/routes";
import { rLogin } from "../../../routes/routes";

const registerSchema = yup.object({
  first_name: yup.string().required("Please enter your first name"),
  last_name: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
});

const TraderRegisterForm = ({
  formHeading,
  type,
  invite,
}: {
  formHeading: string;
  type: "trader" | "investor";
  invite?: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    setValue,
  } = useForm<ITraderModel>({
    resolver: yupResolver(registerSchema),
  });

  const mutateOptions = {
    onError: (
      err:
        | AuthTraderRegisterCreateErrorResponse400
        | ErrorResponse405
        | ErrorResponse406
        | ErrorResponse415
        | ErrorResponse500
    ) => {
      if (!err.errors[0].detail) {
        toast.error("Error registering investor");
      }
      formServerErrorsHandler(setError, getValues, err);
    },
    onSuccess: () => {
      navigate(rRegisterSuccess);
    },
  };

  const { mutate: mutateTrader, isLoading } = useAuthTraderRegisterCreate({
    mutation: mutateOptions,
  });

  const { mutate: mutateInvestor, isLoading: isLoadingInvestor } =
    useAuthInvestorRegisterCreate({
      mutation: {
        ...mutateOptions,
        onSuccess(data) {
          if (data && data.warn === "company_limit_reached") {
            navigate(rRegisterSuccess + "?warn_investor_not_added=true");
          } else {
            navigate(rRegisterSuccess);
          }
        },
      },
    });

  const onSubmit = handleSubmit((data: ITraderModel) => {
    if (type === "investor" && invite) {
      mutateInvestor({ data: { ...data, invite_token: invite } });
    } else if (type === "investor" && !invite) {
      mutateInvestor({ data });
    } else {
      mutateTrader({ data });
    }
    clearErrors();
  });

  useEffect(() => {
    const emailQueryParam = searchParams.get("email");
    if (emailQueryParam) setValue("email", emailQueryParam);
  }, [searchParams]);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Stack gap="16px">
        <Center>
          <Heading
            color="gray.50"
            fontFamily="Open Sans"
            fontSize="16px"
            fontWeight="700"
            height="24px"
            letterSpacing="0em"
            lineHeight="24px"
            mt="1"
            textAlign="center"
          >
            {formHeading}
          </Heading>
        </Center>
        <FormControl isInvalid={!!errors.first_name}>
          <FormLabel
            color="gray.50"
            fontSize={14}
            fontWeight={400}
            htmlFor="first_name"
          >
            First name
          </FormLabel>
          <Input
            id="first_name"
            placeholder="Enter your first name here"
            type="text"
            variant="borderless"
            {...register("first_name")}
          />
          <FormErrorMessage color="error.600">
            {errors.first_name && errors.first_name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.last_name}>
          <FormLabel
            color="gray.50"
            fontSize={14}
            fontWeight={400}
            htmlFor="last_name"
          >
            Last name
          </FormLabel>
          <Input
            id="last_name"
            placeholder="Enter your last name here"
            type="text"
            variant="borderless"
            {...register("last_name")}
          />
          <FormErrorMessage color="error.600">
            {errors.last_name && errors.last_name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.email}>
          <FormLabel
            color="gray.50"
            fontSize={14}
            fontWeight={400}
            htmlFor="email"
          >
            Email address
          </FormLabel>
          <Input
            id="email"
            placeholder="Enter your email here"
            type="email"
            variant="borderless"
            {...register("email")}
          />
          <FormErrorMessage color="error.600">
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <SpinnerButton
          loading={isLoading || isLoadingInvestor}
          size="lg"
          type="submit"
          w="100%"
        >
          Register
        </SpinnerButton>
        <Text color="gray.50" fontSize="sm" textAlign="center">
          Yes i have an account?{" "}
          <Button
            as={ChakraLink}
            colorScheme="blue"
            href={rLogin}
            variant="link"
          >
            Login
          </Button>
        </Text>
      </Stack>
    </form>
  );
};

export default TraderRegisterForm;
