import { action, makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import { ApiService } from "../services";

export default class AdminTenderStore {
  constructor() {
    makeAutoObservable(this, {
      createPaymentIntent: action,
    });
  }

  createPaymentIntent = async (tender_id: number, callback?: () => void) => {
    const axios = ApiService.getInstance();
    return axios
      .get(`/auctions/${tender_id}/create-payment-intent/`)
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        if (err.response.data.detail) {
          toast.error(err.response.data.detail);
          if (callback) {
            callback();
          }
        } else {
          toast.error("Error creating payment object");
        }
      });
  };
}
