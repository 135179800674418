/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */

export type DutchAuctionTypedAuctionType =
  (typeof DutchAuctionTypedAuctionType)[keyof typeof DutchAuctionTypedAuctionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DutchAuctionTypedAuctionType = {
  DutchAuction: "DutchAuction",
} as const;
