import "simplebar-react/dist/simplebar.min.css";

import { Flex, StackDivider, VStack } from "@chakra-ui/react";
import SimpleBar from "simplebar-react";

type Props = {
  children: JSX.Element[] | JSX.Element;
  center?: boolean;
};
const OrderListWrapper = ({ center = false, children }: Props) => {
  return (
    <VStack
      align="stretch"
      as={Flex}
      divider={<StackDivider />}
      flex="1 1 auto"
      flexDir="column"
      // h="0px"
      minH="35vh"
      spacing="0"
      style={{
        scrollbarWidth: "auto",
        msOverflowStyle: "auto",
      }}
      {...(center && { justifyContent: "center" })}
    >
      <SimpleBar
        autoHide={false}
        forceVisible="y"
        style={{ maxHeight: "35vh" }}
      >
        {children}
      </SimpleBar>
    </VStack>
  );
};

export default OrderListWrapper;
