import {
  Button,
  Flex,
  HStack,
  IconButton,
  // Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BiLogOut } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useAuthLogoutCreate } from "src/api/auth";

import FSTLogo from "../../components/icons/FSTLogo";
import {
  rAdmin,
  rLogin,
  // rMarketPlace,
  rPricing,
  rRoot,
  rTenders,
  rTraderRegister,
} from "../../routes/routes";
import { AuthService } from "../../services";
import { exitPromptStore } from "../../stores";

export default function LandingNavbar({
  variant = "transparent",
}: {
  variant?: "transparent" | "solid";
}) {
  const navigate = useNavigate();

  const { mutate: logoutByApi } = useAuthLogoutCreate({
    mutation: {
      onSuccess: () => {
        AuthService.resetAuthData();
        navigate(rRoot);
      },
      onError: () => {
        AuthService.resetAuthData();
        navigate(rRoot);
      },
      meta: {
        ignore_errors: true,
      },
    },
  });

  const logout = (e: React.SyntheticEvent) => {
    exitPromptStore.confirmPrompt((res) => {
      if (res) {
        e.preventDefault();
        logoutByApi();
      }
    });
  };
  const isTrader = AuthService.getUserGroup()?.value === "Trader";

  const route = isTrader ? rTenders : rAdmin;
  const isSignedIn = AuthService.getAccessToken();
  const actionText = isSignedIn
    ? isTrader
      ? "Auctions List"
      : "Admin panel"
    : "Login";

  return (
    <HStack
      backgroundColor={variant === "solid" ? "primary.900" : "transparent"}
      h="80px"
      mb={["64px", "96px"]}
      px={["16px", "96px"]}
      w="100%"
    >
      <HStack align="center" justify="center" py="12px">
        <Link to={rRoot}>
          <Flex align="center" flexDir="row">
            <FSTLogo fill="primary.25" />
            <Text as="span" color="primary.25" fontSize="24px" ml="4px">
              <b>FairStock</b>.Trade
            </Text>
          </Flex>
        </Link>
        {/* DISABLE_MARKETPLACE */}
        {/* <Button
          as={Link}
          data-testid="navbar-marketplace"
          fontSize="14px"
          fontWeight="semibold"
          marginStart={4}
          px="18px"
          size="sm"
          to={rMarketPlace}
          variant="text"
        >
          Companies
        </Button> */}
        <Button
          as={Link}
          data-testid="navbar-marketplace"
          fontSize="14px"
          fontWeight="semibold"
          marginStart={4}
          px="18px"
          size="sm"
          to={rPricing}
          variant="text"
        >
          Pricing
        </Button>
      </HStack>
      <Spacer />

      <Stack
        direction="row"
        display={{ base: "none", md: "inline-flex" }}
        gap="12px"
      >
        {/* <Button
          isExternal
          as={ChakraLink}
          fontSize="xs"
          fontWeight="semibold"
          href="https://api.fairstock.trade/swagger/"
          px="18px"
          variant="text"
        >
          API Docs
        </Button> */}

        {isSignedIn ? (
          <>
            <Button
              fontSize="xs"
              fontWeight="semibold"
              onClick={logout}
              px="18px"
              variant="text"
            >
              Log Out
            </Button>

            <Button
              as={Link}
              data-testid="landing-go-to-action"
              fontSize="xs"
              fontWeight="semibold"
              to={route}
              variant="solid-light"
            >
              Go to {actionText}
            </Button>
          </>
        ) : (
          <>
            <Button
              as={Link}
              fontSize="xs"
              fontWeight="semibold"
              px="18px"
              to={rLogin}
              variant="text"
            >
              Log In
            </Button>
            {/* TODO: unhide when we allow signing up */}
            <Button
              hidden
              as={Link}
              fontSize="xs"
              fontWeight="semibold"
              to={rTraderRegister}
              variant="solid-light"
            >
              Sign Up
            </Button>
          </>
        )}
      </Stack>

      <Menu>
        <MenuButton
          aria-label="Options"
          as={IconButton}
          color="gray.50"
          display={{ base: "inline-flex", md: "none" }}
          fontSize="2xl"
          icon={<FiMenu />}
          size="lg"
          variant="text"
        />
        <MenuList>
          {/* <MenuItem
            isExternal
            as={ChakraLink}
            href="https://api.fairstock.trade/swagger/"
          >
            API Docs
          </MenuItem> */}
          {isSignedIn && (
            <MenuItem as={Link} to={route}>
              Go to {actionText}
            </MenuItem>
          )}
          <MenuItem
            as={Link}
            icon={isSignedIn ? <BiLogOut /> : undefined}
            onClick={isSignedIn ? logout : undefined}
            to={isSignedIn ? "#" : rLogin}
          >
            {isSignedIn ? "Log Out" : "Log In"}
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
}
