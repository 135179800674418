import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { RefObject, useState } from "react";
import { MdOutlineEmail } from "react-icons/md";

export default function Contact({
  ref = undefined,
  mini = false,
}: {
  mini?: boolean;
  ref?: RefObject<HTMLDivElement>;
}) {
  const [messageBody, setMessageBody] = useState("");

  const handleMailtoClick = () => {
    if (!messageBody) return;
    window.location.href = `mailto:support@fairstock.trade?subject=${encodeURIComponent(
      "Getting in touch - FairStock.Trade"
    )}&body=${encodeURIComponent(messageBody)}`;
  };

  return (
    <Stack
      align="center"
      direction={["column", null, "row"]}
      gap={["32px", null, null, "40px"]}
      justify="center"
      my={["32px", null, "96px"]}
      p="16px"
      px={[null, null, "32px"]}
    >
      <VStack hidden={mini} pt="32px" spacing={["16px", null, "24px"]}>
        <Heading
          alignSelf={["center", null, "start"]}
          fontSize={["2xl", null, "5xl"]}
          fontWeight="semibold"
        >
          Let&apos;s Get in Touch!
        </Heading>
        <Text
          align={["center", null, "start"]}
          color="gray.500"
          fontSize={["md", "lg"]}
        >
          Still have a question? Our team is here to support you.
        </Text>
      </VStack>
      <VStack gap="24px" px={["32px", null, null, "96px"]}>
        <Stack ref={ref} direction={["column", null, "row"]} gap="16px">
          <FormControl id="name">
            <FormLabel color="gray.700" fontSize="sm">
              Name
            </FormLabel>
            <InputGroup>
              <Input
                bg="white"
                borderColor="gray.300"
                placeholder="Your name"
                size="md"
                type="text"
              />
            </InputGroup>
          </FormControl>
          <FormControl id="name">
            <FormLabel color="gray.700" fontSize="sm">
              Email
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<MdOutlineEmail color="gray.500" />}
                pointerEvents="none"
              />
              <Input
                bg="white"
                borderColor="gray.300"
                placeholder="Email"
                size="md"
                type="text"
              />
            </InputGroup>
          </FormControl>
        </Stack>
        <FormControl id="name">
          <FormLabel color="gray.700" fontSize="sm">
            Message
          </FormLabel>
          <Textarea
            bg="white"
            borderColor="gray.300"
            onChange={(e) => setMessageBody(e.target.value)}
            placeholder="Leave us a message..."
            value={messageBody}
          />
        </FormControl>
        <FormControl id="name">
          <Button mt="8px" onClick={handleMailtoClick} variant="solid" w="100%">
            Send Message
          </Button>
        </FormControl>
      </VStack>
    </Stack>
  );
}
