/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */

export type DefaultAuctionMemberTypedResourcetype =
  (typeof DefaultAuctionMemberTypedResourcetype)[keyof typeof DefaultAuctionMemberTypedResourcetype];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DefaultAuctionMemberTypedResourcetype = {
  DefaultAuctionMember: "DefaultAuctionMember",
} as const;
