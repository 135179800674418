import {
  Button,
  Flex,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useForm } from "react-hook-form";
import { FiEdit2, FiPlus } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getGetCompanyDocumentsQueryKey,
  useAddCompanyDocument,
  useDeleteCompanyDocument,
  useGetCompanyDocuments,
  useUpdateCompanyDocument,
} from "src/api/companies";
import { CompanyDocument } from "src/api/model";
import { ConfirmationDialog } from "src/components/ConfirmationDialog";
import { ControlWrapper } from "src/components/ControlWrapper";
import { FSTIcon } from "src/components/FSTIcon";
import { HistoryTable } from "src/components/HistoryTable";
import { SpinnerButton } from "src/components/SpinnerButton";
import { AuthService } from "src/services";
import { Dict } from "src/utils/misc";
import * as yup from "yup";

type FormInputs = {
  title: string;
  description: string;
  link: string;
};

const documentSchema = yup.object({
  title: yup.string().required("Please enter a title").max(100),
  description: yup.string().max(500),
  link: yup
    .string()
    .url("Please enter a valid url")
    .required("Please enter a link"),
});

const AddDocumentModal = ({
  data = {
    title: "",
    description: "",
    link: "",
  },
  edit = false,
  selectedDocument = NaN,
}: {
  edit?: boolean;
  selectedDocument?: number;
  data?: FormInputs;
}) => {
  const queryClient = useQueryClient();

  const company = AuthService.getUserCompany();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const preloadedValues = useMemo(
    () => ({
      title: data.title,
      description: data.description,
      link: data.link,
    }),
    [data]
  );

  const {
    formState: { errors },
    reset: formReset,
    watch,
    handleSubmit,
    clearErrors,
    register,
  } = useForm<FormInputs>({
    resolver: yupResolver(documentSchema),
    defaultValues: preloadedValues,
  });

  const handleClose = () => {
    onClose();
    clearErrors();
  };

  useEffect(() => {
    formReset(preloadedValues);
  }, [isOpen]);

  const addDocumentMutation = useAddCompanyDocument({
    mutation: {
      onSuccess: () => {
        toast.success("Added document successfully");
        queryClient.invalidateQueries(getGetCompanyDocumentsQueryKey(company));
        handleClose();
      },
      onError: () => {
        toast.error("Failed to add document");
      },
    },
  });

  const updateDocumentMutation = useUpdateCompanyDocument({
    mutation: {
      onSuccess: () => {
        toast.success("Updated document successfully");
        queryClient.invalidateQueries(getGetCompanyDocumentsQueryKey(company));
        handleClose();
      },
      onError: () => {
        toast.error("Failed to update document");
      },
    },
  });

  const onSubmit = useCallback(
    (submitted: FormInputs) => {
      if (edit) {
        updateDocumentMutation.mutate({
          slug: company,
          documentId: selectedDocument.toString() ?? "",
          data: {
            title: submitted.title,
            description: submitted.description,
            link: submitted.link,
          },
        });
      } else {
        addDocumentMutation.mutate({
          slug: company,
          data: {
            title: submitted.title,
            description: submitted.description,
            link: submitted.link,
          },
        });
      }
    },
    [errors, handleClose]
  );

  return (
    <>
      {edit ? (
        <FSTIcon noBorder pointer Icon={FiEdit2} onClick={onOpen} size={20} />
      ) : (
        <Button leftIcon={<FiPlus />} onClick={onOpen} variant="solid">
          Add Document
        </Button>
      )}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={handleClose}
        size={{ base: "sm", md: "lg" }}
      >
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>
            <FSTIcon withRing Icon={edit ? FiEdit2 : FiPlus} />
            <Text mt="3">{edit ? "Edit Document" : "Add New Document"}</Text>
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack spacing={5}>
                <ControlWrapper
                  fullLine
                  errorMessage={errors.title && errors.title.message}
                  formLabel="Title"
                  isInvalid={errors.title ? true : false}
                >
                  <Input
                    id="title"
                    placeholder="Title"
                    {...register("title", {
                      required: "Please enter document title",
                    })}
                  />
                </ControlWrapper>
                <ControlWrapper
                  fullLine
                  formLabel="Description"
                  errorMessage={
                    errors.description && errors.description.message
                  }
                  isInvalid={
                    errors.description || watch("description")?.length > 500
                      ? true
                      : false
                  }
                >
                  <Textarea
                    id="description"
                    placeholder="Description"
                    {...register("description", {
                      required: "Please enter description",
                    })}
                  />
                  <Text
                    variant="xs"
                    color={
                      watch("description")?.length > 500
                        ? "error.600"
                        : "gray.500"
                    }
                  >
                    {watch("description")?.length || 0}
                    /500 characters
                  </Text>
                </ControlWrapper>
                <ControlWrapper
                  fullLine
                  errorMessage={errors.link && errors.link.message}
                  formLabel="URL"
                  isInvalid={errors.link ? true : false}
                >
                  <Input
                    id="link"
                    placeholder="https://example.com"
                    type="url"
                    {...register("link", {
                      required: "Please enter link",
                    })}
                  />
                </ControlWrapper>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack
                direction={{ base: "column-reverse", md: "row" }}
                spacing={5}
                w="100%"
              >
                <Button
                  variant="outline"
                  w="100%"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <SpinnerButton
                  type="submit"
                  w="100%"
                  isDisabled={
                    Object.keys(errors).length > 0 ||
                    !watch("title") ||
                    !watch("link")
                  }
                  loading={
                    addDocumentMutation.isLoading ||
                    updateDocumentMutation.isLoading
                  }
                >
                  {edit ? "Update" : "Add"}
                </SpinnerButton>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

const CompanyDocumentsTable = ({
  viewOnly = false,
}: {
  viewOnly?: boolean;
}) => {
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: openConfirmDelete,
    onClose: closeConfirmDelete,
  } = useDisclosure();
  const { companyId } = useParams();

  const company = viewOnly ? companyId ?? "" : AuthService.getUserCompany();

  const [selectedDocument, setSelectedDocument] = useState<CompanyDocument>();

  const { data: documents, isLoading: isLoading } = useGetCompanyDocuments(
    company,
    {
      page: currentPage,
      page_size: pageSize,
    },
    {
      request: {
        // DISABLE_MARKETPLACE
        // transformRequest: (data, headers) => {
        //   if (viewOnly) delete headers.authorization;
        //   return data;
        // },
      },
    }
  );

  const deleteAuctionMutation = useDeleteCompanyDocument({
    mutation: {
      onError: (err: AxiosError<Dict>) => {
        toast.error(
          err?.response?.data?.error ||
            "Couldn't delete document. Please try again."
        );
      },
      onSuccess: () => {
        toast.success("Document deleted successfully");
        queryClient.invalidateQueries(getGetCompanyDocumentsQueryKey(company));
        closeConfirmDelete();
      },
    },
  });

  const columns: TableColumn<CompanyDocument>[] = [
    {
      name: "Document Title",
      cell: (row) => row.title,
      selector: (row) => row.title,
    },
    {
      name: "Document Description",
      grow: 3,
      cell: (row) => (row.description === "" ? "—" : row.description),
    },
    {
      name: "Created At",
      omit: viewOnly,
      style: {
        whiteSpace: "nowrap",
      },
      width: "150px",
      cell: (row) => {
        const date = new Date(row.created);
        return (
          <div>
            {date.toLocaleDateString()}
            <br />
            {date.toLocaleTimeString()}
          </div>
        );
      },
      selector: (row) => new Date(row.created).valueOf(),
      sortFunction: (a, b) => {
        if (a.created > b.created) return -1;
        return 1;
      },
    },
    {
      name: "Modified At",
      style: {
        whiteSpace: "nowrap",
      },
      width: "150px",
      cell: (row) => {
        const date = new Date(row.modified);
        return (
          <div>
            {date.toLocaleDateString()}
            <br />
            {date.toLocaleTimeString()}
          </div>
        );
      },
      selector: (row) => new Date(row.modified).valueOf(),
      sortFunction: (a, b) => {
        if (a.modified > b.modified) return -1;
        return 1;
      },
    },
    {
      name: "Document Link",
      cell: (row) => (
        <Link isExternal color="primary.800" href={row.link}>
          <Text color="primary.800" variant="sm-semibold">
            Link to file
          </Text>
        </Link>
      ),
    },
    {
      // name: "Actions",
      omit: viewOnly,
      cell: (row) => {
        return (
          <Flex gap={3}>
            <FSTIcon
              noBorder
              pointer
              Icon={MdDeleteOutline}
              size={20}
              onClick={() => {
                setSelectedDocument(row);
                openConfirmDelete();
              }}
            />
            <AddDocumentModal
              edit
              selectedDocument={row.id}
              data={{
                description: row.description ?? "",
                link: row.link,
                title: row.title,
              }}
            />
          </Flex>
        );
      },
    },
    // {
    //   name: "created",
    //   style: {
    //     whiteSpace: "nowrap",
    //   },
    //   grow: 2,
    //   cell: (row) => new Date(row.created).toLocaleString(),
    //   selector: (row) => new Date(row.created).valueOf(),
    //   sortFunction: (a, b) => {
    //     if (a.created > b.created) return -1;
    //     return 1;
    //   },
    //   omit: false,
    // },
  ];

  return (
    <>
      <ConfirmationDialog
        confirmText="Delete"
        handleClose={closeConfirmDelete}
        isLoading={deleteAuctionMutation.isLoading}
        show={isConfirmDeleteOpen}
        showCloseButton={false}
        title="Remove Document"
        body={
          <>
            Are you sure you want to remove document:{" "}
            <Text as="b" color="blue-primary">
              {selectedDocument?.title}
            </Text>
            ?
          </>
        }
        handleSubmit={() => {
          deleteAuctionMutation.mutate({
            documentId: selectedDocument?.id.toString() ?? "",
            slug: company,
          });
        }}
      />
      <HistoryTable<CompanyDocument>
        noFilters
        noSearch
        buttons={viewOnly ? undefined : <AddDocumentModal />}
        columns={columns}
        data={documents?.results ?? []}
        header=""
        isLoading={isLoading}
        onPageChange={setCurrentPage}
        onSearch={() => {}}
        totalRows={documents?.count ?? 0}
        noData={
          <>
            <Text variant="xs-semibold">No Documents Available</Text>
          </>
        }
      />
    </>
  );
};

export default CompanyDocumentsTable;
