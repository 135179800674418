import "moment-duration-format";

import { Duration } from "duration-converter";
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { auctionTypes } from "src/utils/constants";

import ITenderModel from "../models/ITenderModel";
import { ApiService } from "../services";
import { formatDuration, stringDurationToObj } from "../utils/dateFormatter";
import { defaultOrderStore } from ".";
import DutchOrdersStore from "./dutchOrdersStore";
import OrdersStore from "./ordersStore";

// TODO: refactor TenderStore to be TenderMemberStore
class TenderStore {
  id?: string;
  tender?: ITenderModel;
  currentTenders: ITenderModel[] = [];
  endedTenders: ITenderModel[] = [];
  loadingTender = true;
  loadingTenderList = true;
  tenderEnded = false;
  countdownRemaining = "";
  shouldStartScheduledAuction = false;
  countdownCounter = 0;

  constructor() {
    makeAutoObservable(this, {
      tender: observable,
      currentTenders: observable,
      loadingTender: observable,
      loadingTenderList: observable,
      countdownRemaining: observable,
      shouldStartScheduledAuction: observable,

      endsAt: computed,
      currentBalance: computed,
      currentStocks: computed,
      isTenderFinished: computed,
      tendersLength: computed,

      getTenderMember: action,
      countdown: action.bound,
    });
  }

  get isTenderFinished(): boolean {
    if (this.tender?.auction.ends_at) {
      const now = new Date();
      const then = new Date(this.tender?.auction.ends_at);
      return now > then;
    }
    return true;
  }

  get endsAt(): string {
    if (this.tender?.auction.ends_at)
      return new Date(this.tender?.auction.ends_at).toLocaleString();
    return new Date().toLocaleString();
  }
  get currentStocks(): number {
    const ordersStore = this.auctionOrdersStore as OrdersStore;
    return (this.tender?.phantom_stocks || 0) - ordersStore?.totalSellStock;
  }
  get currentBalance(): number {
    const ordersStore = this.auctionOrdersStore;
    if (this.tender?.auction.auction_type === auctionTypes.dutch) {
      (ordersStore as DutchOrdersStore)?.setExecutedPrice(
        parseFloat(this.tender.auction.current_price_per_share ?? "")
      );
      return (
        (Number.parseFloat(this.tender?.credit_balance ?? "") || 0) -
        ((ordersStore as DutchOrdersStore)
          ?.totalBuyWorthWithExecutedPriceAfterSystemFees ?? 0)
      );
    }
    return (
      (Number.parseFloat(this.tender?.credit_balance ?? "") || 0) -
      (ordersStore?.totalBuyWorthAfterSystemFees ?? 0)
    );
  }
  countdown() {
    if (this.tender?.auction.ends_at) {
      const now = new Date();
      const then = new Date(this.tender?.auction.ends_at);
      if (now.getTime() >= then.getTime()) {
        this.countdownRemaining = "Finished";
        runInAction(() => {
          this.tenderEnded = true;
        });
      } else if (!this.tender.auction.has_started) {
        this.countdownRemaining = "Auction not running";
      } else {
        this.countdownCounter += 1;
        runInAction(() => {
          this.tenderEnded = false;
        });
        this.countdownRemaining = formatDuration(
          Duration.between(now, then).MilliSeconds
        );
        if (this.countdownCounter % 10 === 0) {
          this.getTenderParticipants();
          const ordersStore = this.auctionOrdersStore;
          ordersStore?.getOrdersList(this.tender.auction.id.toString(), true);
        }
      }
    } else {
      const duration = stringDurationToObj(this.tender?.auction.duration ?? "");
      this.countdownRemaining = `${duration.day ?? 0}d ${duration.hour ?? 0}h ${duration.minute}m`;
    }

    return "";
  }

  resetTenderMember = () => {
    runInAction(() => {
      this.id = undefined;
      this.tender = undefined;
      this.tenderEnded = false;
      this.countdownRemaining = "";
    });
  };

  getTenderMember = async (id: string) => {
    runInAction(() => {
      if (!this.tender) {
        this.loadingTender = true;
      }
    });
    const axios = ApiService.getInstance();
    axios
      .get<ITenderModel>(`/auction-members/${id}/`)
      .then((data) => {
        runInAction(() => {
          this.id = id;
          this.tender = data.data;
        });
      })
      .catch(() => {
        console.log("error in");
      })
      .finally(() => {
        runInAction(() => {
          this.loadingTender = false;
        });
      });
  };

  getAllTenders = async () => {
    runInAction(() => {
      this.loadingTenderList = true;
    });
    const axios = ApiService.getInstance();
    axios
      .get<ITenderModel[]>("/auction-members/")
      .then((data) => {
        runInAction(() => {
          [this.currentTenders, this.endedTenders] = this.splitTenders(
            data.data,
            (tenderEndDate) => tenderEndDate >= new Date()
          );
        });
      })
      .catch(() => {
        console.log("error in getAllTenders");
      })
      .finally(() => {
        runInAction(() => {
          this.loadingTenderList = false;
        });
      });
  };

  getTenderParticipants = async () => {
    if (this.tender?.auction.id && !this.isTenderFinished) {
      const axios = ApiService.getInstance();
      axios
        .get<ITenderModel>(`/auction-members/${this.tender.id}/`)
        .then((data) => {
          runInAction(() => {
            if (this.tender) {
              this.tender.auction.number_of_participants =
                data.data.auction.number_of_participants;
              if (this.tender?.auction.auction_type === auctionTypes.dutch) {
                this.tender.auction.current_price_per_share =
                  data.data.auction.current_price_per_share;
              }
              this.tender.auction = data.data.auction;
            }
          });
        })
        .catch(() => {
          console.log("error fetching tender's number of participants");
        });
    }
  };
  private splitTenders(
    array: ITenderModel[],
    isValid: (date: Date) => boolean
  ) {
    return array.reduce(
      ([currentTenders, endedTenders]: ITenderModel[][], tender) => {
        return isValid(new Date(tender.auction.ends_at))
          ? [[...currentTenders, tender], endedTenders]
          : [currentTenders, [...endedTenders, tender]];
      },
      [[], []]
    );
  }

  get tendersLength() {
    return this.currentTenders.length + this.endedTenders.length;
  }

  get auctionType() {
    return this.tender?.auction.auction_type;
  }

  get auctionOrdersStore() {
    if (this.tender)
      return defaultOrderStore(this.tender?.auction.auction_type);
  }
}

export default TenderStore;
