import { Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

interface IProps {
  name: string;
  email: string;
  emailOnHover?: boolean;
}

const NameEmailColumn: React.FC<IProps> = ({
  name,
  email,
  emailOnHover = false,
}) => {
  const [isHovering, setHovering] = useState(false);
  function handleMouseEnter() {
    setHovering(true);
  }
  function handleMouseLeave() {
    setHovering(false);
  }

  return (
    <VStack
      alignItems="flex-start"
      gap={2}
      height="100%"
      justifyContent="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      overflow="hidden"
      transition="all 0.3s ease"
      w="100%"
    >
      <Text
        color="gray.900"
        style={
          emailOnHover
            ? {
                position: "relative",
                top: isHovering ? "0px" : "10px",
                transition: isHovering ? "top ease 0.2s" : "",
              }
            : {}
        }
      >
        {name}
      </Text>
      <Text
        color="gray.500"
        noOfLines={1}
        textOverflow="ellipsis"
        style={
          emailOnHover
            ? {
                position: "relative",
                top: isHovering ? "0px" : "10px",
                transition: isHovering
                  ? "top ease 0.2s, opacity ease 0.9s"
                  : "",
                opacity: isHovering ? 1 : 0,
              }
            : {}
        }
      >
        {email}
      </Text>
    </VStack>
  );
};
export default NameEmailColumn;
