import { Flex, Stack } from "@chakra-ui/react";
import Footer from "src/pages/Landing/Footer";
import LandingNavbar from "src/pages/Landing/LandingNavbar";

export const StandaloneLayout = ({
  noMargin,
  children,
}: {
  noMargin?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Stack backgroundColor="gray.50" bg="gray.50" h="fit-content">
      <LandingNavbar variant="solid" />
      <Flex
        flex="1"
        flexDir="column"
        gap="32px"
        justify="center"
        mb={noMargin ? "0" : "96px"}
        minH="80%"
        mx={noMargin ? "0" : ["16px", "32px", "96px"]}
      >
        {children}
      </Flex>
      <Footer />
    </Stack>
  );
};
