import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

export const Tag: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: "full",
    },
  },
  variants: {
    muted: definePartsStyle({
      container: {
        bg: "gray.100",
        color: "gray.700",
      },
    }),
    error: definePartsStyle({
      container: {
        bg: "error.50",
        color: "error.700",
      },
    }),
    success: definePartsStyle({
      container: {
        bg: "success.50",
        color: "success.700",
      },
    }),
    industry: definePartsStyle({
      container: {
        bg: "primary.25",
        borderColor: "primary.50",
        borderWidth: "1px",
        borderStyle: "solid",
        color: "primary.700",
      },
      closeButton: {
        color: "blue.400",
        opacity: 1,
        _hover: {
          opacity: 0.4,
        },
      },
    }),
  },
});
