import { Flex, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";

import TagsCreate from "./TagsCreate";

type TagsListProps = {
  items: string[];
  closeHandler?: (item: string) => void;
  isCloseable?: boolean;
  addButton?: boolean;
  addHandler?: () => void;
  onAdd?: (item: string[]) => void;
  variant?: "industry";
  addModalText?: [string, string];
  options?: string[];
  centered?: boolean;
};

type TagsListAddButtonProps = {
  items: string[];
  closeHandler?: (item: string) => void;
  isCloseable?: true;
  addButton: true;
  addHandler: () => void;
  onAdd: (items: string[]) => void;
  variant?: "industry";
  addModalText: [string, string];
  options: string[];
  centered?: boolean;
};

const TagsList = ({
  items,
  closeHandler = () => {},
  isCloseable = false,
  variant = "industry",
  addModalText = ["industry", "industries"],
  addButton = false,
  options = [],
  centered = false,
  onAdd = () => {},
}: TagsListAddButtonProps | TagsListProps) => {
  return (
    <Flex
      justifyContent={centered ? "center" : "flex-start"}
      w="100%"
      wrap="wrap"
    >
      {items.map((item) => (
        <Tag
          key={item}
          borderRadius="full"
          mb={2}
          ml={1}
          size="md"
          variant={variant}
          w="fit-content"
        >
          <TagLabel>{item}</TagLabel>
          {isCloseable && <TagCloseButton onClick={() => closeHandler(item)} />}
        </Tag>
      ))}
      {addButton && (
        <TagsCreate
          isLoading={false}
          list={items}
          onSubmit={({ tags }) => onAdd(tags)}
          options={options}
          text={addModalText}
          variant={variant}
        />
      )}
    </Flex>
  );
};

export default TagsList;
