import { Avatar, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { MdDeleteOutline, MdOutlineFileUpload } from "react-icons/md";
import { toast } from "react-toastify";
import { useUpdateCompanyPhoto } from "src/api/companies";
import { UploadAvatarModal } from "src/components/PhotoUploadModal";
import { SpinnerButton } from "src/components/SpinnerButton";
import { AuthService } from "src/services";

const CompanyPhotoUpload = ({
  currentPhoto,
  title,
  isEdit,
}: {
  currentPhoto: string | undefined;
  title?: string;
  isEdit?: boolean;
}) => {
  const company = AuthService.getUserCompany();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [photo, setPhoto] = useState(currentPhoto);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setPhoto(currentPhoto);
  }, [currentPhoto]);

  const updateCompanyPhotoMutation = useUpdateCompanyPhoto({
    mutation: {
      onSuccess: () => {
        toast.success("Updated profile logo successfully");
        // queryClient.invalidateQueries(getCompaniesRetrieveQueryKey(company));
        handleClose();
      },
      onError: () => {
        toast.error("Failed to update logo");
      },
    },
  });

  const onSubmit = useCallback(
    (file: ArrayBuffer | undefined) => {
      if (!file) {
        updateCompanyPhotoMutation.mutate({
          slug: company,
          data: {
            profile_picture: undefined,
          },
        });
        setPhoto(undefined);
        return;
      }
      const blob = new Blob([file], { type: "image/jpeg" });
      const fileWithExt = new File([blob], `profile_${company}.jpg`);

      updateCompanyPhotoMutation.mutate({
        slug: company,
        data: {
          profile_picture: fileWithExt,
        },
      });
      setPhoto(URL.createObjectURL(fileWithExt));
    },
    [handleClose]
  );

  return (
    <Flex align="center" direction="column" gap={4}>
      <Avatar name={title} size="xl" src={photo} />
      {isEdit ? (
        <>
          <Button
            color="primary.800"
            leftIcon={<MdOutlineFileUpload size="18px" />}
            onClick={onOpen}
            size="sm"
            variant="ghost"
          >
            upload
          </Button>

          <SpinnerButton
            color="error.600"
            hidden={!currentPhoto}
            leftIcon={<MdDeleteOutline color="error.600" size="18px" />}
            loading={updateCompanyPhotoMutation.isLoading}
            onClick={() => onSubmit(undefined)}
            size="sm"
            variant="ghost"
          >
            discard
          </SpinnerButton>
          <UploadAvatarModal
            isLoading={updateCompanyPhotoMutation.isLoading}
            isOpen={isOpen}
            onClose={handleClose}
            onSubmitBuffer={onSubmit}
          />
        </>
      ) : null}
    </Flex>
  );
};

export default CompanyPhotoUpload;
