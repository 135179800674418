import {
  DefaultAuctionTypedAuctionType,
  DutchAuctionTypedAuctionType,
} from "../api/model";

// DISABLE_FEES
// export const system_fee = parseFloat(import.meta.env.VITE_SYSTEM_FEE ?? "0");
export const system_fee = parseFloat("0");

export const auctionTypes = {
  default: DefaultAuctionTypedAuctionType.DefaultAuction,
  dutch: DutchAuctionTypedAuctionType.DutchAuction,
};

export const onboardingKey = "onboardingJourneys";

export enum adminOnboardingJourneys {
  ADMIN_COMPANY_PROFILE = "ADMIN_COMPANY_PROFILE",
  ADMIN_TRANSACTIONS = "ADMIN_TRANSACTIONS",
  ADMIN_CAPTABLE = "ADMIN_CAPTABLE",
}

export enum traderOnboardingJourneys {
  TRADER_DUTCH_AUCTION = "TRADER_DUTCH_AUCTION",
}

export enum onboardingExtras {
  ADMIN_TUTORIAL = "ADMIN_TUTORIAL",
  TRADER_TUTORIAL = "TRADER_TUTORIAL",
  INVESTOR_TUTORIAL = "INVESTOR_TUTORIAL",
}

export const onboardingJourneys = Object.assign(
  {},
  adminOnboardingJourneys,
  traderOnboardingJourneys
);

// Reference: https://en.wikipedia.org/wiki/Outline_of_industry#Major_industries
export const industries = [
  "Fishing",
  "Horticulture",
  "Tobacco",
  "Wood",
  "Aerospace",
  "Automotive",
  "Chemical",
  "Computer",
  "Construction",
  "Defense",
  "Electric power",
  "Electronics",
  "Energy",
  "Food",
  "Industrial robot",
  "Technology",
  "Low technology",
  "Meat",
  "Meat packing",
  "Mining",
  "Oil Shale",
  "Petroleum",
  "Pharmaceutical",
  "Pulp and paper",
  "Semiconductor",
  "Steel",
  "Shipbuilding",
  "Telecommunications",
  "Textile",
  "Water",
  "Advertising",
  "Broadcasting",
  "Creative",
  "Cryptocurrency",
  "Cultural",
  "Culture",
  "Education",
  "Entertainment",
  "Fashion",
  "Film",
  "Financial services",
  "Floral",
  "Gambling",
  "Healthcare",
  "Hospitality",
  "Information",
  "Insurance",
  "Internet",
  "Leisure",
  "Mass media",
  "Music",
  "News media",
  "Professional services",
  "Publishing",
  "Real estate",
  "Retail",
  "Software",
  "Sport",
  "Transport",
  "Video Games",
];
