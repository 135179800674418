import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Heading: ComponentStyleConfig = {
  variants: {
    landingHeading: {
      fontFamily: "Poppins, Open Sans, sans-serif",
      fontWeight: "700",
    },
  },
};
