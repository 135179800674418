import {
  Box,
  Button,
  Flex,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { pickBy } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { FaCheckCircle, FaRegCircle, FaStarOfLife } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useCompaniesRetrieve } from "src/api/companies";

import { useAuthOnboardingExtraRetrieve } from "../../api/auth";
import {
  rAdminCompany,
  rAdminTransactions,
  rAdminUsers,
} from "../../routes/routes";
import { AuthService } from "../../services";
import { useOnboardingStore } from "../../stores";
import {
  adminOnboardingJourneys,
  onboardingExtras,
} from "../../utils/constants";
import { FSTIcon } from "../FSTIcon";
import { SpinnerButton } from "../SpinnerButton";

const journeysTitles: {
  [key: string]: {
    title: string;
    path: string;
    order?: number;
  };
} = {
  ADMIN_COMPANY_PROFILE: {
    title: "Managing company profile",
    path: rAdminCompany,
    order: 1,
  },
  ADMIN_CAPTABLE: {
    title: "Managing cap table",
    path: rAdminUsers,
    order: 2,
  },
  ADMIN_TRANSACTIONS: {
    title: "Managing transactions",
    path: rAdminTransactions,
    order: 3,
  },
};

const adminTutorial = onboardingExtras.ADMIN_TUTORIAL;
const TutorialDialog = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const isAdmin = AuthService.getUserGroup()?.value === "Admin";
  const { journeys, extra, toggleExtra, setInProgress } = useOnboardingStore();
  const [shownJourneys, setShownJourneys] = useState<typeof journeys>();

  const { data } = useCompaniesRetrieve(AuthService.getUserCompany(), {
    query: {
      enabled: isAdmin,
    },
  });

  const updateOnboardingExtra = useAuthOnboardingExtraRetrieve(adminTutorial, {
    query: {
      enabled: false,
      onSuccess: () => {
        toggleExtra(adminTutorial);
      },
    },
  });

  const handleClose = () => {
    onClose();
    updateOnboardingExtra.refetch();
  };

  useEffect(() => {
    const admin = pickBy(journeys, (_, journey) =>
      Object.values(adminOnboardingJourneys).includes(
        journey as adminOnboardingJourneys
      )
    ) as typeof journeys;
    // commented for now because of type errors
    // const user = pickBy(journeys, (_, journey) =>
    //   Object.values(traderOnboardingJourneys).includes(
    //     journey as traderOnboardingJourneys
    //   )
    // );
    isAdmin && setShownJourneys(admin);

    const shouldShow = Object.values(isAdmin ? admin : {}).some(
      (journey) => !journey
    );

    shouldShow ? onOpen() : onClose();
    return () => {};
  }, [journeys, isAdmin]);

  if (isAdmin && !data?.is_subscribed) {
    return null;
  }

  if (isAdmin && extra.ADMIN_TUTORIAL) {
    return null;
  }

  if (!isAdmin && extra.TRADER_TUTORIAL) {
    return null;
  }
  return (
    <Modal
      isCentered
      autoFocus={false}
      closeOnEsc={false}
      // closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        p={8}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader p={0}>
          <FSTIcon withRing Icon={FaStarOfLife} />
          <Box py="4">
            <Text fontSize="lg" pb="2">
              Getting Started
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody px={{ base: 0, md: 6 }}>
          <List>
            {Object.entries(shownJourneys ?? {})
              .sort(
                ([a], [b]) =>
                  journeysTitles[a].order! - journeysTitles[b].order!
              )
              .map(([journey, done]) => {
                return (
                  <Fragment key={journey}>
                    <ListItem
                      as={Button}
                      bgColor={done ? "success.100" : "gray.100"}
                      cursor={done ? "default" : "pointer"}
                      display="flex"
                      fontSize="sm"
                      fontWeight="normal"
                      mb="2"
                      variant="ghost"
                      whiteSpace="unset"
                      onClick={() => {
                        if (done) return;
                        navigate(journeysTitles[journey].path);
                        setInProgress(journey);
                        onClose();
                      }}
                    >
                      {done ? (
                        <FSTIcon
                          Icon={FaCheckCircle}
                          bg="success.100"
                          iconFill="success.600"
                          mr="2"
                        />
                      ) : (
                        <FSTIcon
                          pointer
                          Icon={FaRegCircle}
                          bg="gray.100"
                          iconFill="gray.600"
                          mr="2"
                        />
                      )}
                      <p>{journeysTitles[journey].title}</p>
                    </ListItem>
                  </Fragment>
                );
              })}
          </List>
          <Flex justifyContent="flex-end" mt="8">
            <SpinnerButton
              className="tutorial-dialog__walk-me-later"
              loading={updateOnboardingExtra.isFetching}
              loadingText=""
              onClick={handleClose}
              variant="outline"
            >
              Walk me later
            </SpinnerButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TutorialDialog;
