import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Tabs: ComponentStyleConfig = {
  defaultProps: {
    variant: "unstyled",
  },
  baseStyle: () => ({
    tab: {
      color: "primary.800",
      _selected: { fontWeight: "bold", color: "primary.800" },
    },
  }),
};
