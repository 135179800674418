// import { createBrowserRouter } from "react-router-dom";
// import App from "../App";
// import { HomePage } from "../pages/Home";
// import { LoginPage } from "../pages/Login";

export const rRoot = "/";
export const rTrader = "/trader";
export const rAdmin = "/admin";

export const rLogin = "/login";
export const rTraderRegister = "/trader-register";
export const rInvestorRegister = "/investor-register";
export const rStartupRegister = "/startup-register";
export const rMarketPlace = "/marketplace";
export const rPricing = "/pricing";
export const rCompanyPublicProfile = "/company/:companyId";

export const rLoginToken = "/login/:token";

export const rRegisterSuccess = "/registered";
export const rLoginSuccess = "/email-sent";

export const rTender = rTrader + "/auction/:id";
export const rTenderVar = rTender.split(":")[0];
export const rTenders = rTrader + "/auctions";
export const rTraderTransactions = rTrader + "/transactions";
export const rTraderUser = rTrader + "/user";

export const rInvestorBrowseCompanies = rTrader + "/companies";
export const rInvestorBrowseAuctions = rTrader + "/browse-auctions";
export const rInvestorCompany = rTrader + "/companies/:companyId";

export const rAdminTenders = rAdmin + "/auctions";
export const rAdminAuctionsDraft = rAdmin + "/auctions/draft";
export const rAdminAuctionsActive = rAdmin + "/auctions/active";
export const rAdminAuctionsEnded = rAdmin + "/auctions/ended";

export const rAdminTenderDetails = rAdmin + "/auction/:auction_id";
export const rAdminUsers = rAdmin + "/users";
export const rAdminEditHistory = rAdminUsers + "/edit-history";
export const rAdminCompany = rAdmin + "/company";
export const rAdminCompanyEquity = rAdmin + "/company/equity";
export const rAdminCompanyPreferences = rAdmin + "/company/preferences";
export const rAdminCompanyDocuments = rAdmin + "/company/documents";
export const rAdminCompanyBilling = rAdmin + "/company/billing";
export const rAdminCompanyData = rAdmin + "/company/data";
export const rAdminTransactions = rAdmin + "/transactions";

export const rCompanyInvestLink = "/invest/:companyId";
export const rCompanyInvestorFormFileLink =
  "/invest/responses/download/:companyId/*";

// export const router = createBrowserRouter([
//   {
//     path: rRoot,
//     // here should handle if user is logged in redirect to home else redirect to login
//     element: <App />,
//   },
//   {
//     path: rLogin,
//     element: <LoginPage />,
//   },
//   {
//     path: rHome,
//     element: <HomePage />,
//   },
// ]);
