/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type {
  CancelSubscriptionPreview,
  CancelSubscriptionPreviewRequest,
  CancelSubscriptionRequest,
  ChangeSubscriptionPreview,
  ChangeSubscriptionPreviewRequest,
  ChangeSubscriptionRequest,
  ErrorResponse401,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
  PortalRequest,
  SubscribeCancelSubscriptionCreateErrorResponse400,
  SubscribeCancelSubscriptionPreviewCreateErrorResponse400,
  SubscribeChangeSubscription2CreateErrorResponse400,
  SubscribeChangeSubscriptionPreviewCreateErrorResponse400,
  SubscribePortalCreateErrorResponse400,
  SubscribeSubscribeCreateErrorResponse400,
  Subscription,
  SubscriptionRequest,
} from "./model";
import { apiCustomInstance } from "../services/apiService";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Cancel subscription
 */
export const subscribeCancelSubscriptionCreate = (
  cancelSubscriptionRequest: CancelSubscriptionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/subscribe/cancel-subscription/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: cancelSubscriptionRequest,
    },
    options
  );
};

export const getSubscribeCancelSubscriptionCreateMutationOptions = <
  TError =
    | SubscribeCancelSubscriptionCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeCancelSubscriptionCreate>>,
    TError,
    { data: CancelSubscriptionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscribeCancelSubscriptionCreate>>,
  TError,
  { data: CancelSubscriptionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscribeCancelSubscriptionCreate>>,
    { data: CancelSubscriptionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return subscribeCancelSubscriptionCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscribeCancelSubscriptionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscribeCancelSubscriptionCreate>>
>;
export type SubscribeCancelSubscriptionCreateMutationBody =
  CancelSubscriptionRequest;
export type SubscribeCancelSubscriptionCreateMutationError =
  | SubscribeCancelSubscriptionCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useSubscribeCancelSubscriptionCreate = <
  TError =
    | SubscribeCancelSubscriptionCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeCancelSubscriptionCreate>>,
    TError,
    { data: CancelSubscriptionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof subscribeCancelSubscriptionCreate>>,
  TError,
  { data: CancelSubscriptionRequest },
  TContext
> => {
  const mutationOptions =
    getSubscribeCancelSubscriptionCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * get prorated amount for canceling subscription
 */
export const subscribeCancelSubscriptionPreviewCreate = (
  cancelSubscriptionPreviewRequest: CancelSubscriptionPreviewRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<CancelSubscriptionPreview>(
    {
      url: `/subscribe/cancel-subscription-preview/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: cancelSubscriptionPreviewRequest,
    },
    options
  );
};

export const getSubscribeCancelSubscriptionPreviewCreateMutationOptions = <
  TError =
    | SubscribeCancelSubscriptionPreviewCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeCancelSubscriptionPreviewCreate>>,
    TError,
    { data: CancelSubscriptionPreviewRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscribeCancelSubscriptionPreviewCreate>>,
  TError,
  { data: CancelSubscriptionPreviewRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscribeCancelSubscriptionPreviewCreate>>,
    { data: CancelSubscriptionPreviewRequest }
  > = (props) => {
    const { data } = props ?? {};

    return subscribeCancelSubscriptionPreviewCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscribeCancelSubscriptionPreviewCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof subscribeCancelSubscriptionPreviewCreate>>
  >;
export type SubscribeCancelSubscriptionPreviewCreateMutationBody =
  CancelSubscriptionPreviewRequest;
export type SubscribeCancelSubscriptionPreviewCreateMutationError =
  | SubscribeCancelSubscriptionPreviewCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useSubscribeCancelSubscriptionPreviewCreate = <
  TError =
    | SubscribeCancelSubscriptionPreviewCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeCancelSubscriptionPreviewCreate>>,
    TError,
    { data: CancelSubscriptionPreviewRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof subscribeCancelSubscriptionPreviewCreate>>,
  TError,
  { data: CancelSubscriptionPreviewRequest },
  TContext
> => {
  const mutationOptions =
    getSubscribeCancelSubscriptionPreviewCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Preview upgrade/downgrade subscription, will return session @ 202 if no active subscription found
 */
export const subscribeChangeSubscriptionPreviewCreate = (
  changeSubscriptionPreviewRequest: ChangeSubscriptionPreviewRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<ChangeSubscriptionPreview | void>(
    {
      url: `/subscribe/change-subscription-preview/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changeSubscriptionPreviewRequest,
    },
    options
  );
};

export const getSubscribeChangeSubscriptionPreviewCreateMutationOptions = <
  TError =
    | SubscribeChangeSubscriptionPreviewCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeChangeSubscriptionPreviewCreate>>,
    TError,
    { data: ChangeSubscriptionPreviewRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscribeChangeSubscriptionPreviewCreate>>,
  TError,
  { data: ChangeSubscriptionPreviewRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscribeChangeSubscriptionPreviewCreate>>,
    { data: ChangeSubscriptionPreviewRequest }
  > = (props) => {
    const { data } = props ?? {};

    return subscribeChangeSubscriptionPreviewCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscribeChangeSubscriptionPreviewCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof subscribeChangeSubscriptionPreviewCreate>>
  >;
export type SubscribeChangeSubscriptionPreviewCreateMutationBody =
  ChangeSubscriptionPreviewRequest;
export type SubscribeChangeSubscriptionPreviewCreateMutationError =
  | SubscribeChangeSubscriptionPreviewCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useSubscribeChangeSubscriptionPreviewCreate = <
  TError =
    | SubscribeChangeSubscriptionPreviewCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeChangeSubscriptionPreviewCreate>>,
    TError,
    { data: ChangeSubscriptionPreviewRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof subscribeChangeSubscriptionPreviewCreate>>,
  TError,
  { data: ChangeSubscriptionPreviewRequest },
  TContext
> => {
  const mutationOptions =
    getSubscribeChangeSubscriptionPreviewCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Change subscription
 */
export const subscribeChangeSubscription2Create = (
  changeSubscriptionRequest: ChangeSubscriptionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/subscribe/change-subscription2/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changeSubscriptionRequest,
    },
    options
  );
};

export const getSubscribeChangeSubscription2CreateMutationOptions = <
  TError =
    | SubscribeChangeSubscription2CreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeChangeSubscription2Create>>,
    TError,
    { data: ChangeSubscriptionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscribeChangeSubscription2Create>>,
  TError,
  { data: ChangeSubscriptionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscribeChangeSubscription2Create>>,
    { data: ChangeSubscriptionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return subscribeChangeSubscription2Create(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscribeChangeSubscription2CreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscribeChangeSubscription2Create>>
>;
export type SubscribeChangeSubscription2CreateMutationBody =
  ChangeSubscriptionRequest;
export type SubscribeChangeSubscription2CreateMutationError =
  | SubscribeChangeSubscription2CreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useSubscribeChangeSubscription2Create = <
  TError =
    | SubscribeChangeSubscription2CreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeChangeSubscription2Create>>,
    TError,
    { data: ChangeSubscriptionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof subscribeChangeSubscription2Create>>,
  TError,
  { data: ChangeSubscriptionRequest },
  TContext
> => {
  const mutationOptions =
    getSubscribeChangeSubscription2CreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Redirect to stripe portal
 */
export const subscribePortalCreate = (
  portalRequest: PortalRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/subscribe/portal/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: portalRequest,
    },
    options
  );
};

export const getSubscribePortalCreateMutationOptions = <
  TError =
    | SubscribePortalCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribePortalCreate>>,
    TError,
    { data: PortalRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscribePortalCreate>>,
  TError,
  { data: PortalRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscribePortalCreate>>,
    { data: PortalRequest }
  > = (props) => {
    const { data } = props ?? {};

    return subscribePortalCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscribePortalCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscribePortalCreate>>
>;
export type SubscribePortalCreateMutationBody = PortalRequest;
export type SubscribePortalCreateMutationError =
  | SubscribePortalCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useSubscribePortalCreate = <
  TError =
    | SubscribePortalCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribePortalCreate>>,
    TError,
    { data: PortalRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof subscribePortalCreate>>,
  TError,
  { data: PortalRequest },
  TContext
> => {
  const mutationOptions = getSubscribePortalCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Subscribe to a pricing tier
 */
export const subscribeSubscribeCreate = (
  subscriptionRequest: SubscriptionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<Subscription>(
    {
      url: `/subscribe/subscribe/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: subscriptionRequest,
    },
    options
  );
};

export const getSubscribeSubscribeCreateMutationOptions = <
  TError =
    | SubscribeSubscribeCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeSubscribeCreate>>,
    TError,
    { data: SubscriptionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscribeSubscribeCreate>>,
  TError,
  { data: SubscriptionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscribeSubscribeCreate>>,
    { data: SubscriptionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return subscribeSubscribeCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscribeSubscribeCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscribeSubscribeCreate>>
>;
export type SubscribeSubscribeCreateMutationBody = SubscriptionRequest;
export type SubscribeSubscribeCreateMutationError =
  | SubscribeSubscribeCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useSubscribeSubscribeCreate = <
  TError =
    | SubscribeSubscribeCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribeSubscribeCreate>>,
    TError,
    { data: SubscriptionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof subscribeSubscribeCreate>>,
  TError,
  { data: SubscriptionRequest },
  TContext
> => {
  const mutationOptions = getSubscribeSubscribeCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
