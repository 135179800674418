import {
  Box,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { MdDeleteOutline, MdOutlineModeEditOutline } from "react-icons/md";

import useChosenOrdersStore from "../../hooks/useChosenOrdersStore";
import { useDateUtils } from "../../hooks/useDateUtils";
import { IOrder } from "../../models/IOrder";
import OrderType from "../../models/OrderType";
import { tenderStore, userAgreementStore } from "../../stores";
import NumberFormat from "../NumberFormat";

type OrderCardProps = {
  order: IOrder;
  count: number;
  price: number;
  type: OrderType;
  index: number;
};

function OrderCard({ order, price, count, index, type }: OrderCardProps) {
  const { formatDateWithSlash } = useDateUtils();
  const { ordersStore, orderStoreType } = useChosenOrdersStore();

  const sell = type === OrderType.SELL;

  const onClickRemove = (e: React.SyntheticEvent) => {
    e.preventDefault();
    ordersStore?.deleteOrder(type, order);
    userAgreementStore.checkUserAgreement(() => {
      ordersStore?.saveOrdersList(tenderStore.tender?.auction.id);
    });
  };

  const onClickEdit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    ordersStore?.setOrder(order);
    ordersStore?.setOrderType(type);
    if (ordersStore) {
      ordersStore.editModalIndex = index - 1;
      ordersStore.showModal = true;
    }
  };

  return (
    <Box>
      <Grid
        alignItems="center"
        templateColumns={{ base: "1fr 10fr", md: "auto 1fr auto auto" }}
      >
        <Text as="span" p="16px" pl="0px" variant="sm-regular">
          {index}
        </Text>
        <VStack alignItems="baseline" px="8px" py="16px">
          {sell ? (
            <Text color="gray.500" variant="sm-semibold">
              Sell{" "}
              <Text as="span" color="primary.800">
                <NumberFormat type="number" value={count} />
              </Text>{" "}
              shares for{" "}
              <Text as="span" color="primary.800">
                <NumberFormat type="currency" value={price} />
              </Text>{" "}
              per share
            </Text>
          ) : orderStoreType === "DefaultAuction" ? (
            <Text color="gray.500" variant="sm-semibold">
              Buy minimum number of{" "}
              <Text as="span" color="primary.800">
                <NumberFormat type="number" value={count} />
              </Text>{" "}
              shares for a total of{" "}
              <Text as="span" color="primary.800">
                <NumberFormat type="currency" value={price} />
              </Text>
            </Text>
          ) : (
            <Text color="gray.500" variant="sm-semibold">
              Buy{" "}
              <Text as="b" variant="secondary">
                <NumberFormat type="number" value={count} />
              </Text>{" "}
              share{count === 1 ? "" : "s"},{" "}
              <NumberFormat type="currency" value={price} /> per share for a
              total of{" "}
              <Text as="b" variant="secondary">
                <NumberFormat type="currency" value={price * count} />
              </Text>
            </Text>
          )}

          {order.created ? (
            <>
              <HStack>
                <Tooltip
                  label={`Created at ${formatDateWithSlash({
                    date: order.created,
                    withTime: true,
                    withSeconds: true,
                  })}`}
                >
                  <Flex alignItems="center" gridGap="4px">
                    <Text color="gray.500" variant="xs-regular">
                      Last Modified:{" "}
                    </Text>
                    <Text color="primary.800" variant="xs-regular">
                      {formatDateWithSlash({
                        date: order.modified || "",
                        withTime: true,
                        withSeconds: true,
                      })}
                    </Text>
                  </Flex>
                </Tooltip>
              </HStack>
            </>
          ) : (
            <HStack>
              <Text as="b" color="error.600" textStyle="h6">
                Not saved
              </Text>
            </HStack>
          )}
        </VStack>
        <Spacer />
        {!tenderStore.tenderEnded && (
          <Box mb={{ base: "4px", md: "0px" }} mr="14px">
            <Tooltip label="Remove order">
              <IconButton
                aria-label="Remove order"
                fontSize="20px"
                icon={<Icon as={MdDeleteOutline} color="gray.500" />}
                onClick={onClickRemove}
                variant="ghost"
              />
            </Tooltip>
            <Tooltip label="Edit order">
              <IconButton
                aria-label="Edit order"
                fontSize="20px"
                icon={<Icon as={MdOutlineModeEditOutline} color="gray.500" />}
                onClick={onClickEdit}
                variant="ghost"
              />
            </Tooltip>
          </Box>
        )}
        {tenderStore.tenderEnded &&
          tenderStore.tender?.auction.has_a_published_resolution && (
            <Box mb={{ base: "8px", md: "0px" }}>
              {order.executed ? (
                <HStack>
                  <Tag variant="success">Executed</Tag>
                </HStack>
              ) : (
                <HStack>
                  <Tag variant="error">Not executed</Tag>
                </HStack>
              )}
            </Box>
          )}
      </Grid>
    </Box>
  );
}

export default observer(OrderCard);
