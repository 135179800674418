import { action, makeAutoObservable, observable, runInAction } from "mobx";

export default class ExitPromptStore {
  isBlocked = false;
  constructor() {
    makeAutoObservable(this, {
      isBlocked: observable,
      handleUpdatedOrderListChange: action,
    });
  }
  initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  };
  initPopState = (showExitPrompt: boolean) => {
    const prevLocation = window.location.pathname;
    window.onpopstate = (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          this.confirmPrompt((res) => {
            if (!res) {
              e.stopImmediatePropagation();
              showExitPrompt = false;
              history.pushState(history.state, "", prevLocation);
              showExitPrompt = true;
            }
          });
        }
        return "";
      }
    };
  };
  handleUpdatedOrderListChange = (value: boolean) => {
    runInAction(() => {
      this.isBlocked = value;
      this.initBeforeUnLoad(this.isBlocked);
      this.initPopState(this.isBlocked);
    });
  };

  confirmPrompt = (callback?: (res: boolean) => void) => {
    let res = true;
    if (this.isBlocked) {
      res = window.confirm("Changes were not saved, Reload site?");
    }
    callback && callback(res);
    if (res) {
      this.handleUpdatedOrderListChange(false);
    }
  };
}
