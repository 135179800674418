import _ from "lodash";

import { onboardingKey } from "../utils/constants";

class AuthService {
  private static keyMap: Record<string, string> = {
    accessTokenKey: "accessToken",
    userGroupKey: "userGroup",
    userEmailKey: "userEmail",
    userCompanyKey: "userCompany",
  };

  public static setAccessToken(token: string) {
    localStorage.setItem(this.keyMap["accessTokenKey"], token);
  }

  public static getAccessToken() {
    return localStorage.getItem(this.keyMap["accessTokenKey"]);
  }

  public static removeAccessToken() {
    localStorage.removeItem(this.keyMap["accessTokenKey"]);
  }

  private static setUserGroup(userGroup: string) {
    localStorage.setItem(
      this.keyMap["userGroupKey"],
      JSON.stringify(userGroup)
    );
  }

  public static getUserGroup(): {
    key: "Admin" | "CorporateAdmin" | "Trader" | "Investor";
    value: "Admin" | "Trader";
  } | null {
    const userGroup: string | null = localStorage.getItem(
      this.keyMap["userGroupKey"]
    );

    if (!_.isNil(userGroup)) {
      const key = JSON.parse(userGroup ?? "");
      return {
        key,
        value: ["Trader", "Investor"].includes(key) ? "Trader" : "Admin",
      };
    }
    return null;
  }

  private static setUserCompany(userCompany: string) {
    localStorage.setItem(
      this.keyMap["userCompanyKey"],
      JSON.stringify(userCompany)
    );
  }

  public static getUserCompany() {
    const userCompany: string | null = localStorage.getItem(
      this.keyMap["userCompanyKey"]
    );
    if (!_.isNil(userCompany)) {
      return JSON.parse(userCompany ?? "");
    }
    return null;
  }

  private static setUserEmail(userEmail: string) {
    localStorage.setItem(
      this.keyMap["userEmailKey"],
      JSON.stringify(userEmail)
    );
  }

  public static getUserEmail() {
    const userData: string | null = localStorage.getItem(
      this.keyMap["userEmailKey"]
    );
    if (!_.isNil(userData)) {
      return JSON.parse(userData ?? "");
    }
    return null;
  }
  public static setUserName(firstName: string, lastName: string) {
    localStorage.setItem(
      "userName",
      JSON.stringify(`${firstName} ${lastName}`)
    );
  }

  public static getUserName() {
    const userData: string | null = localStorage.getItem("userName");
    if (!_.isNil(userData)) {
      return JSON.parse(userData ?? "");
    }
    return null;
  }

  public static setUserInfo(userObject: {
    userGroup: string;
    userEmail: string;
    userCompany: string;
    userName: {
      firstName: string;
      lastName: string;
    };
  }) {
    this.setUserGroup(userObject.userGroup);
    this.setUserEmail(userObject.userEmail);
    this.setUserCompany(userObject.userCompany);
    this.setUserName(
      userObject.userName.firstName,
      userObject.userName.lastName
    );
  }

  public static switchCompany(userGroup: string, userCompany: string) {
    this.setUserGroup(userGroup);
    this.setUserCompany(userCompany);
    // reload the page to reset the state
    window.location.reload();
  }

  public static removeUserDetails() {
    localStorage.removeItem(this.keyMap["userEmailKey"]);
    localStorage.removeItem(this.keyMap["userGroupKey"]);
    localStorage.removeItem(this.keyMap["userCompanyKey"]);
    localStorage.removeItem("userName");
  }

  public static removeOnboardingData() {
    localStorage.removeItem(onboardingKey);
  }

  public static isUserInvestor() {
    const userGroup = this.getUserGroup();
    return userGroup?.key === "Investor";
  }

  public static resetAuthData() {
    this.removeAccessToken();
    this.removeUserDetails();
    this.removeOnboardingData();
  }
}

export default AuthService;
