import { Box, Button, CloseButton, Flex, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Step,
  TooltipRenderProps,
} from "react-joyride";
import { useCompaniesRetrieve } from "src/api/companies";
import { AuthService } from "src/services";

import { useAuthOnboardingRetrieve } from "../api/auth";
import { useColorFromToken } from "../hooks/useColorFromToken";
import { useOnboardingStore } from "../stores";
import {
  adminOnboardingJourneys,
  onboardingExtras,
  traderOnboardingJourneys,
} from "../utils/constants";

const CustomOnboardingTooltip = ({
  index,
  isLastStep,
  primaryProps,
  step,
  tooltipProps,
  size,
  closeProps,
  hideIndicator,
  backProps,
}: TooltipRenderProps & {
  hideIndicator: boolean;
}) => {
  const isFirstStep = index === 0;

  return (
    <Box
      {...tooltipProps}
      as={Stack}
      bg="primary.800"
      borderRadius="8px"
      color="gray.50"
      gap="4px"
      maxW="300px"
      padding="12px"
    >
      <Flex
        alignItems="center"
        bgColor="primary.800"
        justifyContent="space-between"
        padding="8px 0"
      >
        <Text color="inherit" variant="xs-semibold">
          Step {index + 1}
        </Text>
        <CloseButton size="sm" {...closeProps} />
      </Flex>
      <Box>
        {step.title && (
          <Text
            color="inherit"
            fontSize={index == 0 ? "14px" : "12px"}
            variant="sm-semibold"
          >
            {step.title}
          </Text>
        )}
        {step.content && (
          <Text color="inherit" fontSize="12px" variant="xs-regular">
            {step.content}
          </Text>
        )}
      </Box>
      <Flex
        alignItems="center"
        bgColor="primary.800"
        gap={hideIndicator ? "2" : "4"}
        justifyContent={hideIndicator ? "right" : "space-between"}
        padding="8px 0"
      >
        <Box hidden={hideIndicator}>
          <Stack direction="row" gap="12px">
            <Box bg="gray.25" borderRadius="50%" height="8px" width="8px" />
            {Array(size - 1)
              .fill(null)
              .map((_, i) => (
                <Box
                  key={i}
                  bg={index < i + 1 ? "brand.700" : "gray.25"}
                  borderRadius="50%"
                  height="8px"
                  width="8px"
                />
              ))}
          </Stack>
        </Box>
        <Button
          {...backProps}
          data-testid="prev-onboarding"
          hidden={isFirstStep}
          size="md"
          variant="outline"
        >
          Back
        </Button>
        <Button
          {...primaryProps}
          data-testid="next-onboarding"
          size="md"
          variant="outline"
        >
          {!isLastStep ? "Next" : "Finish"}
        </Button>
      </Flex>
    </Box>
  );
};

export type FSTStep = Step & {
  onAppear?: () => void;
  onNext?: () => void;
  waitForNext?: number;
};

const FSTOnboarding = ({
  steps,
  journey,
}: {
  steps: FSTStep[];
  journey: adminOnboardingJourneys | traderOnboardingJourneys;
  tutorial: onboardingExtras;
}) => {
  const getColorFromToken = useColorFromToken();
  const isAdmin = AuthService.getUserGroup()?.value === "Admin";

  const [localSteps, setLocalSteps] = useState(steps);
  const [joyrideKey, setJoyrideKey] = useState<string>("1");
  const [stepIndex, setStepIndex] = useState(0);
  const [running, setRunning] = useState(true);

  const { toggleJourney, journeys, inProgress, setInProgress, setInJourney } =
    useOnboardingStore();

  const updateUserJourneys = useAuthOnboardingRetrieve(journey as string, {
    query: {
      enabled: false,
      onSuccess: () => {
        // toggle journey in the local store
        toggleJourney(journey, true);
        setRunning(false);
      },
    },
  });

  const { data } = useCompaniesRetrieve(AuthService.getUserCompany(), {
    query: {
      enabled: isAdmin,
    },
  });

  useEffect(() => {
    if (inProgress === journey) {
      setLocalSteps((prev) => {
        prev[0].disableBeacon = true;

        return prev;
      });
      setJoyrideKey((prev) => prev + "-");
    }
  }, [inProgress]);

  const handleJoyrideCallback: (data: CallBackProps) => void = (data) => {
    const { action, index, type } = data;
    if (type === "tooltip" && index === 0) {
      setInJourney(true);
    }
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      if (action === ACTIONS.NEXT) {
        if (steps[index + 1] && steps[index + 1].onAppear) {
          steps[index + 1].onAppear?.();
        }
        if (steps[index].onNext) {
          steps[index].onNext?.();
        }
        if (steps[index].waitForNext) {
          setTimeout(() => {
            setStepIndex(index + 1);
          }, steps[index].waitForNext);
        } else {
          setStepIndex(index + 1);
        }
      } else if (action === ACTIONS.PREV) {
        // no previous button implemented yet
        setStepIndex(index - 1);
      } else if (action === ACTIONS.CLOSE) {
        setStepIndex(0);
        setJoyrideKey((prev) => prev + "-");
        setInProgress("");
        setInJourney(false);
        setLocalSteps((prev) => {
          prev[0].disableBeacon = false;

          return prev;
        });
      }
    }

    if (type === EVENTS.TOUR_END) {
      if (action === ACTIONS.NEXT) {
        // console.log("end of tour -- next");
      }
      if (action === ACTIONS.SKIP) {
        // console.log("end of tour -- skip");
      }
      updateUserJourneys.refetch();
      setInProgress("");
    }
    // console.groupCollapsed(type);
    // console.log(data); //eslint-disable-line no-console
    // console.groupEnd();
  };

  // Don't show onboarding if the user has already completed it
  // or if the user is admin and the current company open is not subscribed
  if (journeys[journey] || (isAdmin && !data?.is_subscribed)) {
    return null;
  }

  const TooltipComponent = (props: TooltipRenderProps) => {
    return (
      <CustomOnboardingTooltip {...props} hideIndicator={steps.length > 7} />
    );
  };

  return (
    <Joyride
      key={joyrideKey}
      disableScrollParentFix
      spotlightClicks
      callback={handleJoyrideCallback}
      continuous={true}
      run={running}
      stepIndex={stepIndex}
      steps={localSteps}
      tooltipComponent={TooltipComponent}
      styles={{
        options: {
          arrowColor: getColorFromToken("primary.800"),
          zIndex: 100000,
        },
      }}
    />
  );
};

export default FSTOnboarding;
