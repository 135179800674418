import {
  Box,
  Card,
  CardBody,
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "src/hooks/useDebounce";
import { rAdminEditHistory, rAdminUsers } from "src/routes/routes";

import AddInvestorFormModal from "./components/AddInvestorFormModal";
import CapTable from "./components/CapTable";
import CompanyEditHistory from "./components/CompanyEditHistory";
import CompanyCaptableOnboarding from "./components/Onboardings/CompanyCaptableOnboarding";
import UserFormModal from "./components/UserFormModal";

const AdminUsersPage = () => {
  // const company_id = AuthService.getUserCompany();

  // const { refetch } = useDownloadInvestorsResponses(company_id, {
  //   query: {
  //     enabled: false,
  //     onSuccess: (data) => {
  //       if (data) {
  //         const element = document.createElement("a");
  //         const file = new Blob([data], {
  //           type: "text/csv",
  //         });
  //         element.href = URL.createObjectURL(file);
  //         element.download = "investor-responses.csv";
  //         document.body.appendChild(element);
  //         element.click();
  //       }
  //     },
  //     onError: () => {
  //       toast.error("Failed to download responses");
  //     },
  //   },
  // });

  // const handleDownload = () => {
  //   refetch();
  // };

  const [searchQuery, setSearchQuery] = useState("");
  const [[debouncedSearch]] = useDebounce([searchQuery], 1000);
  const [currentCaptableTab, setCurrentCaptableTab] = useState(0);

  const navigate = useNavigate();

  const handleTabsChange = (index: number) => {
    switch (index) {
      case 1:
        navigate(rAdminEditHistory);
        break;
      case 0:
      default:
        navigate(rAdminUsers);
        break;
    }
  };

  useEffect(() => {
    const index = getIndexFromPath(location.pathname);
    handleTabsChange(index);
    // setCurrentTab(index);
  }, [location.pathname]);

  const getIndexFromPath = (path: string) => {
    switch (path) {
      case rAdminEditHistory:
        return 1;
      case rAdminUsers:
      default:
        return 0;
    }
  };

  return (
    <Container maxH="100%" minW="100%">
      <Box>
        <Heading>Users</Heading>
        <Text color="gray.500" size="sm">
          View and update users details
        </Text>
      </Box>
      <CompanyCaptableOnboarding />
      <Box pt="5">
        <Tabs
          isLazy
          index={getIndexFromPath(location.pathname)}
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab className="capTableTab">Manage CAP Table</Tab>
            <Tab className="editHistoryTab">Edit History</Tab>
            <Spacer />
            {/* <Button onClick={handleDownload}>
              <FiDownloadCloud />
              <Text color="inherit" pl={2}>
                Download Investors Form Responses
              </Text>
            </Button> */}
          </TabList>
          <TabIndicator
            bg="primary.800"
            borderRadius="1px"
            height="2px"
            mt="-1.5px"
          />

          <TabPanels>
            <TabPanel>
              <Stack dir="column">
                <Stack direction={["column", "row"]}>
                  <InputGroup w={["100%", "200%"]}>
                    <InputLeftElement
                      children={<FiSearch />}
                      pointerEvents="none"
                    />
                    <Input
                      aria-describedby="search-icon"
                      aria-label="search"
                      bgColor="white"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={({ target: { value } }) =>
                        setSearchQuery(value)
                      }
                    />
                  </InputGroup>
                  <AddInvestorFormModal />
                  <UserFormModal edit={false} />
                </Stack>

                <Card
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="8px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);"
                  mt="24px"
                >
                  <CardBody className="shareholderListTable" p="0">
                    <Tabs
                      isLazy
                      index={currentCaptableTab}
                      onChange={setCurrentCaptableTab}
                    >
                      <TabList mb="10px" mt="20px" mx="10px">
                        <Tab>Cap table</Tab>
                        <Tab>Eligible purchasers</Tab>
                        <Tab>Archived members</Tab>
                        <Spacer />
                      </TabList>
                      <TabIndicator
                        bg="primary.800"
                        borderRadius="1px"
                        height="2px"
                        mt="-8px"
                      />
                      <TabPanels mt="30px">
                        <TabPanel p="0">
                          <CapTable
                            mode="general"
                            search={
                              currentCaptableTab === 0 ? debouncedSearch : ""
                            }
                          />
                        </TabPanel>
                        <TabPanel p="0">
                          <CapTable
                            mode="eligible"
                            search={
                              currentCaptableTab === 1 ? debouncedSearch : ""
                            }
                          />
                        </TabPanel>
                        <TabPanel p="0">
                          <CapTable
                            mode="archived"
                            search={
                              currentCaptableTab === 2 ? debouncedSearch : ""
                            }
                          />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </CardBody>
                </Card>
              </Stack>
            </TabPanel>
            <TabPanel>
              <CompanyEditHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
};

export default observer(AdminUsersPage);
