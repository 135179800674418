import { Stack, VStack } from "@chakra-ui/react";

import bg from "/png/bg.png";

import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Features from "./Features";
import Footer from "./Footer";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import LandingNavbar from "./LandingNavbar";
import RegisterCompany from "./RegisterCompany";

export default function LandingPage() {
  return (
    <Stack bg="gray.50">
      <VStack
        bg="primary.900"
        bgImage={`url(${bg})`}
        bgPosition="center"
        bgSize="cover"
      >
        <LandingNavbar />
        <Hero />
      </VStack>
      <Features />
      <AboutUs />
      <HowItWorks />
      <Contact />
      <RegisterCompany />
      <Footer />
    </Stack>
  );
}
