import { useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { BiErrorCircle } from "react-icons/bi";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  useAuthenticateInvestorInvite,
  useAuthenticateInvestorInviteLoggedIn,
} from "src/api/investor";
import { AuthService } from "src/services";

import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { FSTIcon } from "../../components/FSTIcon";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { rInvestorRegister, rRoot, rTraderUser } from "../../routes/routes";
import NotFoundPage from "../NotFound/NotFoundPage";

export default function InvestLink() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { mutate: loggedOutToken, isLoading: isLoadingLoggedOut } =
    useAuthenticateInvestorInvite({
      mutation: {
        onSuccess: () => {
          navigate(
            {
              pathname: rInvestorRegister,
              search: createSearchParams({
                invite: searchParams.get("invite") ?? "",
                email: searchParams.get("email") ?? "",
              }).toString(),
            }
            // search=
            // + searchParams.get("invite")
            // + searchParams.get("email")
            // ? "&email=" + searchParams.get("email")
            // : ""
          );
        },
        meta: {
          ignore_errors: true,
        },
      },
    });

  const { mutate: loggedInInvestorToken, isLoading: isLoadingLoggedIn } =
    useAuthenticateInvestorInviteLoggedIn({
      mutation: {
        onSuccess: () => {
          navigate(rTraderUser);
        },
      },
    });

  useEffect(() => {
    if (searchParams.get("old_invest")) {
      onOpen();
      return;
    }

    // window.location.href =
    //   `${import.meta.env.VITE_API_ROOT_URL}` +
    //   `/companies/${companyId}/invest/form/`;
  }, [searchParams.get("old_invest")]);

  let flag = false;
  if (searchParams.get("invite") && !flag) {
    flag = true;
    if (AuthService.getUserGroup() !== null && !isLoadingLoggedIn) {
      if (AuthService.getUserGroup()?.key !== "Investor") {
        return (
          <>
            <ConfirmationDialog
              show
              confirmText="Go to home page"
              handleClose={onClose}
              icon={<FSTIcon withRing Icon={BiErrorCircle} />}
              isLoading={false}
              showCancelButton={false}
              showCloseButton={false}
              title="Invalid Invitation"
              body={
                <>
                  The link is only intended for new invited investors or a user
                  with an investor status. Contact the admin for more
                  information.
                </>
              }
              handleSubmit={() => {
                navigate(rRoot);
              }}
            />

            <NotFoundPage />
          </>
        );
      }
      loggedInInvestorToken({
        data: { token: searchParams.get("invite") ?? "" },
      });
    } else if (!isLoadingLoggedOut) {
      loggedOutToken({ data: { token: searchParams.get("invite") ?? "" } });
    }
  }

  if (searchParams.get("old_invest")) {
    return (
      <>
        <ConfirmationDialog
          confirmText="Go to home page"
          handleClose={onClose}
          icon={<FSTIcon withRing Icon={BiErrorCircle} />}
          isLoading={false}
          show={isOpen}
          showCancelButton={false}
          title="Invalid Link"
          body={
            <>
              The link is invalid or expired, ask the admin to send you a new
              link.
            </>
          }
          handleSubmit={() => {
            navigate(rRoot);
          }}
        />

        <NotFoundPage />
      </>
    );
  }
  return <LoadingSpinner fullHeight />;
}
