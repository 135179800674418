import {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";

interface Props {
  condition?: boolean | undefined;
  wrapper: (children: ReactNode) => ReactElement;
}

const ConditionalWrapper: FunctionComponent<PropsWithChildren<Props>> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : <>{children}</>);

export default ConditionalWrapper;
