import {
  Button,
  Center,
  Icon,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloudUpload } from "react-icons/ai";

import { SpinnerButton } from "../SpinnerButton";
import ImportForm from "./ImportForm";

export type FormInputs = {
  csv_file: FileList;
};

type ImportFormModalProps = {
  title: string;
  submitAction: (data: FormInputs, handleClose: () => void) => void;
  isLoading?: boolean;
  type: string;
};

function ImportFormModal({
  title,
  submitAction,
  isLoading = false,
  type,
}: ImportFormModalProps) {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    register,
  } = useForm<FormInputs>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClose = useCallback(() => {
    onClose();
    reset();
    clearErrors();
  }, []);

  const onSubmit = useCallback(
    (data: FormInputs) => {
      submitAction(data, handleClose);
    },
    [errors, handleClose]
  );

  const renderItem = (type: string) => {
    if (type === "dropdown") {
      return (
        <>
          <MenuItem onClick={onOpen}>{title}</MenuItem>
        </>
      );
    } else if (type === "button") {
      return (
        <>
          <Button
            leftIcon={<Icon as={AiOutlineCloudUpload} color="blue-primary" />}
            onClick={onOpen}
            variant="outline"
            w="100%"
          >
            {title}
          </Button>
        </>
      );
    }
  };
  return (
    <>
      {renderItem(type)}
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center>{title}</Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ImportForm errors={errors} register={register} />
            </form>
          </ModalBody>
          <ModalFooter>
            <Stack direction={["column", "row"]} spacing={5} w="100%">
              <Button onClick={handleClose} variant="outline" w="100%">
                Cancel
              </Button>
              <SpinnerButton
                isDisabled={isLoading}
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                type="submit"
                w="100%"
              >
                {title}
              </SpinnerButton>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default observer(ImportFormModal);
