import "react-datepicker/dist/react-datepicker.css";

import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { FaCheckCircle, FaSpinner } from "react-icons/fa";
import { VscError } from "react-icons/vsc";
import { FSTIcon } from "src/components/FSTIcon";

import { useCompaniesTransactionsList } from "../../api/companies";
import {
  AuctionTransactionTypedTransactionType,
  FundTransactionTyped,
  ShareTransactionTyped,
  Transaction,
} from "../../api/model";
import { HistoryTable } from "../../components/HistoryTable";
import NameEmailColumn from "../../components/NameEmailColumn";
import NumberFormat from "../../components/NumberFormat";
import TreasuryTag from "../../components/TreasuryTag";
import { useDateUtils } from "../../hooks/useDateUtils";
import { AuthService } from "../../services";
import { ObjWithName, parseName } from "../../utils/misc";
import CreditBulkTransactionsModal from "./components/CreditBulkTransactionsModal";
import CreditManualTransactionModal from "./components/CreditManualTransactionModal";
import ManualShareTransactionForm from "./components/ManualShareTransactionForm";
import CompanyTransactionsOnboarding from "./components/Onboardings/CompanyTransactionsOnboarding";

export const parseTrader = ({
  trader,
  transaction,
  can_be_buyback = false,
  is_buyback = false,
  emailOnHover = false,
}: {
  trader?: ObjWithName;
  transaction?: Transaction;
  can_be_buyback?: boolean;
  is_buyback?: boolean;
  emailOnHover?: boolean;
}) => {
  const isBuyback =
    can_be_buyback &&
    transaction &&
    transaction.type === AuctionTransactionTypedTransactionType.auction &&
    trader?.email === transaction.author?.email;

  if (isBuyback || is_buyback) {
    return <TreasuryTag />;
  }

  if (trader) {
    return (
      <NameEmailColumn
        email={trader.email ?? ""}
        emailOnHover={emailOnHover}
        name={parseName(trader)}
      />
    );
  }
  return <TreasuryTag />;
};

const AdminTransactionsPage = () => {
  const { formatDate } = useDateUtils();
  const [currentSharePage, setCurrentSharePage] = useState(1);
  const [currentCreditPage, setCurrentCreditPage] = useState(1);
  const [pageSize] = useState(10);

  const { data: shareTransactions, isLoading: isLoadingShareTransactions } =
    useCompaniesTransactionsList(AuthService.getUserCompany(), {
      page: currentSharePage,
      page_size: pageSize,
      type: "share",
    });

  const { data: creditTransactions, isLoading: isLoadingCreditTransactions } =
    useCompaniesTransactionsList(AuthService.getUserCompany(), {
      page: currentCreditPage,
      page_size: pageSize,
      type: "credit",
    });

  const creditTransactionCollapseColumns = useMemo(
    (): TableColumn<FundTransactionTyped>[] => [
      {
        name: "Creator",
        cell: (transaction) => parseTrader({ trader: transaction.author }),
      },
      {
        name: "Blockchain TX",
        cell: (transaction) => {
          const hasValidTx =
            !!transaction.web3_tx_id && transaction.web3_tx_id !== "pending";
          const isPending = transaction.web3_tx_id === "pending";
          return (
            <Link
              color="primary.800"
              isExternal={hasValidTx}
              href={
                hasValidTx
                  ? `https://explorer.celo.org/alfajores/tx/${transaction.web3_tx_id}`
                  : "#"
              }
            >
              <FSTIcon
                pointer={hasValidTx}
                Icon={
                  transaction.web3_tx_id
                    ? isPending
                      ? FaSpinner
                      : FaCheckCircle
                    : VscError
                }
                iconFill={
                  isPending
                    ? "gray.500"
                    : transaction.web3_tx_id
                      ? "success.500"
                      : "gray.500"
                }
              />
            </Link>
          );
        },
      },
    ],
    []
  );

  const creditTransactionColumns = useMemo(
    (): TableColumn<FundTransactionTyped>[] => [
      {
        name: "Date",
        cell: (transaction) => formatDate(transaction.created),
      },
      {
        name: "User",
        cell: (transaction) =>
          parseInt(transaction.amount ?? "0") > 0
            ? parseTrader({
                trader: transaction.beneficiary?.trader,
                emailOnHover: true,
              })
            : parseTrader({
                trader: transaction.remitter?.trader,
                emailOnHover: true,
              }),
      },
      {
        name: "Type",
        cell: (transaction) =>
          parseInt(transaction.amount ?? "0") > 0 ? "Deposit" : "Withdraw",
      },
      {
        name: "Amount",
        right: true,
        cell: (transaction) => (
          <NumberFormat type="currency" value={transaction.amount} />
        ),
      },
    ],
    []
  );

  const shareTransactionColumns = useMemo(
    (): TableColumn<ShareTransactionTyped>[] => [
      {
        name: "Date",
        cell: (transaction) => formatDate(transaction.created),
      },
      {
        name: "Buyer Name",
        cell: (transaction) =>
          parseTrader({
            trader: transaction.beneficiary?.trader,
            transaction: transaction,
            can_be_buyback: true,
            emailOnHover: true,
          }),
      },
      {
        name: "Seller Name",
        cell: (transaction) =>
          parseTrader({
            trader: transaction.remitter?.trader,
            transaction: transaction,
            can_be_buyback: true,
            emailOnHover: true,
          }),
      },
      {
        name: "Shares Quantity",
        right: true,
        cell: (transaction) => (
          <NumberFormat type="number" value={transaction.quantity} />
        ),
      },
      {
        name: "Price Per Share",
        right: true,
        cell: (transaction) => (
          <NumberFormat type="currency" value={transaction.price_per_unit} />
        ),
      },
    ],
    []
  );

  const collapseColumns = useMemo(
    (): TableColumn<ShareTransactionTyped>[] => [
      {
        name: "Creator",
        cell: (transaction) => (
          <NameEmailColumn
            email={transaction.author.email}
            name={parseName(transaction.author)}
          />
        ),
      },
      {
        name: "Type",
        cell: (transaction) =>
          Number.parseInt(transaction.price_per_unit) === 0 &&
          transaction.type === "share"
            ? "grant".toUpperCase()
            : transaction.type.toUpperCase(),
      },
      {
        name: "Blockchain TX",
        cell: (transaction) => {
          const hasValidTx =
            !!transaction.web3_tx_id && transaction.web3_tx_id !== "pending";
          const isPending = transaction.web3_tx_id === "pending";
          return (
            <Link
              color="primary.800"
              isExternal={hasValidTx}
              href={
                hasValidTx
                  ? `https://explorer.celo.org/alfajores/tx/${transaction.web3_tx_id}`
                  : "#"
              }
            >
              <FSTIcon
                pointer={hasValidTx}
                Icon={
                  transaction.web3_tx_id
                    ? isPending
                      ? FaSpinner
                      : FaCheckCircle
                    : VscError
                }
                iconFill={
                  isPending
                    ? "gray.500"
                    : transaction.web3_tx_id
                      ? "success.500"
                      : "gray.500"
                }
              />
            </Link>
          );
        },
      },
      {
        name: "Note",
        cell: (transaction) => transaction.note ?? "—",
      },
    ],
    []
  );

  return (
    <Container maxH="100%" minW="100%">
      <CompanyTransactionsOnboarding />
      <Box>
        <Heading>Transactions</Heading>
        <Text color="gray.500" size="sm">
          Detailed record of share & credit transactions
        </Text>
      </Box>

      <Box pt="5">
        <Tabs>
          <TabList>
            <Tab className="shareTransactionsTab">Share Transactions</Tab>
            <Tab className="creditTransactionsTab">Credit Transactions</Tab>
          </TabList>
          <TabIndicator
            bg="primary.800"
            borderRadius="1px"
            height="2px"
            mt="-1.5px"
          />
          <TabPanels>
            <TabPanel>
              <HistoryTable<ShareTransactionTyped>
                noFilters
                buttons={<ManualShareTransactionForm />}
                className="shareTransactionsTable"
                collapseColumns={collapseColumns}
                columns={shareTransactionColumns}
                data={shareTransactions?.results as ShareTransactionTyped[]}
                header="Transaction History"
                isLoading={isLoadingShareTransactions}
                onPageChange={(page) => setCurrentSharePage(page)}
                onSearch={() => {}}
                totalRows={shareTransactions?.count ?? 0}
                noData={
                  <>
                    <Text variant="xs-semibold">No transactions available</Text>
                    <Text>
                      Sorry, currently there are no transactions to display.
                    </Text>
                  </>
                }
              />
            </TabPanel>

            <TabPanel>
              <HistoryTable<FundTransactionTyped>
                noFilters
                className="creditTransactionsTable"
                collapseColumns={creditTransactionCollapseColumns}
                columns={creditTransactionColumns}
                data={creditTransactions?.results as FundTransactionTyped[]}
                header="Transaction History"
                isLoading={isLoadingCreditTransactions}
                onPageChange={(page) => setCurrentCreditPage(page)}
                onSearch={() => {}}
                totalRows={creditTransactions?.count ?? 0}
                buttons={
                  <Flex gap={3} wrap={{ base: "wrap", lg: "nowrap" }}>
                    <CreditBulkTransactionsModal />
                    <CreditManualTransactionModal edit={false} />
                  </Flex>
                }
                noData={
                  <>
                    <Text variant="xs-semibold">No transactions available</Text>
                    <Text>
                      Sorry, currently there are no transactions to display.
                    </Text>
                  </>
                }
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
};

export default AdminTransactionsPage;
