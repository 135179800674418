import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { LoadingSpinner } from "../../components/LoadingSpinner";
import {
  rAdminCompany,
  rAdminTenders,
  rRoot,
  rTenders,
} from "../../routes/routes";
import { AuthService } from "../../services";

const LoginSuccessPage = () => {
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  useEffect(() => {
    const {
      user_token,
      user_group: group,
      company_uuid: company,
      user_email: email,
      no_company_profile: adminRedirectToProfile,
      user_first_name: firstName,
      user_last_name: lastName,
    } = jwtDecode<{
      user_token: string;
      user_group: string;
      company_uuid: string;
      user_email: string;
      no_company_profile: boolean;
      user_first_name: string;
      user_last_name: string;
    }>(token ?? "");

    AuthService.setAccessToken(user_token ?? "");
    AuthService.setUserInfo({
      userGroup: group ?? "",
      userCompany: company ?? "",
      userEmail: email ?? "",
      userName: {
        firstName: firstName ?? "",
        lastName: lastName ?? "",
      },
    });

    const redirect_to = searchParams.get("redirect_to");
    const admin_redirect = adminRedirectToProfile
      ? rAdminCompany
      : rAdminTenders;

    if (redirect_to && redirect_to !== rRoot) {
      navigate(redirect_to);
    } else {
      navigate(group === "CorporateAdmin" ? admin_redirect : rTenders);
    }
  }, [token, searchParams]);

  return <LoadingSpinner fullHeight />;
};

export default LoginSuccessPage;
