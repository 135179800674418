import _ from "lodash";
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { Values } from "react-currency-format";
import { toast } from "react-toastify";

import IDutchBidOrders from "../models/IDutchBidOrders";
import IDutchBuyOrder, {
  iDutchBuyOrderFactory,
} from "../models/IDutchBuyOrder";
import { IOrder } from "../models/IOrder";
import OrderModel from "../models/OrderModel";
import OrderType from "../models/OrderType";
import { ApiService } from "../services";
import { system_fee } from "../utils/constants";

class DutchOrdersStore {
  orders: IDutchBidOrders = {
    id: 0,
    buy_orders: [],
    tender: 0,
    tender_member: 0,
  };
  stockOrder: OrderModel = {};
  loading = true;
  showAlertMessage = false;
  showModal = false;
  editModalIndex = -1;
  executed_price = 0;

  constructor() {
    makeAutoObservable(this, {
      showAlertMessage: observable,
      showModal: observable,
      orders: observable,
      deleteOrder: action,
      getOrdersList: action,

      stockOrder: observable,
      addOrder: action,
      updateOrder: action,
      saveOrdersList: action,
      hideAlertMessage: action,
      setExecutedPrice: action,

      totalBuyStock: computed,
      totalBuyWorth: computed,
      totalBuyWorthWithExecutedPriceAfterSystemFees: computed,
      totalBuyWorthAfterSystemFees: computed,
      updatedOrdersList: computed,
    });
  }

  resetOrdersStore = () => {
    runInAction(() => {
      this.orders = {
        id: 0,
        buy_orders: [],
        tender: 0,
        tender_member: 0,
      };
      this.stockOrder = {};
      this.loading = true;
      this.showAlertMessage = false;
      this.editModalIndex = -1;
    });
  };

  resetOrder = (keepModal = false) => {
    runInAction(() => {
      this.editModalIndex = -1;
      if (keepModal) {
        this.stockOrder = {
          type: this.stockOrder.type,
        };
      } else {
        this.stockOrder = {};
      }
    });
  };

  hideAlertMessage = () => {
    runInAction(() => {
      this.showAlertMessage = !this.showAlertMessage;
    });
  };

  updateOrder = (key: string, values: Values) => {
    key = key as keyof OrderModel;

    runInAction(() => {
      switch (key) {
        case "stockPrice":
        case "stockCount":
          this.stockOrder[key] = values.floatValue;
          this.stockOrder[`${key}String`] = values.formattedValue;
          break;
        default:
          break;
      }
    });
  };

  setOrderType = (type: OrderType) => {
    this.stockOrder.type = type;
  };

  setOrder = (order: IOrder) => {
    const stockCount = (order as IDutchBuyOrder).quantity;
    const stockPrice = (order as IDutchBuyOrder).price_per_unit;
    const created = (order as IDutchBuyOrder).created;
    this.stockOrder.stockCount = stockCount;
    this.stockOrder.stockCountString = stockCount ? stockCount.toString() : "";
    this.stockOrder.stockPrice = stockPrice;
    this.stockOrder.stockPriceString = stockPrice ? stockPrice.toString() : "";
    this.stockOrder.created = created;
  };

  addOrder = () => {
    if (!this.stockOrder.stockCount || !this.stockOrder.stockPrice)
      return false;

    runInAction(() => {
      this.orders.buy_orders.push({
        price_per_unit: this.stockOrder.stockPrice || 0,
        quantity: this.stockOrder.stockCount || 0,
        resourcetype: "DutchBuyOrder",
        deleted: false,
      });
      this.stockOrder = {};
    });
    return true;
  };

  editOrder = () => {
    if (!this.stockOrder.stockCount || !this.stockOrder.stockPrice)
      return false;

    runInAction(() => {
      this.orders.buy_orders[this.editModalIndex] = {
        created: this.stockOrder.created,
        modified: new Date().toISOString(),
        price_per_unit: this.stockOrder.stockPrice || 0,
        quantity: this.stockOrder.stockCount || 0,
        resourcetype: "DutchBuyOrder",
        deleted: false,
      };
    });
    return true;
  };

  getOrdersList = (id: string, noLoad = false) => {
    if (!noLoad) {
      runInAction(() => {
        this.loading = true;
      });
    }
    const axios = ApiService.getInstance();
    axios
      .get<IDutchBidOrders>(`/orders/${id}/bid/`)
      .then((data) => {
        runInAction(() => {
          this.orders = {
            ...data.data,
            buy_orders: [
              ...iDutchBuyOrderFactory(data.data.buy_orders),
              ...this.orders.buy_orders.filter((o) => !o.created),
            ],
          };
        });
      })
      .catch(() => {
        console.log("Error getting bids");
      })
      .finally(() => {
        if (!noLoad) {
          runInAction(() => {
            this.loading = false;
          });
        }
      });
  };

  deleteOrder = (type: OrderType, order: IOrder) => {
    runInAction(() => {
      const index = _.findIndex(this.orders.buy_orders, order);
      this.orders.buy_orders[index].deleted = true;
    });
  };

  saveOrdersList = (id: number | undefined) => {
    runInAction(() => {
      this.loading = true;
    });
    const axios = ApiService.getInstance();
    axios
      .put<IDutchBidOrders>(`/orders/${id}/bid/`, {
        buy_orders: this.buyOrders,
        sell_orders: [],
      })
      .then((data) => {
        runInAction(() => {
          this.orders = data.data;
          this.orders.buy_orders = iDutchBuyOrderFactory(data.data.buy_orders);
          this.showAlertMessage = true;
        });
      })
      .catch(() => {
        console.log("Error getting bids");
        toast.error("Failed to save orders list");
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  get totalBuyStock(): number {
    return this.buyOrders.reduce(
      (accumulator, obj) => accumulator + (obj.quantity || 0),
      0
    );
  }

  get totalBuyWorth(): number {
    return this.buyOrders.reduce(
      (accumulator, obj) =>
        accumulator + (obj.price_per_unit * obj.quantity || 0),
      0
    );
  }

  setExecutedPrice = (price: number) => {
    runInAction(() => {
      this.executed_price = price;
    });
  };

  get totalBuyWorthWithExecutedPriceAfterSystemFees(): number {
    const total = this.buyOrders.reduce((accumulator, obj) => {
      if (obj.executed) {
        // console.log(
        //   "acc",
        //   accumulator,
        //   "+",
        //   this.executed_price,
        //   "*",
        //   obj.quantity || 0,
        //   "=",
        //   accumulator + (this.executed_price * obj.quantity || 0)
        // );
        return accumulator + (this.executed_price * obj.quantity || 0);
      }
      // console.log(
      //   "acc",
      //   accumulator,
      //   "+",
      //   obj.price_per_unit,
      //   "*",
      //   obj.quantity || 0,
      //   "=",
      //   accumulator + (obj.price_per_unit * obj.quantity || 0)
      // );
      return accumulator + (obj.price_per_unit * obj.quantity || 0);
    }, 0);
    return total + total * system_fee;
  }

  get totalBuyWorthAfterSystemFees(): number {
    return this.totalBuyWorth + this.totalBuyWorth * system_fee;
  }

  get hasNoInput(): boolean {
    return (
      [undefined, NaN].includes(this.stockOrder.stockCount) ||
      [undefined, NaN].includes(this.stockOrder.stockPrice)
    );
  }

  get stockOrderTotal(): number {
    if (this.hasNoInput) return NaN;

    return this.stockOrder.stockCount! * this.stockOrder.stockPrice!;
  }

  get stockBuyOrderTotalAfterFees() {
    if (this.hasNoInput) return NaN;
    return this.stockOrderTotal + this.stockOrderTotal * system_fee;
  }

  @computed get minBuyOrder() {
    return _.minBy(this.orders.buy_orders, "price_per_unit");
  }

  @computed get updatedOrdersList() {
    return _.some(
      this.orders.buy_orders,
      (order) => (!order.id && !order.deleted) || (order.id && order.deleted)
    );
  }
  @computed get buyOrders() {
    return _.filter(this.orders.buy_orders, (buy_order) => !buy_order.deleted);
  }
}

export default DutchOrdersStore;
