import { InputGroup, InputRightAddon } from "@chakra-ui/input";
import { Grid, Text } from "@chakra-ui/layout";
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/number-input";
import {
  Control,
  Controller,
  FieldErrors,
  Path,
  UseFormWatch,
} from "react-hook-form";

import { ControlWrapper } from "../../../../components/ControlWrapper";

type Props<
  T extends {
    duration_days: string;
    duration_hours: string;
    duration_minutes: string;
  },
> = {
  has_started?: boolean;
  control: Control<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
};

function Duration<
  T extends {
    duration_days: string;
    duration_hours: string;
    duration_minutes: string;
  },
>({ has_started, control, errors, watch }: Props<T>): JSX.Element {
  return (
    <ControlWrapper
      fullLine
      formLabel="Duration"
      errorMessage={
        (errors.duration_days &&
          (errors.duration_days.message as string | undefined)) ||
        (errors.duration_hours &&
          (errors.duration_hours.message as string | undefined)) ||
        (errors.duration_minutes &&
          (errors.duration_minutes.message as string | undefined))
      }
      isInvalid={
        errors.duration_days && errors.duration_hours && errors.duration_minutes
          ? true
          : false
      }
    >
      <Grid
        gap={2}
        templateColumns={{
          sm: "repeat(3, 1fr)",
          md: "repeat(3, 1fr)",
        }}
      >
        <Controller
          control={control}
          name={"duration_days" as Path<T>}
          render={({ field: { value, onChange } }) => (
            <InputGroup>
              <NumberInput
                data-testid="duration_days"
                isDisabled={has_started}
                max={36500}
                min={0}
                value={value}
                width="100%"
                onChange={(valueString) =>
                  onChange(
                    isNaN(parseInt(valueString)) ? "0" : parseInt(valueString)
                  )
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <InputRightAddon children="days" bgColor="transparent" />
            </InputGroup>
          )}
          rules={{
            required: "Please enter days",
            validate: (value, { duration_hours, duration_minutes }) => {
              if (
                value.toString().toString() === "0" &&
                duration_hours.toString().toString() === "0" &&
                duration_minutes.toString().toString() === "0"
              ) {
                return "Auction duration should be at least 1 minute";
              }
              return true;
            },
          }}
        />
        <Controller
          control={control}
          name={"duration_hours" as Path<T>}
          render={({ field: { value, onChange } }) => (
            <InputGroup>
              <NumberInput
                data-testid="duration_hours"
                isDisabled={has_started}
                max={23}
                min={0}
                value={value}
                width="100%"
                onChange={(valueString) =>
                  onChange(
                    isNaN(parseInt(valueString)) ? "0" : parseInt(valueString)
                  )
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <InputRightAddon children="hours" bgColor="transparent" />
            </InputGroup>
          )}
          rules={{
            required: "Please enter hours",
            validate: (value, { duration_days, duration_minutes }) => {
              if (
                value.toString().toString() === "0" &&
                duration_days.toString().toString() === "0" &&
                duration_minutes.toString().toString() === "0"
              ) {
                return "Auction duration should be at least 1 minute";
              }
              return true;
            },
          }}
        />
        <Controller
          control={control}
          name={"duration_minutes" as Path<T>}
          render={({ field: { value, onChange } }) => (
            <InputGroup>
              <NumberInput
                data-testid="duration_minutes"
                isDisabled={has_started}
                max={59}
                min={0}
                value={value}
                width="100%"
                onChange={(valueString) =>
                  onChange(
                    isNaN(parseInt(valueString)) ? "0" : parseInt(valueString)
                  )
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <InputRightAddon children="minutes" bgColor="transparent" />
            </InputGroup>
          )}
          rules={{
            required: "Please enter minutes",
            validate: (value, { duration_days, duration_hours }) => {
              if (
                value.toString() === "0" &&
                duration_days.toString() === "0" &&
                duration_hours.toString() === "0"
              ) {
                return "Auction duration should be at least 1 minute";
              }
              return true;
            },
          }}
        />
      </Grid>
      <Text color="gray.500" fontSize="sm">
        Duration Notice: Once initiated, this auction will last for{" "}
        {watch("duration_days" as Path<T>)} days,{" "}
        {watch("duration_hours" as Path<T>)} hours, and{" "}
        {watch("duration_minutes" as Path<T>)} minutes.
      </Text>
    </ControlWrapper>
  );
}

export default Duration;
