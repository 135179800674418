import {
  Box,
  Button,
  Collapse,
  Grid,
  GridItem,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { RefObject, useRef, useState } from "react";
import { IconType } from "react-icons";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { FSTIcon } from "src/components/FSTIcon";
import NumberFormat from "src/components/NumberFormat";
import { rStartupRegister } from "src/routes/routes";

import { FSTPricingPlans } from "../Admin/components/CompanyBillingForm";
import Contact from "../Landing/Contact";

const PricingPlanComponent = ({
  planName,
  isUnlimited,
  price,
  isMonthly,
  description,
  users,
  index,
  Icon,
}: {
  planName: string;
  isUnlimited: boolean;
  price: string | number;
  isMonthly: boolean;
  description?: string;
  users: number;
  index: number;
  Icon?: IconType;
}) => {
  const navigate = useNavigate();
  const contactFormRef: RefObject<HTMLDivElement> = useRef(null);

  const [showContact, setShowContact] = useState(false);
  return (
    <>
      <Grid
        bg="primary.900"
        borderRadius="8px"
        color="white"
        p="40px"
        w="100%"
        gridTemplateColumns={{
          base: "1fr",
          lg: "1fr 1fr",
        }}
      >
        <VStack
          align="center"
          alignContent="center"
          justify="center"
          justifyItems="center"
          textAlign="center"
          minW={{
            base: "70vw",
            lg: "200px",
          }}
          w={{
            base: "70vw",
            md: "200px",
          }}
        >
          <FSTIcon
            Icon={Icon}
            bg="primary.25"
            iconFill="primary.800"
            size={30}
          />
          <Heading
            fontFamily="Open Sans, sans-serif"
            fontSize="20px"
            fontWeight="600"
            justifySelf="center"
            lineHeight="30px"
          >
            {planName}
          </Heading>
          <Text
            color="white"
            fontFamily="Open Sans, sans-serif"
            fontSize="48px"
            fontWeight="600"
            justifySelf="center"
            letterSpacing={-1}
            lineHeight="60px"
            pl="8px"
          >
            {isUnlimited ? (
              "Contact us"
            ) : (
              <>
                <NumberFormat type="currency" value={Number(price)} />
                /mth
              </>
            )}
          </Text>
          {price && (
            <Text color="white">
              {isMonthly ? null : (
                <>
                  <NumberFormat type="currency" value={Number(price) * 10} />{" "}
                  billed annually
                </>
              )}
              {isMonthly ? null : (
                <>
                  <Box
                    cursor="pointer"
                    display="flex"
                    gridColumn="3"
                    justifyContent="center"
                    pt="8px"
                  >
                    <Tag> 2 Months free</Tag>
                  </Box>
                </>
              )}
            </Text>
          )}
        </VStack>
        <VStack
          align="left"
          flexGrow={1}
          justifyContent="center"
          minH="250px"
          spacing="12px"
        >
          <Grid gap="12px">
            <GridItem as={Grid} gap={2} gridRow="1" templateColumns="auto 1fr">
              <FaRegCircleCheck color="white" style={{ marginTop: "4px" }} />
              <Text color="white">{description}</Text>
            </GridItem>
            <GridItem as={Grid} gap={2} gridRow="2" templateColumns="auto 1fr">
              <FaRegCircleCheck color="white" style={{ marginTop: "4px" }} />
              {isUnlimited ? (
                <Text color="white">Unlimited users</Text>
              ) : (
                <Text color="white">Up to {users} individual users</Text>
              )}
            </GridItem>
          </Grid>
          {isUnlimited ? (
            <Button
              size="lg"
              variant="solid"
              onClick={() => {
                setShowContact(true);
              }}
            >
              Contact us
            </Button>
          ) : (
            <Button
              size="lg"
              variant="outline"
              onClick={() =>
                navigate(
                  rStartupRegister +
                    "?plan=" +
                    (index + (isMonthly ? 0 : 100)) +
                    (isMonthly ? "" : "&annual=true")
                )
              }
            >
              Get started
            </Button>
          )}
        </VStack>
      </Grid>

      {isUnlimited && (
        <Collapse in={showContact}>
          <Contact ref={contactFormRef} mini />
        </Collapse>
      )}
    </>
  );
};

export default function PricingBlocks({ isMonthly }: { isMonthly: boolean }) {
  const sliderSteps = FSTPricingPlans.sort((a, b) => a.users - b.users).map(
    (plan) => ({
      ...plan,
    })
  );
  const [sliderValue, setSliderValue] = useState(1);

  return (
    <Stack align="center" p={["32px", "48px", "64px"]} spacing="48px">
      <Heading fontSize="4xl">
        Get the complete capital management platform.
      </Heading>
      <Grid
        gap={6}
        templateColumns={["1fr"]}
        w={{ sm: "90%", md: "70%", xl: "50%" }}
      >
        <GridItem
          alignSelf="center"
          justifySelf="center"
          maxW="100%"
          pb="8"
          w="100%"
        >
          <Slider
            aria-label="slider-ex-1"
            defaultValue={0}
            max={FSTPricingPlans.length - 1}
            maxW="100%"
            min={0}
            onChange={(value) => setSliderValue(value)}
            step={1}
            value={sliderValue}
            w="100%"
          >
            {FSTPricingPlans.sort((a, b) => a.users - b.users).map(
              (plan, i) => (
                <SliderMark
                  key={plan.users ?? i}
                  mt="4"
                  value={i}
                  ml={{
                    base: i === FSTPricingPlans.length - 1 ? "-7" : "-3",
                    md: i === FSTPricingPlans.length - 1 ? "-7" : "-3",
                  }}
                >
                  <Text
                    cursor="pointer"
                    display={["none", "none", "block"]}
                    onClick={() => setSliderValue(i)}
                    pointerEvents="auto"
                  >
                    {plan.isMore ? (
                      "more"
                    ) : (
                      <Text>
                        {plan.users} <br />
                        <Text fontSize="sm" ml={i === 0 ? "-10px" : "-1"}>
                          users
                        </Text>
                      </Text>
                    )}
                  </Text>

                  <Text
                    cursor="pointer"
                    display={["block", "block", "none"]}
                    onClick={() => setSliderValue(i)}
                    pointerEvents="auto"
                  >
                    {plan.isMore ? "more" : `${plan.users}`}
                  </Text>
                </SliderMark>
              )
            )}
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb
              border="2px solid"
              borderColor="primary.800"
              boxSize={6}
              p={0}
            />
          </Slider>
        </GridItem>
        <GridItem h="100%" maxH="100%" maxW="100%" w="100%">
          <PricingPlanComponent
            Icon={sliderSteps[sliderValue].icon}
            description={sliderSteps[sliderValue].shortDescription}
            index={sliderSteps[sliderValue].index}
            isMonthly={isMonthly}
            isUnlimited={sliderSteps[sliderValue].isMore ?? false}
            planName={sliderSteps[sliderValue].planName}
            price={sliderSteps[sliderValue].price ?? 0}
            users={sliderSteps[sliderValue].users}
          />
        </GridItem>
      </Grid>
    </Stack>
  );
}
