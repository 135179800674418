import { useCallback } from "react";

import { theme } from "../chakra/theme";

export const useColorFromToken = () => {
  return useCallback((token: string) => {
    if (!token.includes(".")) return token;

    const [color, degree] = token.split(".");
    return theme.colors[color][degree];
  }, []);
};
