import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { BiTrash } from "react-icons/bi";
import { FiCheckCircle } from "react-icons/fi";
import { MdErrorOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getGetAuctionAggregateResolutionQueryKey,
  getGetAuctionDummyResolutionQueryKey,
  useDeleteMemberOrders,
  useGetAuctionAggregateResolution,
} from "../../../api/auctions";
import { useCompaniesTransactionsCreate } from "../../../api/companies";
import { AuctionAggregateResolutionBuyer } from "../../../api/model";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { CustomTable } from "../../../components/CustomTable";
import { FSTIcon } from "../../../components/FSTIcon";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import NumberFormat from "../../../components/NumberFormat";
import { AuthService } from "../../../services";
import { parseTrader } from "../AdminTransactionsPage";

const AggregateResolutionTab = () => {
  const [
    showResolutionDeletionDialogForEmail,
    setShowResolutionDeletionDialogForEmail,
  ] = useState("");

  const queryClient = useQueryClient();
  const [selectedRow, setSelectedRow] = useState<
    AuctionAggregateResolutionBuyer | undefined
  >();
  const [refreshing, setRefreshing] = useState(false);

  const { auction_id } = useParams();

  if (auction_id === undefined) {
    return null;
  }

  const [success50, success100, success600] = useToken("colors", [
    "success.50",
    "success.100",
    "success.600",
  ]);

  const {
    isOpen: isSettleConfirmOpen,
    onOpen: openConfirmSettle,
    onClose: closeConfirmSettle,
  } = useDisclosure();

  const {
    data,
    isLoading,
    refetch: refreshAggregateResolution,
  } = useGetAuctionAggregateResolution(Number.parseInt(auction_id ?? ""));

  const deleteMemberOrders = useDeleteMemberOrders({
    mutation: {
      onSuccess: () => {
        toast.success("Deleted user buy orders successfully");
        setShowResolutionDeletionDialogForEmail("");
        setRefreshing(true);
        setTimeout(() => {
          refreshAggregateResolution().then(() => {
            setRefreshing(false);
          });
        }, 5000);
      },
      onError: () => {
        toast.error("Failed to delete buy orders");
      },
    },
  });

  const handleClose = () => {
    setSelectedRow(undefined);
    closeConfirmSettle();
  };

  const { mutate: createTransactionMutation, isLoading: isSettleLoading } =
    useCompaniesTransactionsCreate({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(
            getGetAuctionAggregateResolutionQueryKey(
              Number.parseInt(auction_id ?? "")
            )
          );
          queryClient.invalidateQueries(
            getGetAuctionDummyResolutionQueryKey(
              Number.parseInt(auction_id ?? "")
            )
          );
          toast.success("Settled amount successfully");
          handleClose();
        },
      },
    });

  const columns: TableColumn<AuctionAggregateResolutionBuyer>[] = [
    {
      name: "Buyer",
      cell: (row) =>
        parseTrader({
          trader: row,
          is_buyback: row.is_buyback,
        }),
    },
    {
      name: "Total Outstanding Amount",
      right: true,
      cell: (row) => (
        <NumberFormat type="currency" value={row.outstanding_amount} />
      ),
      selector: (row) =>
        row.outstanding_amount ? Number.parseFloat(row.outstanding_amount) : 0,
    },
    {
      name: "Current Credit Balance",
      right: true,
      cell: (row) => (
        <NumberFormat type="currency" value={row.current_credit_balance} />
      ),
      selector: (row) =>
        row.current_credit_balance
          ? Number.parseFloat(row.current_credit_balance)
          : 0,
    },
    {
      name: "Amount to Settle",
      right: true,
      cell: (row) => (
        <NumberFormat type="currency" value={row.amount_to_settle} />
      ),
      selector: (row) =>
        row.amount_to_settle ? Number.parseFloat(row.amount_to_settle) : 0,
    },
    {
      name: "",
      cell: (row) => {
        if (row.is_buyback) {
          return <></>;
        }
        const canSettle =
          Number.parseFloat(row.amount_to_settle) > 0 && data!.can_settle;
        const showDeleteConfirmModal =
          row.email === showResolutionDeletionDialogForEmail;

        return (
          <Flex alignItems="center" gap="10">
            <Button
              isDisabled={!canSettle}
              size="sm"
              onClick={() => {
                setSelectedRow(row);
              }}
            >
              Settle
            </Button>
            {showDeleteConfirmModal && (
              <ConfirmationDialog
                confirmText="Delete"
                handleClose={() => setShowResolutionDeletionDialogForEmail("")}
                handleSubmit={() => handleConfirmDeleteOrders(row.email)}
                isLoading={deleteMemberOrders.isLoading}
                show={showDeleteConfirmModal}
                showCloseButton={false}
                title={<Text sx={{ mt: 4 }}>Delete Buy Orders</Text>}
                body={
                  <>
                    Please confirm your intent to delete all the buy orders for{" "}
                    {row.full_name || row.email}
                  </>
                }
                icon={
                  <FSTIcon
                    withRing
                    Icon={MdErrorOutline}
                    bg="error.100"
                    iconFill="error.600"
                    size={32}
                    wrapperBg="error.50"
                  />
                }
              />
            )}
            <FSTIcon
              noBorder
              pointer
              Icon={BiTrash}
              iconFill={canSettle ? "gray.500" : "gray.200"}
              size={24}
              onClick={
                canSettle
                  ? () => setShowResolutionDeletionDialogForEmail(row.email)
                  : undefined
              }
            />
          </Flex>
        );
      },
    },
  ];

  const handleConfirmDeleteOrders = useCallback(
    (email: string) => {
      if (auction_id) {
        deleteMemberOrders.mutate({
          id: Number.parseInt(auction_id ?? ""),
          data: {
            email,
          },
        });
      }
    },
    [auction_id, deleteMemberOrders]
  );

  useEffect(() => {
    if (selectedRow) {
      openConfirmSettle();
    }
  }, [selectedRow]);

  return (
    <Box>
      <ConfirmationDialog
        confirmText="Confirm"
        handleClose={handleClose}
        isLoading={isSettleLoading}
        show={isSettleConfirmOpen}
        title="Settle Amount Due"
        body={
          <>
            Please confirm your intent to settle the{" "}
            <Text as="span">amount due</Text> of{" "}
            <Text as="span" color="primary.800" fontWeight="600">
              <NumberFormat
                type="currency"
                value={selectedRow?.amount_to_settle}
              />
              .
            </Text>{" "}
            This will deposit{" "}
            <Text as="span" color="primary.800" fontWeight="600">
              <NumberFormat
                type="currency"
                value={selectedRow?.amount_to_settle}
              />
            </Text>{" "}
            in{" "}
            <Text as="span" color="primary.800" fontWeight="600">
              {selectedRow?.full_name ?? selectedRow?.email}
            </Text>
            's credit balance
          </>
        }
        handleSubmit={() => {
          createTransactionMutation({
            uuid: AuthService.getUserCompany(),
            data: {
              amount: selectedRow?.amount_to_settle,
              // FIXME: lots of type errors with IOSerializer and UserEmailRelatedField
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              beneficiary: selectedRow?.email as any,
              remitter: AuthService.getUserEmail() ?? "",
              transaction_type: "credit",
            },
          });
        }}
        icon={
          <FSTIcon
            withRing
            Icon={FiCheckCircle}
            bg={success100}
            iconFill={success600}
            wrapperBg={success50}
          />
        }
      />
      {isLoading || refreshing || data === undefined ? (
        <LoadingSpinner />
      ) : (
        <CustomTable columns={columns} data={data!.aggregate} />
      )}
    </Box>
  );
};

export default AggregateResolutionTab;
