import {
  Box,
  Card,
  CardBody,
  Grid,
  Icon,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from "@chakra-ui/react";
import { TableColumn } from "react-data-table-component";
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from "react-icons/io";
import PageWrapper from "src/components/FSTPageWrapper";
import NameEmailColumn from "src/components/NameEmailColumn";
import { useDateUtils } from "src/hooks/useDateUtils";
import { AuthService } from "src/services";

import {
  useGetTraderCreditTransactions,
  useGetTraderShareTransactions,
} from "../../api/auction-members";
import { FundTransaction, ShareTransactionPoly } from "../../api/model";
import { HistoryTable } from "../../components/HistoryTable";
import NumberFormat from "../../components/NumberFormat";
import { parseName } from "../../utils/misc";

/**
 *
 * @param investor used to determine if the user is an investor, this component is used in the trader/investor auctions
 * list and investor explore auctions page; the first is where the investor/trader can only see the auctions
 * they are part of, the second is where the investor can see all the public auctions
 * @returns React component
 */
const TraderTransactionsPage = () => {
  const { formatDate } = useDateUtils();

  const currentUser = AuthService.getUserEmail();

  const {
    data: creditTransactions,
    isLoading: isRefetchingCredit,
    isFetching: isFetchingCredit,
  } = useGetTraderCreditTransactions();

  const {
    data: shareTransactions,
    isLoading: isRefetchingShare,
    isFetching: isFetchingShare,
  } = useGetTraderShareTransactions();

  const columnsCredit: TableColumn<FundTransaction>[] = [
    {
      name: "Amount",
      selector: (transaction) => Math.abs(Number(transaction.amount) ?? 0),
      cell: (transaction) => (
        <NumberFormat tableValue type="currency" value={transaction.amount} />
      ),
      sortable: true,
    },
    {
      name: "Admin Name",
      cell: (transaction) => (
        <NameEmailColumn
          email={transaction.author.email}
          name={parseName(transaction.author)}
        />
      ),
    },
    {
      name: "Company Name",
      cell: (transaction) => transaction.beneficiary?.company,
    },
    {
      name: "Date",
      selector: (transaction) => transaction.created,
      cell: (transaction) => formatDate(transaction.created),
      sortable: true,
    },
    {
      name: "Type",
      cell: (transaction) =>
        parseInt(transaction.amount ?? "0") > 0 ? "Deposit" : "Withdraw",
    },
  ];

  const DeltaComponent = (props: {
    email?: string;
    beneficiary: { delta: number; percentage: string };
    remitter: { delta: number; percentage: string };
    notCurrency?: boolean;
  }) => {
    const isBeneficiary = props.email === currentUser;
    const delta = isBeneficiary
      ? props.beneficiary?.delta ?? 0
      : props.remitter?.delta ?? 0;
    const percentage = isBeneficiary
      ? props.beneficiary?.percentage ?? 0
      : props.remitter?.percentage ?? 0;

    return (
      <Grid alignItems="center" gap={2} templateColumns="1fr auto">
        <NumberFormat
          tableValue
          type={props.notCurrency ? "number" : "currency"}
          value={delta}
        />
        <Tag
          alignItems="center"
          as={Grid}
          gap={1}
          variant={delta > 0 ? "success" : "error"}
          wordBreak="keep-all"
        >
          <Icon
            as={delta > 0 ? IoMdArrowRoundUp : IoMdArrowRoundDown}
            color={delta > 0 ? "success.700" : "error.700"}
          />
          {percentage}
        </Tag>
      </Grid>
    );
  };

  const columnsShare: TableColumn<ShareTransactionPoly>[] = [
    {
      name: "Auction Name",
      // cell: (transaction) => transaction.type === "auction" && transaction.
      cell: (transaction) =>
        transaction.transaction_type === "auction"
          ? transaction.resolution.auction.title
          : "—",
    },
    {
      name: "Company Name",
      cell: (transaction) => transaction.beneficiary?.company,
    },
    {
      name: "Type",
      cell: (transaction) =>
        transaction.beneficiary?.trader.email === currentUser ? "Buy" : "Sell",
    },
    {
      name: "Quantity",
      cell: (transaction) => transaction.quantity,
    },
    {
      name: "Price Per Unit",
      selector: (transaction) => transaction.price_per_unit,
      cell: (transaction) => (
        <NumberFormat
          tableValue
          type="currency"
          value={transaction.price_per_unit}
        />
      ),
    },
    {
      name: "Total Price",
      cell: (transaction) => (
        <NumberFormat
          tableValue
          type="currency"
          value={Number(transaction.price_per_unit) * transaction.quantity}
        />
      ),
    },
    {
      name: "Credit Balance Delta",
      width: "200px",
      selector: (transaction) =>
        transaction.beneficiary?.trader.email === currentUser
          ? transaction.credit_balance_beneficiary_delta.delta
          : transaction.credit_balance_remitter_delta.delta,
      cell: (transaction) => (
        <DeltaComponent
          beneficiary={transaction.credit_balance_beneficiary_delta}
          email={transaction.beneficiary?.trader.email}
          remitter={transaction.credit_balance_remitter_delta}
        />
      ),
    },
    {
      name: "Share Balance Delta",
      width: "180px",
      selector: (transaction) =>
        transaction.beneficiary?.trader.email === currentUser
          ? transaction.shares_beneficiary_delta.delta
          : transaction.shares_remitter_delta.delta,
      cell: (transaction) => (
        <DeltaComponent
          notCurrency
          beneficiary={transaction.shares_beneficiary_delta}
          email={transaction.beneficiary?.trader.email}
          remitter={transaction.shares_remitter_delta}
        />
      ),
    },
    {
      name: "Date",
      selector: (transaction) => transaction.created,
      cell: (transaction) => formatDate(transaction.created),
      sortable: true,
    },
  ];

  const getTableProps = (
    data: FundTransaction[] | ShareTransactionPoly[],
    isLoading: boolean,
    onPageChange: (page: number) => void,
    onSearch: (search: string) => void
  ) => ({
    noFilters: true,
    pointerOnHover: false,
    buttons: <></>,
    isLoading: isLoading,
    noData: (
      <>
        <Text variant="display-xs-semibold">No Auctions Available</Text>
        <Text>Sorry, there are currently no auctions to display</Text>
      </>
    ),
    onSearch: onSearch,
    totalRows: data?.length ?? 0,
    onPageChange: onPageChange,
    noServerPagination: true,
    searchBarPosition: "top" as const,
  });

  const Table = () => {
    return (
      <Box pt="5">
        <Tabs>
          <TabList>
            <Tab>Credit Transactions</Tab>
            <Tab>Shares Transactions</Tab>
          </TabList>
          <TabIndicator
            bg="primary.800"
            borderRadius="1px"
            height="2px"
            mt="-1.5px"
          />
          <TabPanels>
            <TabPanel>
              <Grid
                color="gray.900"
                gap="24px"
                mb="32px"
                templateColumns={{
                  base: "1fr",
                  lg: "repeat(3, 1fr)",
                }}
              >
                <Skeleton isLoaded={!isRefetchingCredit}>
                  <Card
                    border="1px solid"
                    borderColor="gray.200"
                    boxShadow="0px 1px 3px 0px rgba(16, 24, 40, 0.1);"
                    color="inherit"
                  >
                    <CardBody color="inherit">
                      <Text color="inherit" variant="md-semibold">
                        Total Current Credit Balance
                      </Text>
                      <Text color="inherit" variant="display-sm-semibold">
                        <NumberFormat
                          type="currency"
                          value={
                            creditTransactions?.total_current_credit_balance
                          }
                        />
                      </Text>
                    </CardBody>
                  </Card>
                </Skeleton>
                <Skeleton isLoaded={!isRefetchingCredit}>
                  <Card
                    border="1px solid"
                    borderColor="gray.200"
                    boxShadow="0px 1px 3px 0px rgba(16, 24, 40, 0.1);"
                    color="inherit"
                  >
                    <CardBody color="inherit">
                      <Text color="inherit" variant="md-semibold">
                        Total Credit Withdrawal
                      </Text>
                      <Text color="inherit" variant="display-sm-semibold">
                        <NumberFormat
                          type="currency"
                          value={
                            creditTransactions?.total_credit_withdrawn
                              ? Math.abs(
                                  Number(
                                    creditTransactions.total_credit_withdrawn
                                  )
                                )
                              : undefined
                          }
                        />
                      </Text>
                    </CardBody>
                  </Card>
                </Skeleton>
                <Skeleton isLoaded={!isRefetchingCredit}>
                  <Card
                    border="1px solid"
                    borderColor="gray.200"
                    boxShadow="0px 1px 3px 0px rgba(16, 24, 40, 0.1);"
                    color="inherit"
                  >
                    <CardBody color="inherit">
                      <Text color="inherit" variant="md-semibold">
                        Total Credit Deposited
                      </Text>
                      <Text color="inherit" variant="display-sm-semibold">
                        {creditTransactions?.total_credit_deposited ? (
                          <NumberFormat
                            type="currency"
                            value={creditTransactions?.total_credit_deposited}
                          />
                        ) : (
                          <NumberFormat type="currency" value={0} />
                        )}
                      </Text>
                    </CardBody>
                  </Card>
                </Skeleton>
              </Grid>
              <HistoryTable<FundTransaction>
                {...getTableProps(
                  creditTransactions?.transactions as FundTransaction[],
                  isRefetchingCredit,
                  () => {},
                  () => {}
                )}
                columns={columnsCredit}
                data={creditTransactions?.transactions as FundTransaction[]}
                header="All transactions"
                isLoading={isRefetchingCredit}
                totalRows={creditTransactions?.transactions?.length ?? 0}
                filterOptions={[
                  {
                    default: "Date",
                    accessor: "created",
                    filterType: "date",
                  },
                  {
                    default: "User",
                    accessor: "author",
                    filterType: "text",
                  },
                ]}
              />
            </TabPanel>
            <TabPanel>
              <Grid
                color="gray.900"
                gap="24px"
                mb="32px"
                templateColumns={{
                  base: "1fr",
                  lg: "repeat(3, 1fr)",
                }}
              >
                <Card
                  border="1px solid"
                  borderColor="gray.200"
                  boxShadow="0px 1px 3px 0px rgba(16, 24, 40, 0.1);"
                  color="inherit"
                >
                  <CardBody color="inherit">
                    <Text color="inherit" variant="md-semibold">
                      Total Shares Owned
                    </Text>
                    <Text color="inherit" variant="display-sm-semibold">
                      <NumberFormat
                        type="number"
                        value={shareTransactions?.total_owned_shares}
                      />
                    </Text>
                  </CardBody>
                </Card>
                <Card
                  border="1px solid"
                  borderColor="gray.200"
                  boxShadow="0px 1px 3px 0px rgba(16, 24, 40, 0.1);"
                  color="inherit"
                >
                  <CardBody color="inherit">
                    <Text color="inherit" variant="md-semibold">
                      Upcoming Auctions Count
                    </Text>
                    <Text color="inherit" variant="display-sm-semibold">
                      <NumberFormat
                        type="number"
                        value={shareTransactions?.upcoming_auctions_count}
                      />
                    </Text>
                  </CardBody>
                </Card>
                <Card
                  border="1px solid"
                  borderColor="gray.200"
                  boxShadow="0px 1px 3px 0px rgba(16, 24, 40, 0.1);"
                  color="inherit"
                >
                  <CardBody color="inherit">
                    <Text color="inherit" variant="md-semibold">
                      Total Executed Orders
                    </Text>
                    <Text color="inherit" variant="display-sm-semibold">
                      <NumberFormat
                        type="number"
                        value={shareTransactions?.total_executed_orders}
                      />
                    </Text>
                  </CardBody>
                </Card>
              </Grid>
              <HistoryTable<ShareTransactionPoly>
                {...getTableProps(
                  shareTransactions?.transactions as ShareTransactionPoly[],
                  isRefetchingShare,
                  () => {},
                  () => {}
                )}
                columns={columnsShare}
                data={shareTransactions?.transactions as ShareTransactionPoly[]}
                header="All transactions"
                isLoading={isRefetchingShare}
                totalRows={shareTransactions?.transactions?.length ?? 0}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
  };

  return (
    <PageWrapper
      isLoading={isFetchingCredit || isFetchingShare}
      loadingText="Updating transactions"
      pageSubtitle="Detailed record of share & credit transactions"
      pageTitle="Transactions"
    >
      <Table />
    </PageWrapper>
  );
};

export default TraderTransactionsPage;
