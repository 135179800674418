import {
  Box,
  Heading,
  HStack,
  Radio,
  Text,
  useRadio,
  UseRadioProps,
} from "@chakra-ui/react";

type Props = UseRadioProps & {
  title: string;
  description: string;
  optionId: string;
};

const RadioCard = ({ title, description, optionId, ...rest }: Props) => {
  const { getInputProps, getRadioProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" id={optionId}>
      <input {...input} />
      <HStack
        {...checkbox}
        borderRadius="md"
        borderWidth="1px"
        cursor="pointer"
        justifyItems="end"
        p={4}
        _checked={{
          bg: "#E6F8FC",
          borderColor: "#90E0EF",
          color: "primary.800",
        }}
      >
        <Radio isChecked={rest.isChecked} />
        <Box>
          <Heading fontSize="sm" fontWeight="semibold">
            {title}
          </Heading>
          <Text color="gray.500" fontSize="sm" fontWeight="normal">
            {description}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

export default RadioCard;
