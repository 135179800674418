import {
  Flex,
  FlexProps,
  Icon,
  SlideFade,
  useBreakpointValue,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { exitPromptStore } from "../../stores";

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: string;
  route: string;
  isCollapsed?: boolean;
  highlightRoutes?: string[];
  onClick?: () => void;
}

const NavItem = ({
  icon,
  route,
  isCollapsed,
  children,
  highlightRoutes,
  onClick,
  ...rest
}: NavItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const goTo = () => {
    exitPromptStore.confirmPrompt((res) => {
      if (res) {
        navigate(route);
      }
    });
  };

  const selectionColor = useBreakpointValue({
    base: "blue.900",
    md: "blue.800",
  });

  const selectedFontColor = "primary.0";

  const fontColor = useBreakpointValue({
    base: "blue.900",
    md: "primary.0",
  });

  const isSelected =
    route === pathname ||
    (highlightRoutes &&
      highlightRoutes
        .map((oneRoute) => (matchPath(oneRoute, pathname) ? true : false))
        .includes(true)) ||
    false;

  return (
    <Flex
      align="center"
      borderRadius={8}
      color={isSelected ? selectedFontColor : fontColor}
      cursor="pointer"
      fontWeight={400}
      justifyContent={!isCollapsed ? "flex-start" : "center"}
      my={1}
      py={2}
      role="group"
      _hover={{
        bg: selectionColor,
        color: selectedFontColor,
      }}
      onClick={() => {
        goTo();
        onClick && onClick();
      }}
      {...(isSelected ? { bg: selectionColor } : {})}
      {...(!isCollapsed ? { px: 2 } : {})}
      {...rest}
    >
      {icon && <Icon as={icon} fontSize="24" mr={!isCollapsed ? 4 : "unset"} />}
      <SlideFade unmountOnExit in={!isCollapsed} offsetX={-8} offsetY={0}>
        {children}
      </SlideFade>
    </Flex>
  );
};

export default NavItem;
