import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { HistoryTable } from "src/components/HistoryTable";
import NameEmailColumn from "src/components/NameEmailColumn";
import { parseName, parseNameOrEmail } from "src/utils/misc";

import { useCompaniesActionLogsList } from "../../../api/companies";
import { ActionLog } from "../../../api/model";
import { AuthService } from "../../../services";

const CompanyEditHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const { data, isLoading } = useCompaniesActionLogsList(
    AuthService.getUserCompany(),
    {
      page: currentPage,
      page_size: pageSize,
    }
  );

  const formatDetails = (row: ActionLog) => {
    if (row.action === "cap_table_upload") {
      return "Uploaded cap table";
    } else if (row.action === "add_user") {
      return (
        <Box>
          Added member{" "}
          <Text as="span" color="primary.900" fontWeight="bold">
            {parseNameOrEmail(row.user)}
          </Text>{" "}
          to the cap table
        </Box>
      );
    }
    if (row.action === "make_user_admin") {
      return (
        <Box>
          Granted admin role to{" "}
          <Text as="span" color="primary.900" fontWeight="bold">
            {parseNameOrEmail(row.user)}
          </Text>
        </Box>
      );
    }
    if (row.action === "revoke_admin_role") {
      return (
        <Box>
          Revoked admin role from{" "}
          <Text as="span" color="primary.900" fontWeight="bold">
            {parseNameOrEmail(row.user)}
          </Text>
        </Box>
      );
    }
    if (row.action === "send_email_invitation") {
      return row.details;
    }
    if (row.action === "investor_joins") {
      return (
        <Box>
          Added new investor{" "}
          <Text as="span" color="primary.900" fontWeight="bold">
            {parseNameOrEmail(row.user)}
          </Text>
        </Box>
      );
    }
  };
  const columns: TableColumn<ActionLog>[] = [
    {
      name: "Date",
      cell: (row) =>
        row.created_date ? new Date(row.created_date).toLocaleString() : "",

      selector: (row) =>
        row.created_date ? new Date(row.created_date).valueOf() : 0,
    },
    {
      name: "Admin Email",
      selector: (row) => {
        return parseNameOrEmail(row.author);
      },
      cell: (row) => (
        <NameEmailColumn
          email={row.author.email}
          name={parseName(row.author)}
        />
      ),
    },
    {
      name: "Description",
      grow: 2,
      cell: (row) => (
        <Box>
          {row.details ? (
            formatDetails(row)
          ) : (
            <Text>`No details available for ${row.action}` </Text>
          )}
        </Box>
      ),
    },
  ];

  return (
    <HistoryTable<ActionLog>
      noFilters
      noSearch
      className="EditHistoryTable"
      columns={columns}
      data={data?.results as ActionLog[]}
      header="Edit History"
      isLoading={isLoading}
      onPageChange={(page) => setCurrentPage(page)}
      onSearch={() => {}}
      totalRows={data?.count ?? 0}
      noData={
        <>
          <Text variant="xs-semibold">No records available</Text>
          <Text>Sorry, currently there are no records to display.</Text>
        </>
      }
      // buttons={<ManualShareTransactionForm />}
    />
  );
};

export default CompanyEditHistory;
