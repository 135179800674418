import {
  DefaultAuctionMemberTypedResourcetype,
  DutchAuctionMemberTypedResourcetype,
} from "../api/model";

export type AuctionMemberType =
  | DefaultAuctionMemberTypedResourcetype
  | DutchAuctionMemberTypedResourcetype;

export const AuctionMemberTypes = {
  default: DefaultAuctionMemberTypedResourcetype.DefaultAuctionMember,
  dutch: DutchAuctionMemberTypedResourcetype.DutchAuctionMember,
};
