import {
  Box,
  CircularProgress,
  Container,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { FaArrowRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useAuctionsRequestToJoinRetrieve } from "src/api/auctions";
import ConditionalWrapper from "src/components/ConditionalWrapper";
import { FSTIcon } from "src/components/FSTIcon";
import { AuthService } from "src/services";

import {
  useAuctionMembersGetActiveAuctionsList,
  useAuctionMembersGetPastAuctionsList,
  useAuctionMembersGetUpcomingAuctionsList,
} from "../../api/auction-members";
import {
  AuctionMemberPolymorphicSerializerReadOnly,
  DefaultAuctionMemberSerializerReadOnly,
} from "../../api/model";
import { HistoryTable } from "../../components/HistoryTable";
import NumberFormat from "../../components/NumberFormat";
import { useDebounce } from "../../hooks/useDebounce";
import { rInvestorRegister, rTenderVar } from "../../routes/routes";
import { stringDurationToObj } from "../../utils/dateFormatter";
import { tableColumnPassClickEvents } from "../../utils/misc";
import { getAuctionType } from "../Admin/AdminAuctionsPage";

/**
 *
 * @param investor used to determine if the user is an investor, this component is used in the trader/investor auctions
 * list and investor explore auctions page; the first is where the investor/trader can only see the auctions
 * they are part of, the second is where the investor can see all the public auctions
 * @returns React component
 */
const TendersPage = ({
  investor = false,
  singleCompany = undefined,
}: {
  investor?: boolean;
  singleCompany?: string;
}) => {
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [currentPastPage, setCurrentPastPage] = useState(1);
  const [currentUpcomingPage, setCurrentUpcomingPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchActive, setSearchActive] = useState("");
  const [searchPast, setSearchPast] = useState("");
  const [searchUpcoming, setSearchUpcoming] = useState("");
  const [
    [debouncedSearchActive, debouncedSearchPast, debouncedSearchUpcoming],
  ] = useDebounce([searchActive, searchPast, searchUpcoming], 1000);

  const {
    data: activeAuctions,
    isLoading: isLoadingActive,
    isFetching: isFetchingActive,
  } = useAuctionMembersGetActiveAuctionsList(
    {
      page: currentActivePage,
      page_size: pageSize,
      search: debouncedSearchActive,
      is_investor: investor,
      ...(singleCompany && { company_uuid: singleCompany }),
    },
    {
      request: {
        transformRequest: (data, headers) => {
          // DISABLE_MARKETPLACE
          if (investor) delete headers.authorization;
          return data;
        },
      },
    }
  );

  const {
    data: pastAuctions,
    isLoading: isLoadingPast,
    isFetching: isFetchingPast,
  } = useAuctionMembersGetPastAuctionsList(
    {
      page: currentPastPage,
      page_size: pageSize,
      search: debouncedSearchPast,
      is_investor: investor,
      ...(singleCompany && { company_uuid: singleCompany }),
    },
    {
      request: {
        transformRequest: (data, headers) => {
          // DISABLE_MARKETPLACE
          if (investor) delete headers.authorization;
          return data;
        },
      },
    }
  );

  const {
    data: upcomingAuctions,
    isLoading: isLoadingUpcoming,
    isFetching: isFetchingUpcoming,
    refetch: refetchUpcoming,
  } = useAuctionMembersGetUpcomingAuctionsList(
    {
      page: currentUpcomingPage,
      page_size: pageSize,
      search: debouncedSearchUpcoming,
      is_investor: investor,
      ...(singleCompany && { company_uuid: singleCompany }),
    },
    {
      request: {
        transformRequest: (data, headers) => {
          // DISABLE_MARKETPLACE
          const isInvestorSignedIn = AuthService.isUserInvestor();
          // We want to get the user participation request status if the user is an investor
          if (investor && !isInvestorSignedIn) delete headers.authorization;
          return data;
        },
      },
    }
  );

  const [selectedAuction, setSelectedAuction] = useState<number>(-1);

  const requestToJoin = useAuctionsRequestToJoinRetrieve(selectedAuction, {
    query: {
      enabled: selectedAuction !== -1,
    },
  });

  useEffect(() => {
    if (requestToJoin.isSuccess) {
      refetchUpcoming();
    }
  }, [requestToJoin.status]);

  const showPast = (!investor && !singleCompany) || (investor && singleCompany);
  const isUpcomingTab = showPast ? currentTab === 2 : currentTab === 1;

  const columns: TableColumn<AuctionMemberPolymorphicSerializerReadOnly>[] = [
    {
      name: "Auction Name",
      cell: (auctionMember) => (
        <Text variant="sm-semibold" {...tableColumnPassClickEvents}>
          {auctionMember.auction.title}
        </Text>
      ),
    },
    {
      name: "Company",
      cell: (auctionMember) => auctionMember.auction.company.title,
      omit: !!singleCompany,
    },
    {
      name: investor ? "Auction Type" : "Type",
      width: !investor ? "120px" : "auto",
      selector: (row) => row.auction.auction_type,
      cell: (row) => getAuctionType(row.auction.auction_type)?.title,
    },
    {
      name: "Duration",
      cell: (auctionMember) => {
        const duration = stringDurationToObj(auctionMember.auction.duration);
        return `${duration.day ?? 0}d ${duration.hour ?? 0}h ${
          duration.minute
        }m`;
      },
      selector: (auctionMember) => auctionMember.auction.duration,
    },
    {
      name: "Available Balance",
      right: true,
      omit: investor,
      cell: (auctionMember) => (
        <NumberFormat
          tableValue
          type="currency"
          value={auctionMember.credit_balance}
        />
      ),
    },
    {
      name: "Available Stocks",
      omit: investor,
      right: true,
      // workaround until we have a new requirement for the auctions table
      cell: (auctionMember) => (
        <NumberFormat
          tableValue
          type="number"
          value={
            (auctionMember as DefaultAuctionMemberSerializerReadOnly)
              .phantom_stocks
          }
        />
      ),
    },
    {
      name: currentTab === 0 ? "Ends At" : "Ended At",
      cell: (auctionMember) =>
        new Date(auctionMember.auction.ends_at!).toLocaleString(),
      omit: isUpcomingTab,
    },
    {
      name: "",
      omit: !investor,
      cell: (auctionMember) => {
        if (!isUpcomingTab) return <></>;
        return (
          <ConditionalWrapper
            condition={!AuthService.isUserInvestor()}
            wrapper={(children) => {
              // if (requestToJoin.isLoading) {
              //   return (
              //     <Box
              //       alignItems="center"
              //       cursor="pointer"
              //       display="flex"
              //       {...tableColumnPassClickEvents}
              //     >
              //       <CircularProgress isIndeterminate size="20px" />
              //     </Box>
              //   );
              // }
              return (
                <Link to={rInvestorRegister} {...tableColumnPassClickEvents}>
                  {children}
                </Link>
              );
            }}
          >
            <Box
              alignItems="center"
              cursor="pointer"
              display="flex"
              onClick={() => {
                if (AuthService.isUserInvestor()) {
                  if (auctionMember.requested_to_join) {
                    return;
                  }
                  if (selectedAuction === auctionMember.auction.id) {
                    requestToJoin.refetch();
                  } else {
                    setSelectedAuction(auctionMember.auction.id);
                  }
                  // requestToJoin.refetch();
                }
              }}
              {...tableColumnPassClickEvents}
            >
              {auctionMember.requested_to_join ? (
                <Text color="success.600" mr={2} variant="sm-semibold">
                  Request sent
                </Text>
              ) : (
                <>
                  <Text color="primary.800" mr={2} variant="sm-semibold">
                    Request Participation
                  </Text>
                  <FSTIcon
                    noBorder
                    pointer
                    Icon={FaArrowRight}
                    iconFill="primary.800"
                  />
                </>
              )}
            </Box>
          </ConditionalWrapper>
        );
      },
    },
  ];

  const getTableProps = (
    data: AuctionMemberPolymorphicSerializerReadOnly[],
    isLoading: boolean,
    onPageChange: (page: number) => void,
    onSearch: (search: string) => void
  ) => ({
    noFilters: true,
    pointerOnHover: !investor,
    buttons: <></>,
    columns: columns,
    data: data,
    isLoading: isLoading,
    noData: (
      <>
        <Text variant="display-xs-semibold">No Auctions Available</Text>
        <Text>Sorry, there are currently no auctions to display</Text>
      </>
    ),
    onSearch: onSearch,
    totalRows: data?.length ?? 0,
    onPageChange: onPageChange,
  });

  const PageWrapper = (children: React.ReactNode) => (
    <Container maxH="100%" minW="100%">
      <Box>
        <Heading>
          {investor ? "Explore Auctions " : "Auction Overview "}
          {(isFetchingActive || isFetchingPast || isFetchingUpcoming) && (
            <Text as="span" fontSize="sm" ml={2}>
              <CircularProgress isIndeterminate mr={2} size="20px" />
              Updating auctions
            </Text>
          )}
        </Heading>
        <Text color="gray.500" hidden={investor} size="sm">
          View list of auctions and specific auction details
        </Text>
      </Box>
      {children}
    </Container>
  );

  const Table = (
    <Box pt="5">
      <Tabs onChange={(index) => setCurrentTab(index)}>
        <TabList>
          <Tab>Active Auctions</Tab>
          {showPast && <Tab>Past Auctions</Tab>}
          <Tab>Upcoming Auctions</Tab>
        </TabList>
        <TabIndicator
          bg="primary.800"
          borderRadius="1px"
          height="2px"
          mt="-1.5px"
        />
        <TabPanels>
          <TabPanel>
            <HistoryTable<AuctionMemberPolymorphicSerializerReadOnly>
              {...getTableProps(
                activeAuctions?.results as AuctionMemberPolymorphicSerializerReadOnly[],
                isLoadingActive,
                (page) => setCurrentActivePage(page),
                (search) => {
                  setSearchActive(search);
                }
              )}
              header="Active Auctions"
              isLoading={isLoadingActive}
              searchBarPosition="header"
              totalRows={activeAuctions?.count ?? 0}
              onRowClicked={(row) => {
                if (investor) return;
                navigate(`${rTenderVar}${row.id}`);
              }}
            />
          </TabPanel>
          {showPast && (
            <TabPanel>
              <HistoryTable<AuctionMemberPolymorphicSerializerReadOnly>
                {...getTableProps(
                  pastAuctions?.results as AuctionMemberPolymorphicSerializerReadOnly[],
                  isLoadingPast,
                  (page) => setCurrentPastPage(page),
                  (search) => {
                    setSearchPast(search);
                  }
                )}
                header="Past Auctions"
                isLoading={isLoadingPast}
                searchBarPosition="header"
                totalRows={pastAuctions?.count ?? 0}
                onRowClicked={(row) => {
                  if (investor) return;
                  navigate(`${rTenderVar}${row.id}`);
                }}
              />
            </TabPanel>
          )}
          <TabPanel>
            <HistoryTable<AuctionMemberPolymorphicSerializerReadOnly>
              {...getTableProps(
                upcomingAuctions?.results as AuctionMemberPolymorphicSerializerReadOnly[],
                isLoadingUpcoming,
                (page) => setCurrentUpcomingPage(page),
                (search) => {
                  setSearchUpcoming(search);
                }
              )}
              header="Upcoming Auctions"
              isLoading={isLoadingUpcoming}
              searchBarPosition="header"
              totalRows={upcomingAuctions?.count ?? 0}
              onRowClicked={(row) => {
                if (investor) return;
                navigate(`${rTenderVar}${row.id}`);
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );

  if (!singleCompany) {
    return PageWrapper(Table);
  } else {
    return Table;
  }
};

export default TendersPage;
