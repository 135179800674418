import { TableStyles } from "react-data-table-component";

import { theme } from "../../chakra/theme";

export const DTtheme: TableStyles = {
  head: {
    style: {
      color: theme.colors.gray[500],
      backgroundColor: theme.colors.gray[50],
      fontSize: "14px",
      fontWeight: 800,
    },
  },
  headRow: {
    style: {
      backgroundColor: theme.colors.gray[50],
      border: `1px solid ${theme.colors.gray[200]}`,
      borderLeft: "none",
    },
  },
  rows: {
    style: {
      color: theme.colors.gray[900],
      backgroundColor: "white",
      borderBottom: `1px solid ${theme.colors.gray[200]}`,
    },
    stripedStyle: {
      color: "#898989",
      backgroundColor: "white",
    },
  },
  cells: {
    style: {
      padding: "16px 24px",
    },
  },
};
