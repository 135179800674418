import {
  Box,
  Grid,
  Heading,
  Stack,
  Tab,
  TabList,
  Tabs,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { usePillTabsStyle } from "src/hooks/usePillTabsStyle";

import bg from "/png/bg.png";

import Footer from "../Landing/Footer";
import LandingNavbar from "../Landing/LandingNavbar";
import PricingBlocks from "./PricingBlocks";

export const Pricing = () => {
  const { tabStyle, selectedTabStyle } = usePillTabsStyle({
    variant: "ghost-pricing",
  });

  const [isMonthly, setIsMonthly] = useState(true);

  return (
    <Stack bg="gray.50">
      <VStack
        bg="primary.900"
        bgImage={`url(${bg})`}
        bgPosition="center"
        bgSize="cover"
      >
        <LandingNavbar />
        <VStack align="center" gap="48px" justify="center" mb="64px">
          <VStack gap="16px" px={["32px", null, null, null, "96px"]}>
            <Heading color="gray.200" fontSize="20px" fontWeight="semibold">
              Continuous Fundraising
            </Heading>
            <Heading
              alignSelf="center"
              color="gray.100"
              fontSize={["2xl", null, "40px"]}
              textAlign="center"
            >
              Pricing Plans
            </Heading>
            <Text
              align="center"
              color="gray.200"
              fontSize={["lg", "20px"]}
              maxW="3xl"
            >
              Simple, transparent pricing that grows with you.
            </Text>
          </VStack>
          <Grid
            alignItems="center"
            justifyContent="center"
            templateColumns="1fr auto 1fr"
            w="100%"
          >
            <Box display="flex" gridColumn="2" justifyContent="center">
              <Tabs
                border="2px solid"
                borderColor="white"
                borderRadius="10px"
                index={isMonthly ? 0 : 1}
                onChange={(index) => setIsMonthly(index === 0)}
                outlineOffset="50px"
                variant="unstyled"
                w="100%"
              >
                <TabList>
                  <Tab
                    _selected={{ ...selectedTabStyle }}
                    borderLeftRadius="8px"
                    borderRight="0"
                    data-testid="private_visibility"
                    sx={{
                      ...tabStyle,
                      lineHeight: "20px",
                      fontSize: "15px",
                    }}
                  >
                    Monthly billing
                  </Tab>
                  <Tab
                    _selected={{ ...selectedTabStyle }}
                    borderLeft="0"
                    borderRightRadius="8px"
                    data-testid="public_visibility"
                    sx={{
                      ...tabStyle,
                      lineHeight: "20px",
                      fontSize: "15px",
                    }}
                  >
                    Annual billing
                  </Tab>
                </TabList>
              </Tabs>
            </Box>
            <Box
              display="flex"
              gridColumn={{ base: "2", md: "3" }}
              gridRow={{ base: "2", md: "1" }}
              justifyContent={{ base: "flex-end", md: "flex-start" }}
              ps="8px"
              pt="8px"
            >
              <Tag
                colorScheme="green"
                cursor="pointer"
                onClick={() => setIsMonthly(false)}
                size={{ base: "lg", md: "md" }}
                userSelect="none"
                css={`
                  -webkit-tap-highlight-color: transparent;
                  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                `}
              >
                15% off
              </Tag>
            </Box>
          </Grid>
        </VStack>
      </VStack>
      <PricingBlocks isMonthly={isMonthly} />
      <Footer />
    </Stack>
  );
};
