import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiCheck, FiLink, FiMail, FiUserPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import ConditionalWrapper from "src/components/ConditionalWrapper";

import {
  useCompaniesRetrieve,
  useGetCompanyInvite,
  useInviteInvestor,
} from "../../../api/companies";
import { ImportInvestorRequest } from "../../../api/model";
import { ControlWrapper } from "../../../components/ControlWrapper";
import { FSTIcon } from "../../../components/FSTIcon";
import { SpinnerButton } from "../../../components/SpinnerButton";
import { AuthService } from "../../../services";

function AddInvestorFormModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const company_id = AuthService.getUserCompany();

  const { data: companyData, isLoading: isLoadingCompanyData } =
    useCompaniesRetrieve(company_id);

  const {
    data: token,
    isFetching: isLoadingToken,
    refetch,
  } = useGetCompanyInvite(company_id, {
    query: {
      enabled: false,
    },
  });

  const { onCopy, hasCopied, setValue, value } = useClipboard("");

  useEffect(() => {
    if (companyData?.slug && token?.token) {
      setValue(
        `${window.location.href
          .split("/")
          .slice(0, 3)
          .join(
            "/"
          )}/invest/${companyData?.slug ?? company_id}?invite=${token?.token}`
      );
    }
  }, [companyData, token]);

  useEffect(() => {
    if (value) {
      onCopy();
    }
  }, [value]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<ImportInvestorRequest>();

  const handleClose = () => {
    onClose();
    reset();
    clearErrors();
  };

  const { mutate: inviteInvestorMutation, isLoading: inviteInvestorLoading } =
    useInviteInvestor({
      mutation: {
        onSuccess: () => {
          toast.success("Sent an invitation to the investor successfully");
          handleClose();
        },
      },
    });

  const onSubmit = (data: ImportInvestorRequest) => {
    inviteInvestorMutation({ uuid: company_id, data });
  };

  return (
    <>
      <ConditionalWrapper
        condition={!companyData?.can_add_users}
        wrapper={(children) => (
          <Tooltip label="You need to upgrade your plan to add more users">
            {children}
          </Tooltip>
        )}
      >
        <Button
          className="addInvestorButton"
          isDisabled={!companyData?.can_add_users}
          w="100%"
          onClick={() => {
            reset();
            onOpen();
          }}
        >
          <AiOutlinePlusCircle />
          <Text color="inherit" pl={2}>
            Invite investor
          </Text>
        </Button>
      </ConditionalWrapper>

      <Modal isCentered isOpen={isOpen} onClose={() => handleClose()} size="lg">
        <ModalOverlay />
        <ModalContent>
          <Box className="addInvestorModal">
            <ModalHeader>
              <FSTIcon withRing Icon={FiUserPlus} />
              <Text mt="3">Add New Investor</Text>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={5}>
                  <ControlWrapper
                    fullLine
                    className="emailInput"
                    errorMessage={errors.email && errors.email.message}
                    formLabel="Email address"
                    isInvalid={!!errors.email}
                  >
                    <InputGroup>
                      <InputLeftElement
                        children={<FiMail />}
                        pointerEvents="none"
                      />
                      <Input
                        autoComplete="off"
                        id="email"
                        placeholder="you@email.com"
                        {...register("email")}
                      />
                    </InputGroup>
                    <Text color="error.600" variant="sm-regular">
                      Please note that if you engage in transactions for which
                      only accredited investors are eligible, you are
                      responsible for confirming that the investor is
                      accredited. For details on whether an investor qualifies
                      as accredited, please see this{" "}
                      <a
                        href="https://www.sec.gov/resources-small-businesses/capital-raising-building-blocks/accredited-investor#:~:text=How%20can%20individuals%20qualify%20as,Professional%20Criteria"
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                      >
                        guideline.
                      </a>
                    </Text>
                  </ControlWrapper>
                  <Stack
                    align="center"
                    className="copyInvitationLink"
                    color="primary.800"
                    direction={["column", "row"]}
                    cursor={
                      isLoadingCompanyData || isLoadingToken
                        ? "not-allowed"
                        : "pointer"
                    }
                    onClick={() => {
                      if (token?.token) {
                        onCopy();
                      } else {
                        refetch();
                      }
                    }}
                  >
                    {hasCopied ? (
                      <FSTIcon noBorder pointer Icon={FiCheck} />
                    ) : isLoadingToken ? (
                      <CircularProgress isIndeterminate size="20px" />
                    ) : companyData?.slug ? (
                      <FSTIcon noBorder pointer Icon={FiLink} />
                    ) : (
                      <CircularProgress isIndeterminate size="20px" />
                    )}
                    <Text color="inherit">Copy invitation link</Text>
                    <Fade in={hasCopied}>
                      <Text as="span" color="primary.900" ml={2}>
                        Copied!
                      </Text>
                    </Fade>
                  </Stack>
                </Stack>
              </form>
            </ModalBody>

            <ModalFooter>
              <Stack direction={["column", "row"]} spacing={5} w="100%">
                <Button
                  className="cancelInvestorModalButton"
                  onClick={() => handleClose()}
                  variant="outline"
                  w="100%"
                >
                  Cancel
                </Button>
                <SpinnerButton
                  loading={inviteInvestorLoading}
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  w="100%"
                >
                  Invite
                </SpinnerButton>
              </Stack>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddInvestorFormModal;
