import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import {
  Box,
  CircularProgress,
  Container,
  Flex,
  Heading,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuctionsDestroy } from "../../api/auctions";
import {
  getGetCompanyActiveAuctionsQueryKey,
  getGetCompanyDraftAuctionsQueryKey,
  getGetCompanyEndedAuctionsQueryKey,
  useGetCompanyActiveAuctions,
  useGetCompanyDraftAuctions,
  useGetCompanyEndedAuctions,
} from "../../api/companies";
import {
  AuctionPolymorphic,
  DefaultAuctionTyped,
  DutchAuctionTyped,
} from "../../api/model";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { FSTIcon } from "../../components/FSTIcon";
import { HistoryTable } from "../../components/HistoryTable";
import { useColorFromToken } from "../../hooks/useColorFromToken";
import { useDebounce } from "../../hooks/useDebounce";
import {
  rAdminAuctionsActive,
  rAdminAuctionsDraft,
  rAdminAuctionsEnded,
  rAdminTenderDetails,
  rAdminTenders,
} from "../../routes/routes";
import { AuthService } from "../../services";
import { auctionTypes } from "../../utils/constants";
import { stringDurationToObj } from "../../utils/dateFormatter";
import { tableColumnPassClickEvents } from "../../utils/misc";
import AddAuctionModal from "./components/AddAuctionModal";
import EditDutch from "./components/auctionForms/EditDutch";
import EditSellerPrice from "./components/auctionForms/EditSellerPrice";

export const getAuctionType = (type: "DefaultAuction" | "DutchAuction") => {
  if (type === auctionTypes.default) {
    return {
      title: "Seller Price",
    };
  } else if (type === auctionTypes.dutch) {
    return {
      title: "Dutch",
    };
  }
};

const AdminTendersPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getColorFromToken = useColorFromToken();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [currentPastPage, setCurrentPastPage] = useState(1);
  const [currentUpcomingPage, setCurrentUpcomingPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchActive, setSearchActive] = useState("");
  const [searchPast, setSearchPast] = useState("");
  const [searchUpcoming, setSearchUpcoming] = useState("");
  const [
    [debouncedSearchActive, debouncedSearchPast, debouncedSearchUpcoming],
  ] = useDebounce([searchActive, searchPast, searchUpcoming], 1000);

  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: openConfirmDelete,
    onClose: closeConfirmDelete,
  } = useDisclosure();
  const company = AuthService.getUserCompany();

  const [selectedAuction, setSelectedAuction] = useState<AuctionPolymorphic>();

  const getIndexFromPath = (path: string) => {
    switch (path) {
      case rAdminAuctionsActive:
        return 1;
      case rAdminAuctionsEnded:
        return 2;
      case rAdminTenders:
      case rAdminAuctionsDraft:
      default:
        return 0;
    }
  };
  const handleTabsChange = (index: number) => {
    switch (index) {
      case 1:
        navigate(rAdminAuctionsActive);
        break;
      case 2:
        navigate(rAdminAuctionsEnded);
        break;
      case 0:
      default:
        navigate(rAdminAuctionsDraft, { replace: true });
        break;
    }
  };
  useEffect(() => {
    const index = getIndexFromPath(location.pathname);
    handleTabsChange(index);
    setCurrentTab(index);
  }, [location.pathname]);

  const { data: activeAuctions, isLoading: isRefetchingActive } =
    useGetCompanyActiveAuctions(company, {
      page: currentActivePage,
      page_size: pageSize,
      search: debouncedSearchActive,
    });

  const { data: pastAuctions, isLoading: isRefetchingPast } =
    useGetCompanyEndedAuctions(company, {
      page: currentPastPage,
      page_size: pageSize,
      search: debouncedSearchPast,
    });

  const { data: upcomingAuctions, isLoading: isRefetchingUpcoming } =
    useGetCompanyDraftAuctions(company, {
      page: currentUpcomingPage,
      page_size: pageSize,
      search: debouncedSearchUpcoming,
    });

  const refetchTab = useCallback(() => {
    if (currentTab === 0) {
      queryClient.invalidateQueries(
        getGetCompanyDraftAuctionsQueryKey(company)
      );
    } else if (currentTab === 1) {
      queryClient.invalidateQueries(
        getGetCompanyActiveAuctionsQueryKey(company)
      );
    } else if (currentTab === 2) {
      queryClient.invalidateQueries(
        getGetCompanyEndedAuctionsQueryKey(company)
      );
    }
  }, [currentTab, queryClient, company]);

  const deleteAuctionMutation = useAuctionsDestroy({
    mutation: {
      onError: (err) => {
        if (!err.errors[0].detail) {
          toast.error("Couldn't delete Auction. Please try again.");
        }
      },
      onSuccess: () => {
        refetchTab();
        toast.success("Auction deleted successfully");
        closeConfirmDelete();
      },
    },
  });

  // const durationOrEndsAt = (row: AuctionPolymorphic) => {
  //   if (currentActivePage === 1) {
  //     return (
  //       <NumberFormat
  //         value={(row as DefaultAuction).duration}
  //         suffix=" days"
  //       />
  //     );
  //   } else {
  //     return new Date(row.ends_at).toLocaleString();
  //   }
  // }

  const columns: TableColumn<AuctionPolymorphic>[] = [
    {
      name: "Title",
      // grow: 2,
      cell: (row) => row.title,
      selector: (row) => row.title,
    },
    {
      name: "Auction Admin",
      grow: 1,
      cell: (row) => row.company_admin.email,
      selector: (row) => row.company_admin.email,
    },
    {
      name: "Type",
      width: "120px",
      selector: (row) => row.auction_type,
      cell: (row) => getAuctionType(row.auction_type)?.title,
    },
    {
      name: "Live",
      selector: (row) => (row.is_custodial ? "Yes" : "No"),
      width: "120px",
      cell: (row) =>
        row.is_custodial ? (
          <FiCheckCircle
            color={getColorFromToken("success.600")}
            size={19}
            {...tableColumnPassClickEvents}
          />
        ) : (
          <FiXCircle
            color={getColorFromToken("error.700")}
            size={19}
            {...tableColumnPassClickEvents}
          />
        ),
    },
    {
      name: currentTab === 1 ? "Ends At" : "Ended At",
      // grow: 2,
      style: {
        whiteSpace: "nowrap",
      },
      width: "150px",
      cell: (row) => new Date(row.ends_at!).toLocaleString(),
      selector: (row) => new Date(row.ends_at!).valueOf(),
      omit: !currentTab,
    },
    {
      name: "Duration",
      cell: (row) => {
        const duration = stringDurationToObj(row.duration);
        return `${duration.day ?? 0}d ${duration.hour ?? 0}h ${
          duration.minute
        }m`;
      },
      selector: (row) => row.duration,
      omit: Boolean(currentTab) && currentTab! > 0,
    },
    // {
    //   name: "Participants Count",
    //   cell: (row) => row.number_of_participants,
    //   selector: (row) => row.number_of_participants,
    // },
    // {
    //   name: "Resolution Status",
    //   cell: (row) => row.resolution_status,
    //   selector: (row) => row.resolution_status,
    // },
    {
      // name: "Actions",
      cell: (row) => {
        // DISABLE_FEES
        // const hasPaidForResolution = row.has_paid_for_resolution;
        const hasPaidForResolution = row.has_approved_resolution;
        return (
          <Flex gap={3}>
            <Tooltip
              label={
                hasPaidForResolution
                  ? "Cannot delete an auctions that has a resolution"
                  : undefined
              }
            >
              <div>
                <FSTIcon
                  noBorder
                  pointer
                  Icon={MdDeleteOutline}
                  disabled={hasPaidForResolution}
                  size={20}
                  onClick={() => {
                    setSelectedAuction(row);
                    openConfirmDelete();
                  }}
                />
              </div>
            </Tooltip>
            {row.auction_type === auctionTypes.dutch ? (
              <EditDutch
                icon
                auctionData={row as DutchAuctionTyped}
                onChange={refetchTab}
              />
            ) : row.auction_type === auctionTypes.default ? (
              <EditSellerPrice
                icon
                auctionData={row as DefaultAuctionTyped}
                onChange={refetchTab}
              />
            ) : null}
          </Flex>
        );
      },
    },
    // {
    //   name: "created",
    //   style: {
    //     whiteSpace: "nowrap",
    //   },
    //   grow: 2,
    //   cell: (row) => new Date(row.created).toLocaleString(),
    //   selector: (row) => new Date(row.created).valueOf(),
    //   sortFunction: (a, b) => {
    //     if (a.created > b.created) return -1;
    //     return 1;
    //   },
    //   omit: false,
    // },
  ];

  columns.forEach((column) => {
    if (column.name === "Actions") column.sortable = false;
    else {
      column.sortable = true;
    }
  });

  const getTableProps = (
    data: AuctionPolymorphic[],
    isLoading: boolean,
    onPageChange: (page: number) => void,
    onSearch: (search: string) => void
  ) => ({
    noFilters: true,
    pointerOnHover: true,
    buttons: <AddAuctionModal />,
    columns: columns,
    data: data,
    isLoading: isLoading,
    noData: (
      <>
        <Text variant="xs-semibold">No Auctions Available</Text>
        <Text>Sorry, there are currently no auctions to display</Text>
      </>
    ),
    onSearch: onSearch,
    totalRows: data?.length ?? 0,
    onPageChange: onPageChange,
  });

  return (
    <Container maxH="100%" minW="100%">
      <ConfirmationDialog
        confirmText="Delete"
        handleClose={closeConfirmDelete}
        isLoading={deleteAuctionMutation.isLoading}
        show={isConfirmDeleteOpen}
        title="Remove Auction"
        body={
          <>
            Are you sure you want to remove auction:{" "}
            <Text as="b" color="blue-primary">
              {selectedAuction?.title}
            </Text>
            ?
          </>
        }
        handleSubmit={() => {
          deleteAuctionMutation.mutate({
            id: selectedAuction!.id,
          });
        }}
      />
      <Box>
        <Heading>
          Auction Overview{" "}
          {(isRefetchingActive || isRefetchingPast || isRefetchingUpcoming) && (
            <Text as="span" fontSize="sm" ml={2}>
              <CircularProgress isIndeterminate mr={2} size="20px" />
              Updating auctions
            </Text>
          )}
        </Heading>
        <Text color="gray.500" size="sm">
          View list of auctions and specific auction details
        </Text>
      </Box>
      <Box pt="5">
        <Tabs
          index={getIndexFromPath(location.pathname)}
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab>Draft Auctions</Tab>
            <Tab>Active Auctions</Tab>
            <Tab>Ended Auctions</Tab>
          </TabList>
          <TabIndicator
            bg="primary.800"
            borderRadius="1px"
            height="2px"
            mt="-1.5px"
          />
          <TabPanels>
            <TabPanel>
              <HistoryTable<AuctionPolymorphic>
                {...getTableProps(
                  upcomingAuctions?.results as AuctionPolymorphic[],
                  isRefetchingUpcoming,
                  (page) => setCurrentUpcomingPage(page),
                  (search) => {
                    setSearchUpcoming(search);
                  }
                )}
                header="Draft Auctions"
                isLoading={isRefetchingUpcoming}
                totalRows={upcomingAuctions?.count ?? 0}
                onRowClicked={(row) => {
                  navigate(
                    rAdminTenderDetails.replace(":auction_id", `${row.id}`)
                  );
                }}
              />
            </TabPanel>
            <TabPanel>
              <HistoryTable<AuctionPolymorphic>
                {...getTableProps(
                  activeAuctions?.results as AuctionPolymorphic[],
                  isRefetchingActive,
                  (page) => setCurrentActivePage(page),
                  (search) => {
                    setSearchActive(search);
                  }
                )}
                header="Active Auctions"
                isLoading={isRefetchingActive}
                totalRows={activeAuctions?.count ?? 0}
                onRowClicked={(row) => {
                  navigate(
                    rAdminTenderDetails.replace(":auction_id", `${row.id}`)
                  );
                }}
              />
            </TabPanel>
            <TabPanel>
              <HistoryTable<AuctionPolymorphic>
                {...getTableProps(
                  pastAuctions?.results as AuctionPolymorphic[],
                  isRefetchingPast,
                  (page) => setCurrentPastPage(page),
                  (search) => {
                    setSearchPast(search);
                  }
                )}
                header="Ended Auctions"
                isLoading={isRefetchingPast}
                totalRows={pastAuctions?.count ?? 0}
                onRowClicked={(row) => {
                  navigate(
                    rAdminTenderDetails.replace(":auction_id", `${row.id}`)
                  );
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
};

export default AdminTendersPage;
