import { Box, Button, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import React from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

interface DropdownMenuProps {
  title: string | React.ReactNode;
  children: JSX.Element[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  title,
  children,
}: DropdownMenuProps) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <Box>
          <MenuButton
            as={Button}
            borderColor="blue-primary"
            borderRadius="xl"
            borderWidth="1px"
            px={4}
            py={2}
            rightIcon={isOpen ? <HiChevronUp /> : <HiChevronDown />}
            transition="all 0.2s"
            variant="outline"
          >
            {title}
          </MenuButton>
          <MenuList zIndex={10}>{children}</MenuList>
        </Box>
      )}
    </Menu>
  );
};
export default DropdownMenu;
