import { Text } from "@chakra-ui/react";
import { TableColumn } from "react-data-table-component";
import { useParams } from "react-router-dom";
import { useGetAuctionTransactions } from "src/api/auction-members";
import { AuctionTransaction } from "src/api/model";
import { HistoryTable } from "src/components/HistoryTable";
import NumberFormat from "src/components/NumberFormat";
import { useDateUtils } from "src/hooks/useDateUtils";

export default () => {
  const { id } = useParams();
  const { formatDate } = useDateUtils();

  const { data: shareTransactions, isLoading: isRefetchingShare } =
    useGetAuctionTransactions(id ?? "");

  const columnsShare: TableColumn<AuctionTransaction>[] = [
    {
      name: "Type",
      cell: (transaction) => (transaction.buy_order ? "Buy" : "Sell"),
    },
    {
      name: "Quantity",
      cell: (transaction) => transaction.quantity,
    },
    {
      name: "Price Per Unit",
      selector: (transaction) => transaction.price_per_unit,
      cell: (transaction) => (
        <NumberFormat
          tableValue
          type="currency"
          value={transaction.price_per_unit}
        />
      ),
    },
    {
      name: "Total Price",
      cell: (transaction) => (
        <NumberFormat
          tableValue
          type="currency"
          value={Number(transaction.price_per_unit) * transaction.quantity}
        />
      ),
    },
    {
      name: "Date",
      selector: (transaction) => transaction.created,
      cell: (transaction) => formatDate(transaction.created),
      sortable: true,
    },
  ];

  return (
    <HistoryTable<AuctionTransaction>
      buttons={<></>}
      columns={columnsShare}
      data={shareTransactions as AuctionTransaction[]}
      header="All transactions"
      isLoading={isRefetchingShare}
      noFilters={true}
      noServerPagination={true}
      onPageChange={() => {}}
      onSearch={() => {}}
      pointerOnHover={false}
      searchBarPosition="top"
      totalRows={shareTransactions?.length ?? 0}
      noData={
        <>
          <Text variant="display-xs-semibold">No Auctions Available</Text>
          <Text>Sorry, there are currently no auctions to display</Text>
        </>
      }
    />
  );
};
