import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Text: ComponentStyleConfig = {
  baseStyle: () => ({
    color: "black-primary",
    fontFamily: "Open Sans, sans-serif",
  }),
  variants: {
    // used as <Text variant="secondary">
    "display-sm-semibold": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "38px",
    },
    "display-xs-semibold": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "32px",
    },
    "lg-semibold": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "28px",
    },
    "md-semibold": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },
    "md-medium": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    "sm-semibold": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "20px",
    },
    "sm-regular": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
    },
    "xs-semibold": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "18px",
    },
    "xs-medium": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "18px",
    },
    "xs-regular": {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
    },
    secondary: {
      color: "blue.900",
    },
    muted: {
      color: "#000000",
      opacity: "40%",
    },
    landingHeading: {
      fontWeight: "700",
    },
    landingText: {
      fontWeight: "400",
    },
  },
};
