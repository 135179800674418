import IBidOrder from "./IBidOrder";

export default interface IBuyOrder extends IBidOrder {
  minimum_quantity: number;
  amount: number;
  created?: string;
}

export function iBuyOrderFactory(orders: IBuyOrder[]) {
  for (const order in orders) {
    if (Object.prototype.hasOwnProperty.call(orders, order)) {
      orders[order].amount = Number.parseFloat(`${orders[order].amount}`);
    }
  }
  return orders;
}
