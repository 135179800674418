import {
  FieldValues,
  Path,
  UseFormGetValues,
  UseFormSetError,
} from "react-hook-form";
import {
  ClientErrorEnum,
  ServerErrorEnum,
  ValidationErrorEnum,
} from "src/api/model";

interface GenericErrorStruct {
  attr: string | null;
  code: string;
  detail: string;
}

interface GenericErrorResponse {
  errors: GenericErrorStruct[];
  type: ValidationErrorEnum | ClientErrorEnum | ServerErrorEnum;
}

export const formServerErrorsHandler = <T extends FieldValues>(
  setError: UseFormSetError<T>,
  getValues: UseFormGetValues<T>,
  error: GenericErrorResponse
) => {
  if (error.type === "validation_error") {
    error.errors.forEach((err) => {
      if (err.attr !== "non_field_errors" && err.attr !== null) {
        const values = getValues() as Record<string, unknown>;
        if (values[err.attr] !== undefined) {
          setError(err.attr as Path<T>, {
            message: err.detail,
          });
        }
      }
    });
  }
};
