/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AuctionMemberPolymorphicSerializerReadOnly,
  AuctionMembersGetActiveAuctionsListErrorResponse400,
  AuctionMembersGetActiveAuctionsListParams,
  AuctionMembersGetPastAuctionsListErrorResponse400,
  AuctionMembersGetPastAuctionsListParams,
  AuctionMembersGetUpcomingAuctionsListErrorResponse400,
  AuctionMembersGetUpcomingAuctionsListParams,
  AuctionMembersListErrorResponse400,
  AuctionMembersListParams,
  AuctionMembersRetrieveErrorResponse400,
  AuctionTransaction,
  ErrorResponse401,
  ErrorResponse403,
  ErrorResponse404,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
  GetAuctionTransactionsErrorResponse400,
  GetTraderCreditTransactionsErrorResponse400,
  GetTraderShareTransactionsErrorResponse400,
  PaginatedAuctionMemberPolymorphicSerializerReadOnlyList,
  TraderFundTransactions,
  TraderShareTransactions,
} from "./model";
import { apiCustomInstance } from "../services/apiService";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const auctionMembersList = (
  params?: AuctionMembersListParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedAuctionMemberPolymorphicSerializerReadOnlyList>(
    { url: `/auction-members/`, method: "GET", params, signal },
    options
  );
};

export const getAuctionMembersListQueryKey = (
  params?: AuctionMembersListParams
) => {
  return [`/auction-members/`, ...(params ? [params] : [])] as const;
};

export const getAuctionMembersListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionMembersList>>,
  TError =
    | AuctionMembersListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: AuctionMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuctionMembersListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionMembersList>>
  > = ({ signal }) => auctionMembersList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionMembersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionMembersList>>
>;
export type AuctionMembersListQueryError =
  | AuctionMembersListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionMembersList = <
  TData = Awaited<ReturnType<typeof auctionMembersList>>,
  TError =
    | AuctionMembersListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: AuctionMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionMembersListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const auctionMembersRetrieve = (
  id: number,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionMemberPolymorphicSerializerReadOnly>(
    { url: `/auction-members/${id}/`, method: "GET", signal },
    options
  );
};

export const getAuctionMembersRetrieveQueryKey = (id: number) => {
  return [`/auction-members/${id}/`] as const;
};

export const getAuctionMembersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionMembersRetrieve>>,
  TError =
    | AuctionMembersRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuctionMembersRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionMembersRetrieve>>
  > = ({ signal }) => auctionMembersRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionMembersRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionMembersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionMembersRetrieve>>
>;
export type AuctionMembersRetrieveQueryError =
  | AuctionMembersRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionMembersRetrieve = <
  TData = Awaited<ReturnType<typeof auctionMembersRetrieve>>,
  TError =
    | AuctionMembersRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionMembersRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all active auctions for an auction member
 */
export const auctionMembersGetActiveAuctionsList = (
  params?: AuctionMembersGetActiveAuctionsListParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedAuctionMemberPolymorphicSerializerReadOnlyList>(
    {
      url: `/auction-members/get_active_auctions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getAuctionMembersGetActiveAuctionsListQueryKey = (
  params?: AuctionMembersGetActiveAuctionsListParams
) => {
  return [
    `/auction-members/get_active_auctions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getAuctionMembersGetActiveAuctionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionMembersGetActiveAuctionsList>>,
  TError =
    | AuctionMembersGetActiveAuctionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: AuctionMembersGetActiveAuctionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersGetActiveAuctionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAuctionMembersGetActiveAuctionsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionMembersGetActiveAuctionsList>>
  > = ({ signal }) =>
    auctionMembersGetActiveAuctionsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionMembersGetActiveAuctionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionMembersGetActiveAuctionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionMembersGetActiveAuctionsList>>
>;
export type AuctionMembersGetActiveAuctionsListQueryError =
  | AuctionMembersGetActiveAuctionsListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionMembersGetActiveAuctionsList = <
  TData = Awaited<ReturnType<typeof auctionMembersGetActiveAuctionsList>>,
  TError =
    | AuctionMembersGetActiveAuctionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: AuctionMembersGetActiveAuctionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersGetActiveAuctionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionMembersGetActiveAuctionsListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all past auctions for an auction member
 */
export const auctionMembersGetPastAuctionsList = (
  params?: AuctionMembersGetPastAuctionsListParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedAuctionMemberPolymorphicSerializerReadOnlyList>(
    {
      url: `/auction-members/get_past_auctions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getAuctionMembersGetPastAuctionsListQueryKey = (
  params?: AuctionMembersGetPastAuctionsListParams
) => {
  return [
    `/auction-members/get_past_auctions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getAuctionMembersGetPastAuctionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionMembersGetPastAuctionsList>>,
  TError =
    | AuctionMembersGetPastAuctionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: AuctionMembersGetPastAuctionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersGetPastAuctionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAuctionMembersGetPastAuctionsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionMembersGetPastAuctionsList>>
  > = ({ signal }) =>
    auctionMembersGetPastAuctionsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionMembersGetPastAuctionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionMembersGetPastAuctionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionMembersGetPastAuctionsList>>
>;
export type AuctionMembersGetPastAuctionsListQueryError =
  | AuctionMembersGetPastAuctionsListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionMembersGetPastAuctionsList = <
  TData = Awaited<ReturnType<typeof auctionMembersGetPastAuctionsList>>,
  TError =
    | AuctionMembersGetPastAuctionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: AuctionMembersGetPastAuctionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersGetPastAuctionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionMembersGetPastAuctionsListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all upcoming auction members
 */
export const auctionMembersGetUpcomingAuctionsList = (
  params?: AuctionMembersGetUpcomingAuctionsListParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedAuctionMemberPolymorphicSerializerReadOnlyList>(
    {
      url: `/auction-members/get_upcoming_auctions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getAuctionMembersGetUpcomingAuctionsListQueryKey = (
  params?: AuctionMembersGetUpcomingAuctionsListParams
) => {
  return [
    `/auction-members/get_upcoming_auctions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getAuctionMembersGetUpcomingAuctionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof auctionMembersGetUpcomingAuctionsList>>,
  TError =
    | AuctionMembersGetUpcomingAuctionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: AuctionMembersGetUpcomingAuctionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersGetUpcomingAuctionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAuctionMembersGetUpcomingAuctionsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof auctionMembersGetUpcomingAuctionsList>>
  > = ({ signal }) =>
    auctionMembersGetUpcomingAuctionsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auctionMembersGetUpcomingAuctionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionMembersGetUpcomingAuctionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof auctionMembersGetUpcomingAuctionsList>>
>;
export type AuctionMembersGetUpcomingAuctionsListQueryError =
  | AuctionMembersGetUpcomingAuctionsListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuctionMembersGetUpcomingAuctionsList = <
  TData = Awaited<ReturnType<typeof auctionMembersGetUpcomingAuctionsList>>,
  TError =
    | AuctionMembersGetUpcomingAuctionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: AuctionMembersGetUpcomingAuctionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof auctionMembersGetUpcomingAuctionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionMembersGetUpcomingAuctionsListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all transactions for a public auction trader
 */
export const getAuctionTransactions = (
  auctionId: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionTransaction[]>(
    {
      url: `/auction-members/transactions/auctions/${auctionId}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAuctionTransactionsQueryKey = (auctionId: string) => {
  return [`/auction-members/transactions/auctions/${auctionId}/`] as const;
};

export const getGetAuctionTransactionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuctionTransactions>>,
  TError =
    | GetAuctionTransactionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  auctionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuctionTransactions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAuctionTransactionsQueryKey(auctionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAuctionTransactions>>
  > = ({ signal }) => getAuctionTransactions(auctionId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!auctionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuctionTransactions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAuctionTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuctionTransactions>>
>;
export type GetAuctionTransactionsQueryError =
  | GetAuctionTransactionsErrorResponse400
  | ErrorResponse401
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetAuctionTransactions = <
  TData = Awaited<ReturnType<typeof getAuctionTransactions>>,
  TError =
    | GetAuctionTransactionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  auctionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuctionTransactions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuctionTransactionsQueryOptions(
    auctionId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all credit transactions for a trader
 */
export const getTraderCreditTransactions = (
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<TraderFundTransactions>(
    {
      url: `/auction-members/transactions/get-trader-credit-transactions/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetTraderCreditTransactionsQueryKey = () => {
  return [
    `/auction-members/transactions/get-trader-credit-transactions/`,
  ] as const;
};

export const getGetTraderCreditTransactionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTraderCreditTransactions>>,
  TError =
    | GetTraderCreditTransactionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTraderCreditTransactions>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTraderCreditTransactionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTraderCreditTransactions>>
  > = ({ signal }) => getTraderCreditTransactions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTraderCreditTransactions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTraderCreditTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTraderCreditTransactions>>
>;
export type GetTraderCreditTransactionsQueryError =
  | GetTraderCreditTransactionsErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetTraderCreditTransactions = <
  TData = Awaited<ReturnType<typeof getTraderCreditTransactions>>,
  TError =
    | GetTraderCreditTransactionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTraderCreditTransactions>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTraderCreditTransactionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all share transactions for a trader
 */
export const getTraderShareTransactions = (
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<TraderShareTransactions>(
    {
      url: `/auction-members/transactions/get-trader-share-transactions/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetTraderShareTransactionsQueryKey = () => {
  return [
    `/auction-members/transactions/get-trader-share-transactions/`,
  ] as const;
};

export const getGetTraderShareTransactionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTraderShareTransactions>>,
  TError =
    | GetTraderShareTransactionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTraderShareTransactions>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTraderShareTransactionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTraderShareTransactions>>
  > = ({ signal }) => getTraderShareTransactions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTraderShareTransactions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTraderShareTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTraderShareTransactions>>
>;
export type GetTraderShareTransactionsQueryError =
  | GetTraderShareTransactionsErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetTraderShareTransactions = <
  TData = Awaited<ReturnType<typeof getTraderShareTransactions>>,
  TError =
    | GetTraderShareTransactionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTraderShareTransactions>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTraderShareTransactionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
