import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Icon as ChakraIcon,
  Link,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { MdOutlineInfo } from "react-icons/md";
import NumberFormat from "src/components/NumberFormat";

import { tenderStore } from "../../stores";
import AuctionDetailsOnboarding from "../Trader/Onboardings/AuctionDetailsOnboarding";
import { AuctionTransactions } from "./components";
import AuctionDetails from "./components/AuctionDetails";

const TraderAuctionPage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Container maxH="100%" minW="100%">
      <AuctionDetailsOnboarding />
      <Stack align="baseline" direction={["column", "row"]} pb="5">
        <Heading>{tenderStore.tender?.auction.title}</Heading>

        <Tooltip
          closeOnClick={false}
          label={
            tenderStore.tender?.auction.auction_type === "DefaultAuction" ? (
              "In this auction, participants can place confidential buy and sell orders for shares. Buyers can specify the number of shares they wish to purchase and the price per share they are willing to pay. Similarly, sellers can set the price per share for their sell orders."
            ) : (
              <>
                In this auction, prices start at{" "}
                <NumberFormat
                  type="currency"
                  value={tenderStore.tender?.auction.starting_price_per_share}
                />{" "}
                per share and decrease over time. Buyers place confidential
                orders without seeing others' bids.
              </>
            )
          }
        >
          <Tag
            bgColor="white"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="6px"
            color="gray.700"
            ml="8px"
            padding-left="9px"
            padding-right="2px"
            px="4px"
          >
            {tenderStore.tender?.auction.auction_type === "DefaultAuction"
              ? "Seller Price Auction"
              : "Dutch Auction"}
            <div
              style={{
                display: "inline",
                marginLeft: "2px",
                paddingTop: "4px",
              }}
            >
              <ChakraIcon as={MdOutlineInfo} color="gray.400" />
            </div>
          </Tag>
        </Tooltip>
      </Stack>
      <Tabs
        isLazy
        index={tabIndex}
        onChange={(index) => {
          setTabIndex(index);
        }}
      >
        <Stack
          direction={{ md: "row", base: "column-reverse" }}
          justifyContent="space-between"
        >
          <Box>
            <TabList>
              <Tab className="auctionDetailsTab">Auction Details</Tab>
              <Tab
                isDisabled={
                  !(
                    tenderStore.tender?.auction.has_a_published_resolution ===
                      true &&
                    tenderStore.tender?.auction.resolution_visibility ===
                      "members"
                  )
                }
              >
                Auction Transactions
              </Tab>
            </TabList>
            <TabIndicator
              bg="primary.800"
              borderRadius="1px"
              height="2px"
              mt="-1.5px"
            />
          </Box>
          <HStack gap="2">
            {tenderStore.tender?.auction.info_document ? (
              <Link isExternal href={tenderStore.tender?.auction.info_document}>
                <Button className="viewInfoDocumentButton" variant="outline">
                  View Information Document
                </Button>
              </Link>
            ) : (
              <Text variant="muted">No information document provided</Text>
            )}
          </HStack>
        </Stack>

        <TabPanels>
          <TabPanel>
            <AuctionDetails />
          </TabPanel>
          <TabPanel>
            <AuctionTransactions />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default observer(TraderAuctionPage);
