import {
  Flex,
  HStack,
  IconButton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { useColorFromToken } from "src/hooks/useColorFromToken";

import { NavbarProps } from "./NavbarProps";

const Navbar = ({ onOpen, ...rest }: NavbarProps) => {
  const getColorFromToken = useColorFromToken();

  return (
    <Flex
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      borderBottomWidth="1px"
      display={{ base: "flex", md: "none" }}
      height="20"
      justifyContent="space-between"
      px={{ base: 4, md: 4 }}
      {...rest}
    >
      {/* <HStack width={"78%"} display={{ base: "none", md: "flex" }}>
        <CgSearch color={"#0069BC"} size={30} />
        <Input
          variant="unstyled"
          placeholder="Search for transaction, item, etc"
        />{" "}
      </HStack> */}
      <HStack>
        <IconButton
          aria-label="open menu"
          display={{ base: "flex", md: "none" }}
          icon={<FiMenu color={getColorFromToken("blue.900")} size="26" />}
          onClick={onOpen}
          variant="ghost"
        />
        <Text
          color="blue.900"
          display={{ base: "flex", md: "none" }}
          fontSize="2xl"
          fontWeight="bold"
        >
          FairStock<span style={{ fontWeight: "normal" }}>.Trade</span>
        </Text>
      </HStack>

      {/* <HStack spacing={{ base: "0", md: "6" }}>
        <Flex
          borderLeft="1px"
          borderLeftColor={useColorModeValue("gray.200", "gray.700")}
          alignItems={"center"}
        >
          <HStack color={"#0069BC"} fontWeight={"bold"} mr="8" ml="4">
            <FiCreditCard size={20} />
            <Text>Connect a wallet</Text>
          </HStack>
          <HStack>
            <Box
              backgroundColor={"#F5F5F5"}
              p={1}
              border={"solid 1px #F5F5F5"}
              borderRadius="9999px"
            >
              <IconButton
                size="sm"
                variant="ghost"
                aria-label="open menu"
                icon={<FiUser size={20} color="#0069BC" />}
              />
            </Box>
          </HStack>
        </Flex>
      </HStack> */}
    </Flex>
  );
};

export default Navbar;
