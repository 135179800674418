import {
  Alert,
  AlertIcon,
  Button,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";

import { FormBackground } from "../../components/FormBackground";
import { rLogin } from "../../routes/routes";

const RegisterSuccessPage = () => {
  const [searchParams] = useSearchParams();

  return (
    <FormBackground>
      <Stack align="center" maxW={586} mt="24px" spacing={6}>
        <Heading as="h1" color="gray.100" fontWeight="semibold" size="md">
          Registration Successful
        </Heading>
        <Text color="gray.100" fontSize="md" textAlign="center">
          Thank you for completing your registration! You are now a part of our
          community. We've sent a welcome email to the address you provided.
        </Text>
        <Text color="gray.100" fontSize="md" maxW={500} textAlign="center">
          If you don't see the email in your inbox, please remember to check
          your spam folder.
        </Text>
        <Text color="gray.100" fontSize="md" maxW={500} textAlign="center">
          To get started, simply click the{" "}
          <Text as="span" color="white.100" fontSize="md" fontWeight="bold">
            'Login'
          </Text>{" "}
          button below to access your account.
        </Text>
        <Button as={Link} to={rLogin + "?" + searchParams ?? ""} w="100%">
          Login
        </Button>
        {searchParams.get("warn_investor_not_added") === "true" && (
          <Alert
            borderRadius="8px"
            color="gray.900"
            mt="8"
            status="warning"
            w="90%"
          >
            <AlertIcon />
            The seat limit for this company has been reached. Please contact the
            admin for further assistance.
          </Alert>
        )}
      </Stack>
    </FormBackground>
  );
};

export default RegisterSuccessPage;
