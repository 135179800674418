import { useCallback } from "react";

export const usePrettifyBytes = () => {
  return useCallback((bytesCount: number) => {
    // https://stackoverflow.com/a/20732091/7523525
    const i =
      bytesCount == 0 ? 0 : Math.floor(Math.log(bytesCount) / Math.log(1024));
    return (
      parseFloat((bytesCount / Math.pow(1024, i)).toFixed(2)) +
      " " +
      ["B", "kB", "MB", "GB"][i]
    );
  }, []);
};
