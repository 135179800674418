import {
  Box,
  CircularProgress,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";

type PageWrapperProps = {
  pageTitle: string;
  pageSubtitle: string;
  isLoading: boolean;
  loadingText?: string;
  hideSubtitle?: boolean;
  children: React.ReactNode;
};

const PageWrapper = ({
  pageTitle,
  pageSubtitle,
  isLoading,
  loadingText = "Loading...",
  hideSubtitle = false,
  children,
}: PageWrapperProps) => (
  <Container maxH="100%" minW="100%">
    <Box>
      <Heading>
        {pageTitle}
        {isLoading && (
          <Text as="span" fontSize="sm" ml={2}>
            <CircularProgress isIndeterminate mr={2} size="20px" />
            {loadingText || "Loading"}
          </Text>
        )}
      </Heading>
      <Text color="gray.500" hidden={hideSubtitle} size="sm">
        {pageSubtitle}
      </Text>
    </Box>
    {children}
  </Container>
);

export default PageWrapper;
