import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import { Controller, useForm } from "react-hook-form";
import { BsCurrencyDollar } from "react-icons/bs";
import { toast } from "react-toastify";
import NumberFormat from "src/components/NumberFormat";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";
import * as yup from "yup";

import {
  getCompaniesTransactionsListQueryKey,
  useCompaniesRetrieve,
  useCompaniesTransactionsCreate,
} from "../../../api/companies";
import { ControlWrapper } from "../../../components/ControlWrapper";
import BoltIcon from "../../../components/icons/BoltIcon";
import { SpinnerButton } from "../../../components/SpinnerButton";
import { UsersDropDownSelect } from "../../../components/UsersDropDownSelect";
import { IShareTransactionFormData } from "../../../models/forms/IShareTransactionFormData";
import { AuthService } from "../../../services";
import { Modify } from "../../../utils/typescript-helpers";

type SelectType = {
  label: string;
  value: string | undefined;
};

type FormInputs = Modify<
  IShareTransactionFormData,
  {
    remitter: SelectType;
    beneficiary: SelectType;
    is_grant: boolean;
  }
>;

const manualShareFormSchema = yup
  .object({
    beneficiary: yup.mixed().when("beneficiary_is_treasury", {
      is: true,
      then: () =>
        yup
          .mixed<SelectType>()
          .test(
            "test-empty",
            "Treasury must be selected",
            (value) => value?.label === "Treasury"
          ),
      otherwise: () =>
        yup
          .object({
            label: yup.string().required("Please select a buyer"),
            value: yup.string().required("Please select a buyer"),
          })
          .typeError("Please select a buyer"),
    }),
    quantity: yup
      .number()
      .typeError("Please enter a valid amount")
      .positive("Please enter amount greater than 0")
      .required("Please enter a valid amount"),
    price_per_unit: yup
      .number()
      .typeError("Please enter a valid price per unit")
      .required("Please enter a valid price per unit"),
    note: yup.string().optional(),
    remitter: yup.mixed().when("remitter_is_treasury", {
      is: true,
      then: () =>
        yup
          .mixed<SelectType>()
          .test(
            "test-empty",
            "Treasury must be selected",
            (value) => value?.label === "Treasury"
          ),
      otherwise: () =>
        yup
          .object({
            label: yup.string().required("Please select a seller"),
            value: yup.string().required("Please select a seller"),
          })
          .typeError("Please select a seller"),
    }),
    beneficiary_is_treasury: yup.boolean(),
    remitter_is_treasury: yup.boolean(),
  })
  .test(
    "treasury-flags",
    "You can't select treasury as both the buyer and the seller",
    function (value) {
      return !(value.beneficiary_is_treasury && value.remitter_is_treasury);
    }
  );

const ManualShareTransactionForm = () => {
  const queryClient = useQueryClient();

  const [companyTreasury, setCompanyTreasury] = useState<
    "remitter" | "beneficiary" | null
  >(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const company_id = AuthService.getUserCompany();

  const { data: companyData } = useCompaniesRetrieve(company_id);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    resetField,
    clearErrors,
    setError,
    getValues,
  } = useForm<FormInputs>({
    defaultValues: {
      beneficiary_is_treasury: false,
      remitter_is_treasury: false,
    },
    resolver: yupResolver(manualShareFormSchema),
  });

  const {
    mutate: createTransactionMutation,
    isLoading: createTransactionLoading,
  } = useCompaniesTransactionsCreate({
    mutation: {
      onSuccess: () => {
        toast.success("Transaction created successfully");
        handleClose();
      },
      onError: (error) => {
        formServerErrorsHandler(setError, getValues, error);
      },
    },
  });

  const handleClose = useCallback(
    (refetch = true) => {
      if (refetch) {
        queryClient.invalidateQueries(
          getCompaniesTransactionsListQueryKey(AuthService.getUserCompany(), {
            type: "share",
          })
        );
      }
      onClose();
      clearErrors();
    },
    [onClose]
  );

  const watchTreasuryBeneficiary = watch("beneficiary_is_treasury");
  const watchTreasuryRemitter = watch("remitter_is_treasury");
  const watchIsGrant = watch("is_grant");

  useEffect(() => {
    if (watchTreasuryBeneficiary) {
      setCompanyTreasury("beneficiary");
      resetField("beneficiary", {
        defaultValue: {
          label: "Treasury",
          value: undefined,
        },
      });
    } else if (!watchTreasuryBeneficiary) {
      setCompanyTreasury(null);
      resetField("beneficiary", {
        defaultValue: {
          label: "Select a buyer...",
          value: undefined,
        },
      });
    }
  }, [watchTreasuryBeneficiary]);

  useEffect(() => {
    resetField("is_grant", {
      defaultValue: false,
      keepDirty: false,
    });
    if (watchTreasuryRemitter) {
      setCompanyTreasury("remitter");
      resetField("remitter", {
        defaultValue: {
          label: "Treasury",
          value: undefined,
        },
      });
    } else if (!watchTreasuryRemitter) {
      setCompanyTreasury(null);
      resetField("remitter", {
        defaultValue: {
          label: "Select a seller...",
          value: undefined,
        },
      });
    }
  }, [watchTreasuryRemitter]);

  useEffect(() => {
    if (watchIsGrant) {
      resetField("price_per_unit", {
        defaultValue: 0,
      });
    } else {
      resetField("price_per_unit", {
        defaultValue: "" as unknown as number,
      });
    }
  }, [watchIsGrant]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  const onSubmit = async (values: FormInputs) => {
    createTransactionMutation({
      uuid: AuthService.getUserCompany(),
      data: {
        ...values,
        // TODO: Fix IOSerializer, generated type is dict {email: string}

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        remitter: values.remitter.value as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beneficiary: values.beneficiary.value as any,
        remitter_is_treasury: values.remitter_is_treasury,
        beneficiary_is_treasury: values.beneficiary_is_treasury,
        price_per_unit: String(values.price_per_unit),
        transaction_type: "share",
      },
    });
  };

  return (
    <>
      <Button className="createManualShareTransactionButton" onClick={onOpen}>
        Create Manual Transaction
      </Button>
      <Modal isOpen={isOpen} onClose={() => handleClose(false)} size="xl">
        <ModalOverlay />
        <ModalContent margin="auto">
          <Box
            alignItems="flex-start"
            className="manualShareTransactionModal"
            p={6}
          >
            <BoltIcon />

            <Spacer mb={6} />

            <Box>
              <Heading fontSize={16}>Create Manual Transaction</Heading>
              <Text color="gray.500" fontSize={14}>
                Input Customized Transaction Entries To Create Manual
                Transaction
              </Text>
            </Box>

            <Spacer mb={6} />

            <VStack>
              <Box className="buyer-step" w="100%">
                <ControlWrapper
                  fullLine
                  formLabel="Buyer"
                  isInvalid={!!errors.beneficiary}
                  errorMessage={
                    errors.beneficiary?.message ||
                    errors.beneficiary?.value?.message
                  }
                >
                  <UsersDropDownSelect<FormInputs>
                    emailAsValue
                    showSelectedDetails
                    control={control}
                    field_name="beneficiary"
                    isDisabled={companyTreasury === "beneficiary"}
                    placeholder="Select a buyer..."
                  />
                </ControlWrapper>
                <Collapse animateOpacity in={companyTreasury === "beneficiary"}>
                  <Text
                    alignSelf="flex-start"
                    color="primary.800"
                    ml="1"
                    variant="sm-regular"
                  >
                    Shares in Treasury:{" "}
                    <NumberFormat
                      type="number"
                      value={companyData?.company_shares}
                    />
                  </Text>
                </Collapse>
                <ControlWrapper>
                  <Checkbox
                    {...register("beneficiary_is_treasury")}
                    isDisabled={companyTreasury === "remitter"}
                    sx={{ mt: 2 }}
                  >
                    <Text fontSize={14}>Select treasury as the buyer</Text>
                  </Checkbox>
                </ControlWrapper>
              </Box>
              <Box className="seller-step" w="100%">
                <ControlWrapper
                  fullLine
                  formLabel="Seller"
                  isInvalid={!!errors.remitter}
                  errorMessage={
                    errors.remitter?.message || errors.remitter?.value?.message
                  }
                >
                  <UsersDropDownSelect<FormInputs>
                    emailAsValue
                    showSelectedDetails
                    control={control}
                    field_name="remitter"
                    isDisabled={companyTreasury === "remitter"}
                    placeholder="Select a seller..."
                  />
                </ControlWrapper>
                <Collapse animateOpacity in={companyTreasury === "remitter"}>
                  <Text
                    alignSelf="flex-start"
                    color="primary.800"
                    ml="1"
                    variant="sm-regular"
                  >
                    Shares in Treasury:{" "}
                    <NumberFormat
                      type="number"
                      value={companyData?.company_shares}
                    />
                  </Text>
                </Collapse>

                <ControlWrapper isInvalid={!!errors.beneficiary_is_treasury}>
                  <Checkbox
                    {...register("remitter_is_treasury")}
                    isDisabled={companyTreasury === "beneficiary"}
                    sx={{ mt: 2 }}
                  >
                    <Text fontSize={14}>Select treasury as the seller</Text>
                  </Checkbox>
                </ControlWrapper>
              </Box>
              <HStack w="100%">
                <ControlWrapper
                  fullLine
                  className="quantity-step"
                  errorMessage={errors.quantity?.message}
                  formLabel="Share quantity"
                  isInvalid={!!errors.quantity}
                >
                  <Controller
                    control={control}
                    name="quantity"
                    render={({ field: { value, onChange } }) => (
                      <Input
                        _placeholder={{ color: "gray.500" }}
                        allowNegative={false}
                        as={CurrencyFormat}
                        decimalScale={0}
                        placeholder="20"
                        thousandSeparator={true}
                        value={isNaN(value) ? "" : value}
                        onValueChange={(values: Values) => {
                          onChange(values.floatValue);
                        }}
                      />
                    )}
                  />
                </ControlWrapper>

                <ControlWrapper
                  fullLine
                  className="price-step"
                  errorMessage={errors.price_per_unit?.message}
                  formLabel="Price per share"
                  isInvalid={!!errors.price_per_unit}
                >
                  <Controller
                    control={control}
                    name="price_per_unit"
                    render={({ field: { value, onChange } }) => (
                      <InputGroup>
                        <InputLeftElement
                          children={<BsCurrencyDollar />}
                          pointerEvents="none"
                        />
                        <Input
                          _placeholder={{ color: "gray.500" }}
                          allowNegative={false}
                          as={CurrencyFormat}
                          decimalScale={5}
                          decimalSeparator="."
                          isDisabled={watch("is_grant")}
                          placeholder="1,000.00"
                          thousandSeparator={true}
                          value={value}
                          onValueChange={(values: Values) => {
                            onChange(values.value);
                          }}
                        />
                      </InputGroup>
                    )}
                  />
                </ControlWrapper>
              </HStack>
              <Box alignSelf="flex-start" w="100%">
                <Collapse animateOpacity in={!watch("is_grant")}>
                  <Text
                    alignSelf="flex-start"
                    color="primary.800"
                    variant="sm-regular"
                  >
                    Total price for this transaction is:{" "}
                    {watch("quantity") && watch("price_per_unit") ? (
                      <NumberFormat
                        type="currency"
                        value={
                          Number(watch("quantity")) *
                          Number(watch("price_per_unit"))
                        }
                      />
                    ) : (
                      "–"
                    )}
                  </Text>
                </Collapse>
                <Collapse animateOpacity in={companyTreasury === "remitter"}>
                  <ControlWrapper>
                    <Checkbox
                      isChecked={watch("is_grant")}
                      {...register("is_grant")}
                    >
                      <Text fontSize={14}>
                        This transaction is a share grant
                      </Text>
                    </Checkbox>
                  </ControlWrapper>
                </Collapse>
              </Box>

              <ControlWrapper
                fullLine
                className="note-step"
                errorMessage={errors.note?.message}
                formLabel="Optional note"
                isInvalid={!!errors.note}
              >
                <Textarea
                  placeholder="Enter a note..."
                  resize="none"
                  {...register("note")}
                />
              </ControlWrapper>

              <Text alignSelf="flex-start" color="gray.500" fontSize={12}>
                Include any relevant details for the recipient regarding this
                manual transaction.
              </Text>
            </VStack>

            <Spacer mt={6} />
            <HStack>
              <Button
                className="cancelManualShareTransactionButton"
                variant="outline"
                w="100%"
                onClick={() => {
                  handleClose(false);
                }}
              >
                Cancel
              </Button>
              <SpinnerButton
                loading={createTransactionLoading}
                onClick={handleSubmit(onSubmit)}
                type="submit"
                w="100%"
              >
                Create Transaction
              </SpinnerButton>
            </HStack>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(ManualShareTransactionForm);
