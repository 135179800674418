import {
  Box,
  Card,
  CardBody,
  Flex,
  Spacer,
  Stack,
  StackDivider,
  Tag,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { RefObject, useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { auctionTypes } from "src/utils/constants";

import useChosenOrdersStore from "../../hooks/useChosenOrdersStore";
import OrderType from "../../models/OrderType";
import { tenderStore } from "../../stores";
import NumberFormat from "../NumberFormat";
import { SpinnerButton } from "../SpinnerButton";
import BuyOrders from "./BuyOrders";
import SellOrders from "./SellOrders";

type OrdersListProps = {
  buy?: boolean;
  sell?: boolean;
  scrollRef: RefObject<HTMLDivElement>;
};

function OrdersList(props: OrdersListProps) {
  const { ordersStore } = useChosenOrdersStore();
  const { sell } = props;

  const onClickAdd = (e: React.SyntheticEvent) => {
    e.preventDefault();
    ordersStore?.setOrderType(sell ? OrderType.SELL : OrderType.BUY);
    if (ordersStore) {
      ordersStore.showModal = true;
    }
  };
  const is_upcoming = !tenderStore.tender?.auction.has_started;

  useEffect(() => {
    if (tenderStore.tenderEnded) {
      if (ordersStore) {
        ordersStore.showModal = false;
      }
    }
  }, [tenderStore.tenderEnded]);

  return (
    <Card w="full">
      <CardBody>
        <Stack divider={<StackDivider />} minH="100%" minW="100%" spacing="2">
          <Flex
            alignItems="center"
            flexDir={{ base: "column", lg: "row" }}
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            gap="12px"
            justifyContent="space-between"
            w="full"
          >
            <Flex alignItems="center" flexWrap="wrap" gap="8px">
              <Text variant="lg-semibold">
                {sell ? "Sell Orders" : "Buy Orders"}
              </Text>
              {ordersStore?.updatedOrdersList && (
                <Tag
                  cursor="pointer"
                  onClick={() => props.scrollRef.current!.scrollIntoView()}
                  variant="error"
                >
                  Order list not saved
                </Tag>
              )}
              {!is_upcoming &&
              tenderStore.isTenderFinished &&
              !tenderStore.tender?.auction.has_a_published_resolution ? (
                <Tag bg="warning.50" color="warning.700">
                  Resolution Pending Publication
                </Tag>
              ) : null}
            </Flex>
            <Spacer />
            {tenderStore.tender?.auction.has_a_published_resolution &&
            tenderStore.auctionType === auctionTypes.dutch ? (
              <Text color="success.600" variant="sm-regular">
                <Text as="span" color="success.600" fontWeight="600">
                  {ordersStore?.totalBuyStock ? (
                    <NumberFormat
                      type="number"
                      value={ordersStore?.totalBuyStock}
                    />
                  ) : (
                    "—"
                  )}
                </Text>{" "}
                shares bought at{" "}
                <Text as="span" color="success.600" fontWeight="600">
                  {ordersStore?.totalBuyStock ? (
                    <NumberFormat
                      type="currency"
                      value={parseFloat(
                        tenderStore.tender?.auction.current_price_per_share ??
                          "0"
                      )}
                    />
                  ) : (
                    "—"
                  )}{" "}
                </Text>
                per share totaling{" "}
                <Text as="span" color="success.600" fontWeight="600">
                  {ordersStore?.totalBuyStock ? (
                    <NumberFormat
                      type="currency"
                      value={
                        parseFloat(
                          tenderStore.tender?.auction.current_price_per_share ??
                            "0"
                        ) * ordersStore.totalBuyStock
                      }
                    />
                  ) : (
                    "—"
                  )}
                </Text>
              </Text>
            ) : null}
            <SpinnerButton
              className={sell ? "addSellOrdersButton" : "addBuyOrdersButton"}
              isDisabled={is_upcoming || tenderStore.tenderEnded}
              justifySelf="flex-end"
              loading={ordersStore?.loading ?? true}
              onClick={onClickAdd}
              variant="outline"
              w={{ base: "full", lg: "auto" }}
            >
              <Box as="b" pr="2">
                <MdAdd />
              </Box>
              {sell ? "Add Sell Orders" : "Add Buy Orders"}
            </SpinnerButton>
          </Flex>
          {sell ? <SellOrders /> : <BuyOrders />}
        </Stack>
      </CardBody>
    </Card>
  );
}

export default observer(OrdersList);
