import {
  Button,
  Heading,
  Image,
  Link as ChakraLink,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { rStartupRegister } from "../../routes/routes";

export default function RegisterCompany() {
  return (
    <Stack
      align="center"
      alignSelf="center"
      bg="white"
      direction={["column", null, "row"]}
      gap={["32px", null, null, "100px"]}
      justify="center"
      maxW="6xl"
      my={["32px", null, "96px"]}
      px={["16px", null, "48px"]}
      py="32px"
    >
      <Image src="/svg/SignContract.svg?url" width="200px" />
      <VStack gap="20px">
        <Heading
          color="gray.900"
          fontSize={["2xl", "4xl"]}
          fontWeight="semibold"
          textAlign={["center", null, "start"]}
        >
          Ready To Transform Your Fundraising Strategy?
        </Heading>
        <Text
          alignSelf={["center", null, "start"]}
          color="gray.500"
          fontSize="xl"
          lineHeight="30px"
          textAlign={["center", null, "start"]}
        >
          Join FairStock.Trade now and experience innovation meeting
          opportunity!
        </Text>
        <Button
          isExternal
          alignSelf={["center", null, "start"]}
          as={ChakraLink}
          href={rStartupRegister}
          variant="solid"
        >
          Register Your Company Now
        </Button>
      </VStack>
    </Stack>
  );
}
