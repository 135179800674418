import { Tag } from "@chakra-ui/react";
import { Center, CircularProgress } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";

import OrderType from "../../models/OrderType";
import { ordersStore } from "../../stores";
import { OrderCard } from "../OrderCard";
import OrderListWrapper from "./OrderListWrapper";

const SellOrders = observer(() => {
  if (ordersStore.loading) {
    return (
      <OrderListWrapper center>
        <Center w="full">
          <CircularProgress isIndeterminate />
        </Center>
      </OrderListWrapper>
    );
  }
  if (ordersStore.sellOrders.length == 0) {
    return (
      <OrderListWrapper center>
        <Center w="full">
          <Tag variant="muted">No sell orders yet</Tag>
        </Center>
      </OrderListWrapper>
    );
  } else {
    return (
      <OrderListWrapper>
        {ordersStore.sellOrders.map((order, key) => (
          <OrderCard
            key={key}
            count={order.quantity}
            index={key + 1}
            order={order}
            price={order.price_per_unit}
            type={OrderType.SELL}
          />
        ))}
      </OrderListWrapper>
    );
  }
});

export default SellOrders;
