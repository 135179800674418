/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */

/**
 * * `authentication_failed` - Authentication Failed
 * `not_authenticated` - Not Authenticated
 */
export type ErrorCode401Enum =
  (typeof ErrorCode401Enum)[keyof typeof ErrorCode401Enum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorCode401Enum = {
  authentication_failed: "authentication_failed",
  not_authenticated: "not_authenticated",
} as const;
