import "./index.css";
import "./sentry";
import "react-toastify/dist/ReactToastify.css";

import * as Sentry from "@sentry/react";
import {
  DefaultOptions,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

import {
  ErrorResponse403,
  ErrorResponse404,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
} from "./api/model";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
  },
};

const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  mutationCache: new MutationCache({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError(error, variables, context, mutation) {
      if (mutation.meta?.ignore_errors) {
        return;
      }
      if (
        (error as ErrorResponse404).type === "client_error" &&
        (error as ErrorResponse404).errors[0].code === "not_found"
      ) {
        toast.error("Resource not found");
        return;
      }
      if ((error as AxiosError).response?.status === 500) {
        toast.error(
          "Could not perform this action. The team has been notified."
        );
        Sentry.captureException(error, {
          data: {
            response: (error as AxiosError).response?.data,
          },
        });
        return;
      }
      const e:
        | ErrorResponse403
        | ErrorResponse405
        | ErrorResponse406
        | ErrorResponse415
        | ErrorResponse500 = error as
        | ErrorResponse403
        | ErrorResponse405
        | ErrorResponse406
        | ErrorResponse415
        | ErrorResponse500;

      if (
        e.errors[0].attr === "non_field_errors" ||
        e.errors[0].attr === null ||
        e.errors[0].code == "permission_denied"
      ) {
        toast.error(`${e.errors[0].detail}`, {
          toastId:
            e.errors[0].code == "permission_denied"
              ? "permission_denied"
              : undefined,
        });
      }
    },
  }),
  queryCache: new QueryCache({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (error, query) => {
      if ((error as AxiosError).response?.status === 500) {
        toast.error(
          "Could not perform this action. The team has been notified."
        );
        Sentry.captureException(error, {
          data: {
            response: (error as AxiosError).response?.data,
          },
        });
        return;
      }
      const e:
        | ErrorResponse403
        | ErrorResponse405
        | ErrorResponse406
        | ErrorResponse415
        | ErrorResponse500 = error as
        | ErrorResponse403
        | ErrorResponse405
        | ErrorResponse406
        | ErrorResponse415
        | ErrorResponse500;

      if (
        (e?.errors && e.errors[0].attr === "non_field_errors") ||
        e.errors[0].code == "permission_denied"
      ) {
        toast.error(`${e.errors[0].detail}`, {
          toastId:
            e.errors[0].code == "permission_denied"
              ? "permission_denied"
              : undefined,
        });
      }
    },
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale="en-US">
          <App />
          <ToastContainer />
        </IntlProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
