import { useEffect, useState } from "react";

import { tenderStore } from "../stores";
import { ChosenOrdersStore, defaultOrderStore } from "../stores";

const useChosenOrdersStore = () => {
  const [ordersStore, setOrdersStore] = useState<ChosenOrdersStore>();
  const [orderStoreType, setOrderStoreType] = useState<string>();

  useEffect(() => {
    const result = defaultOrderStore(
      tenderStore.auctionType ?? "DefaultAuction"
    );
    setOrdersStore(result);
    setOrderStoreType(tenderStore.auctionType);
  }, [tenderStore.tender?.auction.auction_type]);

  return { ordersStore, orderStoreType };
};
export default useChosenOrdersStore;
