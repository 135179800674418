import {
  Button,
  Center,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import { isNaN } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import {
  Controller,
  DefaultValues,
  FieldErrorsImpl,
  useForm,
} from "react-hook-form";
import { AiOutlineNumber } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import { HiOutlinePencil } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";
import * as yup from "yup";

import {
  getAuctionsMembersListQueryKey,
  useAuctionsMemberPartialUpdate,
  useAuctionsRetrieve,
} from "../../../../api/auctions";
import {
  AuctionMemberPolymorphic,
  DefaultAuctionMemberTyped,
} from "../../../../api/model";
import { ControlWrapper } from "../../../../components/ControlWrapper";
import { FSTIcon } from "../../../../components/FSTIcon";
import { SpinnerButton } from "../../../../components/SpinnerButton";
import {
  AuctionMemberType,
  AuctionMemberTypes,
} from "../../../../models/IAdminTenderMemberModel";

type AuctionMemberFormModalProps = {
  auctionMemberData: AuctionMemberPolymorphic;
  auction_member_type: AuctionMemberType;
  disabled: boolean;
};

const validationSchema = yup.object({
  trader_id: yup.string(),
  credit_balance: yup.string().required("Please enter credit balance"),
  phantom_stocks: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Please enter shares"),
});

const dutchValidationSchema = yup.object({
  trader_id: yup.string(),
  credit_balance: yup.string().required("Please enter credit balance"),
});

function EditAuctionMemberFormModal(props: AuctionMemberFormModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { auction_id } = useParams();
  const queryClient = useQueryClient();

  const preloadedValues: DefaultValues<AuctionMemberPolymorphic> =
    useMemo(() => {
      return props.auctionMemberData.resourcetype === AuctionMemberTypes.default
        ? {
            trader_id: props.auctionMemberData.trader_id,
            credit_balance: parseFloat(
              props.auctionMemberData.credit_balance
            ).toString(),
            phantom_stocks: props.auctionMemberData.phantom_stocks,
            resourcetype: props.auctionMemberData.resourcetype,
          }
        : {
            trader_id: props.auctionMemberData.trader_id,
            credit_balance: parseFloat(
              props.auctionMemberData.credit_balance
            ).toString(),
            resourcetype: props.auctionMemberData.resourcetype,
          };
    }, [props.auctionMemberData]);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
    setError,
    getValues,
  } = useForm<AuctionMemberPolymorphic>({
    defaultValues: preloadedValues,
    resolver: yupResolver(
      props.auctionMemberData.resourcetype === AuctionMemberTypes.default
        ? validationSchema
        : dutchValidationSchema
    ),
  });
  type DefaultAuctionMemberError = Partial<
    FieldErrorsImpl<DefaultAuctionMemberTyped>
  >;

  const handleClose = useCallback(() => {
    onClose();
    clearErrors();
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) return;
    reset(preloadedValues);
  }, [isOpen]);

  const { data: auction } = useAuctionsRetrieve(
    Number.parseInt(auction_id ?? "")
  );

  const {
    mutate: updateAuctionMember,
    isLoading: isLoadingUpdateAuctionMember,
  } = useAuctionsMemberPartialUpdate({
    mutation: {
      onSuccess: () => {
        handleClose();
        toast.success("Auction member updated successfully");
        if (Number.parseInt(auction_id ?? "")) {
          queryClient.invalidateQueries(
            getAuctionsMembersListQueryKey(Number.parseInt(auction_id ?? ""))
          );
        }
      },
      onError: (err) => {
        if (!err.errors[0].detail) {
          toast.error("Error editing auction member");
        }
        formServerErrorsHandler(setError, getValues, err);
      },
    },
  });

  const onSubmit = (data: AuctionMemberPolymorphic) => {
    if (data.resourcetype === AuctionMemberTypes.default) {
      updateAuctionMember({
        id: Number.parseInt(auction_id ?? ""),
        memberId: props.auctionMemberData.id.toString(),
        data: {
          resourcetype: data.resourcetype,
          credit_balance: data.credit_balance,
          ...(!auction?.is_custodial
            ? {
                phantom_stocks: data.phantom_stocks,
              }
            : null),
        },
      });
    } else if (data.resourcetype === AuctionMemberTypes.dutch) {
      updateAuctionMember({
        id: Number.parseInt(auction_id ?? ""),
        memberId: props.auctionMemberData.id.toString(),
        data: {
          resourcetype: data.resourcetype,
          credit_balance: data.credit_balance,
        },
      });
    }
  };

  return (
    <>
      <FSTIcon
        noBorder
        pointer
        Icon={HiOutlinePencil}
        disabled={props.disabled}
        onClick={onOpen}
        size={20}
      />
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center>Edit Auction Member</Center>
            <Divider />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={5}>
                {props.auction_member_type === AuctionMemberTypes.default
                  ? !auction?.is_custodial && (
                      <ControlWrapper
                        formLabel="Shares:"
                        errorMessage={
                          (errors as DefaultAuctionMemberError)
                            .phantom_stocks &&
                          (errors as DefaultAuctionMemberError).phantom_stocks
                            ?.message
                        }
                        isInvalid={
                          (errors as DefaultAuctionMemberError).phantom_stocks
                            ? true
                            : false
                        }
                      >
                        <Controller
                          control={control}
                          name="phantom_stocks"
                          render={({ field: { value, onChange } }) => (
                            <InputGroup>
                              <InputLeftElement
                                children={<AiOutlineNumber />}
                                pointerEvents="none"
                              />
                              <Input
                                allowNegative={false}
                                as={CurrencyFormat}
                                decimalScale={0}
                                thousandSeparator={true}
                                value={isNaN(value) ? "" : value}
                                onValueChange={(values: Values) => {
                                  onChange(values.floatValue);
                                }}
                              />
                            </InputGroup>
                          )}
                        />
                      </ControlWrapper>
                    )
                  : null}
                <ControlWrapper
                  formLabel="Credit balance:"
                  isInvalid={errors.credit_balance ? true : false}
                  errorMessage={
                    errors.credit_balance && errors.credit_balance.message
                  }
                >
                  <Controller
                    control={control}
                    name="credit_balance"
                    render={({ field: { value, onChange } }) => (
                      <InputGroup>
                        <InputLeftElement
                          children={<BsCurrencyDollar />}
                          pointerEvents="none"
                        />
                        <Input
                          allowNegative={false}
                          as={CurrencyFormat}
                          decimalScale={5}
                          decimalSeparator="."
                          thousandSeparator={true}
                          value={isNaN(value) || value === "0" ? "" : value}
                          onValueChange={(values: Values) => {
                            onChange(values.floatValue);
                          }}
                        />
                      </InputGroup>
                    )}
                  />
                </ControlWrapper>
                <ControlWrapper
                  errorMessage={errors.trader_id && errors.trader_id.message}
                  formLabel="Member email:"
                  isInvalid={errors.trader_id ? true : false}
                >
                  <Controller
                    control={control}
                    name="trader_id"
                    render={({ field: { onChange } }) => (
                      <Select
                        isDisabled={true}
                        onChange={(val) => onChange(val?.value)}
                        value={{
                          value: props.auctionMemberData.trader_id,
                          label: props.auctionMemberData.trader_email,
                        }}
                      />
                    )}
                  />
                </ControlWrapper>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <Stack direction={["column", "row"]} spacing={5} w="100%">
              <Button onClick={handleClose} variant="outline" w="100%">
                Cancel
              </Button>
              <SpinnerButton
                loading={isLoadingUpdateAuctionMember}
                onClick={handleSubmit(onSubmit)}
                type="submit"
                w="100%"
              >
                Update Auction Member
              </SpinnerButton>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default observer(EditAuctionMemberFormModal);
