/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AuthGetUserDataRetrieveErrorResponse400,
  AuthInvestorRegisterCreate202,
  AuthInvestorRegisterCreateErrorResponse400,
  AuthLogoutCreateErrorResponse400,
  AuthLogoutallCreateErrorResponse400,
  AuthOnboardingExtraRetrieveErrorResponse400,
  AuthOnboardingRetrieveErrorResponse400,
  AuthSwitchCompanyRetrieveErrorResponse400,
  AuthTraderRegisterCreateErrorResponse400,
  AuthValidateLoginTokenRetrieveErrorResponse400,
  CorporateRegistrationErrorResponse400,
  CorporateRegistrationRequest,
  CorporateRegistrationSerializerResponse,
  ErrorResponse401,
  ErrorResponse404,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
  InvestorRegistrationRequest,
  LoginErrorResponse400,
  LoginRequestRequest,
  TraderRegistrationRequest,
  UserGroups,
} from "./model";
import { apiCustomInstance } from "../services/apiService";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a corporate and a corporate admin for it
 */
export const corporateRegistration = (
  corporateRegistrationRequest: CorporateRegistrationRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<CorporateRegistrationSerializerResponse>(
    {
      url: `/auth/corporate-register/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: corporateRegistrationRequest,
    },
    options
  );
};

export const getCorporateRegistrationMutationOptions = <
  TError =
    | CorporateRegistrationErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof corporateRegistration>>,
    TError,
    { data: CorporateRegistrationRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof corporateRegistration>>,
  TError,
  { data: CorporateRegistrationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof corporateRegistration>>,
    { data: CorporateRegistrationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return corporateRegistration(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CorporateRegistrationMutationResult = NonNullable<
  Awaited<ReturnType<typeof corporateRegistration>>
>;
export type CorporateRegistrationMutationBody = CorporateRegistrationRequest;
export type CorporateRegistrationMutationError =
  | CorporateRegistrationErrorResponse400
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCorporateRegistration = <
  TError =
    | CorporateRegistrationErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof corporateRegistration>>,
    TError,
    { data: CorporateRegistrationRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof corporateRegistration>>,
  TError,
  { data: CorporateRegistrationRequest },
  TContext
> => {
  const mutationOptions = getCorporateRegistrationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns the user data given the user knox token
 */
export const authGetUserDataRetrieve = (
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<UserGroups>(
    { url: `/auth/get-user-data/`, method: "GET", signal },
    options
  );
};

export const getAuthGetUserDataRetrieveQueryKey = () => {
  return [`/auth/get-user-data/`] as const;
};

export const getAuthGetUserDataRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof authGetUserDataRetrieve>>,
  TError =
    | AuthGetUserDataRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof authGetUserDataRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthGetUserDataRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authGetUserDataRetrieve>>
  > = ({ signal }) => authGetUserDataRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authGetUserDataRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthGetUserDataRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof authGetUserDataRetrieve>>
>;
export type AuthGetUserDataRetrieveQueryError =
  | AuthGetUserDataRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthGetUserDataRetrieve = <
  TData = Awaited<ReturnType<typeof authGetUserDataRetrieve>>,
  TError =
    | AuthGetUserDataRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof authGetUserDataRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthGetUserDataRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates an investor
 */
export const authInvestorRegisterCreate = (
  investorRegistrationRequest: InvestorRegistrationRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void | AuthInvestorRegisterCreate202>(
    {
      url: `/auth/investor-register/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: investorRegistrationRequest,
    },
    options
  );
};

export const getAuthInvestorRegisterCreateMutationOptions = <
  TError =
    | AuthInvestorRegisterCreateErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authInvestorRegisterCreate>>,
    TError,
    { data: InvestorRegistrationRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authInvestorRegisterCreate>>,
  TError,
  { data: InvestorRegistrationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authInvestorRegisterCreate>>,
    { data: InvestorRegistrationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return authInvestorRegisterCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthInvestorRegisterCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authInvestorRegisterCreate>>
>;
export type AuthInvestorRegisterCreateMutationBody =
  InvestorRegistrationRequest;
export type AuthInvestorRegisterCreateMutationError =
  | AuthInvestorRegisterCreateErrorResponse400
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthInvestorRegisterCreate = <
  TError =
    | AuthInvestorRegisterCreateErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authInvestorRegisterCreate>>,
    TError,
    { data: InvestorRegistrationRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authInvestorRegisterCreate>>,
  TError,
  { data: InvestorRegistrationRequest },
  TContext
> => {
  const mutationOptions = getAuthInvestorRegisterCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sends an email to the user with a login link
 */
export const login = (
  loginRequestRequest: LoginRequestRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/auth/login/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: loginRequestRequest,
    },
    options
  );
};

export const getLoginMutationOptions = <
  TError =
    | LoginErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof login>>,
  TError,
  { data: LoginRequestRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof login>>,
    { data: LoginRequestRequest }
  > = (props) => {
    const { data } = props ?? {};

    return login(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof login>>
>;
export type LoginMutationBody = LoginRequestRequest;
export type LoginMutationError =
  | LoginErrorResponse400
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useLogin = <
  TError =
    | LoginErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof login>>,
  TError,
  { data: LoginRequestRequest },
  TContext
> => {
  const mutationOptions = getLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authLogoutCreate = (
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    { url: `/auth/logout/`, method: "POST" },
    options
  );
};

export const getAuthLogoutCreateMutationOptions = <
  TError =
    | AuthLogoutCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLogoutCreate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authLogoutCreate>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authLogoutCreate>>,
    void
  > = () => {
    return authLogoutCreate(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthLogoutCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authLogoutCreate>>
>;

export type AuthLogoutCreateMutationError =
  | AuthLogoutCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthLogoutCreate = <
  TError =
    | AuthLogoutCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLogoutCreate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authLogoutCreate>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAuthLogoutCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Log the user out of all sessions
I.E. deletes all auth tokens for the user
 */
export const authLogoutallCreate = (
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    { url: `/auth/logoutall/`, method: "POST" },
    options
  );
};

export const getAuthLogoutallCreateMutationOptions = <
  TError =
    | AuthLogoutallCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLogoutallCreate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authLogoutallCreate>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authLogoutallCreate>>,
    void
  > = () => {
    return authLogoutallCreate(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthLogoutallCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authLogoutallCreate>>
>;

export type AuthLogoutallCreateMutationError =
  | AuthLogoutallCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthLogoutallCreate = <
  TError =
    | AuthLogoutallCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLogoutallCreate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authLogoutallCreate>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAuthLogoutallCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * To store extra onboarding data such as skipped tutorials or read release notes
 */
export const authOnboardingExtraRetrieve = (
  key: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<void>(
    { url: `/auth/onboarding-extra/${key}/`, method: "GET", signal },
    options
  );
};

export const getAuthOnboardingExtraRetrieveQueryKey = (key: string) => {
  return [`/auth/onboarding-extra/${key}/`] as const;
};

export const getAuthOnboardingExtraRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof authOnboardingExtraRetrieve>>,
  TError =
    | AuthOnboardingExtraRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  key: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authOnboardingExtraRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthOnboardingExtraRetrieveQueryKey(key);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authOnboardingExtraRetrieve>>
  > = ({ signal }) => authOnboardingExtraRetrieve(key, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!key,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof authOnboardingExtraRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthOnboardingExtraRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof authOnboardingExtraRetrieve>>
>;
export type AuthOnboardingExtraRetrieveQueryError =
  | AuthOnboardingExtraRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthOnboardingExtraRetrieve = <
  TData = Awaited<ReturnType<typeof authOnboardingExtraRetrieve>>,
  TError =
    | AuthOnboardingExtraRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  key: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authOnboardingExtraRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthOnboardingExtraRetrieveQueryOptions(key, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * To store onboarding journeys progress for user
 */
export const authOnboardingRetrieve = (
  journey: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<void>(
    { url: `/auth/onboarding/${journey}/`, method: "GET", signal },
    options
  );
};

export const getAuthOnboardingRetrieveQueryKey = (journey: string) => {
  return [`/auth/onboarding/${journey}/`] as const;
};

export const getAuthOnboardingRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof authOnboardingRetrieve>>,
  TError =
    | AuthOnboardingRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  journey: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authOnboardingRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthOnboardingRetrieveQueryKey(journey);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authOnboardingRetrieve>>
  > = ({ signal }) => authOnboardingRetrieve(journey, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!journey,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof authOnboardingRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthOnboardingRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof authOnboardingRetrieve>>
>;
export type AuthOnboardingRetrieveQueryError =
  | AuthOnboardingRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthOnboardingRetrieve = <
  TData = Awaited<ReturnType<typeof authOnboardingRetrieve>>,
  TError =
    | AuthOnboardingRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  journey: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authOnboardingRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthOnboardingRetrieveQueryOptions(journey, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Switches the company for the user, persists the change and returns the user group
 */
export const authSwitchCompanyRetrieve = (
  companyUuid: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<void>(
    { url: `/auth/switch-company/${companyUuid}/`, method: "GET", signal },
    options
  );
};

export const getAuthSwitchCompanyRetrieveQueryKey = (companyUuid: string) => {
  return [`/auth/switch-company/${companyUuid}/`] as const;
};

export const getAuthSwitchCompanyRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof authSwitchCompanyRetrieve>>,
  TError =
    | AuthSwitchCompanyRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  companyUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authSwitchCompanyRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthSwitchCompanyRetrieveQueryKey(companyUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authSwitchCompanyRetrieve>>
  > = ({ signal }) =>
    authSwitchCompanyRetrieve(companyUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!companyUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof authSwitchCompanyRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthSwitchCompanyRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof authSwitchCompanyRetrieve>>
>;
export type AuthSwitchCompanyRetrieveQueryError =
  | AuthSwitchCompanyRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthSwitchCompanyRetrieve = <
  TData = Awaited<ReturnType<typeof authSwitchCompanyRetrieve>>,
  TError =
    | AuthSwitchCompanyRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  companyUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authSwitchCompanyRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthSwitchCompanyRetrieveQueryOptions(
    companyUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a trader
 */
export const authTraderRegisterCreate = (
  traderRegistrationRequest: TraderRegistrationRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/auth/trader-register/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: traderRegistrationRequest,
    },
    options
  );
};

export const getAuthTraderRegisterCreateMutationOptions = <
  TError =
    | AuthTraderRegisterCreateErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authTraderRegisterCreate>>,
    TError,
    { data: TraderRegistrationRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authTraderRegisterCreate>>,
  TError,
  { data: TraderRegistrationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authTraderRegisterCreate>>,
    { data: TraderRegistrationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return authTraderRegisterCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthTraderRegisterCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authTraderRegisterCreate>>
>;
export type AuthTraderRegisterCreateMutationBody = TraderRegistrationRequest;
export type AuthTraderRegisterCreateMutationError =
  | AuthTraderRegisterCreateErrorResponse400
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthTraderRegisterCreate = <
  TError =
    | AuthTraderRegisterCreateErrorResponse400
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authTraderRegisterCreate>>,
    TError,
    { data: TraderRegistrationRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authTraderRegisterCreate>>,
  TError,
  { data: TraderRegistrationRequest },
  TContext
> => {
  const mutationOptions = getAuthTraderRegisterCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authValidateLoginTokenRetrieve = (
  emailToken: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<void>(
    { url: `/auth/validate_login_token/${emailToken}/`, method: "GET", signal },
    options
  );
};

export const getAuthValidateLoginTokenRetrieveQueryKey = (
  emailToken: string
) => {
  return [`/auth/validate_login_token/${emailToken}/`] as const;
};

export const getAuthValidateLoginTokenRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof authValidateLoginTokenRetrieve>>,
  TError =
    | AuthValidateLoginTokenRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  emailToken: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authValidateLoginTokenRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAuthValidateLoginTokenRetrieveQueryKey(emailToken);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authValidateLoginTokenRetrieve>>
  > = ({ signal }) =>
    authValidateLoginTokenRetrieve(emailToken, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!emailToken,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof authValidateLoginTokenRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthValidateLoginTokenRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof authValidateLoginTokenRetrieve>>
>;
export type AuthValidateLoginTokenRetrieveQueryError =
  | AuthValidateLoginTokenRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAuthValidateLoginTokenRetrieve = <
  TData = Awaited<ReturnType<typeof authValidateLoginTokenRetrieve>>,
  TError =
    | AuthValidateLoginTokenRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  emailToken: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authValidateLoginTokenRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthValidateLoginTokenRetrieveQueryOptions(
    emailToken,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
