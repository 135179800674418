import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Input: ComponentStyleConfig = {
  defaultProps: {
    errorBorderColor: "error.600",
  },
  variants: {
    borderless: {
      field: {
        color: "gray.800",
        bg: "gray.100",
        borderColor: "none",
        _invalid: {
          border: "1px solid",
          borderColor: "error.600",
        },
        _focus: {
          bg: "gray.200",
          outline: "none",
        },
        _dark: {
          color: "gray.200",
          bg: "gray.600",
          _focus: {
            bg: "gray.800",
          },
        },
      },
    },
  },
};
