import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useDateUtils = () => {
  const intl = useIntl();

  const subtractDaysFromDate = (date: Date | string, numOfDays: number) => {
    return new Date(
      Math.abs(new Date(date).getTime() - 1000 * 1000 * 60 * 60 * numOfDays)
    );
  };

  const formatDate = useCallback(
    (date: Date | string) => {
      return intl.formatDate(date, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },
    [intl]
  );

  const formatDateWithSlash = useCallback(
    ({
      date,
      withTime = false,
      withSeconds = false,
    }: {
      date: Date | string;
      withTime?: boolean;
      withSeconds?: boolean;
    }) => {
      return intl.formatDate(date, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: withTime ? "2-digit" : undefined,
        minute: withTime ? "2-digit" : undefined,
        second: withTime && withSeconds ? "2-digit" : undefined,
      });
    },
    [intl]
  );

  const getOffsetFromGMT = useCallback(() => {
    const offset = new Date().getTimezoneOffset();
    const absoluteOffset = Math.abs(offset);
    const hours = Math.floor(absoluteOffset / 60);
    const minutes = absoluteOffset % 60;
    const sign = offset > 0 ? "-" : "+";

    // If minutes are zero, do not display them
    const formattedOffset =
      minutes === 0
        ? `GMT ${sign}${hours}`
        : `GMT ${sign}${hours}:${String(minutes).padStart(2, "0")}`;

    return formattedOffset;
  }, []);

  return {
    subtractDaysFromDate,
    formatDate,
    formatDateWithSlash,
    getOffsetFromGMT,
  };
};
