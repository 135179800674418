import {
  Card,
  CardBody,
  Checkbox,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useCompaniesRetrieve, useExportData } from "src/api/companies";
import { CompanyExportDataRequest } from "src/api/model";
import { ConfirmationDialog } from "src/components/ConfirmationDialog";
import { ControlWrapper } from "src/components/ControlWrapper";
import { SpinnerButton } from "src/components/SpinnerButton";
import { AuthService } from "src/services";

const CompanyDataExport = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: companyData } = useCompaniesRetrieve(
    AuthService.getUserCompany()
  );

  const { handleSubmit, register } = useForm<CompanyExportDataRequest>();

  const createExport = useExportData({
    mutation: {
      onSuccess: () => {
        onOpen();
      },
    },
  });

  const onSubmit = async (values: CompanyExportDataRequest) => {
    createExport.mutate({
      slug: AuthService.getUserCompany(),
      data: {
        ...values,
      },
    });
  };

  return (
    <Stack>
      <Stack spacing="24px">
        <Card>
          <ConfirmationDialog
            handleClose={onClose}
            handleSubmit={onClose}
            show={isOpen}
            showCancelButton={false}
            showCloseButton={true}
            showConfirmButton={false}
            title="Company export request received"
            body={
              <>
                You will receive an email with a link to download the data once
                the export is complete.
              </>
            }
          />
          <CardBody>
            <Text mb="4" variant="display-xs-semibold">
              Export your company data
            </Text>
            <Text mb="4">
              Select the data you would like to be included in the export:
            </Text>
            <Stack direction={{ base: "column" }} spacing="2">
              <ControlWrapper>
                <Checkbox {...register("auctions")} sx={{ mt: 2 }}>
                  <Text fontSize={14}>Auctions</Text>
                </Checkbox>
              </ControlWrapper>
              <ControlWrapper>
                <Checkbox {...register("cap_table")} sx={{ mt: 2 }}>
                  <Text fontSize={14}>Cap Table</Text>
                </Checkbox>
              </ControlWrapper>
              <ControlWrapper>
                <Checkbox {...register("credit_transactions")} sx={{ mt: 2 }}>
                  <Text fontSize={14}>Credit Transactions</Text>
                </Checkbox>
              </ControlWrapper>
              <ControlWrapper>
                <Checkbox {...register("share_transactions")} sx={{ mt: 2 }}>
                  <Text fontSize={14}>Share Transactions</Text>
                </Checkbox>
              </ControlWrapper>

              <ControlWrapper>
                <Checkbox {...register("edit_history")} sx={{ mt: 2 }}>
                  <Text fontSize={14}>Edit History</Text>
                </Checkbox>
              </ControlWrapper>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              spacing="32px"
            >
              <Text></Text>
              <SpinnerButton
                isDisabled={!companyData}
                loading={createExport.isLoading || !companyData}
                loadingText="Exporting..."
                // variant="outline"
                onClick={handleSubmit(onSubmit)}
                // createExport.mutate(
                //   AuthService.getUserCompany(),
                //   {
                //     data: {
                //     },
                //   },
                //   {
                //     onSuccess: (data) => {
                //       const url = (data as unknown as { url: string }).url;
                //       const link = document.createElement("a");
                //       link.href = url;
                //       document.body.appendChild(link);
                //       link.click();
                //     },
                //   }
                // );
              >
                Export Data
              </SpinnerButton>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </Stack>
  );
};

export default CompanyDataExport;
