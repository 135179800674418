/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FairStock.Trade API
 * FairStock.Trade Auction API documentation
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AddCompanyDocumentErrorResponse400,
  AddUserToCompanyErrorResponse400,
  AuctionPolymorphic,
  BulkCreateCreditTransactionsErrorResponse400,
  CompaniesActionLogsListErrorResponse400,
  CompaniesActionLogsListParams,
  CompaniesCreateErrorResponse400,
  CompaniesInvestFormRetrieveErrorResponse400,
  CompaniesListErrorResponse400,
  CompaniesPartialUpdateErrorResponse400,
  CompaniesRetrieveErrorResponse400,
  CompaniesTransactionsCreateErrorResponse400,
  CompaniesTransactionsListErrorResponse400,
  CompaniesTransactionsListParams,
  Company,
  CompanyAnalytics,
  CompanyAuctionsAnalytics,
  CompanyDocument,
  CompanyDocumentRequest,
  CompanyExportDataRequest,
  CompanyRequest,
  DeleteCompanyDocumentErrorResponse400,
  DownloadInvestorsResponsesErrorResponse400,
  ErrorResponse401,
  ErrorResponse403,
  ErrorResponse404,
  ErrorResponse405,
  ErrorResponse406,
  ErrorResponse415,
  ErrorResponse500,
  ExportDataErrorResponse400,
  GetCompanyActiveAuctionsErrorResponse400,
  GetCompanyActiveAuctionsParams,
  GetCompanyAnalyticsErrorResponse400,
  GetCompanyAuctionsAnalyticsErrorResponse400,
  GetCompanyAuctionsErrorResponse400,
  GetCompanyCaptableErrorResponse400,
  GetCompanyCaptableParams,
  GetCompanyDocumentsErrorResponse400,
  GetCompanyDocumentsParams,
  GetCompanyDraftAuctionsErrorResponse400,
  GetCompanyDraftAuctionsParams,
  GetCompanyEndedAuctionsErrorResponse400,
  GetCompanyEndedAuctionsParams,
  GetCompanyInviteErrorResponse400,
  ImportCAPTableRequest,
  ImportCapTableErrorResponse400,
  ImportCreditTransactionsRequest,
  ImportInvestorRequest,
  InvestorDownloadFileErrorResponse400,
  InvestorInviteRequest,
  InvestorListCompaniesErrorResponse400,
  InvestorListCompaniesParams,
  InviteInvestorErrorResponse400,
  PaginatedActionLogList,
  PaginatedAuctionPolymorphicList,
  PaginatedCompanyDocumentList,
  PaginatedCompanyInvestorListList,
  PaginatedShareholderList,
  PaginatedTransactionList,
  PatchedCompanyDocumentRequest,
  PatchedCompanyProfilePhotoRequest,
  PatchedCompanyRequest,
  PatchedUserDetailRequest,
  Transaction,
  TransactionRequest,
  UpdateCompanyDocumentErrorResponse400,
  UpdateCompanyPhotoErrorResponse400,
  UpdateUserRoleErrorResponse400,
  UserDetail,
  UserDetailRequest,
} from "./model";
import { apiCustomInstance } from "../services/apiService";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const companiesList = (
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<Company[]>(
    { url: `/companies/`, method: "GET", signal },
    options
  );
};

export const getCompaniesListQueryKey = () => {
  return [`/companies/`] as const;
};

export const getCompaniesListQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesList>>,
  TError =
    | CompaniesListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof companiesList>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCompaniesListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof companiesList>>> = ({
    signal,
  }) => companiesList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesList>>
>;
export type CompaniesListQueryError =
  | CompaniesListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCompaniesList = <
  TData = Awaited<ReturnType<typeof companiesList>>,
  TError =
    | CompaniesListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof companiesList>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const companiesCreate = (
  companyRequest: CompanyRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<Company>(
    {
      url: `/companies/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: companyRequest,
    },
    options
  );
};

export const getCompaniesCreateMutationOptions = <
  TError =
    | CompaniesCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesCreate>>,
    TError,
    { data: CompanyRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesCreate>>,
  TError,
  { data: CompanyRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesCreate>>,
    { data: CompanyRequest }
  > = (props) => {
    const { data } = props ?? {};

    return companiesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesCreate>>
>;
export type CompaniesCreateMutationBody = CompanyRequest;
export type CompaniesCreateMutationError =
  | CompaniesCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCompaniesCreate = <
  TError =
    | CompaniesCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesCreate>>,
    TError,
    { data: CompanyRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesCreate>>,
  TError,
  { data: CompanyRequest },
  TContext
> => {
  const mutationOptions = getCompaniesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesRetrieve = (
  slug: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<Company>(
    { url: `/companies/${slug}/`, method: "GET", signal },
    options
  );
};

export const getCompaniesRetrieveQueryKey = (slug: string) => {
  return [`/companies/${slug}/`] as const;
};

export const getCompaniesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesRetrieve>>,
  TError =
    | CompaniesRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof companiesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCompaniesRetrieveQueryKey(slug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesRetrieve>>
  > = ({ signal }) => companiesRetrieve(slug, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesRetrieve>>
>;
export type CompaniesRetrieveQueryError =
  | CompaniesRetrieveErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCompaniesRetrieve = <
  TData = Awaited<ReturnType<typeof companiesRetrieve>>,
  TError =
    | CompaniesRetrieveErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof companiesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesRetrieveQueryOptions(slug, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const companiesPartialUpdate = (
  slug: string,
  patchedCompanyRequest: PatchedCompanyRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<Company>(
    {
      url: `/companies/${slug}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCompanyRequest,
    },
    options
  );
};

export const getCompaniesPartialUpdateMutationOptions = <
  TError =
    | CompaniesPartialUpdateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesPartialUpdate>>,
    TError,
    { slug: string; data: PatchedCompanyRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesPartialUpdate>>,
  TError,
  { slug: string; data: PatchedCompanyRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesPartialUpdate>>,
    { slug: string; data: PatchedCompanyRequest }
  > = (props) => {
    const { slug, data } = props ?? {};

    return companiesPartialUpdate(slug, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesPartialUpdate>>
>;
export type CompaniesPartialUpdateMutationBody = PatchedCompanyRequest;
export type CompaniesPartialUpdateMutationError =
  | CompaniesPartialUpdateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCompaniesPartialUpdate = <
  TError =
    | CompaniesPartialUpdateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesPartialUpdate>>,
    TError,
    { slug: string; data: PatchedCompanyRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesPartialUpdate>>,
  TError,
  { slug: string; data: PatchedCompanyRequest },
  TContext
> => {
  const mutationOptions = getCompaniesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get active auctions for a company
 */
export const getCompanyActiveAuctions = (
  slug: string,
  params?: GetCompanyActiveAuctionsParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedAuctionPolymorphicList>(
    {
      url: `/companies/${slug}/active_auctions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCompanyActiveAuctionsQueryKey = (
  slug: string,
  params?: GetCompanyActiveAuctionsParams
) => {
  return [
    `/companies/${slug}/active_auctions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompanyActiveAuctionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyActiveAuctions>>,
  TError =
    | GetCompanyActiveAuctionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyActiveAuctionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyActiveAuctions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompanyActiveAuctionsQueryKey(slug, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyActiveAuctions>>
  > = ({ signal }) =>
    getCompanyActiveAuctions(slug, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyActiveAuctions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyActiveAuctionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyActiveAuctions>>
>;
export type GetCompanyActiveAuctionsQueryError =
  | GetCompanyActiveAuctionsErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyActiveAuctions = <
  TData = Awaited<ReturnType<typeof getCompanyActiveAuctions>>,
  TError =
    | GetCompanyActiveAuctionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyActiveAuctionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyActiveAuctions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyActiveAuctionsQueryOptions(
    slug,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * get company analytics
 */
export const getCompanyAnalytics = (
  slug: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<CompanyAnalytics>(
    { url: `/companies/${slug}/analytics/`, method: "GET", signal },
    options
  );
};

export const getGetCompanyAnalyticsQueryKey = (slug: string) => {
  return [`/companies/${slug}/analytics/`] as const;
};

export const getGetCompanyAnalyticsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyAnalytics>>,
  TError =
    | GetCompanyAnalyticsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyAnalytics>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompanyAnalyticsQueryKey(slug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyAnalytics>>
  > = ({ signal }) => getCompanyAnalytics(slug, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyAnalytics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyAnalyticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyAnalytics>>
>;
export type GetCompanyAnalyticsQueryError =
  | GetCompanyAnalyticsErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyAnalytics = <
  TData = Awaited<ReturnType<typeof getCompanyAnalytics>>,
  TError =
    | GetCompanyAnalyticsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyAnalytics>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyAnalyticsQueryOptions(slug, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all auctions for a company
 */
export const getCompanyAuctions = (
  slug: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<AuctionPolymorphic[]>(
    { url: `/companies/${slug}/auctions/`, method: "GET", signal },
    options
  );
};

export const getGetCompanyAuctionsQueryKey = (slug: string) => {
  return [`/companies/${slug}/auctions/`] as const;
};

export const getGetCompanyAuctionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyAuctions>>,
  TError =
    | GetCompanyAuctionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyAuctions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompanyAuctionsQueryKey(slug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyAuctions>>
  > = ({ signal }) => getCompanyAuctions(slug, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyAuctions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyAuctionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyAuctions>>
>;
export type GetCompanyAuctionsQueryError =
  | GetCompanyAuctionsErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyAuctions = <
  TData = Awaited<ReturnType<typeof getCompanyAuctions>>,
  TError =
    | GetCompanyAuctionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyAuctions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyAuctionsQueryOptions(slug, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * get company auctions analytics
 */
export const getCompanyAuctionsAnalytics = (
  slug: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<CompanyAuctionsAnalytics>(
    { url: `/companies/${slug}/auctions-analytics/`, method: "GET", signal },
    options
  );
};

export const getGetCompanyAuctionsAnalyticsQueryKey = (slug: string) => {
  return [`/companies/${slug}/auctions-analytics/`] as const;
};

export const getGetCompanyAuctionsAnalyticsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyAuctionsAnalytics>>,
  TError =
    | GetCompanyAuctionsAnalyticsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyAuctionsAnalytics>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompanyAuctionsAnalyticsQueryKey(slug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyAuctionsAnalytics>>
  > = ({ signal }) => getCompanyAuctionsAnalytics(slug, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyAuctionsAnalytics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyAuctionsAnalyticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyAuctionsAnalytics>>
>;
export type GetCompanyAuctionsAnalyticsQueryError =
  | GetCompanyAuctionsAnalyticsErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyAuctionsAnalytics = <
  TData = Awaited<ReturnType<typeof getCompanyAuctionsAnalytics>>,
  TError =
    | GetCompanyAuctionsAnalyticsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyAuctionsAnalytics>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyAuctionsAnalyticsQueryOptions(
    slug,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * List paginated captable
 */
export const getCompanyCaptable = (
  slug: string,
  params?: GetCompanyCaptableParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedShareholderList>(
    { url: `/companies/${slug}/captable/`, method: "GET", params, signal },
    options
  );
};

export const getGetCompanyCaptableQueryKey = (
  slug: string,
  params?: GetCompanyCaptableParams
) => {
  return [`/companies/${slug}/captable/`, ...(params ? [params] : [])] as const;
};

export const getGetCompanyCaptableQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyCaptable>>,
  TError =
    | GetCompanyCaptableErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyCaptableParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyCaptable>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompanyCaptableQueryKey(slug, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyCaptable>>
  > = ({ signal }) => getCompanyCaptable(slug, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyCaptable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyCaptableQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyCaptable>>
>;
export type GetCompanyCaptableQueryError =
  | GetCompanyCaptableErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyCaptable = <
  TData = Awaited<ReturnType<typeof getCompanyCaptable>>,
  TError =
    | GetCompanyCaptableErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyCaptableParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyCaptable>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyCaptableQueryOptions(slug, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * get all documents for a company
 */
export const getCompanyDocuments = (
  slug: string,
  params?: GetCompanyDocumentsParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedCompanyDocumentList>(
    { url: `/companies/${slug}/documents/`, method: "GET", params, signal },
    options
  );
};

export const getGetCompanyDocumentsQueryKey = (
  slug: string,
  params?: GetCompanyDocumentsParams
) => {
  return [
    `/companies/${slug}/documents/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompanyDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyDocuments>>,
  TError =
    | GetCompanyDocumentsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompanyDocumentsQueryKey(slug, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyDocuments>>
  > = ({ signal }) => getCompanyDocuments(slug, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyDocuments>>
>;
export type GetCompanyDocumentsQueryError =
  | GetCompanyDocumentsErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyDocuments = <
  TData = Awaited<ReturnType<typeof getCompanyDocuments>>,
  TError =
    | GetCompanyDocumentsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyDocumentsQueryOptions(
    slug,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * add new document to company
 */
export const addCompanyDocument = (
  slug: string,
  companyDocumentRequest: CompanyDocumentRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<CompanyDocument>(
    {
      url: `/companies/${slug}/documents/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: companyDocumentRequest,
    },
    options
  );
};

export const getAddCompanyDocumentMutationOptions = <
  TError =
    | AddCompanyDocumentErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addCompanyDocument>>,
    TError,
    { slug: string; data: CompanyDocumentRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addCompanyDocument>>,
  TError,
  { slug: string; data: CompanyDocumentRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addCompanyDocument>>,
    { slug: string; data: CompanyDocumentRequest }
  > = (props) => {
    const { slug, data } = props ?? {};

    return addCompanyDocument(slug, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddCompanyDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof addCompanyDocument>>
>;
export type AddCompanyDocumentMutationBody = CompanyDocumentRequest;
export type AddCompanyDocumentMutationError =
  | AddCompanyDocumentErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAddCompanyDocument = <
  TError =
    | AddCompanyDocumentErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addCompanyDocument>>,
    TError,
    { slug: string; data: CompanyDocumentRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addCompanyDocument>>,
  TError,
  { slug: string; data: CompanyDocumentRequest },
  TContext
> => {
  const mutationOptions = getAddCompanyDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * update document from company
 */
export const updateCompanyDocument = (
  slug: string,
  documentId: string,
  patchedCompanyDocumentRequest: PatchedCompanyDocumentRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<CompanyDocument>(
    {
      url: `/companies/${slug}/documents/${documentId}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCompanyDocumentRequest,
    },
    options
  );
};

export const getUpdateCompanyDocumentMutationOptions = <
  TError =
    | UpdateCompanyDocumentErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCompanyDocument>>,
    TError,
    { slug: string; documentId: string; data: PatchedCompanyDocumentRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCompanyDocument>>,
  TError,
  { slug: string; documentId: string; data: PatchedCompanyDocumentRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCompanyDocument>>,
    { slug: string; documentId: string; data: PatchedCompanyDocumentRequest }
  > = (props) => {
    const { slug, documentId, data } = props ?? {};

    return updateCompanyDocument(slug, documentId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCompanyDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCompanyDocument>>
>;
export type UpdateCompanyDocumentMutationBody = PatchedCompanyDocumentRequest;
export type UpdateCompanyDocumentMutationError =
  | UpdateCompanyDocumentErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useUpdateCompanyDocument = <
  TError =
    | UpdateCompanyDocumentErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCompanyDocument>>,
    TError,
    { slug: string; documentId: string; data: PatchedCompanyDocumentRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCompanyDocument>>,
  TError,
  { slug: string; documentId: string; data: PatchedCompanyDocumentRequest },
  TContext
> => {
  const mutationOptions = getUpdateCompanyDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * delete document from company
 */
export const deleteCompanyDocument = (
  slug: string,
  documentId: string,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    { url: `/companies/${slug}/documents/${documentId}/`, method: "DELETE" },
    options
  );
};

export const getDeleteCompanyDocumentMutationOptions = <
  TError =
    | DeleteCompanyDocumentErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCompanyDocument>>,
    TError,
    { slug: string; documentId: string },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCompanyDocument>>,
  TError,
  { slug: string; documentId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCompanyDocument>>,
    { slug: string; documentId: string }
  > = (props) => {
    const { slug, documentId } = props ?? {};

    return deleteCompanyDocument(slug, documentId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCompanyDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCompanyDocument>>
>;

export type DeleteCompanyDocumentMutationError =
  | DeleteCompanyDocumentErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useDeleteCompanyDocument = <
  TError =
    | DeleteCompanyDocumentErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCompanyDocument>>,
    TError,
    { slug: string; documentId: string },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCompanyDocument>>,
  TError,
  { slug: string; documentId: string },
  TContext
> => {
  const mutationOptions = getDeleteCompanyDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get draft auctions for a company
 */
export const getCompanyDraftAuctions = (
  slug: string,
  params?: GetCompanyDraftAuctionsParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedAuctionPolymorphicList>(
    {
      url: `/companies/${slug}/draft_auctions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCompanyDraftAuctionsQueryKey = (
  slug: string,
  params?: GetCompanyDraftAuctionsParams
) => {
  return [
    `/companies/${slug}/draft_auctions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompanyDraftAuctionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyDraftAuctions>>,
  TError =
    | GetCompanyDraftAuctionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyDraftAuctionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyDraftAuctions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompanyDraftAuctionsQueryKey(slug, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyDraftAuctions>>
  > = ({ signal }) =>
    getCompanyDraftAuctions(slug, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyDraftAuctions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyDraftAuctionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyDraftAuctions>>
>;
export type GetCompanyDraftAuctionsQueryError =
  | GetCompanyDraftAuctionsErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyDraftAuctions = <
  TData = Awaited<ReturnType<typeof getCompanyDraftAuctions>>,
  TError =
    | GetCompanyDraftAuctionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyDraftAuctionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyDraftAuctions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyDraftAuctionsQueryOptions(
    slug,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get ended auctions for a company
 */
export const getCompanyEndedAuctions = (
  slug: string,
  params?: GetCompanyEndedAuctionsParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedAuctionPolymorphicList>(
    {
      url: `/companies/${slug}/ended_auctions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCompanyEndedAuctionsQueryKey = (
  slug: string,
  params?: GetCompanyEndedAuctionsParams
) => {
  return [
    `/companies/${slug}/ended_auctions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompanyEndedAuctionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyEndedAuctions>>,
  TError =
    | GetCompanyEndedAuctionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyEndedAuctionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyEndedAuctions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompanyEndedAuctionsQueryKey(slug, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyEndedAuctions>>
  > = ({ signal }) =>
    getCompanyEndedAuctions(slug, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyEndedAuctions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyEndedAuctionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyEndedAuctions>>
>;
export type GetCompanyEndedAuctionsQueryError =
  | GetCompanyEndedAuctionsErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyEndedAuctions = <
  TData = Awaited<ReturnType<typeof getCompanyEndedAuctions>>,
  TError =
    | GetCompanyEndedAuctionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  params?: GetCompanyEndedAuctionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyEndedAuctions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyEndedAuctionsQueryOptions(
    slug,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * export company data
 */
export const exportData = (
  slug: string,
  companyExportDataRequest: CompanyExportDataRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/companies/${slug}/export/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: companyExportDataRequest,
    },
    options
  );
};

export const getExportDataMutationOptions = <
  TError =
    | ExportDataErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportData>>,
    TError,
    { slug: string; data: CompanyExportDataRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exportData>>,
  TError,
  { slug: string; data: CompanyExportDataRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportData>>,
    { slug: string; data: CompanyExportDataRequest }
  > = (props) => {
    const { slug, data } = props ?? {};

    return exportData(slug, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof exportData>>
>;
export type ExportDataMutationBody = CompanyExportDataRequest;
export type ExportDataMutationError =
  | ExportDataErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useExportData = <
  TError =
    | ExportDataErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportData>>,
    TError,
    { slug: string; data: CompanyExportDataRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exportData>>,
  TError,
  { slug: string; data: CompanyExportDataRequest },
  TContext
> => {
  const mutationOptions = getExportDataMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * import company's CAP table
 */
export const importCapTable = (
  slug: string,
  importCAPTableRequest: ImportCAPTableRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append("csv_file", importCAPTableRequest.csv_file);

  return apiCustomInstance<Company>(
    {
      url: `/companies/${slug}/import-cap/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getImportCapTableMutationOptions = <
  TError =
    | ImportCapTableErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importCapTable>>,
    TError,
    { slug: string; data: ImportCAPTableRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importCapTable>>,
  TError,
  { slug: string; data: ImportCAPTableRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importCapTable>>,
    { slug: string; data: ImportCAPTableRequest }
  > = (props) => {
    const { slug, data } = props ?? {};

    return importCapTable(slug, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportCapTableMutationResult = NonNullable<
  Awaited<ReturnType<typeof importCapTable>>
>;
export type ImportCapTableMutationBody = ImportCAPTableRequest;
export type ImportCapTableMutationError =
  | ImportCapTableErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useImportCapTable = <
  TError =
    | ImportCapTableErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importCapTable>>,
    TError,
    { slug: string; data: ImportCAPTableRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof importCapTable>>,
  TError,
  { slug: string; data: ImportCAPTableRequest },
  TContext
> => {
  const mutationOptions = getImportCapTableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a company investor invite token
 */
export const getCompanyInvite = (
  slug: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<InvestorInviteRequest>(
    { url: `/companies/${slug}/invite/`, method: "GET", signal },
    options
  );
};

export const getGetCompanyInviteQueryKey = (slug: string) => {
  return [`/companies/${slug}/invite/`] as const;
};

export const getGetCompanyInviteQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanyInvite>>,
  TError =
    | GetCompanyInviteErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyInvite>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyInviteQueryKey(slug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompanyInvite>>
  > = ({ signal }) => getCompanyInvite(slug, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanyInvite>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompanyInviteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyInvite>>
>;
export type GetCompanyInviteQueryError =
  | GetCompanyInviteErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useGetCompanyInvite = <
  TData = Awaited<ReturnType<typeof getCompanyInvite>>,
  TError =
    | GetCompanyInviteErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanyInvite>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompanyInviteQueryOptions(slug, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * update company photo
 */
export const updateCompanyPhoto = (
  slug: string,
  patchedCompanyProfilePhotoRequest: PatchedCompanyProfilePhotoRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  if (patchedCompanyProfilePhotoRequest.profile_picture !== undefined) {
    formData.append(
      "profile_picture",
      patchedCompanyProfilePhotoRequest.profile_picture
    );
  }

  return apiCustomInstance<Company>(
    {
      url: `/companies/${slug}/photo/`,
      method: "PATCH",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getUpdateCompanyPhotoMutationOptions = <
  TError =
    | UpdateCompanyPhotoErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCompanyPhoto>>,
    TError,
    { slug: string; data: PatchedCompanyProfilePhotoRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCompanyPhoto>>,
  TError,
  { slug: string; data: PatchedCompanyProfilePhotoRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCompanyPhoto>>,
    { slug: string; data: PatchedCompanyProfilePhotoRequest }
  > = (props) => {
    const { slug, data } = props ?? {};

    return updateCompanyPhoto(slug, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCompanyPhotoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCompanyPhoto>>
>;
export type UpdateCompanyPhotoMutationBody = PatchedCompanyProfilePhotoRequest;
export type UpdateCompanyPhotoMutationError =
  | UpdateCompanyPhotoErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useUpdateCompanyPhoto = <
  TError =
    | UpdateCompanyPhotoErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCompanyPhoto>>,
    TError,
    { slug: string; data: PatchedCompanyProfilePhotoRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCompanyPhoto>>,
  TError,
  { slug: string; data: PatchedCompanyProfilePhotoRequest },
  TContext
> => {
  const mutationOptions = getUpdateCompanyPhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * add new user to company
 */
export const addUserToCompany = (
  slug: string,
  userDetailRequest: UserDetailRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<UserDetail>(
    {
      url: `/companies/${slug}/users/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userDetailRequest,
    },
    options
  );
};

export const getAddUserToCompanyMutationOptions = <
  TError =
    | AddUserToCompanyErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserToCompany>>,
    TError,
    { slug: string; data: UserDetailRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addUserToCompany>>,
  TError,
  { slug: string; data: UserDetailRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addUserToCompany>>,
    { slug: string; data: UserDetailRequest }
  > = (props) => {
    const { slug, data } = props ?? {};

    return addUserToCompany(slug, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddUserToCompanyMutationResult = NonNullable<
  Awaited<ReturnType<typeof addUserToCompany>>
>;
export type AddUserToCompanyMutationBody = UserDetailRequest;
export type AddUserToCompanyMutationError =
  | AddUserToCompanyErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useAddUserToCompany = <
  TError =
    | AddUserToCompanyErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserToCompany>>,
    TError,
    { slug: string; data: UserDetailRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addUserToCompany>>,
  TError,
  { slug: string; data: UserDetailRequest },
  TContext
> => {
  const mutationOptions = getAddUserToCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * update user role in company
 */
export const updateUserRole = (
  slug: string,
  userId: string,
  patchedUserDetailRequest: PatchedUserDetailRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<UserDetail>(
    {
      url: `/companies/${slug}/users/${userId}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedUserDetailRequest,
    },
    options
  );
};

export const getUpdateUserRoleMutationOptions = <
  TError =
    | UpdateUserRoleErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserRole>>,
    TError,
    { slug: string; userId: string; data: PatchedUserDetailRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserRole>>,
  TError,
  { slug: string; userId: string; data: PatchedUserDetailRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserRole>>,
    { slug: string; userId: string; data: PatchedUserDetailRequest }
  > = (props) => {
    const { slug, userId, data } = props ?? {};

    return updateUserRole(slug, userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserRole>>
>;
export type UpdateUserRoleMutationBody = PatchedUserDetailRequest;
export type UpdateUserRoleMutationError =
  | UpdateUserRoleErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useUpdateUserRole = <
  TError =
    | UpdateUserRoleErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserRole>>,
    TError,
    { slug: string; userId: string; data: PatchedUserDetailRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserRole>>,
  TError,
  { slug: string; userId: string; data: PatchedUserDetailRequest },
  TContext
> => {
  const mutationOptions = getUpdateUserRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesActionLogsList = (
  uuid: string,
  params?: CompaniesActionLogsListParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedActionLogList>(
    { url: `/companies/${uuid}/action-logs/`, method: "GET", params, signal },
    options
  );
};

export const getCompaniesActionLogsListQueryKey = (
  uuid: string,
  params?: CompaniesActionLogsListParams
) => {
  return [
    `/companies/${uuid}/action-logs/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getCompaniesActionLogsListQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesActionLogsList>>,
  TError =
    | CompaniesActionLogsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  params?: CompaniesActionLogsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof companiesActionLogsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCompaniesActionLogsListQueryKey(uuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesActionLogsList>>
  > = ({ signal }) =>
    companiesActionLogsList(uuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesActionLogsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesActionLogsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesActionLogsList>>
>;
export type CompaniesActionLogsListQueryError =
  | CompaniesActionLogsListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCompaniesActionLogsList = <
  TData = Awaited<ReturnType<typeof companiesActionLogsList>>,
  TError =
    | CompaniesActionLogsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  params?: CompaniesActionLogsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof companiesActionLogsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesActionLogsListQueryOptions(
    uuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Proxy for typeform files
 */
export const investorDownloadFile = (
  uuid: string,
  splat: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<Blob>(
    {
      url: `/companies/${uuid}/invest/download-file/${splat}/`,
      method: "GET",
      responseType: "blob",
      signal,
    },
    options
  );
};

export const getInvestorDownloadFileQueryKey = (
  uuid: string,
  splat: string
) => {
  return [`/companies/${uuid}/invest/download-file/${splat}/`] as const;
};

export const getInvestorDownloadFileQueryOptions = <
  TData = Awaited<ReturnType<typeof investorDownloadFile>>,
  TError =
    | InvestorDownloadFileErrorResponse400
    | string
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  splat: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof investorDownloadFile>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getInvestorDownloadFileQueryKey(uuid, splat);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof investorDownloadFile>>
  > = ({ signal }) => investorDownloadFile(uuid, splat, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(uuid && splat),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof investorDownloadFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InvestorDownloadFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof investorDownloadFile>>
>;
export type InvestorDownloadFileQueryError =
  | InvestorDownloadFileErrorResponse400
  | string
  | ErrorResponse403
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useInvestorDownloadFile = <
  TData = Awaited<ReturnType<typeof investorDownloadFile>>,
  TError =
    | InvestorDownloadFileErrorResponse400
    | string
    | ErrorResponse403
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  splat: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof investorDownloadFile>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInvestorDownloadFileQueryOptions(
    uuid,
    splat,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get investor form
 */
export const downloadInvestorsResponses = (
  uuid: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<Blob>(
    {
      url: `/companies/${uuid}/invest/download-investors-responses/`,
      method: "GET",
      responseType: "blob",
      signal,
    },
    options
  );
};

export const getDownloadInvestorsResponsesQueryKey = (uuid: string) => {
  return [`/companies/${uuid}/invest/download-investors-responses/`] as const;
};

export const getDownloadInvestorsResponsesQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadInvestorsResponses>>,
  TError =
    | DownloadInvestorsResponsesErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadInvestorsResponses>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadInvestorsResponsesQueryKey(uuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadInvestorsResponses>>
  > = ({ signal }) => downloadInvestorsResponses(uuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadInvestorsResponses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadInvestorsResponsesQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadInvestorsResponses>>
>;
export type DownloadInvestorsResponsesQueryError =
  | DownloadInvestorsResponsesErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useDownloadInvestorsResponses = <
  TData = Awaited<ReturnType<typeof downloadInvestorsResponses>>,
  TError =
    | DownloadInvestorsResponsesErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadInvestorsResponses>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadInvestorsResponsesQueryOptions(uuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get investor form
 */
export const companiesInvestFormRetrieve = (
  uuid: string,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<unknown>(
    { url: `/companies/${uuid}/invest/form/`, method: "GET", signal },
    options
  );
};

export const getCompaniesInvestFormRetrieveQueryKey = (uuid: string) => {
  return [`/companies/${uuid}/invest/form/`] as const;
};

export const getCompaniesInvestFormRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesInvestFormRetrieve>>,
  TError =
    | void
    | CompaniesInvestFormRetrieveErrorResponse400
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof companiesInvestFormRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCompaniesInvestFormRetrieveQueryKey(uuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesInvestFormRetrieve>>
  > = ({ signal }) => companiesInvestFormRetrieve(uuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesInvestFormRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesInvestFormRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesInvestFormRetrieve>>
>;
export type CompaniesInvestFormRetrieveQueryError =
  | void
  | CompaniesInvestFormRetrieveErrorResponse400
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCompaniesInvestFormRetrieve = <
  TData = Awaited<ReturnType<typeof companiesInvestFormRetrieve>>,
  TError =
    | void
    | CompaniesInvestFormRetrieveErrorResponse400
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof companiesInvestFormRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesInvestFormRetrieveQueryOptions(
    uuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Send an investor an invitation
 */
export const inviteInvestor = (
  uuid: string,
  importInvestorRequest: ImportInvestorRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<void>(
    {
      url: `/companies/${uuid}/invest/invite/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: importInvestorRequest,
    },
    options
  );
};

export const getInviteInvestorMutationOptions = <
  TError =
    | InviteInvestorErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteInvestor>>,
    TError,
    { uuid: string; data: ImportInvestorRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inviteInvestor>>,
  TError,
  { uuid: string; data: ImportInvestorRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inviteInvestor>>,
    { uuid: string; data: ImportInvestorRequest }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return inviteInvestor(uuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InviteInvestorMutationResult = NonNullable<
  Awaited<ReturnType<typeof inviteInvestor>>
>;
export type InviteInvestorMutationBody = ImportInvestorRequest;
export type InviteInvestorMutationError =
  | InviteInvestorErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useInviteInvestor = <
  TError =
    | InviteInvestorErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteInvestor>>,
    TError,
    { uuid: string; data: ImportInvestorRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inviteInvestor>>,
  TError,
  { uuid: string; data: ImportInvestorRequest },
  TContext
> => {
  const mutationOptions = getInviteInvestorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesTransactionsList = (
  uuid: string,
  params?: CompaniesTransactionsListParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedTransactionList>(
    { url: `/companies/${uuid}/transactions/`, method: "GET", params, signal },
    options
  );
};

export const getCompaniesTransactionsListQueryKey = (
  uuid: string,
  params?: CompaniesTransactionsListParams
) => {
  return [
    `/companies/${uuid}/transactions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getCompaniesTransactionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesTransactionsList>>,
  TError =
    | CompaniesTransactionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  params?: CompaniesTransactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof companiesTransactionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesTransactionsListQueryKey(uuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesTransactionsList>>
  > = ({ signal }) =>
    companiesTransactionsList(uuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesTransactionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesTransactionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesTransactionsList>>
>;
export type CompaniesTransactionsListQueryError =
  | CompaniesTransactionsListErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCompaniesTransactionsList = <
  TData = Awaited<ReturnType<typeof companiesTransactionsList>>,
  TError =
    | CompaniesTransactionsListErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  uuid: string,
  params?: CompaniesTransactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof companiesTransactionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesTransactionsListQueryOptions(
    uuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const companiesTransactionsCreate = (
  uuid: string,
  transactionRequest: TransactionRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  return apiCustomInstance<Transaction>(
    {
      url: `/companies/${uuid}/transactions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: transactionRequest,
    },
    options
  );
};

export const getCompaniesTransactionsCreateMutationOptions = <
  TError =
    | CompaniesTransactionsCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesTransactionsCreate>>,
    TError,
    { uuid: string; data: TransactionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesTransactionsCreate>>,
  TError,
  { uuid: string; data: TransactionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesTransactionsCreate>>,
    { uuid: string; data: TransactionRequest }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return companiesTransactionsCreate(uuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesTransactionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesTransactionsCreate>>
>;
export type CompaniesTransactionsCreateMutationBody = TransactionRequest;
export type CompaniesTransactionsCreateMutationError =
  | CompaniesTransactionsCreateErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useCompaniesTransactionsCreate = <
  TError =
    | CompaniesTransactionsCreateErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesTransactionsCreate>>,
    TError,
    { uuid: string; data: TransactionRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesTransactionsCreate>>,
  TError,
  { uuid: string; data: TransactionRequest },
  TContext
> => {
  const mutationOptions =
    getCompaniesTransactionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Import bulk credit transactions from a csv file
 */
export const bulkCreateCreditTransactions = (
  uuid: string,
  importCreditTransactionsRequest: ImportCreditTransactionsRequest,
  options?: SecondParameter<typeof apiCustomInstance>
) => {
  const formData = new FormData();
  formData.append("csv_file", importCreditTransactionsRequest.csv_file);

  return apiCustomInstance<unknown>(
    {
      url: `/companies/${uuid}/transactions/bulk-create-credit-transactions/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getBulkCreateCreditTransactionsMutationOptions = <
  TError =
    | BulkCreateCreditTransactionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkCreateCreditTransactions>>,
    TError,
    { uuid: string; data: ImportCreditTransactionsRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkCreateCreditTransactions>>,
  TError,
  { uuid: string; data: ImportCreditTransactionsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkCreateCreditTransactions>>,
    { uuid: string; data: ImportCreditTransactionsRequest }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return bulkCreateCreditTransactions(uuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkCreateCreditTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkCreateCreditTransactions>>
>;
export type BulkCreateCreditTransactionsMutationBody =
  ImportCreditTransactionsRequest;
export type BulkCreateCreditTransactionsMutationError =
  | BulkCreateCreditTransactionsErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useBulkCreateCreditTransactions = <
  TError =
    | BulkCreateCreditTransactionsErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkCreateCreditTransactions>>,
    TError,
    { uuid: string; data: ImportCreditTransactionsRequest },
    TContext
  >;
  request?: SecondParameter<typeof apiCustomInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof bulkCreateCreditTransactions>>,
  TError,
  { uuid: string; data: ImportCreditTransactionsRequest },
  TContext
> => {
  const mutationOptions =
    getBulkCreateCreditTransactionsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List paginated companies
 */
export const investorListCompanies = (
  params?: InvestorListCompaniesParams,
  options?: SecondParameter<typeof apiCustomInstance>,
  signal?: AbortSignal
) => {
  return apiCustomInstance<PaginatedCompanyInvestorListList>(
    { url: `/companies/investor-list/`, method: "GET", params, signal },
    options
  );
};

export const getInvestorListCompaniesQueryKey = (
  params?: InvestorListCompaniesParams
) => {
  return [`/companies/investor-list/`, ...(params ? [params] : [])] as const;
};

export const getInvestorListCompaniesQueryOptions = <
  TData = Awaited<ReturnType<typeof investorListCompanies>>,
  TError =
    | InvestorListCompaniesErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: InvestorListCompaniesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof investorListCompanies>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getInvestorListCompaniesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof investorListCompanies>>
  > = ({ signal }) => investorListCompanies(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof investorListCompanies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InvestorListCompaniesQueryResult = NonNullable<
  Awaited<ReturnType<typeof investorListCompanies>>
>;
export type InvestorListCompaniesQueryError =
  | InvestorListCompaniesErrorResponse400
  | ErrorResponse401
  | ErrorResponse403
  | ErrorResponse404
  | ErrorResponse405
  | ErrorResponse406
  | ErrorResponse415
  | ErrorResponse500;

export const useInvestorListCompanies = <
  TData = Awaited<ReturnType<typeof investorListCompanies>>,
  TError =
    | InvestorListCompaniesErrorResponse400
    | ErrorResponse401
    | ErrorResponse403
    | ErrorResponse404
    | ErrorResponse405
    | ErrorResponse406
    | ErrorResponse415
    | ErrorResponse500,
>(
  params?: InvestorListCompaniesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof investorListCompanies>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiCustomInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInvestorListCompaniesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
