import { Heading, Stack } from "@chakra-ui/react";

import { FormBackground } from "../../components/FormBackground";
import { LoginForm } from "../../components/Login";

const LoginPage = () => {
  return (
    <FormBackground>
      <Stack align="center" mt="12px" spacing={5}>
        <Heading as="h1" color="gray.50" fontWeight="bold" size="sm">
          Please Fill In Your Unique Login Details Below
        </Heading>
        <LoginForm />
      </Stack>
    </FormBackground>
  );
};

export default LoginPage;
