import {
  Flex,
  Grid,
  Icon as ChakraIcon,
  IconButton,
  Text,
  Tooltip,
  useToken,
  VStack,
} from "@chakra-ui/react";
import { MdHelpOutline } from "react-icons/md";

type CustomCardProps = {
  leading: string | JSX.Element;
  title: string;
  subtitlePrefix?: string;
  subtitle?: string | JSX.Element;
  subtitleSuffix?: string;
  Icon: React.ElementType;
  className?: string;
  info?: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[];
};

function AuctionCard({
  className,
  leading,
  title,
  subtitlePrefix,
  subtitle,
  subtitleSuffix,
  Icon,
  info,
}: CustomCardProps) {
  const [gray25, gray300] = useToken("colors", ["gray.25", "gray.300"]);

  return (
    <Flex
      backgroundColor="white"
      border={`1px solid ${gray300}`}
      borderRadius={8}
      className={className ?? ""}
      gap="16px"
      p="16px"
      w="100%"
    >
      <Flex
        alignItems="center"
        alignSelf="center"
        bgColor="primary.500"
        borderRadius={13}
        justifyContent="center"
        minH={12}
        minW={12}
      >
        <Icon fill={gray25} size="32px" />
      </Flex>
      <VStack alignItems="flex-start" spacing="4px">
        <Grid
          alignItems="center"
          justifyContent="center"
          templateColumns="auto 1fr"
        >
          <Text color="gray.600" variant="sm-regular">
            {title}
          </Text>

          {info && (
            <Tooltip closeOnClick={false} label={info}>
              <IconButton
                aria-label="info"
                icon={<ChakraIcon as={MdHelpOutline} color="gray.400" />}
                ml="auto"
                variant="link"
              />
            </Tooltip>
          )}
        </Grid>
        <Text
          as="span"
          color="primary.900"
          data-testid={`leading-${title.toLowerCase().replace(" ", "-")}`}
          variant="lg-semibold"
          whiteSpace="pre-wrap"
          css={{
            fontVariantNumeric: "tabular-nums",
            fontFeatureSettings: "tnum",
          }}
        >
          {leading}
        </Text>

        {subtitle && (
          <Text
            as="span"
            color="gray.600"
            fontSize="10px"
            fontWeight={400}
            variant="secondary"
          >
            {subtitlePrefix && <>{subtitlePrefix} </>}
            {subtitle}
            {subtitleSuffix && <> {subtitleSuffix}</>}
          </Text>
        )}
      </VStack>
    </Flex>
  );
}

export default AuctionCard;
