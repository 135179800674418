import { Center } from "@chakra-ui/react";

const NotFoundPage = () => {
  return (
    <Center
      color="#898989"
      fontSize="24px"
      h={{ base: "30vh", md: "70vh" }}
      textAlign="center"
    >
      Opps!
      <br />
      We cant seem to find the page you are looking for.
    </Center>
  );
};

export default NotFoundPage;
