import _ from "lodash";
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { Values } from "react-currency-format";
import { toast } from "react-toastify";

import IBidOrders from "../models/IBidOrders";
import IBuyOrder, { iBuyOrderFactory } from "../models/IBuyOrder";
import { IOrder } from "../models/IOrder";
import ISellOrder, { iSellOrderFactory } from "../models/ISellOrder";
import OrderModel from "../models/OrderModel";
import OrderType from "../models/OrderType";
import { ApiService } from "../services";
import { system_fee } from "../utils/constants";

class OrdersStore {
  orders: IBidOrders = {
    id: 0,
    buy_orders: [],
    sell_orders: [],
    tender: 0,
    tender_member: 0,
  };
  stockOrder: OrderModel = {};
  loading = true;
  showAlertMessage = false;
  showModal = false;
  editModalIndex = -1;

  constructor() {
    makeAutoObservable(this, {
      showAlertMessage: observable,
      showModal: observable,
      orders: observable,
      deleteOrder: action,
      getOrdersList: action,

      stockOrder: observable,
      addOrder: action,
      updateOrder: action,
      saveOrdersList: action,
      hideAlertMessage: action,

      totalBuyStock: computed,
      totalSellStock: computed,
      totalBuyWorth: computed,
      totalBuyWorthAfterSystemFees: computed,
      totalSellWorth: computed,
      totalSellWorthAfterSystemFees: computed,
      updatedOrdersList: computed,
    });
  }

  resetOrdersStore = () => {
    runInAction(() => {
      this.orders = {
        id: 0,
        buy_orders: [],
        sell_orders: [],
        tender: 0,
        tender_member: 0,
      };
      this.stockOrder = {};
      this.loading = true;
      this.showAlertMessage = false;
      this.editModalIndex = -1;
    });
  };

  resetOrder = (keepModal = false) => {
    runInAction(() => {
      this.editModalIndex = -1;
      if (keepModal) {
        this.stockOrder = {
          type: this.stockOrder.type,
        };
      } else {
        this.stockOrder = {};
      }
    });
  };

  hideAlertMessage = () => {
    runInAction(() => {
      this.showAlertMessage = !this.showAlertMessage;
    });
  };

  updateOrder = (key: string, values: Values) => {
    key = key as keyof OrderModel;

    runInAction(() => {
      switch (key) {
        case "stockPrice":
        case "stockCount":
          this.stockOrder[key] = values.floatValue;
          this.stockOrder[`${key}String`] = values.formattedValue;
          break;
        default:
          break;
      }
    });
  };

  setOrderType = (type: OrderType) => {
    this.stockOrder.type = type;
  };

  setOrder = (order: IOrder) => {
    const stockCount =
      (order as IBuyOrder).minimum_quantity || (order as ISellOrder).quantity;
    const stockPrice =
      (order as IBuyOrder).amount || (order as ISellOrder).price_per_unit;
    const created = order.created;

    this.stockOrder.stockCount = stockCount;
    this.stockOrder.stockCountString = stockCount ? stockCount.toString() : "";
    this.stockOrder.stockPrice = stockPrice;
    this.stockOrder.stockPriceString = stockPrice ? stockPrice.toString() : "";
    this.stockOrder.created = created;
  };

  addOrder = () => {
    if (!this.stockOrder.stockCount || !this.stockOrder.stockPrice)
      return false;

    runInAction(() => {
      if (this.stockOrder.type === OrderType.SELL) {
        this.orders.sell_orders.push({
          price_per_unit: this.stockOrder.stockPrice || 0,
          quantity: this.stockOrder.stockCount || 0,
          resourcetype: "DefaultSellOrder",
          deleted: false,
        });
      } else {
        this.orders.buy_orders.push({
          amount: this.stockOrder.stockPrice || 0,
          minimum_quantity: this.stockOrder.stockCount || 0,
          resourcetype: "DefaultBuyOrder",
          deleted: false,
        });
      }
      this.stockOrder = {};
    });
    return true;
  };

  editOrder = () => {
    if (!this.stockOrder.stockCount || !this.stockOrder.stockPrice)
      return false;

    runInAction(() => {
      if (this.stockOrder.type === OrderType.SELL) {
        this.orders.sell_orders[this.editModalIndex] = {
          price_per_unit: this.stockOrder.stockPrice || 0,
          quantity: this.stockOrder.stockCount || 0,
          resourcetype: "DefaultSellOrder",
          deleted: false,
          created: this.stockOrder.created,
          modified: new Date().toISOString(),
        };
      } else {
        this.orders.buy_orders[this.editModalIndex] = {
          amount: this.stockOrder.stockPrice || 0,
          minimum_quantity: this.stockOrder.stockCount || 0,
          resourcetype: "DefaultBuyOrder",
          deleted: false,
          created: this.stockOrder.created,
          modified: new Date().toISOString(),
        };
      }
    });
    return true;
  };

  getOrdersList = (id: string, noLoad = false) => {
    if (!noLoad) {
      runInAction(() => {
        this.loading = true;
      });
    }
    const axios = ApiService.getInstance();
    axios
      .get<IBidOrders>(`/orders/${id}/bid/`)
      .then((data) => {
        runInAction(() => {
          this.orders = {
            ...data.data,
            buy_orders: [
              ...iBuyOrderFactory(data.data.buy_orders),
              ...this.orders.buy_orders.filter((o) => !o.created),
            ],
            sell_orders: [
              ...iSellOrderFactory(data.data.sell_orders),
              ...this.orders.sell_orders.filter((o) => !o.created),
            ],
          };
        });
      })
      .catch(() => {
        toast.error("Error getting bids");
      })
      .finally(() => {
        if (!noLoad) {
          runInAction(() => {
            this.loading = false;
          });
        }
      });
  };

  deleteOrder = (type: OrderType, order: IOrder) => {
    runInAction(() => {
      if (type === OrderType.SELL) {
        const index = _.findIndex(this.orders.sell_orders, order);
        this.orders.sell_orders[index].deleted = true;
      } else {
        const index = _.findIndex(this.orders.buy_orders, order);
        this.orders.buy_orders[index].deleted = true;
      }
    });
  };

  saveOrdersList = (id: number | undefined) => {
    runInAction(() => {
      this.loading = true;
    });
    const axios = ApiService.getInstance();
    axios
      .put<IBidOrders>(`/orders/${id}/bid/`, {
        sell_orders: this.sellOrders,
        buy_orders: this.buyOrders,
      })
      .then((data) => {
        runInAction(() => {
          this.orders = data.data;
          this.orders.buy_orders = iBuyOrderFactory(data.data.buy_orders);
          this.orders.sell_orders = iSellOrderFactory(data.data.sell_orders);
          this.showAlertMessage = true;
        });
      })
      .catch(() => {
        toast.error("Failed to save orders list");
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  get totalBuyStock(): number {
    return this.buyOrders.reduce(
      (accumulator, obj) => accumulator + (obj.minimum_quantity || 0),
      0
    );
  }

  get totalSellStock(): number {
    return this.sellOrders.reduce(
      (accumulator, obj) => accumulator + (obj.quantity || 0),
      0
    );
  }

  get totalBuyWorth(): number {
    return this.buyOrders.reduce(
      (accumulator, obj) => accumulator + (obj.amount || 0),
      0
    );
  }

  get totalBuyWorthAfterSystemFees(): number {
    return this.totalBuyWorth + this.totalBuyWorth * system_fee;
  }

  get totalSellWorth(): number {
    return this.sellOrders.reduce(
      (accumulator, obj) =>
        accumulator + (obj.price_per_unit || 0) * (obj.quantity || 0),
      0
    );
  }

  get totalSellWorthAfterSystemFees(): number {
    return this.totalSellWorth - this.totalSellWorth * system_fee;
  }

  get hasNoInput(): boolean {
    return (
      [undefined, NaN].includes(this.stockOrder.stockCount) ||
      [undefined, NaN].includes(this.stockOrder.stockPrice)
    );
  }

  get stockOrderTotal(): number {
    if (this.hasNoInput) return NaN;

    if (this.stockOrder.type === OrderType.BUY) {
      return this.stockOrder.stockPrice!;
    } else {
      if (this.hasNoInput) return NaN;
      return this.stockOrder.stockCount! * this.stockOrder.stockPrice!;
    }
    return 0;
  }

  get stockBuyOrderTotalAfterFees() {
    if (this.hasNoInput) return NaN;
    return this.stockOrderTotal + this.stockOrderTotal * system_fee;
  }

  get stockSellOrderTotalAfterFees() {
    if (this.hasNoInput) return NaN;
    return this.stockOrderTotal - this.stockOrderTotal * system_fee;
  }

  get maxPricePerUnit() {
    if (this.hasNoInput) return NaN;
    if (this.stockOrder.stockCount === 0) return 0;
    return this.stockOrder.stockPrice! / this.stockOrder.stockCount!;
  }

  @computed get minSellOrder() {
    return _.minBy(this.orders.sell_orders, "price_per_unit");
  }

  @computed get minBuyOrder() {
    return _.minBy(this.orders.buy_orders, "price_per_unit");
  }

  @computed get updatedOrdersList() {
    return (
      _.some(
        this.orders.buy_orders,
        (order) => (!order.id && !order.deleted) || (order.id && order.deleted)
      ) ||
      _.some(
        this.orders.sell_orders,
        (order) => (!order.id && !order.deleted) || (order.id && order.deleted)
      )
    );
  }

  @computed get sellOrders() {
    return _.filter(
      this.orders.sell_orders,
      (sell_order) => !sell_order.deleted
    );
  }
  @computed get buyOrders() {
    return _.filter(this.orders.buy_orders, (buy_order) => !buy_order.deleted);
  }
}

export default OrdersStore;
