import { Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import DataTable, {
  PaginationComponentProps,
  TableProps,
} from "react-data-table-component";
import { MdArrowDownward } from "react-icons/md";

import { LoadingSpinner } from "../LoadingSpinner";
import { DTtheme } from "./theme";

const sortIcon = <MdArrowDownward />;
const selectProps = {
  indeterminate: (isIndeterminate: boolean) => isIndeterminate,
};
export const CustomNoDataComponent = (noData?: React.ReactNode) => (
  <Flex
    alignItems="center"
    flexDir="column"
    gap={4}
    h="100%"
    justifyContent="center"
    mb={4}
    w="100%"
  >
    <Image src="/png/table-not-found.png" />
    {noData === undefined && (
      <Text variant="display-xs-semibold">There are no records to display</Text>
    )}
    {typeof noData === "string" ? (
      <Text variant="display-xs-semibold">{noData}</Text>
    ) : (
      noData
    )}
  </Flex>
);

const CustomPaginationComponent: React.FC<PaginationComponentProps> = ({
  rowsPerPage,
  rowCount,
  currentPage,
  onChangePage,
}) => {
  const pagesCount = Math.floor(rowCount / rowsPerPage) + 1;
  const hasPrevious = currentPage > 1;
  const hasNext = currentPage < pagesCount;
  const onNext = () => {
    if (hasNext) {
      onChangePage(currentPage + 1, rowCount);
    }
  };
  const onPrevious = () => {
    if (hasPrevious) {
      onChangePage(currentPage - 1, rowCount);
    }
  };

  return (
    <Flex justifyContent="space-between" p={4}>
      <Text>
        Page {currentPage} of {pagesCount}
      </Text>

      <Flex justifyContent="space-between" w={200}>
        <Button
          borderColor="gray.300"
          isDisabled={!hasPrevious}
          onClick={onPrevious}
          variant="outline"
        >
          Previous
        </Button>
        <Button
          borderColor="gray.300"
          isDisabled={!hasNext}
          onClick={onNext}
          variant="outline"
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export type CustomTableProps<T> = TableProps<T> & {
  noPagination?: boolean;
  noData?: React.ReactNode;
  className?: string;
};

export function CustomTable<T>(props: CustomTableProps<T>): JSX.Element {
  return (
    <DataTable
      persistTableHead
      className={props.className || ""}
      customStyles={DTtheme}
      fixedHeader={true}
      noDataComponent={CustomNoDataComponent(props.noData)}
      pagination={!props.noPagination}
      paginationComponent={CustomPaginationComponent}
      progressComponent={<LoadingSpinner />}
      // https://react-data-table-component.netlify.app/?path=/docs/ui-library-material-ui-table--table#make-our-table-material-design
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      sortIcon={sortIcon}
      striped={false}
      {...props}
    />
  );
}
