import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";

const ControlWrapper = ({
  className = "ControlWrapper",
  isInvalid,
  formLabel,
  errorMessage,
  helperText,
  children,
  infoTooltip,
  hidden,
  fullLine,
  fade,
}: {
  className?: string;
  isInvalid?: boolean;
  formLabel?: string;
  errorMessage?: string;
  children: JSX.Element | JSX.Element[];
  helperText?: string | JSX.Element;
  infoTooltip?: string;
  hidden?: boolean;
  fullLine?: boolean;
  fade?: boolean;
}) => (
  <FormControl hidden={hidden} isInvalid={isInvalid ?? false}>
    <Stack
      align="flex-start"
      className={className}
      direction={fullLine ? "column" : ["column", "row"]}
      spacing={fullLine ? -1 : 5}
    >
      {formLabel && (
        <FormLabel
          fontWeight={600}
          mb="4px"
          opacity={fade ? 0.5 : 1}
          w={fullLine ? "95%" : ["100%", "35%"]}
        >
          {formLabel}
          {infoTooltip && (
            <Tooltip closeOnClick={false} isDisabled={fade} label={infoTooltip}>
              <IconButton
                aria-label="info"
                cursor={fade ? "default" : "pointer"}
                icon={<Icon as={FiInfo} color="blue-primary" />}
                ml="auto"
                variant="link"
                verticalAlign="middle"
              />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <Stack dir="row" w="100%">
        {children}

        {errorMessage?.includes("\n") ? (
          errorMessage.split("\n").map((msg, idx) => (
            <FormErrorMessage key={idx} color="error.600">
              {msg}
            </FormErrorMessage>
          ))
        ) : (
          <FormErrorMessage color="error.600">{errorMessage}</FormErrorMessage>
        )}
        {helperText && <FormHelperText mt="-1">{helperText}</FormHelperText>}
      </Stack>
    </Stack>
  </FormControl>
);

export default ControlWrapper;
