import { CircularProgress, Flex } from "@chakra-ui/react";
interface LoadingSpinnerProps {
  fullHeight?: boolean;
}

const LoadingSpinner = ({ fullHeight }: LoadingSpinnerProps) => {
  return fullHeight ? (
    <Flex justifyContent="center" minH="100%" minW="100%">
      <CircularProgress isIndeterminate />
    </Flex>
  ) : (
    <Flex justifyContent="center" minW="100%">
      <CircularProgress isIndeterminate />
    </Flex>
  );
};

export default LoadingSpinner;
