import { ButtonProps } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

interface SpinnerButtonProps extends ButtonProps {
  loading: boolean;
  loadingText?: string;
  children: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[];
}

const SpinnerButton = ({
  loading,
  loadingText = "Loading...",
  children,
  ...props
}: SpinnerButtonProps) => (
  <Button {...props} isLoading={loading} loadingText={loadingText}>
    {children}
  </Button>
);

export default SpinnerButton;
