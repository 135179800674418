import {
  Box,
  Card,
  CardBody,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { AiOutlineMail } from "react-icons/ai";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PageWrapper from "src/components/FSTPageWrapper";
import { useColorFromToken } from "src/hooks/useColorFromToken";
import { tableColumnPassClickEvents } from "src/utils/misc";

import { Shareholder } from "../../api/model";
import { useShareholdersList } from "../../api/shareholders";
import { CustomTable } from "../../components/CustomTable";
import NumberFormat from "../../components/NumberFormat";
import { AuthService } from "../../services";

const TraderUserPage = () => {
  const getColorFromToken = useColorFromToken();
  const navigate = useNavigate();

  const {
    data,
    isLoading: isLoadingSwitchList,
    isRefetching: isFetchingSwitchList,
  } = useShareholdersList();
  const currentCompany = AuthService.getUserCompany();
  const isTrader = AuthService.getUserGroup()?.value === "Trader";
  const [canSwitch, setCanSwitch] = useState(false);

  const traderOption = {
    label: isTrader ? "Trader" : "Switch to Trader",
    value: "Trader",
  };
  const companies = useMemo(() => {
    const temp =
      data?.flatMap((shareholder) => {
        if (!shareholder.is_admin) {
          return [];
        }
        return {
          label: `${shareholder.company} - Admin`,
          value: shareholder.uuid,
        };
      }) ?? [];
    if (temp.length > 0) {
      setCanSwitch(true);
    }
    temp.push(traderOption);
    return temp;
  }, [data]);

  const userEmail = AuthService.getUserEmail();
  const userName = AuthService.getUserName();

  const currentCompanyData =
    data?.find((shareholder) => shareholder.uuid === currentCompany) ??
    (data && data[0]) ??
    ({
      trader: {
        first_name: userName,
        last_name: "",
        email: userEmail,
      },
    } as Shareholder);

  const columns = useMemo(
    (): TableColumn<Shareholder>[] => [
      {
        name: "Company Name",
        cell: (transaction) => (
          <Box
            alignItems="center"
            display="flex"
            gap="2"
            {...tableColumnPassClickEvents}
          >
            {transaction.company}{" "}
            <FaExternalLinkAlt color={getColorFromToken("gray.400")} />
          </Box>
        ),
      },
      {
        name: "Share Balance",
        right: true,
        cell: (transaction) => (
          <NumberFormat tableValue type="number" value={transaction.shares} />
        ),
      },
      {
        name: "Credit Balance",
        right: true,
        cell: (transaction) => (
          <NumberFormat
            tableValue
            type="currency"
            value={transaction.credit_balance}
          />
        ),
      },
    ],
    []
  );
  return (
    <PageWrapper
      isLoading={isFetchingSwitchList}
      loadingText="Loading profile"
      pageSubtitle="View your profile information here"
      pageTitle="Manage Profile"
    >
      <Stack pt="24px" spacing="24px">
        <Card className="companyInfoCard" hidden={!canSwitch}>
          <CardBody>
            <Stack direction={{ base: "column", md: "row" }} spacing="32px">
              <Text
                alignSelf="center"
                display="inline-block"
                mb="4px"
                variant="sm-semibold"
                w="280px"
              >
                You are currently signed in as:
              </Text>
              <Select
                isLoading={isLoadingSwitchList}
                options={companies}
                chakraStyles={{
                  container: (provided) => ({
                    ...provided,
                    width: "full",
                  }),
                }}
                onChange={(newValue) => {
                  if (newValue) {
                    const userGroup = data!.find(
                      (shareholder) => shareholder.uuid === newValue.value
                    )!.is_admin
                      ? "CorporateAdmin"
                      : "Trader";
                    AuthService.switchCompany(userGroup, newValue.value ?? "");
                  }
                }}
                value={
                  isTrader
                    ? traderOption
                    : companies?.find(
                        (shareholder) => shareholder?.value === currentCompany
                      )
                }
              />
            </Stack>
          </CardBody>
        </Card>
        <Box>
          <Text color="gray.700" variant="sm-semibold">
            Personal Info
          </Text>
          <Text color="gray.500" variant="sm-regular">
            Your profile details.
          </Text>
        </Box>
        <Card className="companyInfoCard">
          <CardBody>
            <Stack divider={<StackDivider />} spacing="5">
              <Stack direction={{ base: "column", sm: "row" }}>
                <Text mr="8" variant="sm-semibold" w="280px">
                  Name
                </Text>
                <Text>
                  {currentCompanyData?.trader?.first_name}{" "}
                  {currentCompanyData?.trader?.last_name}
                </Text>
              </Stack>
              <Stack direction={{ base: "column", sm: "row" }}>
                <Text display="flex" mr="8" variant="sm-semibold" w="280px">
                  Email address
                </Text>
                <Box alignItems="center" display="flex" gap="2">
                  <AiOutlineMail />
                  <Text>{currentCompanyData?.trader?.email}</Text>
                </Box>
              </Stack>
            </Stack>
          </CardBody>
        </Card>
        <Box>
          <Text color="gray.700" variant="sm-semibold">
            Balance
          </Text>
          <Text color="gray.500" variant="sm-regular">
            Your balance across companies.
          </Text>
        </Box>
        <Card className="companyInfoCard">
          <CardBody>
            <CustomTable<Shareholder>
              noPagination
              paginationServer
              persistTableHead
              pointerOnHover
              columns={columns}
              data={data ?? []}
              progressPending={isLoadingSwitchList}
              onRowClicked={(row) => {
                navigate(`/trader/companies/${row.slug}`);
              }}
              // onChangePage={onPageChange}
              // paginationTotalRows={totalRows}
              // progressPending={isLoading}
            />
          </CardBody>
        </Card>
      </Stack>
    </PageWrapper>
  );
};

export default TraderUserPage;
