import {
  Box,
  Container,
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { MdOutlineBarChart, MdOutlineSubject } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCompaniesRetrieve } from "src/api/companies";

import CompanyDocumentsTable from "../Admin/components/CompanyDocumentsTable";
import CompanyCard from "../Admin/components/CompanyProfile/CompanyCard";
import NotFoundPage from "../NotFound/NotFoundPage";
import { MarketplaceAnalytics } from "./Analytics";

export const CompanyPublicProfile = ({
  standalone,
}: {
  standalone?: boolean;
}) => {
  const { companyId } = useParams();

  const {
    data: companyData,
    error,
    isError,
  } = useCompaniesRetrieve(companyId ?? "", {
    // DISABLE_MARKETPLACE
    // request: {
    //   transformRequest: (data, headers) => {
    //     delete headers.authorization;
    //     return data;
    //   },
    // },
  });

  useEffect(() => {
    if (isError) {
      if (error.errors[0].code === "not_found") {
        toast.error("Company not found");
      }
    }
  }, [isError]);

  if (!companyId || error?.errors[0].code === "not_found")
    return <NotFoundPage />;

  return (
    <Container maxH="100%" minW="100%">
      {standalone ? (
        <Flex alignItems="start" flexDir="row" justifyContent="start" py="30px">
          <Text
            as="span"
            color="primary.900"
            ml="32px"
            variant="display-sm-semibold"
          >
            Company details
          </Text>
        </Flex>
      ) : (
        <Heading color="gray.900" pb="24px">
          Company Details
        </Heading>
      )}
      <CompanyCard
        viewOnly
        companyId={companyId}
        linkedin={companyData?.linkedin ?? ""}
        tags={companyData?.industries ?? []}
      />
      <Box py="16px">
        <Tabs
        // onChange={(index) => {
        //   if (index === 0) {
        //     onCancelPreferencesSection();
        //   }
        //   if (index === 1) {
        //     onCancelEquitySection();
        //   }
        //   if (index === 2) {
        //     onCancelPreferencesSection();
        //     onCancelEquitySection();
        //   }
        // }}
        >
          <TabList>
            <Tab>
              <MdOutlineBarChart size="24px" />
              <Text ml="2">Analytics</Text>
            </Tab>
            {/* DISABLE_MARKETPLACE */}
            {/* <Tab>
              <MdOutlineMoving size="24px" />
              <Text ml="2">Auctions</Text>
            </Tab> */}
            <Tab>
              <MdOutlineSubject size="24px" />
              <Text ml="2">Documents</Text>
            </Tab>
          </TabList>
          <TabIndicator
            bg="primary.800"
            borderRadius="1px"
            height="2px"
            mt="-1.5px"
          />
          <TabPanels>
            <TabPanel>
              <MarketplaceAnalytics />
            </TabPanel>
            {/* <TabPanel>
              <Stack mt="-5" spacing="24px">
                <TendersPage investor singleCompany={companyId} />
              </Stack>
            </TabPanel> */}
            <TabPanel>
              <CompanyDocumentsTable viewOnly />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
};
