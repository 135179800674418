import {
  AbsoluteCenter,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  FormLabel,
  Heading,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import { ControlWrapper } from "../../../../components/ControlWrapper";
import { ImportForm } from "../../../../components/ImportFormModal";
import { SpinnerButton } from "../../../../components/SpinnerButton";
import { useAddAuctionMembersHandler } from "../../hooks/useAddAuctionMembersHandler";
import AddSingleAuctionMember from "./AddSingleAuctionMember";

const HorizontalLine = () => {
  return (
    <Box padding="10" position="relative">
      <Divider />
      <AbsoluteCenter bg="white" color="gray.500" px="4">
        OR
      </AbsoluteCenter>
    </Box>
  );
};

const AddAuctionMembers = ({
  auctionId,
  auctionType,
  isCustodial,
  handleClose,
  padCard = false,
}: {
  auctionId: number;
  auctionType: string;
  isCustodial: boolean;
  handleClose: () => void;
  padCard?: boolean;
}) => {
  const {
    addAuctionMemberMode,

    onAddMembers,
    isLoading,

    csvErrors,
    csvRegister,
    includeAllControl,
    includeAllErrors,
    singleMemberControl,
    singleMemberErrors,
    singleMemberSetValue,
  } = useAddAuctionMembersHandler({
    auctionId,
    auctionType,
    handleClose,
  });

  return (
    <Stack spacing="5">
      <Card variant="unstyled">
        <CardBody px={padCard ? 8 : 0}>
          <ControlWrapper
            formLabel="Include all members"
            infoTooltip="This auction will import, use and reflect on the CAP table data"
            isInvalid={includeAllErrors.include_employees ? true : false}
            errorMessage={
              includeAllErrors.include_employees &&
              includeAllErrors.include_employees.message
            }
            fade={
              !isCustodial ||
              !["all", "include_all"].includes(addAuctionMemberMode)
            }
          >
            <Controller
              control={includeAllControl}
              name="include_employees"
              render={({ field: { value, onChange } }) => (
                <FormLabel
                  alignSelf="end"
                  cursor="pointer"
                  opacity={
                    !["all", "include_all"].includes(addAuctionMemberMode)
                      ? 0.5
                      : 1
                  }
                >
                  <Switch
                    cursor="pointer"
                    isChecked={value}
                    mr="3"
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={
                      !isCustodial ||
                      !["all", "include_all"].includes(addAuctionMemberMode)
                    }
                  />
                  {value ? "Enabled" : "Disabled"}
                </FormLabel>
              )}
            />
          </ControlWrapper>
          <ControlWrapper
            formLabel="Include all investors"
            infoTooltip="This auction will import, use and reflect on the CAP table data"
            isInvalid={includeAllErrors.include_investors ? true : false}
            errorMessage={
              includeAllErrors.include_investors &&
              includeAllErrors.include_investors.message
            }
            fade={
              !isCustodial ||
              !["all", "include_all"].includes(addAuctionMemberMode)
            }
          >
            <Controller
              control={includeAllControl}
              name="include_investors"
              render={({ field: { value, onChange } }) => (
                <FormLabel
                  alignSelf="end"
                  cursor="pointer"
                  opacity={
                    !["all", "include_all"].includes(addAuctionMemberMode)
                      ? 0.5
                      : 1
                  }
                >
                  <Switch
                    cursor="pointer"
                    isChecked={value}
                    mr="3"
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={
                      !isCustodial ||
                      !["all", "include_all"].includes(addAuctionMemberMode)
                    }
                  />
                  {value ? "Enabled" : "Disabled"}
                </FormLabel>
              )}
            />
          </ControlWrapper>
          <HorizontalLine />
          <Heading
            color="gray.700"
            fontSize="sm"
            fontWeight="semibold"
            mb="6px"
            opacity={["all", "csv"].includes(addAuctionMemberMode) ? 1 : 0.5}
          >
            Upload CSV File
          </Heading>
          <ImportForm
            errors={csvErrors}
            fade={!["all", "csv"].includes(addAuctionMemberMode)}
            isDisabled={!["all", "csv"].includes(addAuctionMemberMode)}
            register={csvRegister}
            templateFileName={
              isCustodial
                ? "auction_invites_template.csv"
                : "non_custodial_auction_invites_template.csv"
            }
          />
          <HorizontalLine />
          <AddSingleAuctionMember
            addAuctionMemberMode={addAuctionMemberMode}
            auctionType={auctionType}
            isCustodial={isCustodial}
            singleMemberControl={singleMemberControl}
            singleMemberErrors={singleMemberErrors}
            singleMemberSetValue={singleMemberSetValue}
          />
        </CardBody>
      </Card>
      <Stack direction={["column-reverse", "row"]} spacing={5} w="100%">
        <Button onClick={handleClose} variant="outline" w="100%">
          Close
        </Button>
        <SpinnerButton
          data-testid="add-members-button"
          isDisabled={addAuctionMemberMode == "all"}
          loading={isLoading}
          onClick={onAddMembers}
          type="submit"
          w="100%"
        >
          Add
        </SpinnerButton>
      </Stack>
    </Stack>
  );
};

export default AddAuctionMembers;
