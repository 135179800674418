import { extendTheme, Tooltip } from "@chakra-ui/react";

import { Button } from "./button";
import { Card } from "./card";
import { Checkbox } from "./checkbox";
import { Heading } from "./heading";
import { Input } from "./input";
import { Tabs } from "./tabs";
import { Tag } from "./tag";
import { Text } from "./text";

// https://github.com/chakra-ui/chakra-ui/issues/3347
Tooltip.defaultProps = {
  hasArrow: true,
  openDelay: 200,
};

export const theme = extendTheme(
  {
    colors: {
      brand: {
        100: "#FF3C00",
        700: "#6941C6",
        900: "#03045E",
      },
      primary: {
        0: "#FFF",
        25: "#E6F8FC",
        50: "#F9F5FF",
        100: "#F4EBFF",
        500: "#0096C7",
        800: "#023E8A",
        900: "#03045E",
      },
      success: {
        50: "#ECFDF3",
        100: "#D1FADF",
        600: "#039855",
        700: "#027A48",
      },
      warning: {
        50: "#FFFAEB",
        700: "#B54708",
      },
      error: {
        50: "#FEF3F2",
        100: "#FEE4E2",
        600: "#D92D20",
        700: "#B42318",
      },
      gray: {
        25: "#FCFCFD",
        50: "#F9FAFB",
        100: "#F2F4F7",
        200: "#EAECF0",
        300: "#D0D5DD",
        500: "#667085",
        600: "#475467",
        700: "#344054",
        800: "#1D2939",
        900: "#101828",
      },
      blue: {
        50: "#EFF8FF",
        200: "#B2DDFF",
        400: "#53B1FD",
        700: "#175CD3",
        900: "#03045E",
        800: "#023E8A",
      },
      sidebar: "white",
      "blue-primary": "#0069BC",
      "blue-dark": "#0063D4",
      "blue-light": "#5BA8FF",
      "black-primary": "#1D242A",
      "light-gray": "#6D7D9326",
      "light-gray-text": "#00000066",
      // red: "#DB180C",
      // green: "#09D943",
    },
    fonts: {
      body: "Open Sans, sans-serif",
      heading: "Open Sans, sans-serif",
    },
    textStyles: {
      h1: { fontSize: ["28px", "64px"], fontWeight: "semibold" },
      h2: { fontSize: ["20px", "36px"], fontWeight: "semibold" },
      h3: { fontSize: ["16px", "28px"], fontWeight: "semibold" },
      h4: { fontSize: ["12px", "20px"], fontWeight: "semiblod" },
      h5: { fontSize: ["10px", "16px"], fontWeight: "semiblod" },
      h6: { fontSize: ["8px", "12px"], fontWeight: "light" },
    },
    styles: {
      global: () => ({
        body: {
          bg: "white",
        },
      }),
    },
    components: {
      Button,
      Card,
      Input,
      Text,
      Heading,
      Tabs,
      Tag,
      Checkbox,
      Tooltip: {
        baseStyle: {
          zIndex: 100110,
          borderRadius: "8px",
          px: "12px",
          py: "8px",
          bgColor: "gray.100",
          color: "primary.800",
          boxShadow:
            "0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08)",
          "--popper-arrow-bg": "#F2F4F7",
        },
      },
      NumberInput: {
        defaultProps: {
          errorBorderColor: "error.600",
        },
      },
      Textarea: {
        defaultProps: {
          errorBorderColor: "error.600",
        },
      },
    },
  }
  // withDefaultColorScheme({
  //   colorScheme: "primary",
  // })
);
