import { Center, CircularProgress, SimpleGrid, Stack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { RefObject, useEffect, useRef, useState } from "react";
import {
  MdOutlineAttachMoney,
  MdOutlineCurrencyExchange,
  MdOutlineMoving,
  MdOutlineSchedule,
} from "react-icons/md";
import { RiExchangeDollarLine } from "react-icons/ri";
import { VscGraphLine } from "react-icons/vsc";
import { useParams, useSearchParams } from "react-router-dom";
import { auctionTypes } from "src/utils/constants";

import { AuctionCard } from "../../../components/AuctionCard";
import NumberFormat from "../../../components/NumberFormat";
import { OrderModal } from "../../../components/OrderModal";
import { OrdersList } from "../../../components/OrdersList";
import { SpinnerButton } from "../../../components/SpinnerButton";
import useChosenOrdersStore from "../../../hooks/useChosenOrdersStore";
import { useNavigateSearch } from "../../../hooks/useNavigateSearch";
import { rLogin } from "../../../routes/routes";
import { AuthService } from "../../../services";
import {
  exitPromptStore,
  tenderStore,
  userAgreementStore,
} from "../../../stores";
import { CustomAlertModal, UserAgreementModal } from ".";

function TenderPage() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const { ordersStore } = useChosenOrdersStore();

  const [tick, setTick] = useState(0);

  useEffect(() => {
    const timerID = setInterval(() => setTick((tick) => tick + 1), 1000);

    return () => clearInterval(timerID);
  }, []);

  // used to scroll to send orders button when clicking on "orders not saved" chip
  const ordersButtonRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const emailQueryParam = searchParams.get("email");
    if (emailQueryParam) {
      if (emailQueryParam !== AuthService.getUserEmail()) {
        AuthService.resetAuthData();
        navigateSearch(rLogin, { email: emailQueryParam });
        return;
      }
    }
    tenderStore.getTenderMember(id ?? "");
  }, [id]);

  useEffect(() => {
    if (tenderStore.tender)
      ordersStore?.getOrdersList(tenderStore.tender.auction.id.toString());
  }, [tenderStore.tender?.auction.id, ordersStore]);

  useEffect(() => {
    exitPromptStore.handleUpdatedOrderListChange(
      ordersStore?.updatedOrdersList ?? false
    );
  }, [ordersStore?.updatedOrdersList]);

  useEffect(() => {
    if (
      tenderStore.tender &&
      !tenderStore.tender?.auction.has_started &&
      tenderStore.tender?.auction?.is_scheduled
    ) {
      const now = new Date();
      const then = new Date(tenderStore.tender?.auction.is_scheduled);
      if (now.getTime() > then.getTime()) {
        tenderStore.getTenderMember(id ?? "");
      }
    }
  }, [tick]);

  const is_upcoming = !tenderStore.tender?.auction.has_started;

  return (
    <Stack spacing={4}>
      <CustomAlertModal />
      <UserAgreementModal />
      <OrderModal />
      {tenderStore.loadingTender || !tenderStore.tender ? (
        <Center>
          <CircularProgress isIndeterminate />
        </Center>
      ) : (
        <SimpleGrid gap="8px" minChildWidth="280px">
          <AuctionCard
            Icon={MdOutlineSchedule}
            className="auctionEndTime"
            leading={tenderStore.countdownRemaining}
            subtitle={
              tenderStore.tender?.auction?.is_scheduled
                ? `Scheduled for ${new Date(tenderStore.tender?.auction?.is_scheduled).toLocaleString()}`
                : ""
            }
            title={
              tenderStore.tender?.auction.ends_at
                ? tenderStore.tenderEnded
                  ? "Status"
                  : "Auction Ends In"
                : "Duration"
            }
          />
          {tenderStore.auctionType === auctionTypes.default && (
            <AuctionCard
              Icon={MdOutlineMoving}
              subtitlePrefix="You started with"
              title="Share Balance"
              leading={
                <NumberFormat type="number" value={tenderStore.currentStocks} />
              }
              subtitle={
                <NumberFormat
                  type="number"
                  value={tenderStore.tender?.phantom_stocks}
                />
              }
              subtitleSuffix={
                tenderStore.tender!.phantom_stocks > 1 ? "shares" : "share"
              }
            />
          )}
          <AuctionCard
            Icon={MdOutlineAttachMoney}
            className="creditBalance"
            subtitlePrefix="You started with"
            title="Credit Balance"
            leading={
              <NumberFormat
                type="currency"
                value={tenderStore.currentBalance}
              />
            }
            subtitle={
              <NumberFormat
                type="currency"
                value={tenderStore.tender!.credit_balance}
              />
            }
            subtitleSuffix={
              Number.parseFloat(tenderStore.tender!.credit_balance) != 1
                ? "credits"
                : "credit"
            }
          />
          {tenderStore.auctionType === auctionTypes.dutch && (
            <AuctionCard
              Icon={RiExchangeDollarLine}
              className="startingPrice"
              subtitlePrefix="Auction minimum price per share is"
              title="Starting Price"
              leading={
                <NumberFormat
                  type="currency"
                  value={tenderStore.tender?.auction.starting_price_per_share}
                />
              }
              subtitle={
                <NumberFormat
                  type="currency"
                  value={tenderStore.tender?.auction.minimum_price_per_share}
                />
              }
            />
          )}
          {tenderStore.auctionType === auctionTypes.dutch && (
            <AuctionCard
              Icon={VscGraphLine}
              className="remainingShares"
              subtitlePrefix="Auction started with"
              subtitleSuffix=" shares"
              title="Remaining Shares"
              leading={
                <NumberFormat
                  type="number"
                  value={tenderStore.tender?.auction.remaining_shares}
                />
              }
              subtitle={
                <NumberFormat
                  type="number"
                  value={tenderStore.tender?.auction.total_shares}
                />
              }
            />
          )}
          {tenderStore.auctionType === auctionTypes.dutch && (
            <AuctionCard
              Icon={RiExchangeDollarLine}
              className="currentPrice"
              title={tenderStore.tenderEnded ? "Final price" : "Current Price"}
              leading={
                <NumberFormat
                  type="currency"
                  value={tenderStore.tender?.auction.current_price_per_share}
                />
              }
            />
          )}
          {tenderStore.auctionType === auctionTypes.default && (
            <AuctionCard
              Icon={MdOutlineCurrencyExchange}
              subtitle={`${tenderStore.tender?.auction.number_of_participants}`}
              subtitlePrefix="Number of participants in auction:"
              title="Buyback Amount"
              leading={
                <NumberFormat
                  type="currency"
                  value={tenderStore.tender?.auction.buyback_amount ?? 0}
                />
              }
            />
          )}
        </SimpleGrid>
      )}

      <Stack
        align="stretch"
        direction={{ base: "column", l: "row" }}
        flex={1}
        minW="full"
        spacing="8px"
      >
        {tenderStore.auctionType === auctionTypes.default && (
          <OrdersList sell scrollRef={ordersButtonRef} />
        )}
        <OrdersList buy scrollRef={ordersButtonRef} />
      </Stack>
      <Center ref={ordersButtonRef} pb="1%">
        {!(tenderStore.auctionType === auctionTypes.dutch) && (
          <SpinnerButton
            loading={ordersStore?.loading ?? true}
            w="50%"
            isDisabled={
              is_upcoming ||
              tenderStore.tenderEnded ||
              !ordersStore?.updatedOrdersList
            }
            onClick={() => {
              userAgreementStore.checkUserAgreement(() => {
                ordersStore?.saveOrdersList(tenderStore.tender?.auction.id);
              });
            }}
          >
            Send Orders
          </SpinnerButton>
        )}
      </Center>
    </Stack>
  );
}

export default observer(TenderPage);
