import {
  Box,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSubscribeSubscribeCreate } from "src/api/subscribe";
import { SpinnerButton } from "src/components/SpinnerButton";
import { BillingSelect } from "src/pages/Admin/components/BillingSelect";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";
import * as yup from "yup";

import { useCorporateRegistration } from "../../api/auth";
import { FormBackground } from "../../components/FormBackground";
import IRegisterFormModel from "../../models/IRegisterFormModel";

const registerSchema = yup.object({
  first_name: yup.string().required("Please enter your first name"),
  last_name: yup.string().required("Please enter your last name"),
  admin_email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  company_name: yup.string().required("Please enter your company name"),
  is_yearly: yup.boolean().optional(),
  agree_to_ts: yup
    .bool()
    .required("Please agree to terms and conditions")
    .oneOf([true], "Please agree to terms and conditions"),
});

const StartupRegisterPage = () => {
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [chosenPlanPrice, setChosenPlanPrice] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    getValues,
    setValue,
    watch,
  } = useForm<IRegisterFormModel>({
    resolver: yupResolver(registerSchema),
  });

  const { mutate, isLoading } = useCorporateRegistration({
    mutation: {
      onError: (err) => {
        if (!err.errors[0].detail) {
          toast.error("Error creating corporate");
        }
        formServerErrorsHandler(setError, getValues, err);
      },
      onSuccess: (data) => {
        toast.success("Corporate created successfully");
        createSubscription.mutate(
          {
            data: {
              companyUUID: data.company_uuid,
              plan_ix: chosenPlanPrice,
              is_yearly: getValues("is_yearly") ?? false,
            },
          },
          {
            onSuccess: (data) => {
              window.location.href = (data as unknown as { url: string }).url;
            },
          }
        );
        // navigate(
        //   rRegisterSuccess +
        //     `?email=${encodeURIComponent(getValues("admin_email"))}`
        // );
      },
    },
  });

  const createSubscription = useSubscribeSubscribeCreate({
    request: {
      transformRequest: (data, headers) => {
        delete headers.authorization;
        return JSON.stringify(data);
      },
    },
  });

  const onSubmitCompanyDetails = handleSubmit((data: IRegisterFormModel) => {
    if (chosenPlanPrice === "") {
      toast.error("Please select a plan");
      return;
    }
    mutate({ data });
    clearErrors();
  });

  useEffect(() => {
    const planQueryParam = searchParams.get("plan");
    const isYearlyQueryParam = searchParams.get("annual");
    if (planQueryParam) {
      setChosenPlanPrice(planQueryParam);
    }
    if (isYearlyQueryParam) {
      setValue("is_yearly", true);
    }
  }, [searchParams]);

  return (
    <FormBackground>
      <>
        <form noValidate onSubmit={onSubmitCompanyDetails}>
          <Stack gap="16px">
            <Center>
              <Heading
                color="gray.50"
                fontSize={16}
                fontWeight="semibold"
                mb="4px"
              >
                Please Fill out the form to Register your Startup!
              </Heading>
            </Center>
            <FormControl isInvalid={!!errors.first_name}>
              <FormLabel
                color="gray.50"
                fontSize={14}
                fontWeight={400}
                htmlFor="first_name"
              >
                First name
              </FormLabel>
              <Input
                id="first_name"
                placeholder="Enter your first name here"
                type="text"
                variant="borderless"
                {...register("first_name")}
              />
              <FormErrorMessage color="error.600">
                {errors.first_name && errors.first_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.last_name}>
              <FormLabel
                color="gray.50"
                fontSize={14}
                fontWeight={400}
                htmlFor="last_name"
              >
                Last name
              </FormLabel>
              <Input
                id="last_name"
                placeholder="Enter your last name here"
                type="text"
                variant="borderless"
                {...register("last_name")}
              />
              <FormErrorMessage color="error.600">
                {errors.last_name && errors.last_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.admin_email}>
              <FormLabel
                color="gray.50"
                fontSize={14}
                fontWeight={400}
                htmlFor="admin_email"
              >
                Email address
              </FormLabel>
              <Input
                id="admin_email"
                placeholder="Enter your email here"
                type="email"
                variant="borderless"
                {...register("admin_email")}
              />
              <FormErrorMessage color="error.600">
                {errors.admin_email && errors.admin_email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.company_name}>
              <FormLabel
                color="gray.50"
                fontSize={14}
                fontWeight={400}
                htmlFor="company_name"
              >
                Company name
              </FormLabel>
              <Input
                id="company_name"
                placeholder="Enter your company name here"
                type="text"
                variant="borderless"
                {...register("company_name")}
              />
              <FormErrorMessage color="error.600">
                {errors.company_name && errors.company_name.message}
              </FormErrorMessage>
            </FormControl>
            <Box bgColor="white" borderRadius="8px" color="black">
              <BillingSelect
                isYearly={watch("is_yearly") ?? false}
                mode="register"
                selectedValue={chosenPlanPrice}
                onChangeCallback={(option) => {
                  setChosenPlanPrice(option.value);
                }}
              />
            </Box>
            <FormControl isInvalid={!!errors.is_yearly}>
              <HStack>
                <Switch
                  checked={watch("is_yearly") ?? false}
                  cursor="pointer"
                  data-testid="is_yearly"
                  onChange={(e) => setValue("is_yearly", e.target.checked)}
                >
                  Annual billing
                </Switch>
              </HStack>
              <FormErrorMessage color="error.600" mt={0}>
                {errors.agree_to_ts && errors.agree_to_ts.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.agree_to_ts}>
              <HStack>
                <Checkbox
                  {...register("agree_to_ts")}
                  data-testid="agree_to_ts"
                />
                <FormLabel
                  color="gray.50"
                  fontWeight={400}
                  htmlFor="agree_to_ts"
                  mb={0}
                >
                  I agree with the{" "}
                  <a
                    href="/terms"
                    style={{ textDecoration: "underline" }}
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>
                </FormLabel>
              </HStack>
              <FormErrorMessage color="error.600" mt={0}>
                {errors.agree_to_ts && errors.agree_to_ts.message}
              </FormErrorMessage>
            </FormControl>
            <SpinnerButton
              data-testid="submit"
              loading={isLoading}
              mt="4px"
              type="submit"
              w="100%"
            >
              Register
            </SpinnerButton>
          </Stack>
        </form>
      </>
    </FormBackground>
  );
};

export default StartupRegisterPage;
