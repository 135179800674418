import {
  Box,
  CircularProgress,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";

import { useCompaniesRetrieve } from "../../api/companies";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { AuthService } from "../../services";
import CompanyProfileForm from "./components/CompanyProfileForm";
import CompanyProfileOnboarding from "./components/Onboardings/CompanyProfileOnboarding";

const AdminCompanyPage = () => {
  const { data, isRefetching } = useCompaniesRetrieve(
    AuthService.getUserCompany()
  );

  return (
    <Container maxH="100%" minW="100%">
      <Heading>
        Manage Profile
        {isRefetching && (
          <Text as="span" fontSize="sm" fontWeight="bold" ml={2}>
            <CircularProgress isIndeterminate mr={2} size="20px" />
            Loading profile
          </Text>
        )}
      </Heading>
      <Text color="gray.500" size="sm">
        Update and Maintain Company Information
      </Text>
      <Box pt="5">
        {data ? <CompanyProfileOnboarding /> : null}
        {data ? (
          <Box pt="5">
            <CompanyProfileForm />
          </Box>
        ) : (
          <LoadingSpinner />
        )}
      </Box>
    </Container>
  );
};

export default AdminCompanyPage;
