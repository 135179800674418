import { SystemStyleObject, useConst } from "@chakra-ui/react";

export const usePillTabsStyle = ({
  variant,
}: {
  variant?: "ghost" | "solid-selected" | "ghost-pricing";
}) => {
  const tabStyle: SystemStyleObject = useConst({
    w: "50%",
    borderWidth: "1px",
    fontSize: ["12px", "16px", "22px"],
    fontWeight: "700",
    lineHeight: "38px",
    LetterSpacing: "-0.02em",
  });
  const selectedTabStyle: SystemStyleObject = useConst({
    color: "white",
    bg: "primary.800",
  });
  if (variant === "ghost") {
    const tabStyle: SystemStyleObject = useConst({
      w: "50%",
      borderWidth: "1px",
      borderRight: "1",
      borderColor: "gray.300",
      fontSize: ["12px", "16px", "22px"],
      fontWeight: "600",
      lineHeight: "38px",
      LetterSpacing: "-0.02em",
      color: "gray.700",
    });
    const selectedTabStyle: SystemStyleObject = useConst({
      color: "#182230",
      bg: "gray.50",
    });
    return { tabStyle, selectedTabStyle };
  } else if (variant === "solid-selected") {
    const tabStyle: SystemStyleObject = useConst({
      w: "50%",
      borderWidth: "1px",
      borderRight: "1",
      borderColor: "gray.300",
      fontSize: ["12px", "16px", "22px"],
      fontWeight: "600",
      lineHeight: "38px",
      LetterSpacing: "-0.02em",
      color: "gray.700",
    });
    const selectedTabStyle: SystemStyleObject = useConst({
      color: "gray.25",
      bg: "primary.800",
    });
    return { tabStyle, selectedTabStyle };
  } else if (variant === "ghost-pricing") {
    const tabStyle: SystemStyleObject = useConst({
      w: "50%",
      borderWidth: "1px",
      borderRight: "1",
      borderColor: "gray.300",
      fontSize: ["12px", "16px", "22px"],
      fontWeight: "600",
      lineHeight: "38px",
      LetterSpacing: "-0.02em",
      color: "white",
    });
    const selectedTabStyle: SystemStyleObject = useConst({
      color: "#182230",
      bg: "gray.50",
    });
    return { tabStyle, selectedTabStyle };
  }
  return { tabStyle, selectedTabStyle };
};
