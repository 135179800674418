import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import { capitalize, concat } from "lodash";
import { useCallback, useEffect, useState } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import { HiOutlinePencil } from "react-icons/hi";
import { IoDocumentOutline } from "react-icons/io5";
import { MdClose, MdOutlineSave } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ExternalTemplateReferLink } from "src/components/ImportFormModal/ImportForm";
import TagsList from "src/components/Tags/TagsList";
import { usePillTabsStyle } from "src/hooks/usePillTabsStyle";
import { industries } from "src/utils/constants";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";
import { addHttps } from "src/utils/misc";
import { ToWords } from "to-words";
import * as yup from "yup";

import {
  getCompaniesRetrieveQueryKey,
  useCompaniesPartialUpdate,
  useCompaniesRetrieve,
  useImportCapTable,
} from "../../../api/companies";
import { Company } from "../../../api/model";
import { useShareholdersList } from "../../../api/shareholders";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { ControlWrapper } from "../../../components/ControlWrapper";
import { FSTIcon } from "../../../components/FSTIcon";
import NumberFormat from "../../../components/NumberFormat";
import { SpinnerButton } from "../../../components/SpinnerButton";
import { usePrettifyBytes } from "../../../hooks/usePrettyBytes";
import {
  rAdminCompanyBilling,
  rAdminCompanyData,
  rAdminCompanyDocuments,
  rAdminCompanyEquity,
  rAdminCompanyPreferences,
  rAdminUsers,
} from "../../../routes/routes";
import { AuthService } from "../../../services";
import CompanyBillingForm from "./CompanyBillingForm";
import CompanyDocumentsTable from "./CompanyDocumentsTable";
import CompanyExportData from "./CompanyExportData";
import CompanyCard from "./CompanyProfile/CompanyCard";

const toWords = new ToWords({ localeCode: "en-US" });

const companySchema = yup.object({
  linkedin: yup
    .string()
    .url("Please enter a valid url")
    .required("Please enter a LinkedIn link")
    .matches(/linkedin\.com/, "Please enter a valid LinkedIn link"),
});

const CompanyProfileForm = () => {
  const queryClient = useQueryClient();
  const prettifyBytes = usePrettifyBytes();
  const navigate = useNavigate();

  const { tabStyle, selectedTabStyle } = usePillTabsStyle({
    variant: "solid-selected",
  });

  const isAdmin = AuthService.getUserGroup()?.value === "Admin";

  const [isEditMainSection, setIsEditMainSection] = useState(false);
  const [isEditEquitySection, setIsEditEquitySection] = useState(false);
  const [isEditPreferencesSection, setIsEditPreferencesSection] =
    useState(false);

  const [acceptOneTimeEditForShares, setAcceptOneTimeEditForShares] =
    useState(false);
  const [capTableFile, setCapTableFile] = useState<null | File>(null);

  const [capTableUploadErrors, setCapTableUploadErrors] = useState<string[]>(
    []
  );

  const getIndexFromPath = (path: string) => {
    switch (path) {
      case rAdminCompanyData:
        return 4;
      case rAdminCompanyBilling:
        return 3;
      case rAdminCompanyDocuments:
        return 2;
      case rAdminCompanyPreferences:
        return 1;
      case rAdminCompanyEquity:
      default:
        return 0;
    }
  };
  const handleTabsChange = (index: number) => {
    if (index === 0) {
      onCancelPreferencesSection();
    }
    if (index === 1) {
      onCancelEquitySection();
    }
    if (index === 2) {
      onCancelPreferencesSection();
      onCancelEquitySection();
    }
    switch (index) {
      case 4:
        navigate(rAdminCompanyData);
        break;
      case 3:
        navigate(rAdminCompanyBilling);
        break;
      case 2:
        navigate(rAdminCompanyDocuments);
        break;
      case 1:
        navigate(rAdminCompanyPreferences);
        break;
      case 0:
      default:
        navigate(rAdminCompanyEquity, { replace: true });
        break;
    }
  };
  useEffect(() => {
    const index = getIndexFromPath(location.pathname);
    handleTabsChange(index);
    // setCurrentTab(index);
  }, [location.pathname]);

  const { data: companyData, isLoading: loading } = useCompaniesRetrieve(
    AuthService.getUserCompany()
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, inputRef } =
    useDropzone({
      accept: { "text/csv": [] },
      multiple: false,
      onDropAccepted: (acceptedFiles) => {
        setCapTableFile(acceptedFiles[0]);
        setShowCapTableUploadConfirmationDialog(true);
        setCapTableUploadErrors([]);
      },
      onDropRejected: (fileRejections) =>
        toast.error(fileRejections[0].errors[0].message),
    });

  const [
    showCapTableUploadConfirmationDialog,
    setShowCapTableUploadConfirmationDialog,
  ] = useState(false);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm<Company>({
    defaultValues: companyData,
    resolver: isEditPreferencesSection ? yupResolver(companySchema) : undefined,
    reValidateMode: "onBlur",
  });

  const formValues = getValues();

  const { data, isLoading: isLoadingSwitchList } = useShareholdersList();

  const { mutate } = useCompaniesPartialUpdate({
    mutation: {
      onError: (e) => {
        reset(companyData);
        formServerErrorsHandler(setError, getValues, e);
      },
      onSuccess: () => {
        toast.success("Updated company profile successfully");
        setIsEditMainSection(false);
        if (!isEditEquitySection) {
          setIsEditEquitySection(false);
        }
        setIsEditPreferencesSection(false);
        queryClient.invalidateQueries(
          getCompaniesRetrieveQueryKey(AuthService.getUserCompany())
        );
        setAcceptOneTimeEditForShares(false);
      },
    },
  });

  const importCAPTableMutation = useImportCapTable({
    mutation: {
      onError: (err) => {
        setShowCapTableUploadConfirmationDialog(false);
        if (err.type === "validation_error") {
          err.errors.forEach((error) => {
            if (error.attr.includes("csv_file.row")) {
              setCapTableUploadErrors((prev) => [
                ...prev,
                `Row ${error.detail} has errors`,
              ]);
            }
            if (error.attr.includes("csv_file.field_errors")) {
              // get column name from the attr after the last dot
              const columnName = error.attr.split(".").pop();
              if (error.code === "required") {
                setCapTableUploadErrors((prev) => [
                  ...prev,
                  `${columnName} column is required`,
                ]);
              }
              if (error.code === "invalid") {
                setCapTableUploadErrors((prev) => [
                  ...prev,
                  `${columnName}: ${error.detail}`,
                ]);
              }
            }
            if (error.attr === "csv_file") {
              setCapTableUploadErrors((prev) => [
                ...prev,
                `${error.detail}, please check the file formatting`,
              ]);
            }
          });
        }
      },
      onSuccess: (data) => {
        toast.success("CAP table uploaded successfully");
        setShowCapTableUploadConfirmationDialog(false);
        queryClient.invalidateQueries(
          getCompaniesRetrieveQueryKey(AuthService.getUserCompany())
        );
        reset(data);
      },
    },
  });

  const onSubmitMainSection = useCallback(
    (data: Company) => {
      let link = data.linkedin;
      if (link) {
        const prefix = "https://";
        const insecurePrefix = "http://";
        if (link.startsWith(prefix) || link.startsWith(insecurePrefix)) {
          link = link.replace(prefix, "").replace(insecurePrefix, "");
        } else {
          link = prefix + link;
        }
      }
      mutate({
        slug: companyData!.uuid,
        data: {
          title: data.title,
          description: data.description,
          linkedin: data.linkedin,
          total_shares: data.total_shares ? data.total_shares : undefined,
          industries: data.industries,
          visibility: data.visibility,
        },
      });
    },
    [errors, isEditMainSection]
  );

  const onUpload = (file: File) => {
    importCAPTableMutation.mutate({
      slug: companyData!.uuid,
      data: {
        csv_file: file,
      },
    });
  };

  const onCancelMainSection = useCallback(() => {
    setIsEditMainSection(!isEditMainSection);
    reset({ ...companyData });
  }, [isEditMainSection, companyData]);

  const onCancelEquitySection = useCallback(() => {
    setIsEditEquitySection(false);
    setAcceptOneTimeEditForShares(false);
    reset({ ...companyData });
  }, [isEditEquitySection, companyData]);

  const onCancelPreferencesSection = useCallback(() => {
    setIsEditPreferencesSection(false);
    reset({ ...companyData });
  }, [isEditPreferencesSection, companyData]);

  // const {
  //   isOpen: isVisibilityChangeConfirmationDialogOpen,
  //   onOpen: openVisibilityChangeConfirmationDialog,
  //   onClose: closeVisibilityChangeConfirmationDialog,
  // } = useDisclosure();

  const handlePublicPrivateChange = useCallback(() => {
    const oldVisibility = companyData?.visibility;
    const newVisibility = getValues("visibility");

    if (oldVisibility === newVisibility) {
      setValue("linkedin", addHttps(getValues("linkedin") ?? ""));
      return handleSubmit(onSubmitMainSection)();
    }
    if (oldVisibility !== newVisibility) {
      // DISABLE_MARKETPLACE
      // openVisibilityChangeConfirmationDialog();
    }
  }, [companyData, onSubmitMainSection]);

  useEffect(() => {
    if (companyData) {
      reset({
        uuid: companyData.uuid,
        title: companyData.title,
        description: companyData.description,
        linkedin: companyData.linkedin,
        industries: companyData.industries,
        total_shares: companyData.total_shares,
        visibility: companyData.visibility,
        shareholder_shares: companyData.shareholder_shares,
        company_shares: companyData.company_shares,
        has_cap_table: companyData.has_cap_table,
        profile_picture: companyData.profile_picture,
        slug: companyData.slug,
      });
    }
  }, [
    companyData?.title,
    companyData?.description,
    companyData?.linkedin,
    companyData?.industries,
    companyData?.total_shares,
    companyData?.visibility,
    companyData?.shareholder_shares,
    companyData?.company_shares,
    companyData?.has_cap_table,
    companyData?.profile_picture,
    companyData?.slug,
  ]);

  return (
    <Stack>
      <Stack spacing="24px">
        <Card className="adminSwitchProfile">
          <CardBody>
            <Stack direction={{ base: "column", md: "row" }} spacing="32px">
              <Text
                alignSelf="center"
                display="inline-block"
                mb="4px"
                variant="sm-semibold"
                w="280px"
              >
                You are currently signed in as:
              </Text>
              <Select
                isLoading={isLoadingSwitchList}
                chakraStyles={{
                  container: (provided) => ({
                    ...provided,
                    width: "full",
                  }),
                }}
                onChange={(newValue) => {
                  if (newValue) {
                    if (newValue.value === "Trader") {
                      AuthService.switchCompany("Trader", "");
                    }
                    const userGroup = data!.find(
                      (shareholder) => shareholder.uuid === newValue.value
                    )!.is_admin
                      ? "CorporateAdmin"
                      : "Trader";
                    AuthService.switchCompany(userGroup, newValue.value ?? "");
                  }
                }}
                options={
                  !data
                    ? []
                    : concat(
                        data.flatMap((shareholder) => {
                          if (!shareholder.is_admin) {
                            return [];
                          }
                          return {
                            label: `${shareholder.company} - ${
                              shareholder.is_admin ? "Admin" : "Trader"
                            }`,
                            value: shareholder.uuid,
                          };
                        }),
                        [
                          {
                            label: "Switch to Trader",
                            value: "Trader",
                          },
                        ]
                      )
                }
                value={{
                  label: `${companyData?.title} - ${isAdmin ? "Admin" : "Trader"}`,
                  value: companyData?.uuid,
                }}
              />
            </Stack>
          </CardBody>
        </Card>
        <CompanyCard
          companyId={AuthService.getUserCompany()}
          errors={errors}
          isEdit={isEditMainSection}
          isSubmitting={isSubmitting}
          linkedin={formValues?.linkedin ?? undefined}
          onCancel={onCancelMainSection}
          register={register}
          tags={formValues?.industries}
          toggleEdit={() => setIsEditMainSection(!isEditMainSection)}
          onSave={() => {
            handleSubmit(onSubmitMainSection)();
          }}
        />

        <Box pt="5">
          <Tabs
            index={getIndexFromPath(location.pathname)}
            onChange={handleTabsChange}
          >
            <TabList>
              <Tab className="sharesInfoTabIcon">Manage Equity</Tab>
              <Tab className="companyPreferencesTabIcon">
                Manage Preferences
              </Tab>
              <Tab className="companyDocumentsTabIcon">Manage Documents</Tab>
              <Tab className="companyBillingTabIcon">Billing</Tab>
              <Tab className="companyDataTabIcon">Data</Tab>
            </TabList>
            <TabIndicator
              bg="primary.800"
              borderRadius="1px"
              height="2px"
              mt="-1.5px"
            />
            <TabPanels>
              <TabPanel>
                <Card className="sharesInfoCard">
                  <CardBody as={Flex} direction="column" gap={8}>
                    <Box
                      alignSelf="flex-end"
                      display="flex"
                      gap={2}
                      hidden={!!companyData?.total_shares}
                    >
                      {isEditEquitySection ? (
                        <Box display="flex" gap={2}>
                          <Button
                            data-testid="manage-equity-cancel-button"
                            onClick={onCancelEquitySection}
                            variant="outline"
                          >
                            <MdClose size="24px" />
                          </Button>
                          <SpinnerButton
                            data-testid="manage-equity-save-button"
                            disabled={loading || isSubmitting}
                            loading={loading || isSubmitting}
                            onClick={handleSubmit(onSubmitMainSection)}
                            variant="solid"
                          >
                            <MdOutlineSave />
                          </SpinnerButton>
                        </Box>
                      ) : (
                        <Button
                          data-testid="manage-equity-edit-button"
                          variant="solid"
                          onClick={() =>
                            setIsEditEquitySection(!isEditEquitySection)
                          }
                        >
                          <HiOutlinePencil />
                        </Button>
                      )}
                    </Box>
                    <ControlWrapper
                      formLabel="Total company shares issued and outstanding"
                      infoTooltip="Cannot change shares info once set"
                      isInvalid={!!errors.total_shares}
                      errorMessage={
                        errors.total_shares && errors.total_shares.message
                      }
                    >
                      <Controller
                        control={control}
                        name="total_shares"
                        render={({ field: { value, onChange } }) => (
                          <Box>
                            <SimpleGrid
                              columns={{
                                base: 1,
                                lg: 2,
                              }}
                              gap={{
                                base: 2,
                                lg: 4,
                              }}
                            >
                              <Input
                                allowNegative={false}
                                as={CurrencyFormat}
                                autoComplete="off"
                                decimalScale={0}
                                id="total_shares"
                                thousandSeparator={true}
                                value={!value || isNaN(value) ? "" : value}
                                onValueChange={(values: Values) => {
                                  onChange(values.floatValue);
                                }}
                                placeholder={
                                  isEditEquitySection
                                    ? "Total number of shares"
                                    : "No number of shares provided"
                                }
                                textAlign={{
                                  base: "left",
                                  lg: isEditEquitySection ? "left" : "right",
                                }}
                                {...(isEditEquitySection &&
                                !formValues?.total_shares
                                  ? { isReadOnly: false }
                                  : {
                                      isReadOnly: true,
                                      border: "none",
                                      textAlign: value ? "right" : "left",
                                    })}
                                isDisabled={
                                  !formValues?.total_shares &&
                                  !acceptOneTimeEditForShares
                                }
                              />
                              <Text
                                {...(isEditEquitySection &&
                                !formValues?.total_shares
                                  ? {
                                      borderRadius: "8px",
                                      border: "1px solid",
                                      borderColor: "gray.100",
                                      px: "14px",
                                      py: "8px",
                                      minH: "24px",
                                      boxSizing: "content-box",
                                    }
                                  : {
                                      border: "none",
                                      textAlign: "left",
                                    })}
                                alignSelf="center"
                                data-testid="totalSharesInWords"
                              >
                                {!value || isNaN(value)
                                  ? ""
                                  : capitalize(toWords.convert(value))}
                              </Text>
                            </SimpleGrid>
                            {isEditEquitySection &&
                            !formValues?.total_shares ? (
                              <Checkbox
                                data-testid="acceptOneTimeEditForShares"
                                isChecked={acceptOneTimeEditForShares}
                                mt="8px"
                                variant="companyProfile"
                                onChange={(e) =>
                                  setAcceptOneTimeEditForShares(
                                    e.target.checked
                                  )
                                }
                              >
                                Total company shares can be set only once.
                                Please check the box to confirm.
                              </Checkbox>
                            ) : (
                              <></>
                            )}
                          </Box>
                        )}
                        rules={{
                          required:
                            isEditEquitySection && acceptOneTimeEditForShares,
                        }}
                      />
                    </ControlWrapper>

                    <Flex
                      align="center"
                      border="1px solid"
                      borderColor="gray.300"
                      borderRadius="8px"
                      gap={4}
                      justify="space-between"
                      p="16px"
                      wrap={{ base: "wrap", lg: "nowrap" }}
                    >
                      <Flex align="center" direction="row" gap={4}>
                        <Text variant="sm-semibold">
                          Total company shares issued and outstanding
                        </Text>
                        <Text
                          color="primary.800"
                          data-testid="manage-equity-total-shares"
                          variant="lg-semibold"
                        >
                          {formValues?.total_shares ? (
                            <NumberFormat
                              type="number"
                              value={formValues?.total_shares}
                            />
                          ) : (
                            "—"
                          )}
                        </Text>
                      </Flex>
                      <Text fontSize="2xl" scaleX={1} transform="auto">
                        =
                      </Text>
                      <Flex align="center" direction="row" gap={4}>
                        <Text variant="sm-semibold">
                          Stakeholders owned shares
                        </Text>
                        <Text
                          color="primary.800"
                          data-testid="manage-equity-shareholder-shares"
                          variant="lg-semibold"
                        >
                          <NumberFormat
                            type="number"
                            value={formValues.shareholder_shares}
                          />
                        </Text>
                      </Flex>
                      <Text fontSize="2xl">+</Text>
                      <Flex align="center" direction="row" gap={4}>
                        <Text variant="sm-semibold">Shares in treasury</Text>
                        <Text
                          color="primary.800"
                          data-testid="manage-equity-company-shares"
                          variant="lg-semibold"
                        >
                          {formValues?.company_shares ? (
                            <NumberFormat
                              type="number"
                              value={formValues?.company_shares}
                            />
                          ) : formValues?.company_shares === 0 ? (
                            <Text color="primary.800" variant="sm-semibold">
                              No shares in treasury
                            </Text>
                          ) : (
                            <Text>—</Text>
                          )}
                        </Text>
                      </Flex>
                    </Flex>

                    <Box className="userInformationCard">
                      {formValues?.has_cap_table && (
                        <Stack
                          align="center"
                          direction={["column", "row"]}
                          spacing={5}
                        >
                          <FormLabel fontWeight={600} w={["100%", "30%"]}>
                            CAP table
                          </FormLabel>
                          <Stack dir="row" w="100%">
                            <Flex
                              align="center"
                              border="1px solid"
                              borderColor="gray.300"
                              borderRadius="md"
                              data-testid="capTableInfo"
                              gap={3}
                              p={2}
                              w={{ base: "100%", md: "60%" }}
                            >
                              <FSTIcon
                                withRing
                                Icon={FiCheckCircle}
                                bg="success.100"
                                iconFill="success.600"
                                wrapperBg="success.50"
                              />
                              Uploaded
                              <Button
                                as={Link}
                                ml="auto"
                                size="sm"
                                to={rAdminUsers}
                                variant="outline"
                              >
                                Manage
                              </Button>
                            </Flex>
                          </Stack>
                        </Stack>
                      )}
                      <ConfirmationDialog
                        isLoading={importCAPTableMutation.isLoading}
                        show={showCapTableUploadConfirmationDialog}
                        body={
                          <>
                            <Box mb={4}>
                              <Text color="gray.500" fontSize={14}>
                                Please confirm your intent to upload this file.
                                Once uploaded, it cannot be deleted.
                              </Text>
                            </Box>

                            <Flex
                              border="1px solid"
                              borderColor="primary.500"
                              borderRadius={8}
                              p={4}
                            >
                              <Box mr={2}>
                                <FSTIcon
                                  withRing
                                  Icon={IoDocumentOutline}
                                  bg="primary.100"
                                  iconFill="blue.800"
                                  size={32}
                                  wrapperBg="primary.50"
                                />
                              </Box>
                              <Flex
                                alignItems="flex-start"
                                flexDirection="column"
                              >
                                <Text
                                  data-testid="capTableFileName"
                                  fontSize={14}
                                  fontWeight={600}
                                >
                                  {capTableFile?.name}
                                </Text>
                                <Text
                                  color="gray.500"
                                  fontSize={14}
                                  fontWeight={400}
                                >
                                  {capTableFile?.size
                                    ? prettifyBytes(capTableFile.size)
                                    : "-"}
                                </Text>
                              </Flex>
                            </Flex>
                          </>
                        }
                        handleClose={() =>
                          setShowCapTableUploadConfirmationDialog(false)
                        }
                        handleSubmit={() =>
                          capTableFile && onUpload(capTableFile)
                        }
                        title={
                          <>
                            <FSTIcon
                              withRing
                              Icon={FiCheckCircle}
                              bg="success.100"
                              iconFill="success.600"
                              wrapperBg="success.50"
                            />
                            <Spacer height={4} />
                            <Text>CAP Table Information</Text>
                          </>
                        }
                      />

                      <ControlWrapper
                        errorMessage={undefined}
                        hidden={formValues?.has_cap_table}
                        isInvalid={false}
                      >
                        <HStack spacing="32px">
                          <Text
                            display="inline-block"
                            mb="4px"
                            variant="sm-semibold"
                            w="fit-content"
                          >
                            CAP Table
                          </Text>
                          <Box margin="auto">
                            <input
                              {...getInputProps()}
                              id="cap_table"
                              placeholder="Information document"
                            />
                            <Stack
                              {...getRootProps()}
                              alignItems="center"
                              bg="gray.25"
                              border="1px dashed"
                              borderRadius="8px"
                              maxW="512px"
                              px="6"
                              py="4"
                              borderColor={
                                isDragAccept
                                  ? "primary.800"
                                  : capTableUploadErrors.length > 0
                                    ? "error.600"
                                    : isDragReject
                                      ? "error.600"
                                      : "gray.200"
                              }
                            >
                              <FSTIcon
                                withRing
                                Icon={AiOutlineCloudUpload}
                                bg="gray.100"
                                iconFill="gray.500"
                                wrapperBg="gray.50"
                              />
                              <Text
                                fontSize="sm"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Text
                                  as="span"
                                  color="primary.800"
                                  cursor="pointer"
                                  onClick={() => inputRef?.current?.click()}
                                  variant="sm-semibold"
                                >
                                  Click to upload CAP TABLE
                                </Text>{" "}
                                or drag and drop
                              </Text>
                              <ExternalTemplateReferLink
                                acceptedFileTypes={["CSV"]}
                                templateFileName="cap_table_template.csv"
                                text="CAP table template"
                              />
                            </Stack>
                            <Text
                              color="error.600"
                              fontSize="sm"
                              hidden={capTableUploadErrors.length === 0}
                              mb="4"
                              mt="2"
                              textAlign="center"
                            >
                              CSV file is incorrectly formatted. Please use the
                              template provided as a reference.
                            </Text>
                            {capTableUploadErrors.map((error, index) => (
                              <Text
                                key={index}
                                color="error.600"
                                variant="sm-semibold"
                              >
                                {error}
                              </Text>
                            ))}
                          </Box>
                        </HStack>
                      </ControlWrapper>
                    </Box>
                  </CardBody>
                </Card>
              </TabPanel>
              <TabPanel>
                {/* DISABLE_MARKETPLACE */}
                {/* <ConfirmationDialog
                  confirmText="Update"
                  handleClose={closeVisibilityChangeConfirmationDialog}
                  isLoading={loading || isSubmitting}
                  show={isVisibilityChangeConfirmationDialogOpen}
                  showCloseButton={false}
                  title="Company Indexing Preference Confirmation"
                  body={
                    <>
                      Are you sure you want to change your company indexing
                      preferences form{" "}
                      <Text as="b" color="primary.800">
                        {companyData?.visibility}
                      </Text>{" "}
                      to{" "}
                      <Text as="b" color="primary.800">
                        {getValues("visibility")}
                      </Text>
                      ?
                    </>
                  }
                  handleSubmit={() => {
                    handleSubmit(onSubmitMainSection)();
                    closeVisibilityChangeConfirmationDialog();
                  }}
                /> */}
                <Card>
                  <CardBody
                    as={Flex}
                    className="companyPreferences"
                    direction="column"
                    gap={8}
                  >
                    <Box alignSelf="flex-end" display="flex" gap={2}>
                      {isEditPreferencesSection ? (
                        <Box display="flex" gap={2}>
                          <Button
                            onClick={onCancelPreferencesSection}
                            variant="outline"
                          >
                            <MdClose size="24px" />
                          </Button>
                          <SpinnerButton
                            data-testid="edit-preferences-save-button"
                            disabled={loading || isSubmitting}
                            loading={loading || isSubmitting}
                            onClick={handlePublicPrivateChange}
                            variant="solid"
                          >
                            <MdOutlineSave />
                          </SpinnerButton>
                        </Box>
                      ) : (
                        <Button
                          data-testid="edit-preferences-button"
                          variant="solid"
                          onClick={() =>
                            setIsEditPreferencesSection(
                              !isEditPreferencesSection
                            )
                          }
                        >
                          <HiOutlinePencil />
                        </Button>
                      )}
                    </Box>
                    <ControlWrapper
                      hidden
                      formLabel="Company Indexing Preference"
                    >
                      <Controller
                        control={control}
                        name="visibility"
                        render={({ field: { onChange, value } }) => (
                          <Tabs
                            index={value === "private" ? 0 : 1}
                            variant="unstyled"
                            w="40%"
                            onChange={(index) => {
                              onChange(index === 0 ? "private" : "public");
                            }}
                          >
                            <TabList>
                              <Tab
                                _selected={{ ...selectedTabStyle }}
                                borderLeftRadius="8px"
                                borderRight="0"
                                data-testid="edit-preferences-private-index"
                                isDisabled={!isEditPreferencesSection}
                                sx={{
                                  ...tabStyle,
                                  lineHeight: "20px",
                                  fontSize: "15px",
                                  _disabled: {
                                    opacity: value === "private" ? 1 : 0.5,
                                    cursor: "not-allowed",
                                  },
                                }}
                              >
                                Private
                              </Tab>
                              <Tab
                                _selected={{ ...selectedTabStyle }}
                                borderLeft="0"
                                borderRightRadius="8px"
                                data-testid="edit-preferences-public-index"
                                isDisabled={!isEditPreferencesSection}
                                sx={{
                                  ...tabStyle,
                                  lineHeight: "20px",
                                  fontSize: "15px",
                                  _disabled: {
                                    opacity: value === "public" ? 1 : 0.5,
                                    cursor: "not-allowed",
                                  },
                                }}
                              >
                                Public
                              </Tab>
                            </TabList>
                          </Tabs>
                        )}
                      />
                    </ControlWrapper>
                    <ControlWrapper fullLine formLabel="Industries">
                      <Controller
                        control={control}
                        name="industries"
                        render={({ field: { onChange, value } }) => (
                          <TagsList
                            addButton={isEditPreferencesSection}
                            isCloseable={isEditPreferencesSection}
                            items={value ?? []}
                            options={industries}
                            closeHandler={(item) => {
                              onChange(value?.filter((i) => i !== item));
                            }}
                            onAdd={(items) => {
                              onChange(items);
                            }}
                          />
                        )}
                      />
                    </ControlWrapper>
                    <ControlWrapper
                      fullLine
                      errorMessage={errors.linkedin?.message}
                      formLabel="LinkedIn Link"
                      isInvalid={!!errors.linkedin}
                    >
                      <Controller
                        control={control}
                        name="linkedin"
                        render={({
                          field: { onChange: onChangeValue, value },
                        }) => (
                          <InputGroup>
                            <InputLeftElement
                              children={<BsLinkedin color="#0A66C2" />}
                              pointerEvents="none"
                            />
                            <Input
                              data-testid="edit-preferences-linkedin"
                              id="linkedin"
                              placeholder="https://linkedin.com/in/example"
                              type="text"
                              value={value ?? ""}
                              onChange={(e) => {
                                const link = e.target.value;
                                onChangeValue(link);
                                clearErrors("linkedin");
                              }}
                              {...(isEditPreferencesSection
                                ? { isReadOnly: false }
                                : { isReadOnly: true, border: "none" })}
                            />
                          </InputGroup>
                        )}
                      />
                    </ControlWrapper>
                  </CardBody>
                </Card>
              </TabPanel>
              <TabPanel className="companyDocumentsTab">
                <CompanyDocumentsTable />
              </TabPanel>
              <TabPanel className="companyBillingTab">
                <CompanyBillingForm />
              </TabPanel>
              <TabPanel className="companyDataTab">
                <CompanyExportData />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CompanyProfileForm;
