import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineEmail } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Reaptcha from "reaptcha";
import { AuthService } from "src/services";
import * as yup from "yup";

import { useLogin } from "../../api/auth";
import { rLoginSuccess } from "../../routes/routes";
import SpinnerButton from "../SpinnerButton/SpinnerButton";

const siteKey = import.meta.env.VITE_RECAPTCHA_KEY;
const captchaFlag = import.meta.env.VITE_CAPTCHA_FLAG === "true";

const loginSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
});

export type FormInputs = {
  email: string;
};

const LoginForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const captcha = useRef<Reaptcha>(null);

  let shownSessionEndToast = false;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
    setError,
  } = useForm<FormInputs>({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    const emailQueryParam = searchParams.get("email");
    const validToken = searchParams.get("valid");
    if (validToken && validToken === "false" && !shownSessionEndToast) {
      toast.error("Session ended please log in again");
      shownSessionEndToast = true;
      AuthService.resetAuthData();
    }
    if (emailQueryParam) setValue("email", emailQueryParam);
  }, [searchParams]);

  const authLoginMutation = useLogin({
    mutation: {
      onError: (e) => {
        if (e.type === "validation_error") {
          setError("email", { message: e.errors[0].detail });
        }
        if (e.errors[0].attr !== "non_field_errors") {
          toast.error("Error logging in");
        }
      },
      onSuccess: (_, { data: { email } }) => {
        navigate(rLoginSuccess, { state: { email } });
        reset();
        clearErrors();
      },
    },
  });

  const onSubmitVerified = handleSubmit((data: FormInputs) => {
    authLoginMutation.mutate({
      data: { ...data, redirectTo: searchParams.get("redirect_to") || "/" },
    });
  });

  const onSubmit = () => {
    if (captchaFlag) {
      captcha.current?.execute();
    } else {
      onSubmitVerified();
    }
  };

  const onVerify = () => {
    onSubmitVerified();
  };

  return (
    <>
      <FormControl id="email" isInvalid={!!errors.email}>
        <FormLabel>Email address</FormLabel>
        <InputGroup>
          <InputLeftElement
            children={<Icon as={MdOutlineEmail} color="gray.500" />}
            pointerEvents="none"
          />
          <Input
            bg={useColorModeValue("gray.100", "gray.600")}
            color={useColorModeValue("gray.800", "gray.200")}
            placeholder="you@email.com"
            rounded="lg"
            type="email"
            {...register("email")}
          />
        </InputGroup>
        {errors.email && (
          <FormErrorMessage color="error.600">
            {errors.email.message}
          </FormErrorMessage>
        )}
        <div className="d-flex justify-content-center">
          {captchaFlag && (
            <Reaptcha
              ref={captcha}
              onVerify={onVerify}
              sitekey={siteKey}
              size="invisible"
            />
          )}
        </div>
      </FormControl>
      <SpinnerButton
        data-testid="login-submit-button"
        loading={authLoginMutation.isLoading}
        onClick={onSubmit}
        rounded="md"
        variant="solid"
        width="100%"
      >
        Sign In
      </SpinnerButton>
    </>
  );
};

export default LoginForm;
