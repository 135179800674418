import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import { HiOutlineTag } from "react-icons/hi";
import { MdOutlineAdd } from "react-icons/md";
import { FSTIcon } from "src/components/FSTIcon";
import { SpinnerButton } from "src/components/SpinnerButton";
import { useColorFromToken } from "src/hooks/useColorFromToken";

import TagsList from "./TagsList";

type params = {
  list: string[];
  options: string[];
  onSubmit: ({ tags }: { tags: string[] }) => void;
  isLoading: boolean;
  text: [string, string];
  variant: string;
};

const TagsCreate = ({
  list,
  options,
  onSubmit,
  isLoading,
  text,
  variant,
}: params) => {
  const getColorFromToken = useColorFromToken();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { reset, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      tags: list,
    },
  });

  const handleClose = () => {
    onClose();
    reset();
    setValue("tags", list);
  };

  useEffect(() => {
    reset();
    setValue("tags", list);
  }, [list]);

  return (
    <>
      <Tag
        bg={getColorFromToken("gray.50")}
        borderRadius="full"
        cursor="pointer"
        mb={2}
        ml={1}
        onClick={onOpen}
        size="md"
        variant={variant}
        w="fit-content"
      >
        <MdOutlineAdd color={getColorFromToken("gray.500")} />
      </Tag>
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="3xl">
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>
            <FSTIcon withRing Icon={HiOutlineTag} />
            <Text mt="3">Add {text[1]}</Text>
          </ModalHeader>
          <ModalBody>
            <Stack spacing={5}>
              <Select
                placeholder={`Search for ${text[0]}`}
                value={null}
                chakraStyles={{
                  container: (provided) => ({
                    ...provided,
                    width: "full",
                  }),
                }}
                components={{
                  ValueContainer: ({ children, ...props }) => (
                    <Stack
                      direction="row"
                      spacing={2}
                      {...props}
                      align="center"
                    >
                      <FiSearch
                        style={{ marginLeft: "14px", marginRight: "8px" }}
                      />
                      {children}
                    </Stack>
                  ),
                }}
                onChange={(value) => {
                  if (value === null) return;
                  reset({
                    tags: [...getValues("tags"), value.value],
                  });
                }}
                options={options
                  .map((option) => ({
                    label: option,
                    value: option,
                  }))
                  .filter(
                    (option) => !getValues("tags").includes(option.value)
                  )}
              />
              <TagsList
                isCloseable
                items={getValues("tags")}
                variant="industry"
                closeHandler={(item) => {
                  reset({
                    tags: getValues("tags").filter((tag) => tag !== item),
                  });
                }}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack
              direction={{ base: "column-reverse", md: "row" }}
              spacing={5}
              w="100%"
            >
              <Button
                variant="outline"
                w="100%"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <SpinnerButton
                loading={isLoading}
                w="100%"
                onClick={() => {
                  handleSubmit(onSubmit)();
                  handleClose();
                }}
              >
                Update {text[1]}
              </SpinnerButton>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TagsCreate;
