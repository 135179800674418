import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formServerErrorsHandler } from "src/utils/formServerErrorsHandler";

import {
  getAuctionsMembersListQueryKey,
  getAuctionsRetrieveQueryKey,
  useAuctionsImportDutchAuctionMembersCreate,
  useAuctionsMembersCreate,
  useImportAuctionMembers,
  useImportAuctionMembersFromCapTable,
} from "../../../api/auctions";
import { getGetCompanyAuctionsQueryKey } from "../../../api/companies";
import { AuctionMemberPolymorphic } from "../../../api/model";
import { AuthService } from "../../../services";
import { auctionTypes } from "../../../utils/constants";
import { Modify } from "../../../utils/typescript-helpers";

export type IncludeAllFormInputs = {
  include_employees: boolean;
  include_investors: boolean;
};

export type CSVFormInputs = {
  csv_file: FileList;
};

export type SingleMemberFormInputs = Modify<
  AuctionMemberPolymorphic,
  {
    trader_id: {
      value: number;
      label: string;
    };
    credit_balance: number;
    phantom_stocks: number;
  }
>;

/**
 * Hook to handle the add auction members form
 * This hook is used in the AddAuctionMembers component
 * Which in turn is used in AddAuctionModal component (Stepper)
 * and used in AuctionDetails component (Add members button inside auction details page for the admin)
 *
 * Why this hook is implemented?
 * To abstract the logic of the form and its submission
 **/
export const useAddAuctionMembersHandler = ({
  auctionId,
  auctionType,
  handleClose,
}: {
  auctionType: string;
  auctionId: number;
  handleClose: () => void;
}) => {
  const { auction_id } = useParams();

  const isInsideAuction = auction_id !== undefined;

  const [addAuctionMemberMode, setAddAuctionMemberMode] = useState<
    "all" | "single" | "csv" | "include_all"
  >("all");

  const {
    formState: { errors: csvErrors },
    watch: csvWatch,
    reset: csvReset,
    clearErrors: csvClearErrors,
    register: csvRegister,
    setError: csvSetError,
  } = useForm<CSVFormInputs>();

  const {
    handleSubmit: singleMemberHandleSubmit,
    formState: { errors: singleMemberErrors },
    control: singleMemberControl,
    watch: singleMemberWatch,
    reset: singleMemberReset,
    clearErrors: singleMemberClearErrors,
    setValue: singleMemberSetValue,
    getValues: singleMemberGetValues,
    setError: singleMemberSetError,
  } = useForm<SingleMemberFormInputs>();

  const {
    control: includeAllControl,
    watch: includeAllWatch,
    reset: includeAllReset,
    formState: { errors: includeAllErrors },
  } = useForm<IncludeAllFormInputs>({
    defaultValues: {
      include_employees: false,
      include_investors: false,
    },
  });

  const queryClient = useQueryClient();

  const onModalClose = useCallback(() => {
    if (isInsideAuction) {
      queryClient.invalidateQueries(getAuctionsMembersListQueryKey(auctionId));
    } else {
      queryClient.invalidateQueries(
        getGetCompanyAuctionsQueryKey(AuthService.getUserCompany())
      );
    }
    csvClearErrors();
    csvReset();
    singleMemberClearErrors();
    singleMemberReset();
    includeAllReset();
    handleClose();
  }, []);

  // Listen to changes in the form to determine the add auction member mode (last step)
  // To disable the other forms when one is active
  useEffect(() => {
    const subscriptionSingle = singleMemberWatch((data) => {
      const isFormEmpty = Object.values(data).every(
        (fieldValue) => !fieldValue
      );
      if (isFormEmpty) {
        setAddAuctionMemberMode("all");
        singleMemberClearErrors();
      } else {
        setAddAuctionMemberMode("single");
      }
    });
    const subscriptionCSV = csvWatch((data) => {
      const isFormEmpty = Object.values(data).every(
        (fieldValue) => !fieldValue
      );
      if (isFormEmpty) {
        setAddAuctionMemberMode("all");
      } else {
        setAddAuctionMemberMode("csv");
      }
    });
    const subscriptionIncludeAll = includeAllWatch((data) => {
      if (data.include_employees || data.include_investors) {
        setAddAuctionMemberMode("include_all");
      } else {
        setAddAuctionMemberMode("all");
      }
    });

    return () => {
      subscriptionSingle.unsubscribe();
      subscriptionCSV.unsubscribe();
      subscriptionIncludeAll.unsubscribe();
    };
  }, [singleMemberWatch, csvWatch, includeAllWatch]);

  const importFromCapTableMutation = useImportAuctionMembersFromCapTable({
    mutation: {
      onSuccess: () => {
        toast.success("Members imported successfully");
        queryClient.invalidateQueries(getAuctionsRetrieveQueryKey(auctionId));
        onModalClose();
      },
    },
  });

  const importMembersFromCSVToDefaultAuctionMutation = useImportAuctionMembers({
    mutation: {
      onError: (err) => {
        if (err.type === "validation_error") {
          const errors: string[] = [];
          err.errors.forEach((error) => {
            if (error.attr.includes("csv_file.row")) {
              errors.push(`Row ${error.detail} has errors`);
            }
            if (error.attr.includes("csv_file.field_errors")) {
              // get column name from the attr after the last dot
              const columnName = error.attr.split(".").pop();
              if (error.code === "required") {
                errors.push(`${columnName} column is required`);
              }
              if (
                error.code === "invalid" ||
                (error.code as string) === "min_value"
              ) {
                errors.push(`${columnName}: ${error.detail}`);
              }
              if ((error.code as string) === "does_not_exist") {
                // extract email "from detail: Object with email=example1@email.com does not exist"
                const matches = error.detail.match(/email=([^ ]+)/);
                errors.push(
                  `The CSV had a an email for a user not in the cap table: ${matches?.[1]}`
                );
              }
            }
          });
          csvSetError("csv_file", {
            message: errors.join("\n"),
          });
        }
      },
      onSuccess: () => {
        toast.success("Members imported successfully");
        queryClient.invalidateQueries(getAuctionsRetrieveQueryKey(auctionId));
        onModalClose();
      },
    },
  });

  const importMembersFromCSVToDutchAuctionMutation =
    useAuctionsImportDutchAuctionMembersCreate({
      mutation: {
        onError: (err) => {
          if (err.type === "validation_error") {
            const errors: string[] = [];
            err.errors.forEach((error) => {
              if (error.attr.includes("csv_file.row")) {
                errors.push(`Row ${error.detail} has errors`);
              }
              if (error.attr.includes("csv_file.field_errors")) {
                // get column name from the attr after the last dot
                const columnName = error.attr.split(".").pop();
                if (error.code === "required") {
                  errors.push(`${columnName} column is required`);
                }
                if (
                  error.code === "invalid" ||
                  (error.code as string) === "min_value"
                ) {
                  errors.push(`${columnName}: ${error.detail}`);
                }
                if ((error.code as string) === "does_not_exist") {
                  // extract email "from detail: Object with email=example1@email.com does not exist"
                  const matches = error.detail.match(/email=([^ ]+)/);
                  errors.push(
                    `The CSV had a an email for a user not in the cap table: ${matches?.[1]}`
                  );
                }
              }
            });
            csvSetError("csv_file", {
              message: errors.join("\n"),
            });
          }
        },
        onSuccess: () => {
          toast.success("Members imported successfully");
          queryClient.invalidateQueries(getAuctionsRetrieveQueryKey(auctionId));
          onModalClose();
        },
      },
    });

  const addMembersMutation = useAuctionsMembersCreate({
    mutation: {
      onSuccess: () => {
        toast.success("Member added successfully");
        onModalClose();
      },
      onError: (error) => {
        formServerErrorsHandler(
          singleMemberSetError,
          singleMemberGetValues,
          error
        );
      },
    },
  });

  const onSubmitSingleMember = singleMemberHandleSubmit(
    (data: SingleMemberFormInputs) => {
      addMembersMutation.mutate({
        id: auctionId,
        data: {
          credit_balance: data.credit_balance.toString(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          phantom_stocks: data.phantom_stocks,
          trader_id: data.trader_id.value,
          resourcetype:
            auctionType === auctionTypes.default
              ? "DefaultAuctionMember"
              : "DutchAuctionMember",
        },
      });
    }
  );

  const onAddMembers = useCallback(() => {
    if (addAuctionMemberMode === "csv") {
      if (auctionType === auctionTypes.default) {
        importMembersFromCSVToDefaultAuctionMutation.mutate({
          id: auctionId,
          data: {
            csv_file: csvWatch("csv_file")[0],
          },
        });
      } else {
        importMembersFromCSVToDutchAuctionMutation.mutate({
          id: auctionId,
          data: {
            csv_file: csvWatch("csv_file")[0],
          },
        });
      }
    } else if (addAuctionMemberMode === "single") {
      onSubmitSingleMember();
    } else if (addAuctionMemberMode === "include_all") {
      importFromCapTableMutation.mutate({
        id: auctionId,
        data: {
          include_investors: includeAllWatch("include_investors"),
          include_employees: includeAllWatch("include_employees"),
        },
      });
    } else {
      onModalClose();
    }
  }, [addAuctionMemberMode]);

  return {
    addAuctionMemberMode,

    onAddMembers,

    isLoading:
      addMembersMutation.isLoading ||
      importFromCapTableMutation.isLoading ||
      importMembersFromCSVToDefaultAuctionMutation.isLoading,

    csvErrors,
    csvReset,
    csvClearErrors,
    csvRegister,
    singleMemberHandleSubmit,
    singleMemberReset,
    singleMemberClearErrors,
    singleMemberErrors,
    singleMemberControl,
    singleMemberSetValue,
    includeAllControl,
    includeAllReset,
    includeAllErrors,
  };
};
