import { Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import {
  // FiActivity,
  FiArrowUpRight,
  FiBriefcase,
  // FiDollarSign,
  // FiLock,
  FiZap,
} from "react-icons/fi";

import { FSTIcon } from "../../components/FSTIcon";

interface CardProps {
  heading: string;
  description: string;
  icon: JSX.Element;
}

const Card = ({ heading, description, icon }: CardProps) => {
  return (
    <VStack align="center" spacing="20px">
      {icon}
      <VStack gap="8px">
        <Heading as="h2" fontSize="xl" fontWeight="semibold" textAlign="center">
          {heading}
        </Heading>
        <Text color="gray.500" fontSize="md" textAlign="center">
          {description}
        </Text>
      </VStack>
    </VStack>
  );
};

export default function Features() {
  return (
    <VStack
      gap={["48px", "64px"]}
      px={["16px", null, "96px"]}
      py={["64px", null, "96px"]}
    >
      <VStack gap="12px">
        <Heading color="primary.800" fontSize="md">
          Features
        </Heading>
        <Heading color="gray.800" fontSize={["2xl", "4xl"]} textAlign="center">
          Elevate Your Fundraising Game
        </Heading>
      </VStack>

      <SimpleGrid
        columns={[1, null, 2, 3]}
        spacingX="32px"
        spacingY={["32px", "64px"]}
      >
        {/* <Card
          description="You decide - host auctions with full custody or opt for a non-custodial approach, putting you in control."
          heading="Custodial &amp; Non-Custodial Auctions"
          icon={
            <FSTIcon
              withRing
              Icon={FiActivity}
              aria-label="activity icon"
              iconFill="primary.800"
            />
          }
        /> */}
        <Card
          description="Tailor your fundraising style with versatile auction types - English, Dutch, and Seller Price."
          heading="English, Dutch &amp; Seller Price Auctions"
          icon={
            <FSTIcon
              withRing
              Icon={FiZap}
              aria-label="zap icon"
              iconFill="primary.800"
            />
          }
        />
        {/* <Card
          description="Experience ultimate flexibility and control with variable price auctions."
          heading="Variable Price Auctions"
          icon={
            <FSTIcon
              withRing
              Icon={FiDollarSign}
              aria-label="dollar sign icon"
              iconFill="primary.800"
            />
          }
        /> */}
        <Card
          description="Boost value and adaptability with seamlessly integrated share buyback options."
          heading="Built-In Share Buybacks"
          icon={
            <FSTIcon
              withRing
              Icon={FiArrowUpRight}
              aria-label="arrow up right icon"
              iconFill="primary.800"
            />
          }
        />
        <Card
          description="Maximize your earnings with a cost-effective cash-out feature."
          heading="Budget-Friendly Cash-Outs"
          icon={
            <FSTIcon
              withRing
              Icon={FiBriefcase}
              aria-label="briefcase icon"
              iconFill="primary.800"
            />
          }
        />
        {/* <Card
          description="Optimize equity control with streamlined CAP table management on FairStock.Trade."
          heading="Effortless CAP Table Management"
          icon={
            <FSTIcon
              withRing
              Icon={FiLock}
              aria-label="lock icon"
              iconFill="primary.800"
            />
          }
        /> */}
      </SimpleGrid>
    </VStack>
  );
}
