import {
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { isNaN } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import { HiOutlinePencil } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getAuctionsRetrieveQueryKey,
  useAuctionsDefaultAuctionPartialUpdate,
} from "../../../../api/auctions";
import {
  DefaultAuctionTyped,
  ResolutionVisibilityEnum,
  VisibilityEnum,
} from "../../../../api/model";
import { ControlWrapper } from "../../../../components/ControlWrapper";
import { FSTIcon } from "../../../../components/FSTIcon";
import { SpinnerButton } from "../../../../components/SpinnerButton";
import { usePillTabsStyle } from "../../../../hooks/usePillTabsStyle";
import { stringDurationToObj } from "../../../../utils/dateFormatter";
import Duration from "./Duration";

export type FormDefaultInputs = {
  title: string;
  buyback_amount: number;
  duration_days: string;
  duration_hours: string;
  duration_minutes: string;
  company_admin_id: number;
  info_document: FileList;
  admins_only: boolean;
  is_custodial: boolean;
  auctionVisibility: VisibilityEnum;
  transactionsVisibility: ResolutionVisibilityEnum;
};

type Props = {
  icon?: boolean;
  auctionData: DefaultAuctionTyped;
  onChange?: () => void;
};

const documentInfo =
  "This is an important document that should contain all relevant information for your investors and employees. It enables your investors to decide whether to buy or sell. For the company, this includes financial statements such as the balance sheet, income statement, profit and loss statement, and cash flow statement. It could also include a letter from management regarding the company's performance. The document might also detail the history of previous options and major events in terms of the company's capitalization table. It could list the largest shareholders on the cap table and significant events such as lawsuits, among other things.";

const EditSellerPrice = ({ icon, auctionData, onChange }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { tabStyle, selectedTabStyle } = usePillTabsStyle({ variant: "ghost" });
  const queryClient = useQueryClient();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => inputRef.current?.click();

  const [showFullText, setShowFullText] = useState(false);

  const truncatedDocumentInfo = useMemo(
    () => `${documentInfo.slice(0, 100)}...`,
    []
  );

  const { auction_id } = useParams();

  const editMutation = useAuctionsDefaultAuctionPartialUpdate({
    mutation: {
      onError: (err) => {
        if (!err.errors[0].detail) {
          toast.error("Cannot update auction");
        }
      },
      onSuccess: () => {
        if (auction_id) {
          queryClient.invalidateQueries(
            getAuctionsRetrieveQueryKey(Number.parseInt(auction_id ?? ""))
          );
        }
        toast.success("Auction updated successfully");
        handleClose();
        onChange && onChange();
      },
    },
  });

  const preloadedValues: DefaultValues<FormDefaultInputs> = useMemo(
    () => ({
      title: auctionData.title,
      buyback_amount: parseFloat(auctionData.buyback_amount),
      duration_days: stringDurationToObj(auctionData.duration).day.toString(),
      duration_hours: stringDurationToObj(auctionData.duration).hour.toString(),
      duration_minutes: stringDurationToObj(
        auctionData.duration
      ).minute.toString(),
      is_custodial: auctionData.is_custodial,
      auctionVisibility: auctionData.visibility,
      transactionsVisibility: auctionData.resolution_visibility,
      admins_only: auctionData.admins_only,
    }),
    [auctionData]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
    watch,
  } = useForm<FormDefaultInputs>({
    defaultValues: preloadedValues,
  });

  const onSubmit = useCallback(
    (data: FormDefaultInputs) => {
      const AuctionData = {
        ...data,
      };
      if (!auctionData.is_after_auction_end_date) {
        editMutation.mutate({
          id: auctionData.id,
          data: {
            ...AuctionData,
            info_document: AuctionData.info_document[0],
            buyback_amount: AuctionData.buyback_amount.toString(),
            duration: `${AuctionData.duration_days} ${AuctionData.duration_hours}:${AuctionData.duration_minutes}:00`,
            visibility: AuctionData.auctionVisibility,
            resolution_visibility: AuctionData.transactionsVisibility,
          },
        });
      }
    },
    [errors, onOpen, onClose]
  );

  const { ref, ...rest } = register("info_document", {}) as {
    ref: (instance: HTMLInputElement | null) => void;
  };

  const handleClose = useCallback(() => {
    onClose();
    reset();
    clearErrors();
  }, []);

  useEffect(() => {
    reset(preloadedValues);
  }, [auctionData]);

  return (
    <>
      {icon ? (
        <FSTIcon
          noBorder
          pointer
          Icon={HiOutlinePencil}
          onClick={onOpen}
          size={20}
        />
      ) : (
        <Button onClick={onOpen} variant="outline">
          Edit Auction
        </Button>
      )}
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="3xl">
        <ModalOverlay />
        <ModalContent
          p={8}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <ModalHeader p={0}>
            <Center fontSize="2xl">Edit Auction</Center>
            <Divider />
          </ModalHeader>
          <ModalBody>
            <Stack spacing={5}>
              <ControlWrapper
                fullLine
                errorMessage={errors.title && errors.title.message}
                formLabel="Auction title"
                isInvalid={errors.title ? true : false}
              >
                <Input
                  id="title"
                  placeholder="Title"
                  {...register("title", {
                    required: "Please enter auction title",
                  })}
                  isDisabled={auctionData.is_after_auction_end_date}
                />
              </ControlWrapper>

              <ControlWrapper
                fullLine
                formLabel="Buyback amount"
                isInvalid={errors.buyback_amount ? true : false}
                errorMessage={
                  errors.buyback_amount && errors.buyback_amount.message
                }
              >
                <Controller
                  control={control}
                  name="buyback_amount"
                  render={({ field: { value, onChange } }) => (
                    <InputGroup>
                      <InputLeftElement
                        children={<BsCurrencyDollar />}
                        pointerEvents="none"
                      />
                      <Input
                        allowNegative={false}
                        as={CurrencyFormat}
                        decimalScale={5}
                        decimalSeparator="."
                        isDisabled={auctionData.has_started}
                        thousandSeparator={true}
                        value={isNaN(value) ? "" : value}
                        onValueChange={(values: Values) => {
                          onChange(values.floatValue);
                        }}
                      />
                    </InputGroup>
                  )}
                  rules={{
                    required: "Please enter buyback amount",
                  }}
                />
              </ControlWrapper>
              <Duration
                control={control}
                errors={errors}
                has_started={auctionData.has_started}
                watch={watch}
              />
              <ControlWrapper
                fullLine
                formLabel="Info document"
                isInvalid={errors.info_document ? true : false}
                errorMessage={
                  errors.info_document && errors.info_document.message
                }
              >
                <Stack>
                  <InputGroup>
                    <Input
                      cursor="pointer"
                      id="info_document"
                      isDisabled={auctionData.is_after_auction_end_date}
                      placeholder="Information document"
                      pt="8px"
                      type="file"
                      sx={{
                        "::file-selector-button": {
                          display: "none",
                        },
                      }}
                      {...rest}
                      ref={(e) => {
                        ref(e);
                        inputRef.current = e;
                      }}
                    />
                    <InputRightElement mr="6px" width="fit-content">
                      <Button
                        isDisabled={auctionData.is_after_auction_end_date}
                        leftIcon={<AiOutlinePaperClip />}
                        onClick={handleClick}
                        size="sm"
                        variant="outline"
                      >
                        Browse
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {auctionData.info_document && (
                    <Link
                      isExternal
                      color="blue-primary"
                      href={auctionData.info_document}
                    >
                      view current info document
                    </Link>
                  )}
                </Stack>
                <Text color="gray.500" fontSize="sm">
                  {showFullText ? documentInfo : truncatedDocumentInfo}
                  {"  "}
                  <Button
                    colorScheme="blue"
                    fontSize="sm"
                    onClick={() => setShowFullText(!showFullText)}
                    variant="link"
                  >
                    {showFullText ? "Show less" : "Read more"}
                  </Button>
                </Text>
              </ControlWrapper>

              <Grid
                gap={5}
                templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
              >
                <GridItem hidden colSpan={2}>
                  <ControlWrapper
                    fullLine
                    formLabel="Auction Visibility"
                    isInvalid={errors.auctionVisibility ? true : false}
                    errorMessage={
                      errors.auctionVisibility &&
                      errors.auctionVisibility.message
                    }
                  >
                    <Controller
                      control={control}
                      name="auctionVisibility"
                      render={({ field: { onChange } }) => (
                        <Tabs
                          variant="unstyled"
                          onChange={(index) => {
                            onChange(index === 0 ? "private" : "public");
                          }}
                        >
                          <TabList>
                            <Tab
                              _selected={{ ...selectedTabStyle }}
                              borderLeftRadius="8px"
                              borderRight="0"
                              isDisabled={auctionData.is_after_auction_end_date}
                              sx={{
                                ...tabStyle,
                                lineHeight: "20px",
                                fontSize: "15px",
                              }}
                            >
                              Private
                            </Tab>
                            <Tab
                              _selected={{ ...selectedTabStyle }}
                              borderLeft="0"
                              borderRightRadius="8px"
                              isDisabled={auctionData.is_after_auction_end_date}
                              sx={{
                                ...tabStyle,
                                lineHeight: "20px",
                                fontSize: "15px",
                              }}
                            >
                              Public
                            </Tab>
                          </TabList>
                        </Tabs>
                      )}
                    />
                  </ControlWrapper>
                </GridItem>
                <GridItem colSpan={2}>
                  <ControlWrapper
                    fullLine
                    formLabel="Share Transaction Visibility"
                    isInvalid={errors.transactionsVisibility ? true : false}
                    errorMessage={
                      errors.transactionsVisibility &&
                      errors.transactionsVisibility.message
                    }
                  >
                    <Controller
                      control={control}
                      name="transactionsVisibility"
                      render={({ field: { onChange } }) => (
                        <Tabs
                          variant="unstyled"
                          onChange={(index) => {
                            onChange(index === 0 ? "members" : "admins");
                          }}
                        >
                          <TabList>
                            <Tab
                              _selected={{ ...selectedTabStyle }}
                              borderLeftRadius="8px"
                              borderRight="0"
                              isDisabled={auctionData.is_after_auction_end_date}
                              sx={{
                                ...tabStyle,
                                lineHeight: "20px",
                                fontSize: "15px",
                              }}
                            >
                              All invites
                            </Tab>
                            <Tab
                              _selected={{ ...selectedTabStyle }}
                              borderLeft="0"
                              borderRightRadius="8px"
                              isDisabled={auctionData.is_after_auction_end_date}
                              sx={{
                                ...tabStyle,
                                lineHeight: "20px",
                                fontSize: "15px",
                              }}
                            >
                              Admins only
                            </Tab>
                          </TabList>
                        </Tabs>
                      )}
                    />
                  </ControlWrapper>
                </GridItem>
              </Grid>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack direction={["column", "row"]} spacing={5} w="100%">
              <Button onClick={handleClose} variant="outline" w="100%">
                Cancel
              </Button>
              <SpinnerButton
                loading={editMutation.isLoading}
                onClick={handleSubmit(onSubmit)}
                type="submit"
                w="100%"
              >
                Update Auction
              </SpinnerButton>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditSellerPrice;
