import {
  Box,
  Button,
  Container,
  Heading,
  Link,
  Skeleton,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAuctionsRetrieve } from "../../api/auctions";
import { DefaultAuctionTyped, DutchAuctionTyped } from "../../api/model";
import { auctionTypes } from "../../utils/constants";
import AuctionDetails from "./components/AuctionDetails";
import EditDutch from "./components/auctionForms/EditDutch";
import EditSellerPrice from "./components/auctionForms/EditSellerPrice";
import AuctionSummary from "./components/AuctionSummary";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

// const stripePromise: Promise<Stripe | null> = Promise.resolve(null);

// DISABLE_FEES
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// if (!(window as any).Cypress) {
//   stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY ?? "");
// }

const AdminTenderDetailsPage = () => {
  const { auction_id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  const { data: auctionData } = useAuctionsRetrieve(
    Number.parseInt(auction_id ?? "")
  );

  useEffect(() => {
    if (auctionData?.is_after_auction_end_date && !auctionData?.has_concluded) {
      setTabIndex(0);
    }
  }, [auctionData?.has_concluded]);

  return (
    // DISABLE_FEES
    // <StripeProvider stripe={stripePromise}>
    <Container maxH="100%" minW="100%">
      <Stack align="baseline" direction={["column", "row"]} pb="5">
        <Heading>Auction Overview / {auctionData?.title}</Heading>
      </Stack>
      <Tabs
        isLazy
        index={tabIndex}
        onChange={(index) => {
          setTabIndex(index);
        }}
      >
        <Stack
          direction={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          spacing="4"
        >
          <Box>
            <TabList>
              <Tab>Auction Details</Tab>
              <Tab
                isDisabled={
                  auctionData?.resolution_status === "No Resolution" ||
                  ((auctionData?.is_after_auction_end_date &&
                    !auctionData?.has_concluded) ??
                    false)
                }
              >
                Auction Summary
              </Tab>
            </TabList>
            <TabIndicator
              bg="primary.800"
              borderRadius="1px"
              height="2px"
              mt="-1.5px"
            />
          </Box>
          <Stack direction={{ base: "column", md: "row" }} gap="2">
            <Skeleton hidden={!!auctionData} isLoaded={!!auctionData}>
              <Button variant="outline">Edit Auction</Button>
            </Skeleton>
            <Skeleton hidden={!!auctionData} isLoaded={!!auctionData}>
              <Button variant="outline">View Information Document</Button>
            </Skeleton>
            {auctionData?.auction_type === auctionTypes.default &&
              auctionData && (
                <EditSellerPrice
                  auctionData={auctionData as DefaultAuctionTyped}
                />
              )}
            {auctionData?.auction_type === auctionTypes.dutch && (
              <EditDutch auctionData={auctionData as DutchAuctionTyped} />
            )}
            {auctionData?.info_document && (
              <Button
                isExternal
                as={Link}
                href={auctionData?.info_document}
                variant="outline"
              >
                View Information Document
              </Button>
            )}
          </Stack>
        </Stack>

        <TabPanels>
          <TabPanel>
            <AuctionDetails
              onRunningAuction={() => {
                setTabIndex(1);
              }}
            />
          </TabPanel>
          <TabPanel>
            <AuctionSummary />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
    // </StripeProvider>
  );
};

export default observer(AdminTenderDetailsPage);
