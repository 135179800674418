import {
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { isNaN, isNumber } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import CurrencyFormat, { Values } from "react-currency-format";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";

import { useCompaniesRetrieve } from "../../../../api/companies";
import {
  ResolutionVisibilityEnum,
  VisibilityEnum,
} from "../../../../api/model";
import { ControlWrapper } from "../../../../components/ControlWrapper";
import NumberFormat from "../../../../components/NumberFormat";
import { usePillTabsStyle } from "../../../../hooks/usePillTabsStyle";
import { AuthService } from "../../../../services";
import Duration from "./Duration";

export type FormDutchInputs = {
  title: string;
  total_shares: number;
  minimum_price_per_share: number;
  starting_price_per_share: number;
  cycle_duration: number;
  decrease_rate: number;
  duration_days: string;
  duration_hours: string;
  duration_minutes: string;
  company_admin_id: number;
  info_document: FileList;
  admins_only: boolean;
  is_custodial: boolean;
  auctionVisibility: VisibilityEnum;
  transactionsVisibility: ResolutionVisibilityEnum;
};

type Props = {
  control: Control<FormDutchInputs>;
  errors: Partial<FieldErrorsImpl<FormDutchInputs>>;
  register: UseFormRegister<FormDutchInputs>;
  watch: UseFormWatch<FormDutchInputs>;
  values: UseFormGetValues<FormDutchInputs>;
  setValue: UseFormSetValue<FormDutchInputs>;
  setError: UseFormSetError<FormDutchInputs>;
  clearErrors: UseFormClearErrors<FormDutchInputs>;
};

const documentInfo =
  "This is an important document that should contain all relevant information for your investors and employees. It enables your investors to decide whether to buy or sell. For the company, this includes financial statements such as the balance sheet, income statement, profit and loss statement, and cash flow statement. It could also include a letter from management regarding the company's performance. The document might also detail the history of previous options and major events in terms of the company's capitalization table. It could list the largest shareholders on the cap table and significant events such as lawsuits, among other things.";

const DutchAuctionForm = ({
  control,
  errors,
  register,
  watch,
  values,
  setValue,
  setError,
  clearErrors,
}: Props) => {
  const { tabStyle, selectedTabStyle } = usePillTabsStyle({ variant: "ghost" });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => inputRef.current?.click();
  const { ref, ...rest } = register("info_document", {
    required: "Please upload auction info document",
  }) as {
    ref: (instance: HTMLInputElement | null) => void;
  };

  const [showFullText, setShowFullText] = useState(false);
  const [decreaseRate, setDecreaseRate] = useState(0);

  const truncatedDocumentInfo = useMemo(
    () => `${documentInfo.slice(0, 100)}...`,
    []
  );

  const { data: companyData } = useCompaniesRetrieve(
    AuthService.getUserCompany()
  );

  useEffect(() => {
    if (!companyData) return;
    const days = parseInt(values("duration_days"));
    const hours = parseInt(values("duration_hours"));
    const minutes = parseInt(values("duration_minutes"));
    const durationInMinutes = days * 24 * 60 + hours * 60 + minutes;
    let newValue =
      (values("starting_price_per_share") - values("minimum_price_per_share")) /
      (durationInMinutes / values("cycle_duration"));
    newValue = parseFloat(newValue.toPrecision(5));
    setDecreaseRate(newValue);
    setValue("decrease_rate", newValue);
  }, [
    watch("duration_days"),
    watch("duration_hours"),
    watch("duration_minutes"),
    watch("cycle_duration"),
    watch("starting_price_per_share"),
    watch("minimum_price_per_share"),
  ]);

  useEffect(() => {
    if (!isNumber(companyData?.total_shares)) {
      setError("total_shares", {
        type: "manual",
        message:
          "Please fill total company shares in company profile before stating auctioned shares",
      });
    } else {
      clearErrors("total_shares");
    }
  }, [companyData?.total_shares]);

  return (
    <Stack spacing={5}>
      <ControlWrapper
        fullLine
        errorMessage={errors.title && errors.title.message}
        formLabel="Auction title"
        isInvalid={errors.title ? true : false}
      >
        <Input
          id="title"
          placeholder="Title"
          {...register("title", { required: "Please enter auction title" })}
        />
      </ControlWrapper>
      <ControlWrapper
        fullLine
        errorMessage={errors.total_shares && errors.total_shares.message}
        formLabel="Auctioned shares"
        isInvalid={errors.total_shares ? true : false}
        helperText={
          <>
            {companyData?.company_shares ?? -1 > 0 ? (
              <Box hidden={!isNumber(companyData?.total_shares)}>
                Total shares in treasury is{" "}
                <NumberFormat
                  type="number"
                  value={companyData?.company_shares ?? 0}
                />{" "}
                and the percentage of auctioned shares in treasury are{" "}
                {isNaN(
                  watch("total_shares") / (companyData?.company_shares ?? 1)
                )
                  ? "0"
                  : parseFloat(
                      (
                        (watch("total_shares") /
                          (companyData?.company_shares ?? 1)) *
                        100
                      ).toPrecision(4)
                    )}
                %
              </Box>
            ) : (
              "There is no shares in treasury"
            )}
          </>
        }
      >
        <Controller
          control={control}
          name="total_shares"
          render={({ field: { value, onChange } }) => (
            <Tooltip
              label="This is the total number of shares that users who have been invited to the auction can purchase in the auction"
              maxWidth="500px"
              placement="top"
            >
              <InputGroup>
                <Input
                  allowNegative={false}
                  as={CurrencyFormat}
                  data-testid="total_shares"
                  decimalScale={0}
                  isDisabled={!isNumber(companyData?.total_shares)}
                  thousandSeparator={true}
                  value={isNaN(value) ? "" : value}
                  onValueChange={(values: Values) => {
                    onChange(values.floatValue);
                  }}
                />
              </InputGroup>
            </Tooltip>
          )}
          rules={{
            required: "Please enter Total Shares",
          }}
        />
      </ControlWrapper>
      <Grid
        gap={5}
        templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
      >
        <GridItem colSpan={2}>
          <ControlWrapper
            fullLine
            formLabel="Starting price"
            isInvalid={errors.starting_price_per_share ? true : false}
            errorMessage={
              errors.starting_price_per_share &&
              errors.starting_price_per_share.message
            }
            helperText={
              <>
                The value of the shares to be auctioned is{" "}
                <NumberFormat
                  type="currency"
                  value={
                    isNaN(
                      values("total_shares") *
                        values("starting_price_per_share")
                    )
                      ? 0
                      : values("total_shares") *
                        values("starting_price_per_share")
                  }
                />
              </>
            }
          >
            <Controller
              control={control}
              name="starting_price_per_share"
              render={({ field: { value, onChange } }) => (
                <Tooltip
                  label="This is the starting price per share then the price per share will decrease with each cycle"
                  placement="top"
                >
                  <InputGroup>
                    <InputLeftElement
                      children={<BsCurrencyDollar />}
                      pointerEvents="none"
                    />
                    <Input
                      allowNegative={false}
                      as={CurrencyFormat}
                      data-testid="starting_price_per_share"
                      decimalScale={5}
                      decimalSeparator="."
                      thousandSeparator={true}
                      value={isNaN(value) ? "" : value}
                      onValueChange={(values: Values) => {
                        onChange(values.value);
                      }}
                    />
                  </InputGroup>
                </Tooltip>
              )}
              rules={{
                required: "Please enter Starting Price",
              }}
            />
          </ControlWrapper>
        </GridItem>
        <GridItem colSpan={2}>
          <ControlWrapper
            fullLine
            formLabel="Reserve price"
            isInvalid={errors.minimum_price_per_share ? true : false}
            errorMessage={
              errors.minimum_price_per_share &&
              errors.minimum_price_per_share.message
            }
          >
            <Controller
              control={control}
              name="minimum_price_per_share"
              render={({ field: { value, onChange } }) => (
                <Tooltip
                  label={
                    "This is the minimum price per share you can't buy a share less than the minimum price per share"
                  }
                >
                  <InputGroup>
                    <InputLeftElement
                      children={<BsCurrencyDollar />}
                      pointerEvents="none"
                    />
                    <Input
                      allowNegative={false}
                      as={CurrencyFormat}
                      data-testid="minimum_price_per_share"
                      decimalScale={5}
                      decimalSeparator="."
                      thousandSeparator={true}
                      value={isNaN(value) ? "" : value}
                      onValueChange={(values: Values) => {
                        onChange(values.value);
                      }}
                    />
                  </InputGroup>
                </Tooltip>
              )}
              rules={{
                required: "Please enter reserve price",
                validate: (value) => {
                  const startingPrice = values("starting_price_per_share");
                  const current_reserve_price = parseFloat(value.toString());
                  const current_starting_price = parseFloat(
                    startingPrice.toString()
                  );
                  if (current_reserve_price > current_starting_price) {
                    return "Reserve price cannot be greater than starting price";
                  }
                  if (current_reserve_price === current_starting_price) {
                    return "Reserve price must be less than starting price";
                  }
                },
              }}
            />
          </ControlWrapper>
        </GridItem>
      </Grid>
      <Duration
        control={control}
        errors={errors}
        has_started={false}
        watch={watch}
      />
      <Grid
        gap={5}
        templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
      >
        <GridItem colSpan={{ sm: 2, md: 1 }}>
          <ControlWrapper
            fullLine
            formLabel="Decrease cycle"
            isInvalid={errors.cycle_duration ? true : false}
            errorMessage={
              errors.cycle_duration && errors.cycle_duration.message
            }
          >
            <Controller
              control={control}
              name="cycle_duration"
              render={({ field: { value, onChange } }) => (
                <InputGroup>
                  <Input
                    data-testid="cycle_duration"
                    maxLength={4}
                    placeholder="Enter cycle duration"
                    value={value}
                    onChange={(e) => {
                      let val = e.target.value;
                      val = val.replace(/\D/g, "");
                      onChange(val);
                    }}
                  />
                  <InputRightAddon children="minutes" bgColor="transparent" />
                </InputGroup>
              )}
              rules={{
                required: "Please enter cycle duration",
              }}
            />
          </ControlWrapper>
        </GridItem>
        <GridItem
          alignSelf={errors.cycle_duration ? "center" : "flex-end"}
          colSpan={{ sm: 2, md: 2 }}
          color="gray.500"
          fontSize="sm"
        >
          <Box>
            This means that after each{" "}
            {values("cycle_duration") ? values("cycle_duration") : "—"} minutes,
            the price will decline by{" "}
            {isFinite(decreaseRate) && decreaseRate !== 0 ? (
              <NumberFormat type="currency" value={decreaseRate} />
            ) : (
              "—"
            )}{" "}
            and the ending price would be{" "}
            {values("minimum_price_per_share") ? (
              <NumberFormat
                type="currency"
                value={values("minimum_price_per_share")}
              />
            ) : (
              "—"
            )}
          </Box>
        </GridItem>
      </Grid>

      <ControlWrapper
        fullLine
        errorMessage={errors.info_document && errors.info_document.message}
        formLabel="Info document"
        isInvalid={errors.info_document ? true : false}
      >
        <Stack>
          <InputGroup>
            <Input
              cursor="pointer"
              data-testid="info_document"
              id="info_document"
              placeholder="Information document"
              pt="8px"
              type="file"
              sx={{
                "::file-selector-button": {
                  display: "none",
                },
              }}
              {...rest}
              ref={(e) => {
                ref(e);
                inputRef.current = e;
              }}
            />
            <InputRightElement mr="6px" width="fit-content">
              <Button
                leftIcon={<AiOutlinePaperClip />}
                onClick={handleClick}
                size="sm"
                variant="outline"
              >
                Browse
              </Button>
            </InputRightElement>
          </InputGroup>
        </Stack>
        <Text color="gray.500" fontSize="sm">
          {showFullText ? documentInfo : truncatedDocumentInfo}
          {"  "}
          <Button
            colorScheme="blue"
            fontSize="sm"
            onClick={() => setShowFullText(!showFullText)}
            variant="link"
          >
            {showFullText ? "Show less" : "Read more"}
          </Button>
        </Text>
      </ControlWrapper>

      <Grid
        gap={5}
        templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
      >
        <GridItem hidden colSpan={2}>
          <ControlWrapper
            fullLine
            formLabel="Auction Visibility"
            isInvalid={errors.auctionVisibility ? true : false}
            errorMessage={
              errors.auctionVisibility && errors.auctionVisibility.message
            }
          >
            <Controller
              control={control}
              name="auctionVisibility"
              render={({ field: { onChange } }) => (
                <Tabs
                  variant="unstyled"
                  onChange={(index) => {
                    onChange(index === 0 ? "private" : "public");
                  }}
                >
                  <TabList>
                    <Tab
                      _selected={{ ...selectedTabStyle }}
                      borderLeftRadius="8px"
                      borderRight="0"
                      data-testid="private_visibility"
                      sx={{
                        ...tabStyle,
                        lineHeight: "20px",
                        fontSize: "15px",
                      }}
                    >
                      Private
                    </Tab>
                    <Tab
                      _selected={{ ...selectedTabStyle }}
                      borderLeft="0"
                      borderRightRadius="8px"
                      data-testid="public_visibility"
                      sx={{
                        ...tabStyle,
                        lineHeight: "20px",
                        fontSize: "15px",
                      }}
                    >
                      Public
                    </Tab>
                  </TabList>
                </Tabs>
              )}
            />
          </ControlWrapper>
        </GridItem>
        <GridItem colSpan={2}>
          <ControlWrapper
            fullLine
            formLabel="Share Transaction Visibility"
            isInvalid={errors.transactionsVisibility ? true : false}
            errorMessage={
              errors.transactionsVisibility &&
              errors.transactionsVisibility.message
            }
          >
            <Controller
              control={control}
              name="transactionsVisibility"
              render={({ field: { onChange } }) => (
                <Tabs
                  variant="unstyled"
                  onChange={(index) => {
                    onChange(index === 0 ? "members" : "admins");
                  }}
                >
                  <TabList>
                    <Tab
                      _selected={{ ...selectedTabStyle }}
                      borderLeftRadius="8px"
                      borderRight="0"
                      data-testid="all_invitees_see_transactions"
                      sx={{
                        ...tabStyle,
                        lineHeight: "20px",
                        fontSize: "15px",
                      }}
                    >
                      All invites
                    </Tab>
                    <Tab
                      _selected={{ ...selectedTabStyle }}
                      borderLeft="0"
                      borderRightRadius="8px"
                      data-testid="admins_only_see_transactions"
                      sx={{
                        ...tabStyle,
                        lineHeight: "20px",
                        fontSize: "15px",
                      }}
                    >
                      Admins only
                    </Tab>
                  </TabList>
                </Tabs>
              )}
            />
          </ControlWrapper>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default DutchAuctionForm;
