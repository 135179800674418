import { computed, makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";

import { tenderStore } from ".";

export default class UserAgreementStore {
  userAgreementToken = "agreed_to_";
  showModal = false;
  onAgree: () => void = () => undefined;
  constructor() {
    makeAutoObservable(this, {
      hasAgreed: computed,
    });
  }
  get hasAgreed(): boolean {
    return localStorage.getItem(
      this.userAgreementToken + tenderStore.tender?.id
    )
      ? true
      : false;
  }
  acceptUserAgreement = () => {
    localStorage.setItem(
      this.userAgreementToken + tenderStore.tender?.id,
      "true"
    );
    this.onAgree();
    runInAction(() => {
      this.showModal = false;
    });
  };
  showUserAgreementModal = () => {
    if (!this.hasAgreed) {
      runInAction(() => {
        this.showModal = true;
      });
    }
  };
  hideUserAgreementModal = () => {
    if (!this.hasAgreed) {
      toast.error("Please Accept User Agreement to be able to save orders");
      runInAction(() => {
        this.showModal = false;
      });
    }
  };
  checkUserAgreement = (onAgree: () => void) => {
    this.onAgree = onAgree;
    if (this.hasAgreed) {
      this.onAgree();
    } else {
      this.showUserAgreementModal();
    }
  };
}
