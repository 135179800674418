import { AuthService } from "src/services";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import {
  adminOnboardingJourneys,
  onboardingExtras,
  onboardingJourneys,
  onboardingKey,
  traderOnboardingJourneys,
} from "../utils/constants";
import AdminTenderStore from "./adminTenderStore";
import AdminUserStore from "./adminUsersStore";
import DutchOrdersStore from "./dutchOrdersStore";
import ExitPromptStore from "./exitPromptStore";
import OrdersStore from "./ordersStore";
import TenderStore from "./tenderStore";
import UserAgreementStore from "./userAgreementStore";

const tenderStore = new TenderStore();
const ordersStore = new OrdersStore();
const dutchOrdersStore = new DutchOrdersStore();
const userAgreementStore = new UserAgreementStore();
const exitPromptStore = new ExitPromptStore();
const adminUserStore = new AdminUserStore();
const adminTenderStore = new AdminTenderStore();

setInterval(tenderStore.countdown, 1000);

export type ChosenOrdersStore = OrdersStore | DutchOrdersStore;

const auctionOrderStores: Record<string, ChosenOrdersStore> = {};

auctionOrderStores["DefaultAuction"] = ordersStore;
auctionOrderStores["DutchAuction"] = dutchOrdersStore;

const defaultOrderStore = (auction_type: string): ChosenOrdersStore => {
  return auctionOrderStores[auction_type];
};

type journeysKeys = adminOnboardingJourneys | traderOnboardingJourneys;
type extraType = {
  [key in onboardingExtras]: boolean;
};

export type DBStore = {
  extra: extraType;
  subscriptions: {
    [key: string]: boolean;
  };
} & {
  [key in journeysKeys]: boolean;
};

export interface IOnboardingStore {
  journeys: {
    [key in journeysKeys]: boolean;
  };
  setJourneys: (journeys: {
    [key in journeysKeys]: boolean;
  }) => void;
  toggleJourney: (journey: journeysKeys, value?: boolean) => void;
  extra: extraType;
  toggleExtra: (extra: onboardingExtras, value?: boolean) => void;
  skipTutorial: (mode: "admin" | "trader" | "investor") => void;
  setStore: (store: DBStore) => void;
  inProgress: string;
  inJourney: boolean;
  setInProgress: (inProgress: string) => void;
  setInJourney: (inJourney: boolean) => void;
  subscriptions: { [key: string]: boolean };
  isCurrentCompanySubscribed: () => boolean;
  toggleCompanySubscription: (company: string, value: boolean) => void;
}

/*
 * Onboarding store
 * This store is used to track the onboarding progress of the user.
 * The state is refreshed from the backend by the setStore, called in the ProtectedRoute on page load.
 * It is persisted in the local storage.
 * Value true means the journey is completed.
 * The admin and trader objects are used to track the progress of the admin and trader onboarding respectively.
 * The toggleJourney function is used to toggle the progress of the onboarding journey.
 * We don't need to persist the inProgress value, so we exclude it from with the partialize option.
 */
export const useOnboardingStore = create<IOnboardingStore>()(
  persist(
    (set, get) => {
      const journeys = Object.values(onboardingJourneys);
      const journeyObject = journeys.reduce(
        (acc, journey) => ({ ...acc, [journey]: false }),
        {}
      );
      const extraObject = {
        [onboardingExtras.ADMIN_TUTORIAL]: false,
        [onboardingExtras.TRADER_TUTORIAL]: false,
        [onboardingExtras.INVESTOR_TUTORIAL]: false,
      };

      return {
        journeys: journeyObject,
        subscriptions: {},
        setJourneys: (journeys) => {
          set((state) => ({
            ...state,
            journeys,
          }));
        },
        toggleJourney: (journey, value) => {
          set((state) => ({
            ...state,
            journeys: {
              ...state.journeys,
              [journey]: value ?? !state.journeys[journey],
            },
          }));
        },
        extra: extraObject,
        toggleExtra: (extra, value) => {
          set((state) => ({
            ...state,
            extra: {
              ...state.extra,
              [extra]: value ?? !state.extra[extra],
            },
          }));
        },
        inProgress: "",
        setInProgress: (inProgress) => {
          set((state) => ({
            ...state,
            inProgress,
          }));
        },
        inJourney: false,
        setInJourney: (inJourney) => {
          set((state) => ({
            ...state,
            inJourney,
          }));
        },
        isCurrentCompanySubscribed: () => {
          const currentCompany = AuthService.getUserCompany();
          return (
            currentCompany &&
            Object.keys(get().subscriptions).includes(currentCompany) &&
            get().subscriptions[currentCompany]
          );
        },
        toggleCompanySubscription: (company: string, value: boolean) => {
          set((state) => ({
            ...state,
            subscriptions: {
              ...state.subscriptions,
              [company]: value,
            },
          }));
        },
        setStore: ({ extra: extra, subscriptions, ...store }) => {
          set(() => ({
            subscriptions: subscriptions,
            extra: {
              ...extraObject,
              ...(extra as extraType),
            },
            journeys: {
              ...journeyObject,
              ...(store as {
                [key in
                  | adminOnboardingJourneys
                  | traderOnboardingJourneys]: boolean;
              }),
            },
          }));
        },
      } as IOnboardingStore;
    },
    {
      name: onboardingKey,
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["inProgress", "inJourney"].includes(key)
          )
        ),
    }
  )
);

export {
  adminTenderStore,
  adminUserStore,
  defaultOrderStore,
  dutchOrdersStore,
  exitPromptStore,
  ordersStore,
  tenderStore,
  userAgreementStore,
};
