import IBidOrder from "./IBidOrder";

export default interface ISellOrder extends IBidOrder {
  quantity: number;
  price_per_unit: number;
  created?: string;
}

export function iSellOrderFactory(orders: ISellOrder[]) {
  for (const order in orders) {
    if (Object.prototype.hasOwnProperty.call(orders, order)) {
      orders[order].price_per_unit = Number.parseFloat(
        `${orders[order].price_per_unit}`
      );
    }
  }
  return orders;
}
