import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React from "react";

import { SpinnerButton } from "../SpinnerButton";

type ConfirmationDialogProps = {
  show: boolean;
  title: string | React.ReactNode;
  handleSubmit: () => void;
  handleClose: () => void;
  body: React.ReactNode;
  isLoading?: boolean;
  confirmText?: string;
  cancelText?: string;
  showCloseButton?: boolean;
  icon?: React.ReactNode;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  dangerAction?: boolean;
};

function ConfirmationDialog({
  show,
  title,
  handleSubmit,
  handleClose,
  body,
  showCloseButton = true,
  showCancelButton = true,
  showConfirmButton = true,
  isLoading = false,
  confirmText = "Confirm",
  cancelText = "Cancel",
  icon,
  dangerAction = false,
}: ConfirmationDialogProps) {
  return (
    <Modal isCentered isOpen={show} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-testid="confirmation-dialog-title">
          {icon ? icon : null}
          {title}
        </ModalHeader>
        <Divider />
        {showCloseButton && <ModalCloseButton />}
        <ModalBody>{body}</ModalBody>

        <ModalFooter justifyContent="center">
          <Stack direction={["column", "row"]} spacing={5} w="100%">
            {showCancelButton && (
              <Button
                data-testid="confirmation-dialog-cancel-button"
                onClick={handleClose}
                variant="outline"
                w="100%"
              >
                {cancelText}
              </Button>
            )}
            {showConfirmButton && (
              <SpinnerButton
                data-testid="confirmation-dialog-confirm-button"
                isDisabled={isLoading}
                loading={isLoading}
                onClick={handleSubmit}
                type="submit"
                variant={dangerAction ? "solid-danger" : "solid"}
                w="100%"
              >
                {confirmText}
              </SpinnerButton>
            )}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmationDialog;
