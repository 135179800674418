import { Box, Text } from "@chakra-ui/react";
import {
  chakraComponents,
  Props as SelectProps,
  Select,
} from "chakra-react-select";
import { useCallback } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { BiEnvelope } from "react-icons/bi";
import { withAsyncPaginate, wrapMenuList } from "react-select-async-paginate";

import { getCompanyCaptable } from "../../api/companies";
import { PaginatedShareholderList } from "../../api/model";
import { AuthService } from "../../services";
import { parseName } from "../../utils/misc";
import NumberFormat from "../NumberFormat";

interface UsersDropDownSelectProps<T extends FieldValues> {
  control: Control<T>;
  field_name: Path<T>;
  field_rules?: { [key: string]: unknown };
  emailAsValue?: boolean;
  onChangeSideEffect?: (val: Option) => void;
  showSelectedDetails?: boolean;
}

interface Option {
  label: string;
  value: string | number;
  name: string;
  credit_balance: string;
  shares_balance: number;
}

const ChakraAsyncPaginate = withAsyncPaginate(Select);
// wrapper around chakra-ui MenuList to preserve style with the menu portal from react-select-async-paginate
const MenuList = wrapMenuList(chakraComponents.MenuList);

/**
 *
 * @param showSelectedDetails show the selected user details under the dropdown, used in the manual transaction modals
 */
function UsersDropDownSelect<T extends FieldValues>({
  control,
  field_name,
  field_rules = {},
  emailAsValue = false,
  onChangeSideEffect,
  showSelectedDetails = false,
  ...props
}: UsersDropDownSelectProps<T> & SelectProps) {
  const company_id = AuthService.getUserCompany();
  const loadOptions = useCallback(
    async (search: string, _loadedOptions: unknown, meta: unknown) => {
      const { page } = meta as { page: number };

      const data: PaginatedShareholderList = await getCompanyCaptable(
        company_id,
        {
          page,
          search,
        }
      );

      const {
        results,
        pages,
        current_page: currentPage,
      } = data as PaginatedShareholderList;

      const hasMore = currentPage < pages;
      const options = results.map((el) => ({
        // NOTE: when backend is refactored to accept shareholders change id
        value: emailAsValue ? el.trader?.email : (el.trader?.id as number),
        label: el.trader?.email ?? "",
        name:
          el.trader?.first_name && el.trader?.last_name
            ? parseName({
                first_name: el.trader.first_name,
                last_name: el.trader.last_name,
              })
            : "",
        credit_balance: el.credit_balance,
        shares_balance: el.shares,
      }));

      return {
        options,
        hasMore,
        additional: {
          page: currentPage + 1,
        },
      };
    },
    []
  );

  return (
    <Controller
      control={control}
      name={field_name}
      rules={field_rules}
      render={({ field: { value, onChange } }) => (
        <>
          <ChakraAsyncPaginate
            useBasicStyles
            additional={{ page: 1 }}
            debounceTimeout={300}
            loadOptions={loadOptions}
            value={value}
            chakraStyles={{
              // space between the icon and the text
              valueContainer: (provided) => ({
                ...provided,
                paddingLeft: "32px",
              }),
            }}
            components={{
              MenuList,
              ValueContainer: ({ children, ...props }) => (
                <chakraComponents.ValueContainer {...props}>
                  <Box left="2" position="absolute">
                    <BiEnvelope size="20px" />
                  </Box>
                  {children}
                </chakraComponents.ValueContainer>
              ),
              Option: ({ children, ...props }) => (
                <chakraComponents.Option {...props}>
                  <p
                    title={(props.data as Option).name}
                    style={{
                      width: "100%",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {children}
                    {!!(props.data as Option).name && (
                      <Text
                        as="span"
                        color={props.isSelected ? "white" : "gray.400"}
                        ml="1"
                        whiteSpace="nowrap"
                      >
                        &lt;{(props.data as Option).name}&gt;
                      </Text>
                    )}
                  </p>
                </chakraComponents.Option>
              ),
            }}
            onChange={(val) => {
              onChangeSideEffect?.(val as Option);
              return onChange(val);
            }}
            {...props}
            isMulti={false}
          />
          {showSelectedDetails && (value as Option)?.value && (
            <Text color="primary.800" variant="sm-regular">
              Credit Balance:{" "}
              <NumberFormat
                type="currency"
                value={(value as Option).credit_balance}
              />{" "}
              Share Balance:{" "}
              <NumberFormat
                type="number"
                value={(value as Option).shares_balance}
              />
            </Text>
          )}
        </>
      )}
    />
  );
}

export default UsersDropDownSelect;
