import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const companyProfile = definePartsStyle({
  control: defineStyle({
    borderRadius: "4px",
    borderWidth: "1px",
    padding: "2px",
    width: "16px",
    height: "16px",
  }),
  label: defineStyle({
    fontFamily: "Open Sans, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  }),
});

export const Checkbox = defineMultiStyleConfig({
  baseStyle: {
    control: {
      _checked: {
        bg: "primary.800",
        borderColor: "primary.800",
        color: "white",
        _hover: {
          bg: "primary.800",
          borderColor: "primary.800",
        },
      },
    },
  },
  variants: { companyProfile },
});
