import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Link as ChakraLink,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { AiOutlinePaperClip } from "react-icons/ai";

import { ControlWrapper } from "../ControlWrapper";

export type FormInputs = {
  csv_file: FileList;
};

type TemplateLinks = {
  [key: string]: string;
};

export const templateLinks: TemplateLinks = {
  "auction_invites_template.csv":
    "https://docs.google.com/spreadsheets/d/16OGv3DHzdHHy48bURXVjGfAKcYyM9EC4CY7Ig2iT4Es/edit?usp=drive_link",
  "non_custodial_auction_invites_template.csv":
    "https://docs.google.com/spreadsheets/d/1XzYa1I_4UHjkAskSCUbrKJFFlwbutkOGrWufsofe_GE/edit?usp=drive_link",
  "cap_table_template.csv":
    "https://docs.google.com/spreadsheets/d/17fJo-40JphiMf3jqljkD7fGbNhhgAXfHX1mVq6t5dXU/edit?usp=drive_link",
  "bulk_credit_transactions.csv":
    "https://docs.google.com/spreadsheets/d/1s1BFS99sPeZuyUkei9B2dK0MuM2eUaR5C88cMloxWhY/edit?usp=drive_link",
};

type ImportFormProps = {
  register: UseFormRegister<FormInputs>;
  errors: Partial<FieldErrorsImpl<FormInputs>>;
  hasLabel?: boolean;
  isDisabled?: boolean;
  fade?: boolean;
  templateFileName?: string;
  acceptedFileTypes?: string[];
};

export const ExternalTemplateReferLink = ({
  templateFileName,
  acceptedFileTypes,
  text = "file template",
}: {
  templateFileName: string;
  acceptedFileTypes?: string[];
  text?: string;
}) => {
  return (
    <Text color="gray.500" fontSize="xs" onClick={(e) => e.stopPropagation()}>
      {acceptedFileTypes && acceptedFileTypes.length > 0 ? (
        <>
          Accepted file types for uploading are:{" "}
          <Text as="span" color="primary.800">
            {acceptedFileTypes.join(", ")}
          </Text>
          .<br />
        </>
      ) : null}
      Refer to the{" "}
      <ChakraLink
        color="primary.800"
        fontWeight="semibold"
        href={templateLinks[templateFileName]}
        isExternal={templateLinks[templateFileName].includes("http")}
      >
        {text}
      </ChakraLink>{" "}
      for more details
    </Text>
  );
};

function ImportForm({
  register,
  errors,
  hasLabel = false,
  isDisabled = false,
  fade = false,
  templateFileName = "",
  acceptedFileTypes,
}: ImportFormProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => inputRef.current?.click();
  const { ref, ...rest } = register("csv_file", {
    required: "Please upload auction info document",
  }) as {
    ref: (instance: HTMLInputElement | null) => void;
  };
  return (
    <Stack opacity={fade ? 0.5 : 1} spacing={1}>
      <ControlWrapper
        errorMessage={errors.csv_file && errors.csv_file.message}
        formLabel={hasLabel ? "File to import:" : undefined}
        isInvalid={errors.csv_file ? true : false}
      >
        <InputGroup>
          <Input
            id="csv_file"
            isDisabled={isDisabled}
            placeholder="Upload file"
            // {...register("info_document", {
            //   required: "Please upload auction info document",
            // })}
            {...rest}
            ref={(e) => {
              ref(e);
              inputRef.current = e;
            }}
            cursor="pointer"
            pt="8px"
            type="file"
            sx={{
              "::file-selector-button": {
                display: "none",
              },
            }}
          />
          <InputRightElement mr="6px" width="fit-content">
            <Button
              isDisabled={isDisabled}
              leftIcon={<AiOutlinePaperClip />}
              onClick={handleClick}
              size="sm"
              variant="outline"
            >
              Browse
            </Button>
          </InputRightElement>
        </InputGroup>
      </ControlWrapper>
      {templateFileName ? (
        <ExternalTemplateReferLink
          acceptedFileTypes={acceptedFileTypes}
          templateFileName={templateFileName}
        />
      ) : null}
    </Stack>
  );
}

export default ImportForm;
