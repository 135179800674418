import {
  Avatar,
  Box,
  Divider,
  Fade,
  Flex,
  Grid,
  Hide,
  Show,
  SlideFade,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import {
  MdOutlineAttachMoney,
  MdOutlineHome,
  MdOutlineLogout,
  MdOutlinePeopleOutline,
  MdOutlineSettings,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuthLogoutCreate } from "src/api/auth";

import {
  rAdminAuctionsActive,
  rAdminAuctionsDraft,
  rAdminAuctionsEnded,
  rAdminCompany,
  rAdminCompanyBilling,
  rAdminCompanyData,
  rAdminCompanyDocuments,
  rAdminCompanyEquity,
  rAdminCompanyPreferences,
  rAdminEditHistory,
  rAdminTenderDetails,
  rAdminTenders,
  rAdminTransactions,
  rAdminUsers,
  rRoot,
  rTender,
  rTenders,
  rTraderTransactions,
  rTraderUser,
} from "../../routes/routes";
import { AuthService } from "../../services";
import { exitPromptStore, useOnboardingStore } from "../../stores";
import { FSTIcon } from "../FSTIcon";
import FSTLogo from "../icons/FSTLogo";
import NavItem from "./NavItem";
import { SidebarContentProps } from "./SidebarContentProps";

interface LinkItemProps {
  name: string;
  icon: IconType;
  route: string;
  highlightRoutes?: string[];
  id: string;
}

const TraderLinkItems: Array<LinkItemProps> = [
  {
    name: "Auction Overview",
    icon: MdOutlineHome,
    route: rTenders,
    id: "auctions",
    highlightRoutes: [rTender],
  },
  {
    name: "Transactions",
    icon: MdOutlineAttachMoney,
    route: rTraderTransactions,
    id: "transactions",
  },
  {
    name: "Manage Profile",
    icon: MdOutlineSettings,
    route: rTraderUser,
    id: "profile",
  },
  // { name: "PAYMENT", icon: BsFillCreditCardFill },
];

const InvestorLinkItems: Array<LinkItemProps> = [
  {
    name: "Auction Overview",
    icon: MdOutlineHome,
    route: rTenders,
    id: "auctions",
  },
  {
    name: "Transactions",
    icon: MdOutlineAttachMoney,
    route: rTraderTransactions,
    id: "transactions",
  },
  // DISABLE_MARKETPLACE
  // {
  //   name: "Browse Companies",
  //   icon: MdOutlineCorporateFare,
  //   route: rInvestorBrowseCompanies,
  //   highlightRoutes: [rInvestorCompany],
  //   id: "companies",
  // },
  // {
  //   name: "Browse Auctions",
  //   icon: MdOutlineMoving,
  //   route: rInvestorBrowseAuctions,
  //   id: "auctions",
  // },
  {
    name: "Manage Profile",
    icon: MdOutlineSettings,
    route: rTraderUser,
    id: "profile",
  },
];

const AdminLinkItems: Array<LinkItemProps> = [
  {
    name: "Auctions Overview",
    icon: MdOutlineHome,
    route: rAdminTenders,
    id: "auctions",
    highlightRoutes: [
      rAdminAuctionsDraft,
      rAdminAuctionsActive,
      rAdminAuctionsEnded,
      rAdminTenderDetails,
    ],
  },
  {
    name: "Transactions",
    icon: MdOutlineAttachMoney,
    route: rAdminTransactions,
    id: "transactions",
  },
  {
    name: "Manage Users",
    icon: MdOutlinePeopleOutline,
    route: rAdminUsers,
    id: "users",
    highlightRoutes: [rAdminUsers, rAdminEditHistory],
  },
  {
    name: "Manage Profile",
    icon: MdOutlineSettings,
    route: rAdminCompany,
    id: "profile",
    highlightRoutes: [
      rAdminCompanyPreferences,
      rAdminCompanyEquity,
      rAdminCompanyDocuments,
      rAdminCompanyBilling,
      rAdminCompanyData,
    ],
  },
];

const SidebarContent = ({
  onClose,
  isCollapsed,
  setCollapsed,
  isAdmin,
  noItems,
  ...rest
}: SidebarContentProps) => {
  const userEmail = AuthService.getUserEmail();
  const userName = AuthService.getUserName();
  const navigate = useNavigate();
  const isInvestor = AuthService.getUserGroup()?.key === "Investor";

  const { subscriptions, isCurrentCompanySubscribed } = useOnboardingStore();

  const [shouldBlur, setShouldBlur] = useState(false);

  const { mutate: logoutByApi } = useAuthLogoutCreate({
    mutation: {
      onSuccess: () => {
        AuthService.resetAuthData();
        navigate(rRoot);
      },
      onError: () => {
        AuthService.resetAuthData();
        navigate(rRoot);
      },
      meta: {
        ignore_errors: true,
      },
    },
  });

  const logoutCallback = (e: React.SyntheticEvent) => {
    exitPromptStore.confirmPrompt((res) => {
      if (res) {
        e.preventDefault();
        logoutByApi();
      }
    });
  };
  const goToRoot = () => {
    exitPromptStore.confirmPrompt((res) => {
      if (res) {
        navigate(rRoot);
      }
    });
  };

  const sidebarBackground = useBreakpointValue({
    base: "primary.0",
    md: "blue.900",
  });

  const contentColor = useBreakpointValue({
    base: "blue.900",
    md: "primary.0",
  });

  useEffect(() => {
    if (isAdmin && !isCurrentCompanySubscribed()) {
      setShouldBlur(true);
    } else {
      setShouldBlur(false);
    }
  }, [subscriptions, isCurrentCompanySubscribed, isAdmin]);

  return (
    <Flex
      bg={sidebarBackground}
      borderRight="1px"
      borderRightColor="gray.200"
      direction="column"
      h="full"
      overflow="clip"
      pos="fixed"
      px={isCollapsed ? 2 : 4}
      transition="0.5s ease"
      w={{ base: "full", md: isCollapsed ? 14 : 64 }}
      className={
        !isCollapsed ? "sidebar-content sidebar-collapsed" : "sidebar-content"
      }
      {...rest}
    >
      <Flex
        alignItems="center"
        h="20"
        justifyContent="center"
        transition="0.5s ease"
      >
        <SlideFade
          unmountOnExit
          in={!isCollapsed}
          offsetX={-8}
          offsetY={0}
          style={{ width: "100%" }}
          transition={{ enter: { duration: 0.25 }, exit: { duration: 0.25 } }}
        >
          {!isCollapsed && (
            <Flex
              alignItems="center"
              className={!isCollapsed ? "sidebar-collapsed-header" : ""}
              cursor="pointer"
              gap={2}
              justify="space-between"
            >
              <Flex alignItems="center" gap={2} onClick={goToRoot}>
                <FSTLogo fill={contentColor} />
                <Text color={contentColor} fontSize="xl">
                  <b>FairStock</b>.Trade
                </Text>
              </Flex>
              <Show above="md">
                <FSTIcon
                  noBorder
                  pointer
                  Icon={isCollapsed ? FiArrowRight : FiArrowLeft}
                  iconFill={contentColor}
                  onClick={setCollapsed}
                />
              </Show>
              <Hide above="md">
                <FSTIcon
                  noBorder
                  pointer
                  Icon={FiArrowLeft}
                  iconFill={contentColor}
                  onClick={onClose}
                  size={24}
                />
              </Hide>
            </Flex>
          )}
        </SlideFade>
        <Fade
          unmountOnExit
          in={isCollapsed}
          transition={{ enter: { delay: 0.2, duration: 0.25 } }}
        >
          <FSTLogo fill={contentColor} />
        </Fade>
      </Flex>

      {isCollapsed && (
        <Show above="md">
          <Box my={4} {...(setCollapsed ? { onClick: setCollapsed } : {})}>
            <FSTIcon
              noBorder
              pointer
              Icon={isCollapsed ? AiOutlineArrowRight : AiOutlineArrowLeft}
              iconFill={contentColor}
              size={24}
            />
          </Box>
        </Show>
      )}

      <Flex direction="column">
        {[
          ...(isAdmin
            ? AdminLinkItems
            : noItems
              ? []
              : isInvestor
                ? InvestorLinkItems
                : TraderLinkItems),
        ].map((link) => (
          <NavItem
            key={link.name}
            icon={link.icon}
            id={`sidebar-${link.id}`}
            isCollapsed={isCollapsed}
            route={link.route}
            {...(link.highlightRoutes
              ? { highlightRoutes: link.highlightRoutes }
              : {})}
            className={`sidebar-item-${link.name.toLowerCase().replace(" ", "-")}`}
            onClick={onClose}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            style={
              shouldBlur && !(link.id === "profile")
                ? { filter: "blur(3px)", userSelect: "none", cursor: "default" }
                : {}
            }
            {...(shouldBlur && !(link.id === "profile")
              ? { pointerEvents: "none" }
              : {})}
          >
            {link.name}
          </NavItem>
        ))}
      </Flex>
      <Spacer />

      <Hide below="md">
        <Flex
          alignItems="center"
          color="white"
          justifySelf="flex-end"
          mb={10}
          visibility={userEmail ? "visible" : "hidden"}
        >
          {!isCollapsed && (
            <Flex
              alignItems="center"
              gap={2}
              justifyContent="space-between"
              w="100%"
            >
              <Box alignItems="center" display="flex">
                <Avatar size="sm" />

                <Grid marginStart="2">
                  <Text
                    color={contentColor}
                    fontSize={14}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {userName}
                  </Text>
                  <Tooltip label={userEmail} placement="bottom">
                    <Text
                      color={contentColor}
                      data-testid="sidebar-user-email"
                      fontSize={14}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      title={userEmail}
                      whiteSpace="nowrap"
                    >
                      {userEmail}
                    </Text>
                  </Tooltip>
                </Grid>
              </Box>

              <FSTIcon
                noBorder
                pointer
                Icon={MdOutlineLogout}
                iconFill={contentColor}
                onClick={logoutCallback}
                size={24}
              />
            </Flex>
          )}

          {isCollapsed && (
            <VStack alignItems="center" w="100%">
              <FSTIcon
                noBorder
                pointer
                Icon={MdOutlineLogout}
                iconFill={contentColor}
                onClick={logoutCallback}
                size={24}
              />
              <Divider my={4} />
              <Tooltip
                placement="bottom"
                label={
                  <Stack>
                    <Text color={contentColor} fontSize={14}>
                      {userName}
                    </Text>
                    <Text color={contentColor} fontSize={14}>
                      {userEmail}
                    </Text>
                  </Stack>
                }
              >
                <Avatar size="xs" />
              </Tooltip>
            </VStack>
          )}
        </Flex>
      </Hide>
    </Flex>
  );
};

export default SidebarContent;
