import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { toast } from "react-toastify";

import { FormInputs } from "../components/ImportFormModal/ImportFormModal";
import IAdminUserModel from "../models/IAdminUserModel";
import { ApiService } from "../services";

export default class AdminUserStore {
  users: IAdminUserModel[] = [];
  originalUsers: IAdminUserModel[] = [];
  loadingUsersList = false;
  loadingCreateUser = false;
  loadingDeleteUser = false;
  loadingRegister = false;
  loadingImportingUsers = false;

  constructor() {
    makeAutoObservable(this, {
      users: observable,
      loadingUsersList: observable,
      loadingCreateUser: observable,
      loadingDeleteUser: observable,
      loadingRegister: observable,
      loadingImportingUsers: observable,
      getAllUsers: action,
      importUsers: action,
      getAdmins: computed,
      getTraders: computed,
    });
  }

  getAllUsers = async () => {
    runInAction(() => {
      this.loadingUsersList = true;
    });
    const axios = ApiService.getInstance();
    axios
      .get<IAdminUserModel[]>("/users/")
      .then((data) => {
        runInAction(() => {
          this.users = data.data;
          this.originalUsers = data.data;
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        runInAction(() => {
          this.loadingUsersList = false;
        });
      });
  };

  importUsers = async (data: FormInputs, callback: () => void) => {
    runInAction(() => {
      this.loadingImportingUsers = true;
    });
    const axios = ApiService.getInstance();
    const formData = new FormData();
    formData.append("csv_file", data.csv_file[0]);
    axios
      .post("/users/import/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        toast.success("Users imported successfully");
        this.getAllUsers();
        callback();
      })
      .catch((err) => {
        if (err.response.data) {
          if (err.response.data.csv_file) {
            toast.error(err.response.data.csv_file[0]);
          } else if (typeof err.response.data[0] === "string") {
            toast.error(err.response.data[0]);
          } else {
            toast.error(
              "Wrong CSV format, accepted headers are trader, first_name, last_name"
            );
          }
        } else {
          toast.error("Error importing auction members");
        }
      })
      .finally(() => {
        runInAction(() => {
          this.loadingImportingUsers = false;
        });
      });
  };

  get getAdmins() {
    const admins = this.users
      .filter((user) => user.is_staff)
      .map((admin) => ({
        value: admin.id,
        label: admin.email,
      }));
    return admins;
  }

  get getTraders() {
    const traders = this.users
      .filter((user) => !user.is_staff)
      .map((admin) => ({
        value: admin.id,
        label: admin.email,
      }));
    return traders;
  }
}
