import { Center, HStack, Spacer, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { rInvestorRegister } from "src/routes/routes";

import FSTLogo from "../../components/icons/FSTLogo";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Stack bg="primary.900" gap="40px" pb="8px" pt="64px" px="24px">
      <Stack direction={["column", "row"]} mx="64px">
        <HStack justify="center" py="12px">
          <FSTLogo fill="primary.25" />
          <Text color="primary.25" fontSize="md" fontWeight="bold">
            FairStock.Trade
          </Text>
        </HStack>
        <Spacer />
        <Stack align={["center", "start"]} gap="16px">
          {/* TODO: add links */}
          <Text color="white" fontSize="sm" fontWeight="semibold">
            About
          </Text>
          <Stack align={["center", "start"]} gap="8px">
            <Text
              as="a"
              color="white"
              fontSize="sm"
              href="/terms.html"
              rel="noreferrer"
              target="_blank"
            >
              Terms &amp; Conditions
            </Text>
            <Text
              as="a"
              color="white"
              fontSize="sm"
              href="/privacy.html"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </Text>
            <Text
              as="a"
              color="white"
              cursor="pointer"
              fontSize="sm"
              href={rInvestorRegister}
              onClick={() => {
                navigate(rInvestorRegister);
              }}
            >
              Invest
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Center py="16px">
        <Text color="white" fontSize="sm" textAlign="center">
          Copyright &copy; 2024 FairStock.Trade
        </Text>
      </Center>
    </Stack>
  );
}
