import { Box, Center, Flex, Stack, Text } from "@chakra-ui/react";

import bg from "/png/bg.png";

type FormBackgroundProps = {
  children: JSX.Element;
};

const FormBackground = ({ children }: FormBackgroundProps) => {
  return (
    <Flex
      align="center"
      bg="primary.900"
      bgImage={`url(${bg})`}
      bgSize="cover"
      justify="center"
      minH="100vh"
      width="100%"
    >
      <Box bg="#6D7D9326" p="32px" rounded="lg">
        <Stack color="gray.50" mx="auto" px="auto" py="auto" spacing="8px">
          <Center>
            <Text color="white" fontSize="2xl">
              <Text as="b" color="white">
                FairStock.
              </Text>
              Trade
            </Text>
          </Center>
          {children}
        </Stack>
      </Box>
    </Flex>
  );
};

export default FormBackground;
