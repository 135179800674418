import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

// export variants in the component theme
export const Card = defineMultiStyleConfig({
  variants: {
    elevated: definePartsStyle({
      container: {
        boxShadow: "4px 4px 20px -10px #0000001A",
        borderRadius: "8px",
        backgroundColor: "white",
      },
    }),
  },
});
