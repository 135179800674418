import "@splidejs/react-splide/css/core";

import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Image,
  Link as ChakraLink,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import adminView from "/png/admin_view.png";
import userView from "/png/user_view.png";

import { rStartupRegister } from "../../routes/routes";

export default function Hero() {
  const cards = [
    { title: "admin view", image: adminView },
    {
      title: "user view",
      image: userView,
    },
  ];

  const hideButtons = useBreakpointValue({ ssr: false, base: true, md: false });

  return (
    <VStack align="center" gap="48px" justify="center">
      <VStack gap="16px" px={["32px", null, null, null, "96px"]}>
        <Heading
          color="gray.200"
          fontSize="20px"
          fontWeight="semibold"
          hidden={hideButtons ?? false}
        >
          {/* Empower Your Equity Journey */}
        </Heading>
        <Heading
          alignSelf="center"
          color="gray.100"
          fontSize={["2xl", null, "40px"]}
          textAlign="center"
        >
          A simple and affordable platform for fundraising and share buybacks
        </Heading>
        <Text
          align="center"
          color="gray.200"
          fontSize={["lg", "20px"]}
          maxW="3xl"
        >
          Design and hold an online auction for your shares whenever you want to
          purchase or sell equity. Simply invite auction participants, upload
          the company information you wish to share with them, and let our
          software calculate the share price and share amounts sold.
        </Text>
      </VStack>
      <Button
        isExternal
        as={ChakraLink}
        href={rStartupRegister}
        variant="solid"
      >
        Register Your Company
      </Button>
      <Box mt={["24px", null, "48px"]} px="32px">
        <Splide
          aria-label="system views"
          hasTrack={false}
          options={{
            type: "loop",
            autoplay: true,
            speed: 1000,
            pagination: false,
          }}
        >
          <HStack gap="32px">
            <Box className="splide__arrows" hidden={hideButtons}>
              <IconButton
                aria-label="left-arrow"
                bg="#6D7D9326"
                className="splide__arrow splide__arrow--prev"
                color="gray.200"
              >
                <FiArrowLeft />
              </IconButton>
            </Box>

            <Box maxW="3xl" minW="xs">
              <SplideTrack>
                {cards.map((value, index) => (
                  <SplideSlide key={index}>
                    <Stack gap="32px">
                      <Text
                        align="center"
                        casing="uppercase"
                        color="gray.200"
                        fontSize="xl"
                        fontWeight="semibold"
                      >
                        {value.title}
                      </Text>
                      <Image src={value.image} />
                    </Stack>
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Box>
            <Box className="splide__arrows" hidden={hideButtons}>
              <IconButton
                aria-label="right-arrow"
                bg="#6D7D9326"
                className="splide__arrow splide__arrow--next"
                color="gray.200"
              >
                <FiArrowRight />
              </IconButton>
            </Box>
          </HStack>
        </Splide>
      </Box>
    </VStack>
  );
}
